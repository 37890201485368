import { connect } from 'react-redux';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Makes from '../../components/vehicle/Makes';
import { getPromptGroupVal } from '../../utils/journey-data';

const mapStateToProps = ({ journeyData }, { promptGroupName, promptGroupIndex }) => {
  const year = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_YEAR
  );
  const make = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_MAKE
  );
  return {
    year,
    make
  };
};

export default connect(mapStateToProps)(Makes);
