// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ErrorText__wrapper--3MH7R{font-size:14px;background:rgba(var(--woop-error-color),.6);margin:10px 0;padding:14px}@media (max-width:550px){.ErrorText__wrapper--3MH7R{padding:14px 20px}}.ErrorText__wrapper--3MH7R.ErrorText__alt--XY5Ug{background:transparent;color:rgb(var(--woop-error-color));padding:14px 0}.ErrorText__wrapper--3MH7R{animation:fadeIn .3s ease}", "",{"version":3,"sources":["ErrorText.css"],"names":[],"mappings":"AAEA,2BACE,cAAiB,CACjB,2CAA8B,CAC9B,aAAc,CACd,YAYF,CAXE,yBALF,2BAMI,iBAUJ,CATE,CACA,iDACE,sBAAuB,CACvB,kCAAkB,CAClB,cACF,CACA,2BACE,yBACF","file":"ErrorText.css","sourcesContent":["@import './variables.css';\n\n.wrapper {\n  font-size: $fontS;\n  background: $errorColorLighter;\n  margin: 10px 0;\n  padding: 14px;\n  @media (--small-viewport) {\n    padding: 14px 20px;\n  }\n  &.alt {\n    background: transparent;\n    color: $errorColor;\n    padding: 14px 0;\n  }\n  :global {\n    animation: fadeIn 300ms ease;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "ErrorText__wrapper--3MH7R",
	"alt": "ErrorText__alt--XY5Ug"
};
module.exports = exports;
