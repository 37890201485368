import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const PromptWrapper = ({ className, shouldScrollTo, unsetScrollToPrompt, children }) => {
  const anchor = useRef();

  useEffect(() => {
    if (shouldScrollTo) {
      anchor?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      unsetScrollToPrompt();
    }
  }, [shouldScrollTo]);

  return (
    <div className={className} ref={anchor}>
      {children}
    </div>
  );
};

export default PromptWrapper;

PromptWrapper.propTypes = {
  className: PropTypes.string,
  shouldScrollTo: PropTypes.bool,
  unsetScrollToPrompt: PropTypes.func,
  children: PropTypes.node
};
