import PropTypes from 'prop-types';
import React from 'react';
import { COVERAGE_TYPES, SCENARIOS } from 'woop-shared/enums';
import { COMPONENT_NAMES } from 'woop-shared/prompts/components';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { PROMPT_PROPS } from '../../../../../journeyjson/prompts/constants';
import Checkbox from '../../../../core/components/Checkbox';
import SMSDisclaimer from '../../../../core/components/SMSDisclaimer';
import { QUERY_PARAMS } from '../../../../core/constants/url';
import { getVal } from '../../../../core/utils/journey-data';
import { getQueryStringParam } from '../../../../core/utils/url';
import { COMPONENT_MAPPING } from '../../../constants/component-mapping';
import styles from './styles.module.css';

const TextField = COMPONENT_MAPPING[COMPONENT_NAMES.TEXT];
const DateField = COMPONENT_MAPPING[COMPONENT_NAMES.DATE];
const PhoneField = COMPONENT_MAPPING[COMPONENT_NAMES.PHONE];
const SelectField = COMPONENT_MAPPING[COMPONENT_NAMES.SELECT];

const PersonalInfoPage = ({ journeyData, showValidation, onCheck }) => {
  const coverageTypes = getVal(journeyData[PROMPT_NAMES.COVERAGE_TYPE]) || [];
  const showMaritalStatus = !!coverageTypes.length && !coverageTypes.includes(COVERAGE_TYPES.AUTO);
  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);

  return (
    <>
      <TextField {...PROMPT_PROPS[PROMPT_NAMES.FIRST_NAME]} showValidation={showValidation} />
      <TextField {...PROMPT_PROPS[PROMPT_NAMES.LAST_NAME]} showValidation={showValidation} />
      <DateField {...PROMPT_PROPS[PROMPT_NAMES.DOB]} showValidation={showValidation} />
      {showMaritalStatus && (
        <SelectField
          {...PROMPT_PROPS[PROMPT_NAMES.MARITAL_STATUS]}
          showValidation={showValidation}
        />
      )}
      <TextField {...PROMPT_PROPS[PROMPT_NAMES.EMAIL]} showValidation={showValidation} />
      <PhoneField {...PROMPT_PROPS[PROMPT_NAMES.PHONE]} showValidation={showValidation} />
      {scenario === SCENARIOS.IV && onCheck && (
        <Checkbox className={styles.checkbox} save={onCheck}>
          Text me my verification results
        </Checkbox>
      )}
      <SMSDisclaimer />
    </>
  );
};

PersonalInfoPage.propTypes = {
  journeyData: PropTypes.object,
  onCheck: PropTypes.func,
  showValidation: PropTypes.bool
};

export default PersonalInfoPage;
