import axios from 'axios';
import { getDeviceId } from '../../../analytics/amplitude/utils';
import { formatAccountObject } from '../utils/api';
import { log } from '../utils/logger';

export const postVisit = async (oid, srcAcctId, originatorId) => {
  const deviceId = getDeviceId();
  log('visit(post) request data: ', oid, srcAcctId, deviceId);
  const body = {
    originationId: oid,
    account: formatAccountObject(srcAcctId, originatorId),
    deviceId
  };
  const { data } = await axios.post(process.env.VISIT_URI, body);
  return {
    visitId: data?.visitId,
    oid: data?.oid
  };
};

export default {
  postVisit
};
