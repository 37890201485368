import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import PrivacyPolicyLink from '../PrivacyPolicy/Link';
import TermsOfServiceLink from '../TermsOfService/Link';
import styles from './styles.css';

const cx = classnames.bind(styles);

const LegalLinks = ({ className }) => (
  <section className={cx('wrapper', className)}>
    <PrivacyPolicyLink className={styles.link} />
    <TermsOfServiceLink className={styles.link} />
  </section>
);

LegalLinks.propTypes = {
  className: PropTypes.string
};

export default LegalLinks;
