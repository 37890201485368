import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../../router/constants/routes';
import ProgressBar from '../../../core/components/ProgressBar';
import styles from './styles.css';

function getStep(pathname) {
  switch (pathname) {
    case ROUTES.SIGN_UP_INFO:
      return 1;
    case ROUTES.SIGN_UP_CONNECT:
    case ROUTES.CONNECT:
    case ROUTES.CONNECT_CARRIERS:
    case ROUTES.CONNECT_LOGIN:
    case ROUTES.CONNECT_MFA_OPTIONS:
    case ROUTES.CONNECT_MFA_INPUT:
    case ROUTES.CONNECT_TOS:
    case ROUTES.CONNECT_SUCCESS:
    case ROUTES.CONNECT_LOADING:
      return 2;
    case ROUTES.SUCCESS:
      return 3;
  }
}

const SignUpWrapper = ({ children }) => {
  const location = useLocation();
  const step = getStep(location.pathname);

  return (
    <>
      <div className={styles.parent}>
        <div className={styles.progressBar}>
          <ProgressBar activeStep={step} steps={['Start', 'Connect', 'Done!']} />
        </div>
      </div>

      {children}
    </>
  );
};

export default SignUpWrapper;

SignUpWrapper.propTypes = {
  children: PropTypes.node
};
