import { createAction } from 'redux-actions';

export const POST_VISIT = 'POST_VISIT';
export const postVisit = createAction(POST_VISIT);

export const POST_VISIT_SUCCESS = 'POST_VISIT_SUCCESS';
export const postVisitSuccess = createAction(POST_VISIT_SUCCESS);

const POST_VISIT_FAIL = 'POST_VISIT_FAIL';
export const postVisitFail = createAction(POST_VISIT_FAIL);
