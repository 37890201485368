import { createAction } from 'redux-actions';

export const SET_ORIGINATOR_ID = 'SET_ORIGINATOR_ID';
export const setOriginatorId = createAction(SET_ORIGINATOR_ID);

export const GET_ORIGINATOR = 'GET_ORIGINATOR';
export const getOriginator = createAction(GET_ORIGINATOR);

export const GET_ORIGINATOR_SUCCESS = 'GET_ORIGINATOR_SUCCESS';
export const getOriginatorSuccess = createAction(GET_ORIGINATOR_SUCCESS);
