// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--3uFhN{font-size:12px;margin-bottom:20px}.styles__wrapper--3uFhN p{line-height:1.6;margin-top:-15px;margin-bottom:0;opacity:.8}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,cAAkB,CAClB,kBAQF,CANE,0BACE,eAAwB,CACxB,gBAAiB,CACjB,eAAgB,CAChB,UACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  font-size: $fontXS;\n  margin-bottom: 20px;\n\n  p {\n    line-height: $lineHeight;\n    margin-top: -15px;\n    margin-bottom: 0;\n    opacity: 0.8;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--3uFhN"
};
module.exports = exports;
