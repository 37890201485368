import { COVERAGE_TYPES } from 'woop-shared/enums';
import { RESIDENCE_TYPES } from 'woop-shared/origination/enums';
import { ICON_NAMES } from 'woop-shared/prompts/icons';
import {
  PROMPT_NAMES,
  PROMPT_GROUP_NAMES,
  PROMPT_GROUP_NAME_VALUES,
  PROMPT_NAME_VALUES
} from 'woop-shared/prompts/names';
import { getPrimaryDriver } from 'woop-shared/utils';
import { DUPLICATED_DRIVER_FIELDS } from '../../journey/constants/prompts';
import { COVERAGE_TYPE_ICONS, ICONS } from '../constants/icons';
import { PROMPT_CONDITIONAL_FUNCTIONS } from '../constants/prompt-conditional-mapping';
import { QUERY_PARAMS } from '../constants/url';
import { getUniqueLabelByIndex as getUniqueDriverLabel } from './driver';
import * as logger from './logger';
import { formatPrompt } from './prompt-formatting';
import { hasVal, isValidPrompt } from './prompt-validation';
import { decodeUrlData, getQueryStringParam } from './url';
import { getLabel as getVehicleLabel } from './vehicle';

/**
 * Parses value property from prompt object.
 *
 * If the prompt is an array, return an array of the formatted values.
 *
 * @param {object|Array} prompt example: { firstName: { value: '123', isValid: true }}
 * @returns {string|Array}
 */
export function getVal(prompt) {
  if (!prompt) return;

  if (typeof prompt === 'object') {
    if (Array.isArray(prompt)) return prompt.map(p => p?.value);
    return prompt?.value;
  }

  switch (typeof prompt) {
    case 'boolean':
    case 'number':
    case 'string':
    default: {
      logger.error('Primitive passed to getVal().', prompt);
      return prompt;
    }
  }
}

/**
 * Grabs the label from prompt objects.
 *
 * For scalar values, fall back to value to the value field.
 * For boolean values, return a Yes or No.
 * For arrays, comma separate the returned string.
 *
 * @param {object} prompt example: { firstName: { value: '123', isValid: true }}
 * @returns {string}
 */
export function getLabel(prompt) {
  if (prompt === null) return prompt;

  switch (typeof prompt) {
    case 'object': {
      if (Array.isArray(prompt)) {
        return prompt.map(p => getLabel(p)).join(', ');
      }

      return prompt.label ? getLabel(prompt.label) : getLabel(prompt.value);
    }
    case 'boolean':
      return prompt ? 'Yes' : 'No';

    case 'number':
    case 'string':
    default:
      return prompt;
  }
}

/**
 * Safely get a value from a prompt group.
 *
 * @param {Array} promptGroup Array of prompt objects inside a prompt group
 * @param {number} promptGroupIndex The index of this prompt's promptGroup
 * @param {string} promptName The name of the prompt we're retrieving the value for.
 * @returns {*} The prompt's value.
 */
export function getPromptGroupVal(promptGroup, promptGroupIndex, promptName) {
  return getVal(promptGroup?.[promptGroupIndex]?.[promptName]);
}

/**
 * Safely get a label from a prompt group.
 *
 * @param {Array} promptGroup Array of prompt objects inside a prompt group
 * @param {number} promptGroupIndex The index of this prompt's promptGroup
 * @param {string} promptName The name of the prompt we're retrieving the value for.
 * @returns {*} The prompt's value.
 */
export function getPromptGroupPromptLabel(promptGroup, promptGroupIndex, promptName) {
  return getLabel(promptGroup?.[promptGroupIndex]?.[promptName]);
}

/**
 * Given a prompt name, find the prompt's icon.
 *
 * Note: this doesn't work for prompts in a prompt group.
 *
 * @param {string} promptName The name of a prompt.
 * @param {object} journeyData Str8 from st8
 * @param {Array} submodules Array of submodules.
 * @returns {Function} An icon name.
 */
export function getPromptIcon(promptName, journeyData, submodules) {
  const val = getVal(journeyData[promptName]);
  if (promptName === PROMPT_NAMES.COVERAGE_TYPE && Array.isArray(val)) {
    if (val.length > 1) return ICONS[ICON_NAMES.BUNDLED];
    const coverageType = val[0];
    return COVERAGE_TYPE_ICONS[coverageType?.toLowerCase()];
  }
  const prompts = submodules.flatMap(submodule => submodule.pages).flatMap(pages => pages.prompts);
  const prompt = prompts.find(p => p.name === promptName);
  const option = prompt?.options?.find(option => option.value === val);
  return option?.icon && ICONS[option?.icon];
}

/**
 * Get a custom label for an item in a prompt group.
 *
 * @param {string} promptGroupName e.g. Driver
 * @param {number} promptGroupIndex
 * @param {Array} promptGroup
 * @returns {string} The label.
 */
export function getPromptGroupLabel(promptGroupName, promptGroupIndex, promptGroup) {
  if (!promptGroupName || isNaN(promptGroupIndex)) return '';
  const defaultLabel = `${promptGroupName} ${promptGroupIndex + 1}`;
  if (!promptGroup?.[promptGroupIndex]) return defaultLabel;
  switch (promptGroupName) {
    case PROMPT_GROUP_NAMES.DRIVERS: {
      return getUniqueDriverLabel(promptGroup, promptGroupIndex);
    }
    case PROMPT_GROUP_NAMES.VEHICLES: {
      return getVehicleLabel(promptGroup[promptGroupIndex]);
    }
    default:
      return defaultLabel;
  }
}

/**
 * Return a boolean indicating whether a prompt's conditionals are met
 *
 * @param {object} journeyData
 * @param {object} conditionals e.g.
 * [
 *   {
 *     promptName: ['vehicleYear'],
 *     hideValues: [2000],
 *     promptGroupName: 'Auto'
 *   },
 *   {
 *     promptName: ['vehicleMake'],
 *     hideValues: [Acura, Honda],
 *     promptGroupName: 'Auto'
 *   }
 * ]
 * @param {string} promptName name of the prompt whose condition we are evaluating
 * @param {number} promptGroupIndex
 * @returns {boolean} boolean
 */
export function maybeShowConditonalPrompt(journeyData, conditionals, promptName, promptGroupIndex) {
  if (!Array.isArray(conditionals)) return;
  const shouldShow =
    conditionals.length &&
    conditionals.every(conditional => {
      const conditionalPromptName = conditional?.promptName;
      const conditionalPromptGroup = conditional?.promptGroupName;
      const promptGroup =
        promptGroupIndex >= 0 &&
        conditionalPromptGroup &&
        journeyData?.[conditionalPromptGroup]?.[promptGroupIndex];

      const value = promptGroup
        ? getVal(promptGroup[conditionalPromptName])
        : getVal(journeyData[conditionalPromptName]);

      const conditionFn = PROMPT_CONDITIONAL_FUNCTIONS?.[conditionalPromptName]?.[promptName];
      if (typeof conditionFn === 'function')
        return conditionFn({
          journeyData,
          value,
          conditionalPromptName,
          conditionalPromptGroup,
          promptName,
          promptGroup,
          promptGroupIndex
        });

      return isConditionMet(conditional, value);
    });
  return shouldShow;
}

/**
 *
 * @param {object} conditional The conditional object, @see maybeShowConditonalPrompt
 * @param {*} value The conditional prompt's value.
 * @returns {boolean}
 */
function isConditionMet(conditional, value) {
  let shouldShow = false;

  if (conditional?.showIfValue && value && hasVal(value)) shouldShow = true;

  if (hasVal(conditional?.showValues) && includesValue(value, conditional.showValues))
    shouldShow = true;
  if (hasVal(conditional?.hideValues)) shouldShow = !includesValue(value, conditional.hideValues);

  return shouldShow;
}

function includesValue(value, valueList) {
  return Array.isArray(value)
    ? value.length > 0 && value.some(val => valueList.includes(val))
    : valueList.includes(value);
}

/**
 * Format journeyData as a flat key: val structure.
 *
 * e.g. { firstName: { value: 'Kevin', isValid: true } } => { firstName: 'Kevin' }
 *
 * @param {object} journeyData
 * @returns {object}
 */
export function flattenJourneyData(journeyData) {
  const entries = Object.entries(journeyData).map(([key, val]) =>
    PROMPT_GROUP_NAME_VALUES.includes(key)
      ? [key, val.map(v => flattenJourneyData(v))]
      : [key, getVal(val)]
  );
  return Object.fromEntries(entries);
}

/**
 * Pass JOURNEY_DATA_URL_PARAM value from the query string to our parser fn.
 *
 * @returns {object}
 */
export function getUrlJourneyData() {
  const encodedJourneyData = getQueryStringParam(QUERY_PARAMS.JOURNEY_DATA);
  return decodeUrlData(encodedJourneyData);
}

/**
 * Format journey data for state.
 *
 * @param {object} journeyData A flat journey data object.
 * @returns {object} journeyData prepped for state.
 */
export function formatJourneyData(journeyData) {
  const validated = validateJourneyData(journeyData);
  return syncPrimaryDriver(validated);
}

/**
 * Take a flat journeyData structure and return a structure consumable by the UI.
 *
 * For each field, validate the key, run a validity check, and format the data for presentation.
 *
 * @param {object} journeyData A flat journeyData object, e.g. {coverageType: ['HOME']}
 * @returns {object} e.g. { coverageType: { value: ['HOME'], isValid: true } }
 */
export function validateJourneyData(journeyData = {}) {
  const unpackedJourneyData = Object.entries(journeyData)
    .map(([key, val]) => {
      // If no value, return.
      if (!hasVal(val)) return;

      // If prompt group, recursive!
      if (PROMPT_GROUP_NAME_VALUES.includes(key))
        return [key, val.map(v => validateJourneyData(v))];

      // If normal prompt.
      if (PROMPT_NAME_VALUES.includes(key)) {
        const value = formatPrompt(key, val);
        const isValid = isValidPrompt(key, value);
        const formattedVal = { value, isValid };
        return [key, formattedVal];
      }
    })
    .filter(Boolean);

  return Object.fromEntries(unpackedJourneyData);
}

/**
 * Sync top-level primary applicant fields to the primary driver.
 *
 * @param {object} uiData Validated journey data.
 * @returns {object}
 */
export function syncPrimaryDriver(uiData) {
  const primaryDriver = {};
  DUPLICATED_DRIVER_FIELDS.forEach(field => {
    if (uiData[field]) {
      primaryDriver[field] = uiData[field];
    }
  });
  if (!Object.keys(primaryDriver).length) return uiData;
  const drivers = uiData[PROMPT_GROUP_NAMES.DRIVERS] || [];
  drivers[0] = { ...getPrimaryDriver(uiData), ...primaryDriver };
  return { ...uiData, [PROMPT_GROUP_NAMES.DRIVERS]: drivers };
}

/**
 *
 * @param {object} uiData The journeyData object from state.
 * @returns {number} The number of residents of driving age
 */
export function getNumDrivingResidents(uiData) {
  const numResidents = getVal(uiData[PROMPT_NAMES.NUM_OF_RESIDENTS]) || 0;
  const numDriversUnderage = getVal(uiData[PROMPT_NAMES.NUM_OF_CHILDREN_NON_DRIVERS]) || 0;
  return Math.max(numResidents - numDriversUnderage, 0);
}

export function getCoverageTypeByResidenceType(residenceType) {
  if (residenceType === RESIDENCE_TYPES.OWN_A_HOME) return COVERAGE_TYPES.HOME;
  if (residenceType === RESIDENCE_TYPES.OWN_A_CONDO) return COVERAGE_TYPES.CONDO;
  const rentersResidenceTypes = [
    RESIDENCE_TYPES.RENTING_AN_APARTMENT,
    RESIDENCE_TYPES.RENTING_A_CONDO,
    RESIDENCE_TYPES.RENTING_A_HOME
  ];
  if (rentersResidenceTypes.includes(residenceType)) return COVERAGE_TYPES.RENTERS;
}

export function isPrimaryDriver(promptGroupName, promptGroupIndex) {
  return promptGroupName === PROMPT_GROUP_NAMES.DRIVERS && promptGroupIndex === 0;
}

export function handleCrossSellValues(coverageTypes, propertyCoverageType) {
  const set = new Set([...coverageTypes, propertyCoverageType]);
  const values = Array.from(set);
  return values;
}
