import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CONNECT_ROUTE_PAGES, ROUTES } from '../../../../router/constants/routes';
import { connectRoute } from '../../../../router/utils';
import OverlayModal from '../OverlayModal';
import CarrierScreen from './components/CarrierScreen';
import LoadingScreen from './components/LoadingScreen';
import LoginScreen from './components/LoginScreen';
import MFAInput from './components/MFAInput';
import MFAOptionsScreen from './components/MFAOptionsScreen';
import SuccessScreen from './components/SuccessScreen';
import TOS from './components/TOS';
import styles from './styles.css';

const CanopyModal = ({ open, toggleOpen, onContinue, abortToBox, baseRoute = ROUTES.CONNECT }) => {
  const location = useLocation();
  const applyAutoHeight = location.pathname === connectRoute(baseRoute, CONNECT_ROUTE_PAGES.LOGIN); // Error state and Canopy fields cause overflow

  return (
    <OverlayModal
      open={open}
      toggleOpen={toggleOpen}
      closeable={true}
      modalClass={applyAutoHeight ? styles.autoHeight : null}
      contentClass={styles.wrapper}
    >
      <Switch>
        <Route
          path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.CARRIERS)}
          render={() => <CarrierScreen abortToBox={abortToBox} modalOpen={open} />}
        />
        <Route path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.TOS)} component={TOS} />
        <Route path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.LOGIN)} component={LoginScreen} />
        <Route
          path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.LOADING)}
          component={LoadingScreen}
        />
        <Route
          path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.MFA_OPTIONS)}
          component={MFAOptionsScreen}
        />
        <Route path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.MFA_INPUT)} component={MFAInput} />
        <Route
          path={connectRoute(baseRoute, CONNECT_ROUTE_PAGES.SUCCESS)}
          render={() => <SuccessScreen onContinue={onContinue} />}
        />
      </Switch>
    </OverlayModal>
  );
};

export default CanopyModal;

CanopyModal.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
  onContinue: PropTypes.func,
  abortToBox: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  baseRoute: PropTypes.string
};
