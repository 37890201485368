import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { getPromptGroupVal, getVal } from '../utils/journey-data';
import { getOptions } from '../utils/roles';
import DependentSelect from './DependentSelect';

const Roles = ({ promptGroupIndex, promptGroupName, ...rest }) => {
  const journeyData = useSelector(state => state.journeyData);

  const industry = promptGroupName
    ? getPromptGroupVal(journeyData[promptGroupName], promptGroupIndex, PROMPT_NAMES.JOB_INDUSTRY)
    : getVal(journeyData[PROMPT_NAMES.JOB_INDUSTRY]);

  return <DependentSelect getOptions={getOptions} dependentFieldVals={{ industry }} {...rest} />;
};

export default Roles;

Roles.propTypes = {
  industry: PropTypes.string,
  promptGroupIndex: PropTypes.any,
  promptGroupName: PropTypes.any
};
