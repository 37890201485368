import { connect } from 'react-redux';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Models from '../../components/vehicle/Models';
import { getPromptGroupVal } from '../../utils/journey-data';

const mapStateToProps = ({ journeyData }, { promptGroupName, promptGroupIndex }) => {
  const year = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_YEAR
  );
  const make = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_MAKE
  );
  const model = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_MODEL
  );
  const dependentFieldVals = { year, make };
  return {
    dependentFieldVals,
    model
  };
};

export default connect(mapStateToProps)(Models);
