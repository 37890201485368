import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import { appScrollToTop } from '../../core/utils/browser';
import { APP_CONTENT_ID } from '../constants/app';
import styles from '../css/Modal.css';
import { useKeyboardClose } from '../hooks/use-keyboard-close';
import { useScrollHint } from '../hooks/use-scroll-hint';
import ScrollHintButton from './ScrollHintButton';

const cx = classnames.bind(styles);

const Modal = ({
  children,
  visible,
  closing,
  animationMode,
  toggleModal,
  showCloseBtn = true,
  className
}) => {
  const [scrollableElement, shouldShowScrollHint] = useScrollHint();
  useEffect(() => {
    if (visible) {
      if (scrollableElement.current)
        scrollableElement.current.scrollTo({
          top: 0
        });
      appScrollToTop();
    }
  }, [visible]);
  useKeyboardClose(visible && toggleModal);

  return ReactDOM.createPortal(
    <div
      className={cx('wrapper', animationMode, className, {
        visible,
        closing
      })}
    >
      <div className={styles.content} ref={scrollableElement}>
        {showCloseBtn && toggleModal && (
          <div role="button" className={styles.closeButton}>
            <FiX onClick={toggleModal}></FiX>
          </div>
        )}
        {children}
        {shouldShowScrollHint && (
          <ScrollHintButton scrollableElement={scrollableElement?.current} />
        )}
      </div>
    </div>,
    document.getElementById(APP_CONTENT_ID)
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  animationMode: PropTypes.oneOf(['slideRight']),
  toggleModal: PropTypes.func,
  showCloseBtn: PropTypes.bool,
  className: PropTypes.string
};
