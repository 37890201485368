import PropTypes from 'prop-types';
import React from 'react';
import insuranceReportMock from '../../../core/assets/insurance_report_mock.png';
import styles from './styles.css';

const SignUpHeader = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.image}>
          <img src={insuranceReportMock} alt="Insurance Report UI" />
        </div>
        <div className={styles.heroText}>
          <div className={styles.heading}>The new way to do insurance.</div>
          <div className={styles.subheading}>All in one place. Always Free.</div>
          {children}
        </div>
      </div>
    </div>
  );
};

SignUpHeader.propTypes = {
  children: PropTypes.node
};

export default SignUpHeader;
