// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--3Ca2i{display:inline-block;padding-bottom:2px;border-bottom:4px solid;border-bottom-color:rgb(var(--woop-primary-color));font-weight:600;margin-bottom:20px}.styles__wrapper--3Ca2i.styles__purple--2rAHH{border-bottom-color:#ab80e3}.styles__wrapper--3Ca2i.styles__pink--3jqC7{border-bottom-color:#ff66c4}.styles__wrapper--3Ca2i.styles__yellow--h7piq{border-bottom-color:#ffe478}.styles__wrapper--3Ca2i.styles__green--2LZrb{border-bottom-color:var(--color-green-light)}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,oBAAqB,CACrB,kBAAmB,CAEnB,uBAAqC,CAArC,kDAAqC,CACrC,eAAyB,CACzB,kBAaF,CAZE,8CACE,2BACF,CACA,4CACE,2BACF,CACA,8CACE,2BACF,CACA,6CACE,4CACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  display: inline-block;\n  padding-bottom: 2px;\n  border-bottom: solid 4px;\n  border-bottom-color: $appPrimaryColor;\n  font-weight: $fw_semibold;\n  margin-bottom: 20px;\n  &.purple {\n    border-bottom-color: $purple;\n  }\n  &.pink {\n    border-bottom-color: $pink;\n  }\n  &.yellow {\n    border-bottom-color: $yellow;\n  }\n  &.green {\n    border-bottom-color: var(--color-green-light);\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--3Ca2i",
	"purple": "styles__purple--2rAHH",
	"pink": "styles__pink--3jqC7",
	"yellow": "styles__yellow--h7piq",
	"green": "styles__green--2LZrb"
};
module.exports = exports;
