import {
  UPDATE_PAGE_VALIDITY,
  UPDATE_PAGES,
  UPDATE_PAGE_SHOW_VALIDATION,
  SET_PROMPT_GROUP_INDEX
} from '../actions/pages';
import { mapSubmodulesToPages, isPageValid } from '../utils/journey-content';

const alwaysValid = !!(process.env.DEBUG && process.env.PAGE_VALIDATION_OFF);

const pages = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAGES: {
      const { submodules = [], journeyData } = action.payload;
      const newPages = mapSubmodulesToPages(submodules, state, journeyData, alwaysValid);
      return { ...state, ...newPages };
    }
    case UPDATE_PAGE_VALIDITY: {
      const { pathname, journeyData } = action.payload;

      // If no entry in page object, take no action
      if (!state[pathname]) return { ...state };

      const page = state[pathname];
      const isValid = isPageValid(page, journeyData, alwaysValid);
      const updatedPage = { ...page, isValid };
      return { ...state, [pathname]: updatedPage };
    }
    case UPDATE_PAGE_SHOW_VALIDATION: {
      const { pathname } = action.payload;
      const page = { ...state[pathname], showValidation: true };
      return { ...state, [pathname]: page };
    }
    case SET_PROMPT_GROUP_INDEX: {
      const { pathname, index } = action.payload;
      const page = { ...state[pathname], promptGroupIndex: index };
      return { ...state, [pathname]: page };
    }
    default:
      return state;
  }
};

export default pages;
