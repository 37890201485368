import { COMPONENT_NAMES } from 'woop-shared/prompts/components';
import AddressAutocompleteGoogle from '../../core/components/AddressAutocompleteGoogle';
import CoverageTypeSelect from '../../core/components/CoverageTypeSelect';
import DateField from '../../core/components/DateField';
import EffectiveDate from '../../core/components/EffectiveDate';
import GPAField from '../../core/components/GPAField';
import InfoText from '../../core/components/InfoText';
import MoneyField from '../../core/components/MoneyField';
import MonthYearField from '../../core/components/MonthYearField';
import NumberField from '../../core/components/NumberField';
import PhoneField from '../../core/components/PhoneField';
import RadioGroup from '../../core/components/RadioGroup';
import Roles from '../../core/components/Roles';
import SelectField from '../../core/components/SelectField';
import SelectWithInput from '../../core/components/SelectWithInput';
import SMSDisclaimer from '../../core/components/SMSDisclaimer';
import SSNField from '../../core/components/SSNField';
import Textarea from '../../core/components/Textarea';
import TextField from '../../core/components/TextField';
import USStateSelect from '../../core/components/USStateSelect';
import Years from '../../core/components/vehicle/Years';
import VisualRadioGroup from '../../core/components/VisualRadioGroup';
import AssignDrivers from '../../core/containers/AssignDrivers';
import DriverSelect from '../../core/containers/DriverSelect';
import Makes from '../../core/containers/vehicle/Makes';
import Models from '../../core/containers/vehicle/Models';
import Submodels from '../../core/containers/vehicle/Submodels';
import WithValueSave from '../../core/enhancers/WithValueSave';

export const COMPONENT_MAPPING = {
  [COMPONENT_NAMES.COVERAGE_TYPE_SELECT]: WithValueSave(CoverageTypeSelect),
  [COMPONENT_NAMES.RADIO]: WithValueSave(RadioGroup),
  [COMPONENT_NAMES.TEXT]: WithValueSave(TextField),
  [COMPONENT_NAMES.VISUAL_RADIO_GROUP]: WithValueSave(VisualRadioGroup),
  [COMPONENT_NAMES.DATE]: WithValueSave(DateField),
  [COMPONENT_NAMES.MONTH_YEAR]: WithValueSave(MonthYearField),
  [COMPONENT_NAMES.PHONE]: WithValueSave(PhoneField),
  [COMPONENT_NAMES.SSN]: WithValueSave(SSNField),
  [COMPONENT_NAMES.SELECT]: WithValueSave(SelectField),
  [COMPONENT_NAMES.SELECT_WITH_INPUT]: WithValueSave(SelectWithInput),
  [COMPONENT_NAMES.ADDRESS_AUTOCOMPLETE]: WithValueSave(AddressAutocompleteGoogle),
  [COMPONENT_NAMES.PRIMARY_ADDRESS]: WithValueSave(AddressAutocompleteGoogle),
  [COMPONENT_NAMES.EFFECTIVE_DATE]: WithValueSave(EffectiveDate),
  [COMPONENT_NAMES.INFO_TEXT]: InfoText,
  [COMPONENT_NAMES.NUMBER]: WithValueSave(NumberField),
  [COMPONENT_NAMES.MONEY]: WithValueSave(MoneyField),
  [COMPONENT_NAMES.STATE_SELECT]: WithValueSave(USStateSelect),
  [COMPONENT_NAMES.SMS_DISCLAIMER]: SMSDisclaimer,
  [COMPONENT_NAMES.ASSIGN_DRIVERS]: AssignDrivers,
  [COMPONENT_NAMES.ROLES]: WithValueSave(Roles),
  [COMPONENT_NAMES.YEARS]: WithValueSave(Years),
  [COMPONENT_NAMES.MAKES]: WithValueSave(Makes),
  [COMPONENT_NAMES.MODELS]: WithValueSave(Models),
  [COMPONENT_NAMES.SUBMODELS]: WithValueSave(Submodels),
  [COMPONENT_NAMES.GPA]: WithValueSave(GPAField),
  [COMPONENT_NAMES.SELECT_DRIVER]: DriverSelect,
  [COMPONENT_NAMES.TEXTAREA]: WithValueSave(Textarea)
};
