import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.css';

const CoverageIcon = ({ icon, color, label }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon} style={{ backgroundColor: color }}>
        {icon()}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default CoverageIcon;

CoverageIcon.propTypes = {
  icon: PropTypes.func,
  color: PropTypes.string,
  label: PropTypes.string
};
