import PropTypes from 'prop-types';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { getAddressComposite } from 'woop-shared/utils';
import BaseTextField from '../BaseTextField';
import { FIELDS } from './constants';
import { mapGoogleAddress, parseHouseNumber } from './utils';

const AddressAutocompleteGoogle = ({
  placeholder,
  value,
  save,
  label,
  prompt,
  name,
  isValid,
  validationText,
  showValidation
}) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_API_KEY,
    onPlaceSelected: (place, ref) => {
      const mappedAddress = mapGoogleAddress(place);
      // If we selected a place that does not have a house number, try to append what was previously inputted.
      if (!mappedAddress[UI_ADDRESS_FIELDS.HOUSE_NUMBER]) {
        const houseNumber = parseHouseNumber(ref.value); // Parse out user-inputted house number
        const composite = mappedAddress[UI_ADDRESS_FIELDS.COMPOSITE] || '';
        if (houseNumber) {
          mappedAddress[UI_ADDRESS_FIELDS.HOUSE_NUMBER] = houseNumber;
          if (composite) {
            mappedAddress[UI_ADDRESS_FIELDS.COMPOSITE] = `${houseNumber} ${composite}`;
          }
        }
      }
      save(mappedAddress);
    },
    options: {
      fields: [FIELDS.FORMATTED_ADDRESS, FIELDS.ADDRESS_COMPONENTS],
      componentRestrictions: { country: 'us' },
      types: ['address']
    }
  });
  const formattedValue = getAddressComposite(value) || '';
  return (
    <BaseTextField
      name={name}
      id={name}
      label={label}
      isValid={isValid}
      placeholder={placeholder || ''}
      prompt={prompt}
      validationText={validationText}
      ref={ref}
      value={formattedValue}
      showValidation={showValidation}
      onKeyDown={e => {
        // Workaround: prevent google api triggering until 3 characters input
        const isCharInput = e.key.length === 1;
        const isPaste = e.key.toLowerCase() === 'v' && (e.ctrlKey || e.metaKey);
        if (isCharInput && !isPaste) {
          const newValue = formattedValue + e.key;
          if (newValue.replace(/\s/g, '').length < 3) {
            // Save input
            save({ [UI_ADDRESS_FIELDS.COMPOSITE]: newValue });
            // Skip all other DOM events
            e.preventDefault();
            e.stopPropagation();
          }
        }

        // On enter, select the first dropdown option.
        const isEnter = e.key === 'Enter';
        if (isEnter) {
          const downArrowEvent = new KeyboardEvent('keydown', { keyCode: 40, which: 40 });
          const enterEvent = new KeyboardEvent('keydown', { keyCode: 13, which: 13 });
          ref.current.dispatchEvent(downArrowEvent);
          ref.current.dispatchEvent(enterEvent);
          return;
        }
      }}
      onChange={event => {
        const val = event.target.value;
        save(val ? { [UI_ADDRESS_FIELDS.COMPOSITE]: val } : null);
      }}
    />
  );
};

AddressAutocompleteGoogle.propTypes = {
  isValid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  save: PropTypes.func,
  prompt: PropTypes.string,
  showValidation: PropTypes.bool,
  validationText: PropTypes.string,
  value: PropTypes.object
};

export default AddressAutocompleteGoogle;
