import { takeLatest, call, put } from 'redux-saga/effects';
import { getTokenSuccess, GET_TOKEN } from '../actions/box';
import { addToLoading, rmFromLoading } from '../actions/loading';
import { getBoxToken } from '../api/originations';
import * as logger from '../utils/logger';

function* handleGetToken(action) {
  yield put(addToLoading(action.type));
  try {
    const token = yield call(getBoxToken);
    yield put(getTokenSuccess(token));
  } catch (error) {
    logger.error(error);
  }
  yield put(rmFromLoading(action.type));
}

export function* watchGetToken() {
  yield takeLatest(GET_TOKEN, handleGetToken);
}
