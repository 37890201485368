import { COVERAGE_TYPES } from 'woop-shared/enums';
import { ROUTES, SUBMODULE_PATHS } from '../../../../router/constants/routes';
import { getCoverageTypeByResidenceType } from '../../../core/utils/journey-data';
import { getNextPagePath } from '../../utils/journey-content';

/**
 * Get the next path in the journey after the cross-sell page.
 *
 * We do this by getting the anchor page (driver discounts) and then looking for the next path.
 *
 * @param {Array} submodules Array of submodules directly from state.
 * @param {object} uiData
 * @returns {string} The next path.
 */
export function getNextPath(submodules, uiData) {
  const currentSubmodule = submodules.find(
    submodule => submodule.path === SUBMODULE_PATHS.DRIVER_DISCOUNTS
  );
  const currentPage = currentSubmodule.pages.length;
  const nextPath = getNextPagePath(submodules, currentSubmodule, currentPage, uiData);
  return `${ROUTES.JOURNEY}${nextPath}`;
}

export function getPropertyLabel(residenceType) {
  const propertyCoverageType = getCoverageTypeByResidenceType(residenceType);
  if (propertyCoverageType === COVERAGE_TYPES.HOME) return 'home';
  if (propertyCoverageType === COVERAGE_TYPES.CONDO) return 'condo';
  if (propertyCoverageType === COVERAGE_TYPES.RENTERS) return 'place';
}
