import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SCENARIOS } from 'woop-shared/enums';
import ConnectIcon from '../../../../modules/core/assets/icons/connected-link.svg';
import ProtectionIcon from '../../../../modules/core/assets/icons/protection.svg';
import VerifiedIcon from '../../../../modules/core/assets/icons/verified.svg';
import { ROUTES } from '../../../../router/constants/routes';
import { setScenario } from '../../../core/actions/scenario';
import AppWrapper from '../../../core/components/AppWrapper';
import Button from '../../../core/components/Button';
import LegalLinks from '../../../core/components/LegalLinks';
import { useDealershipPartnerName } from '../../../core/hooks/use-dealership-partner-name';
import { useSlimApp } from '../../../core/hooks/use-slim-app';
import styles from './styles.css';

const IVStart = () => {
  useSlimApp({ removeOnUnmount: true });

  const history = useHistory();
  const dispatch = useDispatch();

  const partner = useDealershipPartnerName({ capitalize: true });

  useEffect(() => {
    dispatch(setScenario(SCENARIOS.IV));
  }, []);

  const handleClick = () => {
    history.push(ROUTES.IV_INFO);
  };

  return (
    <AppWrapper className={styles.wrapper}>
      <section className={styles.header}>
        <h2 className={styles.title}>{partner} is partnered with Woop to verify your insurance</h2>
        <ProtectionIcon className={styles.headerIcon} />
      </section>

      <section className={styles.content}>
        <div className={styles.info}>
          <ConnectIcon className={styles.icon} />

          <div className={styles.infoContent}>
            <div className={styles.infoTitle}>Connect your insurance</div>
            <div className={styles.p}>
              Login with your insurer to instantly and securely connect your policy details.
            </div>
          </div>
        </div>

        <div className={styles.info}>
          <VerifiedIcon className={styles.icon} />

          <div className={styles.infoContent}>
            <div className={styles.infoTitle}>We&apos;ll verify your coverage</div>
            <div className={styles.p}>
              We will assess your policy and let you know what coverage you need for a loan or
              lease.
            </div>
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button className={styles.button} onClick={handleClick} useKeyboard blockButtonMobile>
            Verify my coverage
          </Button>
        </div>
      </section>

      <LegalLinks className={styles.legal} />
    </AppWrapper>
  );
};

export default IVStart;

IVStart.propTypes = {};
