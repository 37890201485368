import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';

/** Google response and address component fields */
export const FIELDS = {
  FORMATTED_ADDRESS: 'formatted_address',
  ADDRESS_COMPONENTS: 'address_components',
  SHORT_NAME: 'short_name',
  TYPES: 'types'
};

/** Google address component type fields */
const ADDRESS_COMPONENT_TYPES = {
  STREET_NUMBER: 'street_number',
  ROUTE: 'route',
  LOCALITY: 'locality',
  COUNTY: 'administrative_area_level_2',
  STATE: 'administrative_area_level_1',
  POSTAL_CODE: 'postal_code',
  COUNTRY: 'country',
  NEIGHBORHOOD: 'neighborhood'
};

/**
 * Our address fields mapped to their corresponding google address piece type
 */
export const GOOGLE_ADDRESS_TYPE_MAP = {
  [UI_ADDRESS_FIELDS.COUNTRY]: ADDRESS_COMPONENT_TYPES.COUNTRY,
  [UI_ADDRESS_FIELDS.CITY]: ADDRESS_COMPONENT_TYPES.LOCALITY,
  [UI_ADDRESS_FIELDS.COUNTY]: ADDRESS_COMPONENT_TYPES.COUNTY,
  [UI_ADDRESS_FIELDS.DISTRICT]: ADDRESS_COMPONENT_TYPES.NEIGHBORHOOD,
  [UI_ADDRESS_FIELDS.HOUSE_NUMBER]: ADDRESS_COMPONENT_TYPES.STREET_NUMBER,
  [UI_ADDRESS_FIELDS.STREET]: ADDRESS_COMPONENT_TYPES.ROUTE,
  [UI_ADDRESS_FIELDS.POSTAL_CODE]: ADDRESS_COMPONENT_TYPES.POSTAL_CODE,
  [UI_ADDRESS_FIELDS.STATE]: ADDRESS_COMPONENT_TYPES.STATE
};
