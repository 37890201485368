import React, { useState, useEffect } from 'react';
import { getYears } from '../../utils/vehicle';
import SelectField from '../SelectField';

const Years = ({ ...rest }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getYears().then(opts => {
      setOptions(opts);
    });
  }, []);

  return <SelectField options={options} {...rest} />;
};

export default Years;
