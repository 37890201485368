/* eslint-disable react/display-name */
import React from 'react';
import { ICON_NAMES } from 'woop-shared/prompts/icons';

// New icons
import BankTransfer from '../assets/icons/bank-transfer.svg';
import CreditCard from '../assets/icons/credit-card.svg';
import Loan from '../assets/icons/loan.svg';
import Apartments from '../assets/icons_v2/apartments.svg';
import Bundled from '../assets/icons_v2/bundled.svg';
import Condo from '../assets/icons_v2/condo.svg';
import Discount from '../assets/icons_v2/discount.svg';
import Attendant from '../assets/icons_v2/door_attendant.svg';
import DoorLock from '../assets/icons_v2/door_lock.svg';
import Email from '../assets/icons_v2/email.svg';
import FireExtinguisher from '../assets/icons_v2/fire_extinguisher.svg';
import FireFighter from '../assets/icons_v2/firefighter.svg';
import History from '../assets/icons_v2/history.svg';
import Incident from '../assets/icons_v2/incident.svg';
import MobileHome from '../assets/icons_v2/mobile_home.svg';
import MultiFamily from '../assets/icons_v2/multi_family.svg';
import NiceHouse from '../assets/icons_v2/nice_house.svg';
import NoSmoking from '../assets/icons_v2/no_smoking.svg';
import Operator from '../assets/icons_v2/operator.svg';
import People from '../assets/icons_v2/people.svg';
import Policeman from '../assets/icons_v2/policeman.svg';
import RingsInside from '../assets/icons_v2/rings_inside.svg';
import RowHouse from '../assets/icons_v2/row_house.svg';
import SmokeDetector from '../assets/icons_v2/smoke_detector.svg';
import Sprinkler from '../assets/icons_v2/sprinkler.svg';
import Umbrella from '../assets/icons_v2/umbrella.svg';
import User from '../assets/icons_v2/user.svg';
import Vehicles from '../assets/icons_v2/vehicles.svg';
import X from '../assets/icons_v2/x.svg';
import YellowCar from '../assets/icons_v2/yellow_car.svg';

// Old icons

const PAYMENT_METHOD_ICONS = {
  [ICON_NAMES.CREDIT_CARD]: CreditCard,
  [ICON_NAMES.LOAN]: Loan,
  [ICON_NAMES.BANK_TRANSFER]: BankTransfer
};

const PROPERTY_TYPE_ICONS = {
  [ICON_NAMES.ROWHOME]: RowHouse,
  [ICON_NAMES.MULTIFAMILY]: () => <MultiFamily alt="Multi-Family Home" />,
  [ICON_NAMES.MOBILEHOME]: () => <MobileHome alt="Mobile Home" />,
  [ICON_NAMES.SINGLEFAMILY]: () => <NiceHouse alt="Single-Family Home" />,
  [ICON_NAMES.APARTMENT]: () => <Apartments alt="Apartments" />,
  [ICON_NAMES.COOP]: () => <MultiFamily alt="Multi-Family Home" />
};

export const COVERAGE_TYPE_ICONS = {
  [ICON_NAMES.AUTO]: YellowCar,
  [ICON_NAMES.HOME]: NiceHouse,
  [ICON_NAMES.RENTERS]: Apartments,
  [ICON_NAMES.CONDO]: Condo,
  [ICON_NAMES.BUNDLED]: () => <Bundled alt="Home and auto" />,
  [ICON_NAMES.UMBRELLA]: Umbrella
};

const ALARM_ICONS = {
  [ICON_NAMES.POLICE]: () => <Policeman alt="Policeman" />,
  [ICON_NAMES.FIRE_DEPT]: () => <FireFighter alt="Firefighter" />,
  [ICON_NAMES.OPERATOR]: () => <Operator alt="Phone operator" />,
  [ICON_NAMES.ALARM]: () => <RingsInside alt="Ringing bell" />
};

const DISCOUNT_ICONS = {
  [ICON_NAMES.SPRINKLER]: () => <Sprinkler alt="Sprinkler" />,
  [ICON_NAMES.SMOKE_DETECTOR]: () => <SmokeDetector alt="SmokeDetector" />,
  [ICON_NAMES.DEADBOLT]: () => <DoorLock alt="Deadbolt" />,
  [ICON_NAMES.FIRE_EXTINGUISHER]: () => <FireExtinguisher alt="Fire Extinguisher" />,
  [ICON_NAMES.NO_SMOKING]: () => <NoSmoking alt="No Smoking" />,
  [ICON_NAMES.RECEPTIONIST]: () => <Attendant alt="Attendant" />
};

const GENERAL_ICONS = {
  [ICON_NAMES.X]: X,
  [ICON_NAMES.DISCOUNTS]: Discount,
  [ICON_NAMES.USER]: User,
  [ICON_NAMES.PEOPLE_IN_HOME]: () => <MultiFamily alt="members in the household" />,
  [ICON_NAMES.PEOPLE]: People,
  [ICON_NAMES.INCIDENTS]: Incident,
  [ICON_NAMES.HISTORY]: History
};

const AUTO_ICONS = {
  [ICON_NAMES.VEHICLES]: Vehicles
};

const EMAIL_ICONS = {
  [ICON_NAMES.EMAIL]: Email
};

export const ICONS = {
  ...GENERAL_ICONS,
  ...AUTO_ICONS,
  ...ALARM_ICONS,
  ...COVERAGE_TYPE_ICONS,
  ...PROPERTY_TYPE_ICONS,
  ...PAYMENT_METHOD_ICONS,
  ...EMAIL_ICONS,
  ...DISCOUNT_ICONS
};
