import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import PromptWrapper from '../../journey/containers/PromptWrapper';
import styles from '../css/RadioGroup.css';
import Prompt from './Prompt';
import Radio from './Radio';

const cx = classNames.bind(styles);

const RadioGroup = ({
  options,
  prompt,
  name,
  className,
  multiselect,
  deselectable = multiselect || false,
  showValidation,
  isValid,
  tooltip,
  subprompt,
  ...rest
}) => {
  const { value, save } = rest;
  const handleClick = optionValue => {
    if (multiselect) {
      const val = Array.isArray(value) ? value : value ? [value] : [];
      if (val.includes(optionValue)) save(val.filter(v => v !== optionValue));
      else save([...val, optionValue]);
    } else {
      if (deselectable && value === optionValue) save(null);
      else save(optionValue);
    }
  };

  return (
    <PromptWrapper name={name}>
      <Prompt text={prompt} showValidation={!isValid && showValidation} tooltip={tooltip} />
      {subprompt && <p className={styles.subprompt}>{subprompt}</p>}
      <div className={cx('radio-group', className)}>
        {options.map(option => (
          <Radio
            id={`${name}-${option.value}`}
            label={option.label}
            key={option.label}
            onClick={() => handleClick(option.value)}
            selected={multiselect ? value?.includes(option.value) : value === option.value}
          />
        ))}
      </div>
    </PromptWrapper>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  multiselect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string])
    })
  ),
  prompt: PropTypes.string,
  subprompt: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  deselectable: PropTypes.bool,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  tooltip: PropTypes.string
};
