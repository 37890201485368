// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AssignDrivers__row--259I-{display:flex;flex-direction:column;margin-bottom:40px}.AssignDrivers__row--259I- .AssignDrivers__select--1x0Um{flex:1}.AssignDrivers__row--259I- .AssignDrivers__select--1x0Um>div{margin-top:25px}.AssignDrivers__row--259I- .AssignDrivers__select--1x0Um>div{animation:growHeight 1s}@media (max-width:550px){.AssignDrivers__row--259I-{padding:0 20px}}", "",{"version":3,"sources":["AssignDrivers.css"],"names":[],"mappings":"AAEA,2BACE,YAAa,CAEb,qBAAsB,CACtB,kBAcF,CAbE,yDACE,MAQF,CANE,6DACE,eAIF,CAHE,6DACE,uBACF,CAGJ,yBAfF,2BAgBI,cAEJ,CADE","file":"AssignDrivers.css","sourcesContent":["@import './variables.css';\n\n.row {\n  display: flex;\n  margin-bottom: 20px;\n  flex-direction: column;\n  margin-bottom: 40px;\n  .select {\n    flex: 1;\n\n    & > div {\n      margin-top: 25px;\n      :global {\n        animation: growHeight 1000ms;\n      }\n    }\n  }\n  @media (--small-viewport) {\n    padding: 0 $mobileViewPadding;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"row": "AssignDrivers__row--259I-",
	"select": "AssignDrivers__select--1x0Um"
};
module.exports = exports;
