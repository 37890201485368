import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.css';

const cx = classnames.bind(styles);

const SuccessCheckmark = ({ className }) => {
  return (
    <div className={cx('success-checkmark', className)}>
      <div className={cx('check-icon')}>
        <span className={cx('icon-line', 'line-tip')}></span>
        <span className={cx('icon-line', 'line-long')}></span>
        <div className={cx('icon-circle')}></div>
        <div className={cx('icon-fix')}></div>
      </div>
    </div>
  );
};

SuccessCheckmark.propTypes = {
  className: PropTypes.string
};

export default SuccessCheckmark;
