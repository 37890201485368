// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--2LkAD div{padding:10px 0}.styles__wrapper--2LkAD h3{padding:20px 0 5px}.styles__wrapper--2LkAD li{padding:4px 0}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AACE,4BACE,cACF,CAEA,2BACE,kBACF,CAEA,2BACE,aACF","file":"styles.css","sourcesContent":[".wrapper {\n  div {\n    padding: 10px 0px;\n  }\n\n  h3 {\n    padding: 20px 0 5px;\n  }\n\n  li {\n    padding: 4px 0px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--2LkAD"
};
module.exports = exports;
