import { withOptimizely } from '@optimizely/react-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppLoadingScreen } from '../modules/core/components/AppLoadingScreen';
import { getLandingPage } from './utils';

const LandingPage = ({ optimizely }) => {
  const [landingPage, setLandingPage] = useState(null);

  useEffect(() => {
    const fetchLandingPage = async () => {
      const page = await getLandingPage(optimizely);
      setLandingPage(page);
    };

    fetchLandingPage();
  }, [optimizely]);

  if (landingPage) {
    return <Redirect to={landingPage} />;
  }

  return <AppLoadingScreen />;
};

LandingPage.propTypes = {
  optimizely: PropTypes.object
};

export default withOptimizely(LandingPage);
