import { routerMiddleware } from 'connected-react-router';
import { createMemoryHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';
import registerSagas from './sagas';

export const history = createMemoryHistory();

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewareEnhancer = applyMiddleware(sagaMiddleware);
  const routerEnhancer = applyMiddleware(routerMiddleware(history));

  const composedEnhancers = composeWithDevTools(middlewareEnhancer, routerEnhancer);

  const store = createStore(createRootReducer(history), preloadedState, composedEnhancers);

  registerSagas(sagaMiddleware);
  return store;
}
