import PropTypes from 'prop-types';
import React from 'react';
import { ICON_NAMES } from 'woop-shared/prompts/icons';
import { EVENTS } from '../../../../../../analytics/constants';
import { trackEvent } from '../../../../../../analytics/utils';
import { ROUTES } from '../../../../../../router/constants/routes';
import Button from '../../../../../core/components/Button';
import { COVERAGE_TYPE_ICONS } from '../../../../../core/constants/icons';
import CoverageIcon from './CoverageIcon';
import styles from './styles.css';

const coverageIcons = [
  {
    icon: COVERAGE_TYPE_ICONS[ICON_NAMES.AUTO],
    color: '#FFE7F5',
    label: 'Auto'
  },
  {
    icon: COVERAGE_TYPE_ICONS[ICON_NAMES.HOME],
    color: '#E1F1FC',
    label: 'Home'
  },
  {
    icon: COVERAGE_TYPE_ICONS[ICON_NAMES.RENTERS],
    color: '#F0ECF7',
    label: 'Renters'
  },
  {
    icon: COVERAGE_TYPE_ICONS[ICON_NAMES.CONDO],
    color: '#E1F1FC',
    label: 'Condo'
  }
];

const GotYouCovered = ({ onClick }) => {
  const handleClick = () => {
    trackEvent(EVENTS.CLICK_SECONDARY_CTA, {
      section: 'GotYouCovered',
      page: ROUTES.WELCOME
    });
    onClick();
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>{`We've got you covered.`}</div>
      <div className={styles.icons}>
        {coverageIcons.map(cov => (
          <CoverageIcon key={cov.label} icon={cov.icon} color={cov.color} label={cov.label} />
        ))}
      </div>
      <div className={styles.bottomText}>
        Or <span>bundle</span> for even more savings
      </div>
      <Button onClick={handleClick} useKeyboard blockButtonMobile showArrow>
        Get quotes now
      </Button>
    </div>
  );
};

export default GotYouCovered;

GotYouCovered.propTypes = {
  onClick: PropTypes.func
};
