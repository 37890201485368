import { createAction } from 'redux-actions';

export const UPDATE_PROMPT = 'UPDATE_PROMPT';

/**
 * @param {string} name a key of journeyData object
 * @param {string} value the value to update within journeyData[name]
 * @returns {object}
 */
export const updatePrompt = createAction(UPDATE_PROMPT);

export const UPDATE_PROMPT_VALIDITY = 'UPDATE_PROMPT_VALIDITY';
export const updatePromptValidity = createAction(UPDATE_PROMPT_VALIDITY);

export const UPDATE_PROMPT_GROUP_VALIDITY = 'UPDATE_PROMPT_GROUP_VALIDITY';
export const updatePromptGroupValidity = createAction(UPDATE_PROMPT_GROUP_VALIDITY);

export const UPDATE_PROMPT_GROUP = 'UPDATE_PROMPT_GROUP';
export const updatePromptGroup = createAction(UPDATE_PROMPT_GROUP);

export const DELETE_PROMPT_GROUP = 'DELETE_PROMPT_GROUP';
export const deletePromptGroup = createAction(DELETE_PROMPT_GROUP);
