import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../router/constants/routes';
import AppWrapper from '../../../core/components/AppWrapper';
import Button from '../../../core/components/Button';
import LegalLinks from '../../../core/components/LegalLinks';
import { useSlimApp } from '../../../core/hooks/use-slim-app';
import SignUpHeader from '../../components/SignUpHeader';
import { ITEMS } from './constants';
import styles from './styles.css';

/**
 * The landing page for the sign-up scenario
 *
 * @returns {Function} A react component
 */
const SignUpLanding = () => {
  useSlimApp({ removeOnUnmount: true });
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.SIGN_UP_INFO);
  };

  return (
    <AppWrapper className={styles.wrapper}>
      <SignUpHeader>
        <Button
          className={styles.button}
          useKeyboard
          blockButtonMobile
          showArrow
          onClick={handleClick}
        >
          Get Started
        </Button>
      </SignUpHeader>

      <section className={styles.info}>
        {ITEMS.map((item, idx) => (
          <div key={idx} className={styles.item}>
            <div className={styles.icon}>{item.icon()}</div>
            <div className={styles.text}>
              <h3>{item.title}</h3>
              <p>{item.body}</p>
            </div>
          </div>
        ))}
      </section>

      <section>
        <Button
          className={styles.button}
          useKeyboard
          blockButtonMobile
          showArrow
          onClick={handleClick}
        >
          Get Started
        </Button>
      </section>

      <section className={styles.footer}>
        <p>Your information is protected 🔒</p>
        <LegalLinks />
      </section>
    </AppWrapper>
  );
};

export default SignUpLanding;

SignUpLanding.propTypes = {};
