import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { connectComplete, pollForPull, popCanopyPull } from '../../../../actions/canopy';
import { ERROR_STATUSES, SUCCESS_STATUSES } from '../../../../api/canopy/constants';
import H3 from '../../../headers/H3';
import { Spinner } from '../../../Spinner';
import ConnectVisual from '../ConnectVisual';
import styles from './styles.css';
import { getNextRoute, getStatusMessage } from './utils';

const LoadingScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    canopy: { carrier, status },
    router
  } = useSelector(state => state);

  useEffect(() => {
    dispatch(pollForPull());
  }, []);

  useEffect(() => {
    if (!status) return;
    if (ERROR_STATUSES.includes(status)) dispatch(popCanopyPull());
    if (SUCCESS_STATUSES.includes(status)) dispatch(connectComplete());
    const route = getNextRoute(status, router.location.pathname);
    if (route) history.replace(route);
  }, [status]);

  return (
    <div className={styles.wrapper}>
      <ConnectVisual logoUrl={carrier.logo} />
      <H3>{status ? getStatusMessage(status) : 'Connecting...'}</H3>
      <p>
        We are connecting to your insurer. This process can take up to 30 seconds. Grab a snack.
      </p>
      <Spinner className={styles.spinner} />
    </div>
  );
};

export default LoadingScreen;
