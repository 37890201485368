// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "aside.styles__wrapper--3zL0I{font-size:14px;background:rgba(var(--woop-primary-color),.2);margin:10px 0;padding:20px;position:relative;line-height:1.6}aside.styles__wrapper--3zL0I.styles__bar--3ORqM{background:transparent;border-left:3px solid rgb(var(--woop-primary-color));padding:0 .8em}aside.styles__wrapper--3zL0I .styles__icon--2TfFZ>svg{position:absolute;fill:rgb(var(--woop-primary-color));height:100%;width:auto;top:0;left:0;opacity:.1}@media (max-width:550px){aside.styles__wrapper--3zL0I{padding:14px 20px}}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,6BACE,cAAiB,CACjB,6CAAmC,CACnC,aAAc,CACd,YAAa,CACb,iBAAkB,CAClB,eAqBF,CAnBE,gDACE,sBAAuB,CACvB,oDAAuC,CACvC,cACF,CAEA,sDACE,iBAAkB,CAClB,mCAAsB,CACtB,WAAY,CACZ,UAAW,CACX,KAAQ,CACR,MAAS,CACT,UACF,CAEA,yBAxBF,6BAyBI,iBAEJ,CADE","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\naside.wrapper {\n  font-size: $fontS;\n  background: $appPrimaryColorLighter;\n  margin: 10px 0;\n  padding: 20px;\n  position: relative;\n  line-height: $lineHeight;\n\n  &.bar {\n    background: transparent;\n    border-left: solid 3px $appPrimaryColor;\n    padding: 0 0.8em;\n  }\n\n  .icon > svg {\n    position: absolute;\n    fill: $appPrimaryColor;\n    height: 100%;\n    width: auto;\n    top: 0px;\n    left: 0px;\n    opacity: 0.1;\n  }\n\n  @media (--small-viewport) {\n    padding: 14px 20px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--3zL0I",
	"bar": "styles__bar--3ORqM",
	"icon": "styles__icon--2TfFZ"
};
module.exports = exports;
