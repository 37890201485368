import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { getDriverId } from 'woop-shared/utils';
import { createMonthDayString } from './date';
import { getVal } from './journey-data';

const getLabelFunctions = [
  getDriverFirstName,
  getDriverFullName,
  getDriverFirstNameBirthday,
  getDriverFirstNameFullBirthday,
  getDriverFullNameFullBirthday
];

/**
 * Get an array of unique of driver options.
 *
 * @param {Array} drivers Array of drivers from the state.
 * @returns {Array} Array of unique driver labels.
 */
export function getDriverOptions(drivers) {
  const labels = getUniqueLabels(drivers);
  return labels?.map((label, index) => {
    const driver = drivers[index];
    const firstName = getVal(driver?.[PROMPT_NAMES.FIRST_NAME]);
    const lastName = getVal(driver?.[PROMPT_NAMES.LAST_NAME]);
    const dob = getVal(driver?.[PROMPT_NAMES.DOB]);
    return {
      label,
      value: getDriverId(firstName, lastName, dob)
    };
  });
}

/**
 * Get an array of driver options w/ the primaryDriver removed.
 *
 * @param {Array} drivers Array of drivers from the state.
 * @param {string} primaryDriver The selected driver.
 * @returns {Array} Array of driver options.
 */
export function getNonPrimaryDriverOptions(drivers, primaryDriver) {
  const options = getDriverOptions(drivers);
  const filteredOptions = options.filter(({ value }) => value !== primaryDriver);
  return filteredOptions;
}

/**
 * Get an array of unique of driver labels.
 *
 * Go through the hierarchy of getLabelFunctions and get the least-specific driver names.
 *
 * @param {Array} drivers Array of drivers from the state.
 * @returns {Array} Array of unique driver labels.
 */
export function getUniqueLabels(drivers) {
  let labels = [];
  for (let getLabel of getLabelFunctions) {
    labels = drivers?.map(getLabel);

    // If we don't have duplicates, return the labels.
    if (labels?.length === new Set(labels).size) return labels;
  }

  return labels;
}

/**
 * Get the driver's label based on the index.
 *
 * @param {Array} drivers Array of drivers from the state.
 * @param {number} index Index of the current driver.
 * @returns {string} The driver's unique label.
 */
export function getUniqueLabelByIndex(drivers, index) {
  const uniqueLabels = getUniqueLabels(drivers);
  return uniqueLabels?.[index];
}

function getDriverFirstName(driver) {
  const firstName = getVal(driver?.[PROMPT_NAMES.FIRST_NAME]);
  return firstName && `${firstName}`;
}

function getDriverLastName(driver) {
  const lastName = getVal(driver?.[PROMPT_NAMES.LAST_NAME]);
  return lastName && `${lastName}`;
}

function getDriverFullName(driver) {
  const firstName = getDriverFirstName(driver);
  const lastName = getDriverLastName(driver);
  return firstName && lastName && `${firstName} ${lastName}`;
}

function getDriverBirthday(driver) {
  const dob = getVal(driver?.[PROMPT_NAMES.DOB]);
  return dob && createMonthDayString(new Date(dob));
}

function getDriverFullBirthday(driver) {
  return getVal(driver?.[PROMPT_NAMES.DOB]);
}

function getDriverFirstNameBirthday(driver) {
  const firstName = getDriverFirstName(driver);
  const dob = getDriverBirthday(driver);
  return firstName && dob && `${firstName} (${dob})`;
}

function getDriverFirstNameFullBirthday(driver) {
  const firstName = getDriverFirstName(driver);
  const dob = getDriverFullBirthday(driver);
  return firstName && dob && `${firstName} (${dob})`;
}

function getDriverFullNameFullBirthday(driver) {
  const fullName = getDriverFullName(driver);
  const dob = getDriverFullBirthday(driver);
  return fullName && dob && `${fullName} (${dob})`;
}
