import PropTypes from 'prop-types';
import React from 'react';
import { getDetails } from '../../utils/vehicle';
import DependentSelect from '../DependentSelect';

const Submodels = ({ dependentFieldVals, ...rest }) => {
  return (
    <DependentSelect
      getOptions={getDetails}
      dependentFieldVals={dependentFieldVals}
      keepInitialValue
      {...rest}
    />
  );
};

export default Submodels;

Submodels.propTypes = {
  dependentFieldVals: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    make: PropTypes.string,
    model: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};
