// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--mxcSI{flex-direction:column}.styles__wrapper--mxcSI,.styles__wrapper--mxcSI .styles__icon--1oX5t{display:flex;justify-content:center;align-items:center}.styles__wrapper--mxcSI .styles__icon--1oX5t{width:60px;height:60px;border-radius:50%}.styles__wrapper--mxcSI .styles__icon--1oX5t>svg{width:35px;height:35px}.styles__wrapper--mxcSI .styles__label--1LQTl{margin-top:5px;font-size:14px;font-weight:300}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBAEE,qBAuBF,CAnBE,qEALA,YAAa,CAEb,sBAAuB,CACvB,kBAcA,CAZA,6CACE,UAAW,CACX,WAAY,CACZ,iBASF,CAJE,iDACE,UAAW,CACX,WACF,CAGF,8CACE,cAAe,CACf,cAAiB,CACjB,eACF","file":"styles.css","sourcesContent":["@import '../../../../../../core/css/variables.css';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  .icon {\n    width: 60px;\n    height: 60px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    & > svg {\n      width: 35px;\n      height: 35px;\n    }\n  }\n\n  .label {\n    margin-top: 5px;\n    font-size: $fontS;\n    font-weight: $fw_light;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--mxcSI",
	"icon": "styles__icon--1oX5t",
	"label": "styles__label--1LQTl"
};
module.exports = exports;
