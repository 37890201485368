import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useKeyboardNav } from '../../hooks/use-keyboard-nav';
import { Spinner } from '../Spinner';
import styles from './styles.css';

const cx = classNames.bind(styles);

const CircleButton = ({
  className,
  children,
  to,
  disabled,
  onClick = () => {},
  useKeyboard,
  loading
}) => {
  const history = useHistory();
  const handleClick = e => {
    onClick(e);
    if (to) history.push(to);
  };

  if (useKeyboard) useKeyboardNav(handleClick, !disabled);
  return (
    <button
      className={cx(className, 'button')}
      onClick={handleClick}
      disabled={disabled || loading}
    >
      {loading ? <Spinner className={styles.spinner} /> : children}
    </button>
  );
};

export default CircleButton;

CircleButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  useKeyboard: PropTypes.bool,
  loading: PropTypes.bool
};
