import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigButton from '../../../core/components/BigButton';
import Button from '../../../core/components/Button';
import FooterLinks from '../../../core/components/FooterLinks';
import H1 from '../../../core/components/headers/H1';
import { useSlimApp } from '../../../core/hooks/use-slim-app';
import { getQuoteset, GET_QUOTESET } from '../../../quote/actions';
import CarrierLogos from '../../components/CarrierLogos';
import styles from './styles.css';

const WelcomeQuotes = () => {
  useSlimApp();
  const { loading, quoteset } = useSelector(state => state);
  const isLoading = loading.includes(GET_QUOTESET);
  const dispatch = useDispatch();
  const onClick = () => dispatch(getQuoteset());
  const isRecapture = !!quoteset.paymentFailedQuoteId;
  const headerText = isRecapture ? `Update your payment` : `Your quotes are ready!`;

  return (
    <section className={styles.wrapper}>
      <section className={styles.content}>
        <H1 className={styles.heading}>{headerText}</H1>
        <CarrierLogos />
        <Button
          className={styles.mobileButton}
          onClick={onClick}
          useKeyboard
          blockButtonMobile
          showArrow
          loading={isLoading}
        >
          Next
        </Button>
      </section>
      <BigButton className={styles.desktopButton} onClick={onClick} useKeyboard loading={isLoading}>
        Next
      </BigButton>
      <FooterLinks />
    </section>
  );
};

export default WelcomeQuotes;

WelcomeQuotes.propTypes = {};
