import * as Sentry from '@sentry/react';
import { LOG_LEVELS } from '../../modules/core/utils/logger';

export function bindIntercomToElement(elementClass) {
  if (!window.Intercom)
    return Sentry.captureMessage(
      `Intercom was not found on window and was not bound to ${elementClass} :(`,
      LOG_LEVELS.ERROR
    );
  window.Intercom('update', { custom_launcher_selector: `.${elementClass}` });
}

export function intercomExists() {
  return !!window.Intercom;
}

/**
 * Update Intercom instance with data
 *
 * @param {object} data A JSON update object
 */
export function update(data) {
  intercomExists() && window.Intercom('update', data);
}

export function openBlog(blogId) {
  window.Intercom('showArticle', blogId);
}

export function logEvent(event, metadata) {
  intercomExists() && window.Intercom('trackEvent', event, metadata);
}
