// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InvalidIcon__wrapper--iJMIb{display:inline-block;border-radius:50%;border:2px solid rgba(var(--woop-error-color),.6);width:18px;height:18px;transform:translateY(-12%);background-color:rgb(var(--woop-white));position:absolute;margin-left:7px}.InvalidIcon__wrapper--iJMIb svg{fill:rgb(var(--woop-error-color));height:10px;width:2px;position:absolute;left:calc(50% - 1px);top:calc(50% - 5px)}.InvalidIcon__wrapper--iJMIb{animation:fadeIn .3s forwards}", "",{"version":3,"sources":["InvalidIcon.css"],"names":[],"mappings":"AAEA,6BACE,oBAAqB,CACrB,iBAAkB,CAClB,iDAAoC,CACpC,UAAW,CACX,WAAY,CACZ,0BAA2B,CAC3B,uCAAwB,CACxB,iBAAkB,CAClB,eAcF,CAbE,iCAGE,iCAAiB,CACjB,WAAe,CACf,SAAU,CACV,iBAAkB,CAClB,oBAA4B,CAC5B,mBACF,CACA,6BACE,6BACF","file":"InvalidIcon.css","sourcesContent":["@import './variables.css';\n\n.wrapper {\n  display: inline-block;\n  border-radius: 50%;\n  border: solid 2px $errorColorLighter;\n  width: 18px;\n  height: 18px;\n  transform: translateY(-12%);\n  background-color: $white;\n  position: absolute;\n  margin-left: 7px;\n  svg {\n    $height: 10px;\n    $width: 2px;\n    fill: $errorColor;\n    height: $height;\n    width: 2px;\n    position: absolute;\n    left: calc(50% - $width / 2);\n    top: calc(50% - $height / 2);\n  }\n  :global {\n    animation: fadeIn 300ms forwards;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "InvalidIcon__wrapper--iJMIb"
};
module.exports = exports;
