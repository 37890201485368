// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--7AJV-{max-width:900px;box-shadow:5px 5px 20px 2px rgb(var(--woop-box-shadow-color));margin:0 auto}.styles__content--2vgEo{display:flex;align-items:center;justify-content:center;flex-direction:column;width:400px}@media (max-width:550px){.styles__content--2vgEo{margin-bottom:15%;width:auto;padding:0 20px}.styles__content--2vgEo h2{text-align:center}}.styles__content--2vgEo svg{width:150px;height:150px}.styles__text--2YZys p{line-height:1.6}.styles__text--2YZys{text-align:center}", "",{"version":3,"sources":["styles.css","<no source>"],"names":[],"mappings":"AAEA,wBACE,eAAuB,CACvB,6DAA4C,CAC5C,aACF,CAEA,wBACE,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,qBAAsB,CACtB,WAcF,CAbE,yBANF,wBAOI,iBAAkB,CAClB,UAAW,CACX,cAUJ,CATI,2BACE,iBACF,CACF,CAEA,4BACE,WAAY,CACZ,YACF,CAIA,uBACE,eACF,CChCF,qBDiCE,kBCjCF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  max-width: $appMaxWidth;\n  box-shadow: 5px 5px 20px 2px $boxShadowColor;\n  margin: 0px auto;\n}\n\n.content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 400px;\n  @media (--small-viewport) {\n    margin-bottom: 15%;\n    width: auto;\n    padding: 0 20px;\n    h2 {\n      text-align: center;\n    }\n  }\n\n  svg {\n    width: 150px;\n    height: 150px;\n  }\n}\n\n.text {\n  p {\n    line-height: $lineHeight;\n  }\n  text-align: center;\n}\n",null]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--7AJV-",
	"content": "styles__content--2vgEo",
	"text": "styles__text--2YZys"
};
module.exports = exports;
