import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { isValidEmail } from 'woop-shared/utils';
import { TRACKED_PROMPTS } from '../../../analytics/constants';
import { USER_PROPERTIES } from '../../../analytics/intercom/constants';
import * as intercom from '../../../analytics/intercom/utils';
import { trackUserProperty } from '../../../analytics/utils';
import {
  PATCH_JOURNEY_SUCCESS,
  POST_JOURNEY_SUCCESS,
  RESUME_JOURNEY_SUCCESS
} from '../../journey/actions/journey';
import { GET_QUOTESET_SUCCESS } from '../../quote/actions';
import {
  UPDATE_PROMPT,
  UPDATE_PROMPT_GROUP,
  DELETE_PROMPT_GROUP,
  UPDATE_PROMPT_GROUP_VALIDITY
} from '../actions/journey-data';
import { UPDATE_PROMPT_VALIDITY } from '../actions/journey-data';
import { getVal, formatJourneyData } from '../utils/journey-data';

// @todo: Simplify reducer with immer
const journeyData = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROMPT_GROUP: {
      const { promptGroupIndex, promptGroupName, name, value } = action.payload;
      // Initialize nested state
      state[promptGroupName] = state[promptGroupName] || [];
      const promptGroup = state[promptGroupName] || [];
      const prompt = promptGroup[promptGroupIndex] || {};
      prompt[name] = { ...prompt[name], value };
      promptGroup[promptGroupIndex] = prompt;
      return { ...state, [promptGroupName]: [...promptGroup] };
    }
    case DELETE_PROMPT_GROUP: {
      const { promptGroupName, promptGroupIndex } = action.payload;
      // Initialize nested state
      const promptGroup = state[promptGroupName] || [];
      promptGroup.splice(promptGroupIndex, 1);
      return { ...state, [promptGroupName]: promptGroup };
    }
    case UPDATE_PROMPT_GROUP_VALIDITY: {
      const { name, isValid, promptGroupName, promptGroupIndex } = action.payload;
      const promptGroup = state[promptGroupName] || [];
      const prompt = promptGroup[promptGroupIndex] || {};
      prompt[name] = { ...prompt[name], isValid };
      return { ...state, [promptGroupName]: [...promptGroup] };
    }
    case UPDATE_PROMPT: {
      const { name, value } = action.payload;
      const prompt = { ...state[name], value };
      return { ...state, [name]: prompt };
    }
    case UPDATE_PROMPT_VALIDITY: {
      const { name, isValid } = action.payload;
      const prompt = { ...state[name], isValid };
      return { ...state, [name]: prompt };
    }
    case POST_JOURNEY_SUCCESS:
    case PATCH_JOURNEY_SUCCESS:
    case RESUME_JOURNEY_SUCCESS:
    case GET_QUOTESET_SUCCESS: {
      const uiData = action.payload?.uiData || {};
      // Only available on quoteset_success
      const consumerInfo = action.payload?.consumerInfo;
      const email = uiData[PROMPT_NAMES.EMAIL];

      if (consumerInfo) {
        trackUserProperty(PROMPT_NAMES.FIRST_NAME, consumerInfo.firstName);
        trackUserProperty(PROMPT_NAMES.LAST_NAME, consumerInfo.lastName);
        trackUserProperty(
          USER_PROPERTIES.NAME,
          `${consumerInfo.firstName} ${consumerInfo.lastName}`
        );
      }

      if (email && isValidEmail(email)) {
        intercom.update({ [PROMPT_NAMES.EMAIL]: email });
      }

      const formattedUiData = formatJourneyData(uiData);

      // Log user properties on prefill
      TRACKED_PROMPTS.forEach(promptName => {
        const value = getVal(formattedUiData[promptName]);
        if (value) {
          trackUserProperty(promptName, value);
        }
      });

      return { ...state, ...formattedUiData };
    }
    default:
      return state;
  }
};

export default journeyData;
