import * as klaviyoSagas from '../analytics/klaviyo/sagas';
import * as analyticsSagas from '../analytics/sagas';
import * as bindingSagas from '../modules/binding/sagas/binding';
import * as boxSagas from '../modules/core/sagas/box';
import * as canopySagas from '../modules/core/sagas/canopy';
import * as journeyDataSagas from '../modules/core/sagas/journey-data';
import * as navigationSagas from '../modules/core/sagas/navigation';
import * as originationsSagas from '../modules/core/sagas/originations';
import * as originatorSagas from '../modules/core/sagas/originator';
import * as visitSagas from '../modules/core/sagas/visit';
import * as crossSellSagas from '../modules/journey/sagas/cross-sell';
import * as journeySagas from '../modules/journey/sagas/journey';
import * as pageSagas from '../modules/journey/sagas/pages';
import * as quoteSagas from '../modules/quote/sagas';

const sagas = {
  ...journeySagas,
  ...visitSagas,
  ...journeyDataSagas,
  ...pageSagas,
  ...quoteSagas,
  ...klaviyoSagas,
  ...analyticsSagas,
  ...navigationSagas,
  ...bindingSagas,
  ...originationsSagas,
  ...originatorSagas,
  ...crossSellSagas,
  ...canopySagas,
  ...boxSagas
};

export default function registerSagas(middleware) {
  Object.values(sagas).forEach(saga => middleware.run(saga));
}
