import { UPDATE_SUBMODULE_VALIDITY } from '../actions/submodules';
import { mapSubmodulesToState } from '../utils/journey-content';

const initialState = {};

const submodules = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUBMODULE_VALIDITY: {
      const { submodules, pages, journeyData } = action.payload;
      const submodValidity = mapSubmodulesToState(submodules, pages, journeyData);
      return { ...submodValidity };
    }
    default:
      return state;
  }
};

export default submodules;
