import PropTypes from 'prop-types';
import React from 'react';
import { PROMPT_GROUP_NAMES } from 'woop-shared/prompts/names';
import InfoText from '../../../../../core/components/InfoText';
import { getNumDrivingResidents } from '../../../../../core/utils/journey-data';

const ExcludedDriversInfoText = ({ journeyData }) => {
  const numDrivers = journeyData[PROMPT_GROUP_NAMES.DRIVERS]?.length || 0;
  const numDrivingResidents = getNumDrivingResidents(journeyData);
  const numExcludedDrivers = journeyData[PROMPT_GROUP_NAMES.EXCLUDED_DRIVERS]?.length || 0;
  const numExcludedDriversNeeded = numDrivingResidents - numDrivers - numExcludedDrivers;

  const text = numExcludedDriversNeeded
    ? `You listed ${numDrivingResidents} residents of driving age but only ${
        numDrivers === 1 ? `1 driver` : `${numDrivers} drivers`
      }. Please add any other residents of driving age that you wish to exclude from your policy.`
    : `Thank you for listing all of your residents!`;

  return <InfoText className="bar" text={text} />;
};

export default ExcludedDriversInfoText;

ExcludedDriversInfoText.propTypes = {
  journeyData: PropTypes.object
};
