import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import Bang from '../assets/icons/exclamation.svg';
import styles from '../css/InvalidIcon.css';

const cx = classNames.bind(styles);

const InvalidIcon = ({ className }) => (
  <span className={cx('wrapper', className)}>
    <Bang />
  </span>
);

export default InvalidIcon;

InvalidIcon.propTypes = {
  className: PropTypes.string
};
