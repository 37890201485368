import { APP_ELEMENT_ID } from '../constants/app';

export function isSmallViewport() {
  // based on our media-queries.css
  return window.innerWidth <= 550;
}

export function isLargeViewport() {
  // based on our media-queries.css
  return window.innerWidth >= 900;
}

export function appScrollToTop(smooth) {
  const appContainer = document.getElementById(APP_ELEMENT_ID);
  appContainer?.scrollIntoView({
    behavior: smooth ? 'smooth' : 'auto'
  });
}
