import { connect } from 'react-redux';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Submodels from '../../components/vehicle/Submodels';
import { getPromptGroupVal } from '../../utils/journey-data';

const mapStateToProps = ({ journeyData }, { promptGroupName, promptGroupIndex }) => {
  const year = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_YEAR
  );
  const make = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_MAKE
  );
  const model = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_MODEL
  );
  const submodel = getPromptGroupVal(
    journeyData[promptGroupName],
    promptGroupIndex,
    PROMPT_NAMES.VEHICLE_SUBMODEL
  );
  const dependentFieldVals = { year, make, model };
  return {
    dependentFieldVals,
    submodel
  };
};

export default connect(mapStateToProps)(Submodels);
