import { MFA_INPUT_TYPES } from '../../../../api/canopy/constants';

export function getText(mfa, carrierName) {
  const inputType = mfa.inputType;
  switch (inputType) {
    case MFA_INPUT_TYPES.EMAIL_OTP:
      return `You will receive a code at your email address: ${mfa.email}`;
    case MFA_INPUT_TYPES.VOICE_OTP:
      return `You should receive a call with your code at this number: ${mfa.cellphone}.`;
    case MFA_INPUT_TYPES.SMS_OTP:
      return `You should receive a text message with your code at this number: ${mfa.cellphone}.`;
    case MFA_INPUT_TYPES.SECURITY_QUESTION:
      return `Please answer the following security question: ${mfa.securityQuestion}`;
    case MFA_INPUT_TYPES.CARRIER_PIN:
      return `Enter your ${carrierName} PIN`;
    case MFA_INPUT_TYPES.OTHER_OTP:
      return `You should receive a code via: ${mfa.input}.`;
    default:
      return 'Please enter your security code';
  }
}
