import { createAction } from 'redux-actions';

export const POST_JOURNEY = 'POST_JOURNEY';
export const postJourney = createAction(POST_JOURNEY);

export const POST_JOURNEY_SUCCESS = 'POST_JOURNEY_SUCCESS';
export const postJourneySuccess = createAction(POST_JOURNEY_SUCCESS);

export const PATCH_JOURNEY = 'PATCH_JOURNEY';
export const patchJourney = createAction(PATCH_JOURNEY);

export const PATCH_JOURNEY_SUCCESS = 'PATCH_JOURNEY_SUCCESS';
export const patchJourneySuccess = createAction(PATCH_JOURNEY_SUCCESS);

const PATCH_JOURNEY_FAIL = 'PATCH_JOURNEY_FAIL';
export const patchJourneyFail = createAction(PATCH_JOURNEY_FAIL);

export const UPDATE_LOOPABLE_PAGES = 'UPDATE_LOOPABLE_PAGES';
export const updateLoopablePages = createAction(UPDATE_LOOPABLE_PAGES);

export const UPDATE_JOURNEY = 'UPDATE_JOURNEY';
export const updateJourney = createAction(UPDATE_JOURNEY);

export const SAVE_JOURNEY = 'SAVE_JOURNEY';
export const saveJourney = createAction(SAVE_JOURNEY);

export const SAVE_JOURNEY_SUCCESS = 'SAVE_JOURNEY_SUCCESS';
export const saveJourneySuccess = createAction(SAVE_JOURNEY_SUCCESS);

const SAVE_JOURNEY_FAIL = 'SAVE_JOURNEY_FAIL';
export const saveJourneyFail = createAction(SAVE_JOURNEY_FAIL);

export const TOGGLE_JOURNEY_SAVED = 'TOGGLE_JOURNEY_SAVED';
export const toggleJourneySaved = createAction(TOGGLE_JOURNEY_SAVED);

export const RESUME_JOURNEY = 'RESUME_JOURNEY';
export const resumeJourney = createAction(RESUME_JOURNEY);

export const RESUME_JOURNEY_SUCCESS = 'RESUME_JOURNEY_SUCCESS';
export const resumeJourneySuccess = createAction(RESUME_JOURNEY_SUCCESS);

export const SET_THIRD_PARTY_DATA = 'SET_THIRD_PARTY_DATA';
export const setThirdPartyData = createAction(SET_THIRD_PARTY_DATA);

export const SET_IS_QUICK_JOURNEY = 'SET_IS_QUICK_JOURNEY';
export const setIsQuickJourney = createAction(SET_IS_QUICK_JOURNEY);
