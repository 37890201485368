import PropTypes from 'prop-types';
import React from 'react';
import { getModels } from '../../utils/vehicle';
import DependentSelect from '../DependentSelect';

const Models = ({ dependentFieldVals, ...rest }) => {
  return (
    <DependentSelect
      getOptions={getModels}
      dependentFieldVals={dependentFieldVals}
      keepInitialValue
      {...rest}
    />
  );
};

export default Models;

Models.propTypes = {
  dependentFieldVals: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    make: PropTypes.string
  })
};
