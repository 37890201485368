// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--1e8v9{position:absolute;width:0;height:0;border-left:40px solid transparent;border-right:40px solid transparent;border-top:40px solid rgb(var(--woop-primary-color));transform:rotate(135deg);left:-32px;top:-7px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACT,kCAAmC,CACnC,mCAAoC,CACpC,oDAAuC,CACvC,wBAAyB,CACzB,UAAW,CACX,QACF","file":"styles.css","sourcesContent":["@import '../../../../css/variables.css';\n\n.wrapper {\n  position: absolute;\n  width: 0;\n  height: 0;\n  border-left: 40px solid transparent;\n  border-right: 40px solid transparent;\n  border-top: 40px solid $appPrimaryColor;\n  transform: rotate(135deg);\n  left: -32px;\n  top: -7px;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--1e8v9"
};
module.exports = exports;
