import { PARTNERS } from 'woop-shared/db/partners';

export const ORIGINATION_OBJECT_NAME = 'woopOriginationData';

export const ORIGINATION_FIELDS = {
  ACCOUNT_ID: 'srcAcctId',
  OID: 'oid',
  ORIGINATOR_ID: 'originatorId'
};

export const DEFAULT_ORIGINATION_DATA = {
  [ORIGINATION_FIELDS.ACCOUNT_ID]: PARTNERS.WOOP
};
