import { ENVS } from '../constants/environments';

const THIRD_PARTY_ENABLED = 'SameSite=None;Secure;'; // Learn: https://web.dev/samesite-cookie-recipes/

/**
 * Retrieve the value of a cookie by name.
 *
 * @param {string} name The name of the cookie.
 * @returns {string} The value of the cookie, or undefined.
 */
export function getCookie(name) {
  let value;
  const pieces = document.cookie.split(`; `);
  pieces.forEach(piece => {
    const [cookieName, cookieValue] = piece.split('=');
    if (cookieName === name) value = cookieValue;
  });
  return value;
}

/**
 * Set a cookie on the browser.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie.
 *
 * @param {string} name The name of the cookie.
 * @param {string} value The value of the cookie.
 * @param {Date} expiration A date in GMTString format
 */
export function setCookie(name, value, expiration) {
  let cookie = `${name}=${value};`;
  if (process.env.ENV !== ENVS.LOCAL) cookie += THIRD_PARTY_ENABLED; // Secure; won't work on http://
  if (expiration) cookie += `expires=${expiration.toGMTString()};`;
  document.cookie = cookie;
}
