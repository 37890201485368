// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LinkText__link--3Cn0H{cursor:pointer;color:rgb(var(--woop-primary-color));transition:color .5s;text-decoration:underline}.LinkText__link--3Cn0H[disabled]{color:rgba(var(--woop-primary-color),.7);pointer-events:none;-webkit-user-select:none;user-select:none}.LinkText__link--3Cn0H:hover{color:rgb(var(--woop-primary-color-darker))}.LinkText__altLink--1G3PT{text-decoration:none;color:#333;border-bottom:2px solid #6cbbed;padding-bottom:3px;display:inline-block;font-weight:400;margin-top:5px;transition:all .5s}.LinkText__altLink--1G3PT:hover{border-color:rgb(var(--woop-primary-color-darker))}", "",{"version":3,"sources":["LinkText.css"],"names":[],"mappings":"AAEA,uBACE,cAAe,CACf,oCAAuB,CACvB,oBAAgC,CAChC,yBASF,CARE,iCACE,wCAA4B,CAC5B,mBAAoB,CACpB,wBAAiB,CAAjB,gBACF,CACA,6BACE,2CACF,CAGF,0BACE,oBAAqB,CACrB,UAAW,CACX,+BAAgC,CAChC,kBAAmB,CACnB,oBAAqB,CAErB,eAAwB,CACxB,cAAe,CACf,kBAIF,CAHE,gCACE,kDACF","file":"LinkText.css","sourcesContent":["@import './variables.css';\n\n.link {\n  cursor: pointer;\n  color: $appPrimaryColor;\n  transition: color $hoverDuration;\n  text-decoration: underline;\n  &[disabled] {\n    color: $appPrimaryColorLight;\n    pointer-events: none;\n    user-select: none;\n  }\n  &:hover {\n    color: $appPrimaryColorDarker;\n  }\n}\n\n.altLink {\n  text-decoration: none;\n  color: #333;\n  border-bottom: solid 2px #6cbbed;\n  padding-bottom: 3px;\n  display: inline-block;\n  transition: all $hoverDuration;\n  font-weight: $fw_regular;\n  margin-top: 5px;\n  transition: all $hoverDuration;\n  &:hover {\n    border-color: $appPrimaryColorDarker;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"link": "LinkText__link--3Cn0H",
	"altLink": "LinkText__altLink--1G3PT"
};
module.exports = exports;
