import { useState } from 'react';
import { useTimeout } from './use-timeout';

/**
 * React Hook for exposing generic modal controls
 *
 * @returns {Function}
 * @param {boolean} open Whether the modal should be open on render.
 */
export function useModalControls(open = false) {
  const [visible, setVisible] = useState(open);
  const [closing, setClosing] = useState(false);
  const setTimer = useTimeout();

  const toggleModal = () => {
    if (visible) {
      setClosing(true);
      setTimer(() => setVisible(false), 500);
    } else {
      setClosing(false);
      setVisible(true);
    }
  };

  return [visible, closing, toggleModal];
}
