import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { MIXPANEL_PROPERTIES } from '../constants';
import { INTERCOM_EVENTS_MAP } from '../intercom/constants';
import * as intercom from '../intercom/utils';
/**
 * A convenience wrapper for logging an event to multiple platforms.
 *
 * @param {string} name An event name
 * @param {object} properties Properties about the event
 */
export function trackEvent(name, properties) {
  amplitude.getInstance().logEvent(name, properties);
  mixpanel.track(name, properties);

  // Maybe log to Intercom
  const intercomEvent = INTERCOM_EVENTS_MAP[name];
  if (intercomEvent) {
    intercom.logEvent(intercomEvent, properties);
  }
}

/**
 * Given a prompt name, log its value to our analytics services.
 * Do any property name translation if required.
 *
 * @param {string} name A user property name
 * @param {any} value
 */
export function trackUserProperty(name, value) {
  amplitude.getInstance().setUserProperties({ [name]: value });
  // Intercom
  if (name !== PROMPT_NAMES.EMAIL) {
    // Can't handle email yet because can only be set once and "testing@e" is considered valid email on our end right now.
    intercom.update({ [name]: value });
  }
  // Mixpanel
  const mixpanelPropertyName = MIXPANEL_PROPERTIES[name] || name;
  mixpanel.people.set(mixpanelPropertyName, value);
}
