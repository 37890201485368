import PropTypes from 'prop-types';
import React from 'react';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Card from '../';
import CarIcon from '../../../../../../core/assets/icons_v2/lightblue_car.svg';
import { getVal } from '../../../../../../core/utils/journey-data';
import { getLabel } from '../../../../../../core/utils/vehicle';

const VehicleCard = ({ entity, className, onClick, ...rest }) => {
  const line1 = getLabel(entity);
  const line2 = getVal(entity[PROMPT_NAMES.VEHICLE_MODEL]);
  return (
    <Card
      icon={CarIcon}
      line1={line1}
      line2={line2}
      onEdit={onClick}
      className={className}
      {...rest}
    />
  );
};

export default VehicleCard;

VehicleCard.propTypes = {
  entity: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};
