import { GENERAL_FIELDS } from 'woop-shared/db/models';
import { SET_SELECTED_QUOTES } from '../../quote/actions';
import { SET_PAYMENTS, SET_LEGAL, COPY_PAYMENT, UPDATE_PAYMENT } from '../actions/payment';
import { PAYMENT_FIELDS } from '../constants/payment-page';

const payments = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENTS: {
      const { quoteId } = action.payload;
      const paymentToUpdate = state.find(payment => payment.quoteId === quoteId);
      const newPayment = { ...paymentToUpdate, ...action.payload };
      if (!newPayment.id) newPayment.id = new Date().getTime();

      let newState = state.filter(payment => payment.quoteId !== quoteId); // Remove payment that we are updating

      // If we are updating original payment and we're not updating the plan
      const isPlanUpdate =
        paymentToUpdate?.[PAYMENT_FIELDS.PLAN.key] !== newPayment?.[PAYMENT_FIELDS.PLAN.key];
      if (!paymentToUpdate?.isCopied && !isPlanUpdate) {
        // Exclude any matching reused payments
        newState = newState.filter(payment => payment.id !== paymentToUpdate?.id);
      }
      return [...newState, newPayment];
    }
    case UPDATE_PAYMENT: {
      const { quoteId, updates } = action.payload;
      const paymentToUpdate = state.find(payment => payment.quoteId === quoteId);
      const newState = state.filter(payment => payment.quoteId !== quoteId); // Remove payment that we are updating
      return [...newState, { ...paymentToUpdate, ...updates }];
    }
    case COPY_PAYMENT: {
      const { quoteId, id } = action.payload;
      const paymentToCopy = state.find(payment => payment.id === id);
      const newState = state.filter(payment => payment.quoteId !== quoteId);
      const paymentToUpdate = state.find(payment => payment.quoteId === quoteId) || {};
      return [
        ...newState,
        {
          ...paymentToCopy,
          quoteId,
          isCopied: true,
          [PAYMENT_FIELDS.PLAN.key]: paymentToUpdate[PAYMENT_FIELDS.PLAN.key]
        }
      ];
    }
    case SET_SELECTED_QUOTES: {
      const quotes = action.payload;
      const quoteIds = quotes.map(quote => quote[GENERAL_FIELDS.ID]);
      const newState = [...state];
      return newState.filter(payment => quoteIds.includes(payment?.quoteId));
    }
    case SET_LEGAL: {
      const { quoteId, data } = action.payload;
      const paymentToUpdate = state.find(payment => payment.quoteId === quoteId) || {};
      const paymentIndex = state.indexOf(paymentToUpdate);
      if (paymentIndex < 0) return state;
      // Update legal property
      const newPayment = {
        ...paymentToUpdate,
        legal: {
          ...paymentToUpdate.legal,
          ...data
        }
      };
      const newState = [...state];
      newState[paymentIndex] = newPayment;
      return newState;
    }
    default:
      return state;
  }
};

export default payments;
