import { THEMES, WOOP_THEME_DEFAULT } from '../constants/themes';

export function initTheme(srcAcctId) {
  const theme = THEMES[srcAcctId] ? THEMES[srcAcctId] : WOOP_THEME_DEFAULT;

  // Default any values not specified in the theme.
  const styles = { ...WOOP_THEME_DEFAULT, ...theme };

  // Apply styles to the app container.
  Object.keys(styles).forEach(varName => {
    document.documentElement.style.setProperty(varName, styles[varName]);
  });
}
