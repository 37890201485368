import { connect } from 'react-redux';
import { updatePrompt, updatePromptGroup } from '../actions/journey-data';

/* Connects a component with methods to update its value in journeyData */
const mapDispatchToProps = (dispatch, ownProps) => {
  const { name, promptGroupIndex, promptGroupName } = ownProps;
  return {
    save: value => {
      promptGroupName
        ? dispatch(updatePromptGroup({ name, value, promptGroupIndex, promptGroupName }))
        : dispatch(updatePrompt({ name, value }));
    }
  };
};

export default connect(null, mapDispatchToProps);
