import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.css';

const cx = classNames.bind(styles);

const TitleIconCard = ({ heading, icon, children, className }) => {
  return (
    <div className={cx('wrapper', className)}>
      <div className={styles.headingWrapper}>
        <span className={styles.icon}>{icon()}</span>
        <span className={styles.heading}>{heading}</span>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default TitleIconCard;

TitleIconCard.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.func,
  children: PropTypes.node
};
