import { createAction } from 'redux-actions';

export const SET_PAYMENTS = 'SET_PAYMENTS';
export const setPayments = createAction(SET_PAYMENTS);

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const updatePayment = createAction(UPDATE_PAYMENT);

export const COPY_PAYMENT = 'COPY_PAYMENT';
export const copyPayment = createAction(COPY_PAYMENT);

export const SET_LEGAL = 'SET_LEGAL';
export const setLegal = createAction(SET_LEGAL);
