import { getRoles } from '../api/roles';
import { formatOptions } from './select';

/**
 * Fetch & return formatted roles based on the given industry.
 *
 * @param {object} params { industry }
 * @param {string} params.industry
 * @returns {Array} Array of objects formatted for the Select field.
 */
export async function getOptions({ industry }) {
  if (!industry) return;
  const roles = await getRoles(industry);
  const formattedOptions = formatOptions(roles);
  return formattedOptions;
}
