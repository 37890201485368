import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../css/AppWrapper.css';

const cx = classnames.bind(styles);

/**
 * Wrapper with standard desktop dimensions and full-height mobile style
 */

const AppWrapper = ({ children, className, isFloatingCardParent }) => {
  return (
    <section className={cx('wrapper', className, { isFloatingCardParent })}>{children}</section>
  );
};

export default AppWrapper;

AppWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFloatingCardParent: PropTypes.bool
};
