import { ADD_TO_LOADING, RM_FROM_LOADING } from '../actions/loading';

const loading = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_LOADING: {
      if (state.includes(action.payload)) return state;
      return [...state, action.payload];
    }
    case RM_FROM_LOADING: {
      return [...state.filter(key => key !== action.payload)];
    }
    default:
      return state;
  }
};

export default loading;
