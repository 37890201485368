// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--VdZs-{position:absolute;border:none;top:0;background:transparent;font-weight:700;color:rgb(var(--woop-white));width:30px;height:30px}.styles__wrapper--VdZs- svg{width:22px;height:22px}@media (max-width:550px){.styles__wrapper--VdZs-{display:none}.styles__wrapper--VdZs-.styles__mobileNav--3oPI6{width:100%;height:100%;border-radius:0;border:0;background:rgb(var(--woop-primary-color-darker));display:flex;margin:0;left:0}.styles__wrapper--VdZs-.styles__mobileNav--3oPI6 svg{width:24px;height:24px}}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,iBAAkB,CAClB,WAAY,CACZ,KAAQ,CACR,sBAAuB,CACvB,eAAqB,CACrB,4BAAa,CACb,UAAW,CACX,WAKF,CAJE,4BACE,UAAW,CACX,WACF,CAGF,yBACE,wBACE,YACF,CACA,iDACE,UAAW,CACX,WAAY,CACZ,eAAkB,CAClB,QAAS,CACT,gDAAkC,CAClC,YAAa,CACb,QAAW,CACX,MAKF,CAJE,qDACE,UAAW,CACX,WACF,CAEJ","file":"styles.css","sourcesContent":["@import '../../../../css/variables.css';\n\n.wrapper {\n  position: absolute;\n  border: none;\n  top: 0px;\n  background: transparent;\n  font-weight: $fw_bold;\n  color: $white;\n  width: 30px;\n  height: 30px;\n  svg {\n    width: 22px;\n    height: 22px;\n  }\n}\n\n@media (--small-viewport) {\n  .wrapper {\n    display: none;\n  }\n  .wrapper.mobileNav {\n    width: 100%;\n    height: 100%;\n    border-radius: 0px;\n    border: 0;\n    background: $appPrimaryColorDarker;\n    display: flex;\n    margin: 0px;\n    left: 0px;\n    svg {\n      width: 24px;\n      height: 24px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--VdZs-",
	"mobileNav": "styles__mobileNav--3oPI6"
};
module.exports = exports;
