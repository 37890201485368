import { QUERY_PARAMS } from '../../core/constants/url';
import { decodeUrlData, getQueryStringParam } from '../../core/utils/url';

const ACCOUNT_FIELDS = {
  CUSTOMER_ID: 'customerId',
  TEXT_CONSENT_URL: 'textConsentUrl',
  EMAIL_CONSENT_URL: 'emailConsentUrl'
};

/**
 * Decode the account data passed by the partner and pull out only the supported fields.
 *
 * @returns {object}
 */
export function getUrlAccountData() {
  const encoded = getQueryStringParam(QUERY_PARAMS.ACCOUNT);
  if (!encoded) return;
  const decoded = decodeUrlData(encoded);
  const customerId = decoded[ACCOUNT_FIELDS.CUSTOMER_ID];
  const textConsentUrl = decoded[ACCOUNT_FIELDS.TEXT_CONSENT_URL];
  const emailConsentUrl = decoded[ACCOUNT_FIELDS.EMAIL_CONSENT_URL];
  return { customerId, textConsentUrl, emailConsentUrl };
}
