import { SCENARIOS } from 'woop-shared/enums';

export function showConnectAnother(scenario) {
  switch (scenario) {
    case SCENARIOS.IV:
    case SCENARIOS.IV_CHECKLIST:
    case SCENARIOS.TEST_DRIVE:
    case SCENARIOS.ESTIMATE:
    case SCENARIOS.SIGN_UP:
      return false;
    default:
      return true;
  }
}

export function getText({ scenario, carrier, partnerName }) {
  let text = `Thank you for connecting your${carrier ? ` ${carrier}` : ''} policy! `;
  switch (scenario) {
    case SCENARIOS.SIGN_UP:
    case SCENARIOS.ESTIMATE:
      break;
    case SCENARIOS.TEST_DRIVE:
      text += `You're ready for your visit to ${partnerName}.`;
      break;
    case SCENARIOS.IV:
      text += `You will receive an email shortly.`;
      break;
    case SCENARIOS.IV_CHECKLIST:
      text += `We'll send your proof of insurance to ${partnerName}.`;
      break;
    default:
      text += `We have a few more questions before we can start working on your quotes.`;
      break;
  }

  return text.trim();
}

export function getButtonText({ scenario }) {
  switch (scenario) {
    case SCENARIOS.IV:
      return 'Finish';
    default:
      return 'Continue';
  }
}
