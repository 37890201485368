import { ConnectedRouter } from 'connected-react-router';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SCENARIOS } from 'woop-shared/enums';
import { AMPLITUDE_EVENTS } from '../analytics/amplitude/events';
import { logEvent } from '../analytics/amplitude/utils';
import AppFooter from '../modules/core/components/AppFooter';
import AppHeader from '../modules/core/components/AppHeader';
import { AppLoadingScreen } from '../modules/core/components/AppLoadingScreen';
import EnvBanner from '../modules/core/components/EnvBanner';
import LegalLinks from '../modules/core/components/LegalLinks';
import { QUERY_PARAMS } from '../modules/core/constants/url';
import { isProd } from '../modules/core/utils/config';
import { error } from '../modules/core/utils/logger';
import { getQueryStringParam } from '../modules/core/utils/url';
import AutoChecklistStart from '../modules/journey/pages/AutoChecklist/Start';
import ConnectPage from '../modules/journey/pages/ConnectPage';
import DealershipPicker from '../modules/journey/pages/DealershipPicker';
import IVConnect from '../modules/journey/pages/IVConnect';
import IVStart from '../modules/journey/pages/IVStart';
import SignUpConnect from '../modules/journey/pages/SignUpConnect';
import SignUpLanding from '../modules/journey/pages/SignUpLanding';
import Unsubscribe from '../modules/unsubscribe/components/Unsubscribe';
import Welcome from '../modules/welcome/pages/Welcome';
import WelcomeQuotes from '../modules/welcome/pages/WelcomeQuotes';
import { history } from '../store/configureStore';
import { ROUTES } from './constants/routes';
import LandingPage from './landing-page';
import styles from './styles.css';
import { shouldShowExitConfirmation } from './utils';

const lazyRoutesPromise = import(/* webpackPrefetch: true */ './lazy-routes').catch(() =>
  // Retry once
  import(/* webpackPrefetch: true */ './lazy-routes').catch(error)
);
const LazyRoutes = lazy(() => lazyRoutesPromise);

function AppRouter() {
  useEffect(() => {
    if (isProd()) {
      // Add exit confirmation listener
      window.addEventListener('beforeunload', e => {
        const pathname = history?.location?.pathname;
        if (shouldShowExitConfirmation(pathname)) {
          // Show confirmation dialog
          e.preventDefault();
          e.returnValue = ''; // Chrome needs a returnValue to show dialog
        } else {
          delete e['returnValue']; // Guarantee unload event
        }
      });
      return () => window.removeEventListener('beforeunload');
    }
  }, []);

  useEffect(() => {
    // Log tabbing and closing window interactions
    window.addEventListener('visibilitychange', function logData() {
      if (document.visibilityState === 'hidden') {
        const pathname = history?.location?.pathname;
        logEvent(AMPLITUDE_EVENTS.LEAVE_WOOP, { pathname });
      }
      if (document.visibilityState === 'visible') {
        const pathname = history?.location?.pathname;
        logEvent(AMPLITUDE_EVENTS.RETURN_TO_WOOP, { pathname });
      }
    });
  }, []);

  return (
    <ConnectedRouter history={history}>
      <AppHeader />
      <div id="woop-app-content" className="woop-app-content">
        {!isProd() && <EnvBanner />}
        <Switch>
          <Route path={ROUTES.ROOT} exact component={LandingPage} />
          <Route path={ROUTES.WELCOME} component={Welcome} />
          <Route path={ROUTES.WELCOME_QUOTES} component={WelcomeQuotes} />
          <Route path={ROUTES.UNSUBSCRIBE} component={Unsubscribe} />
          <Route
            path={ROUTES.CONNECT}
            render={() => {
              const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
              const isSignUp = scenario === SCENARIOS.SIGN_UP;
              return isSignUp ? <SignUpConnect /> : <ConnectPage />;
            }}
          />
          <Route path={ROUTES.AUTO_CHECKLIST_START} component={AutoChecklistStart} />
          <Route path={ROUTES.IV_CONNECT} component={IVConnect} />
          <Route path={ROUTES.IV_START} component={IVStart} />
          <Route path={ROUTES.DEALERSHIP_PICKER} component={DealershipPicker} />
          <Route path={ROUTES.SIGN_UP_WELCOME} component={SignUpLanding} exact />
          <Suspense fallback={<AppLoadingScreen />}>
            <LazyRoutes />
          </Suspense>
        </Switch>
      </div>
      <Route
        path={ROUTES.CONNECT}
        render={() => (
          <div className={styles.legalLinks}>
            <LegalLinks />
          </div>
        )}
      />

      <AppFooter />
    </ConnectedRouter>
  );
}

export default AppRouter;

AppRouter.propTypes = {};
