export function sanitizeTos(terms) {
  let formatted = terms;

  // Remove styles
  formatted = formatted.replace(/style="(.+)"/g, '');

  // Remove h1s
  formatted = formatted.replace(/<h1(.+)<\/h1>/g, '');

  // Add target="_blank" to <a> tags.
  formatted = formatted.replace(/<a/g, '<a target="_blank"');

  return formatted;
}
