export function updateDomWithPartnerDetails(partner) {
  const faviconUrl = partner?.appConfig?.faviconUrl;
  if (faviconUrl) {
    const favicon = document.getElementById('favicon');
    favicon.href = faviconUrl;
  }

  const htmlTitle = partner?.appConfig?.htmlTitle;
  if (htmlTitle) document.title = htmlTitle;
}
