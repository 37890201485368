import { connect } from 'react-redux';
import { scrollToPrompt } from '../actions/scroll-to';
import PromptWrapper from '../components/PromptWrapper';

const mapStateToProps = ({ scrollTo }, { name }) => {
  return {
    shouldScrollTo: !!name && scrollTo === name
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unsetScrollToPrompt: () => dispatch(scrollToPrompt())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromptWrapper);
