import { OptimizelyProvider } from '@optimizely/react-sdk';
import * as Sentry from '@sentry/react';
import { setAutoFreeze } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { init, afterRender, prefetchAssets } from './init';
import ErrorWrapper from './modules/core/components/ErrorWrapper';
import { APP_ELEMENT_ID } from './modules/core/constants/app';
import { isProd } from './modules/core/utils/config';
import AppRouter from './router';
import './modules/core/css/app.css';

setAutoFreeze(!isProd());

init().then(({ optimizely, store }) => {
  const MOUNT_NODE = document.getElementById(APP_ELEMENT_ID);

  ReactDOM.render(
    <OptimizelyProvider optimizely={optimizely}>
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={ErrorWrapper}>
          <AppRouter />
        </Sentry.ErrorBoundary>
      </Provider>
    </OptimizelyProvider>,
    MOUNT_NODE
  );

  afterRender(store);
});

prefetchAssets();
