import { SENSITIVE_PROMPT_NAMES } from '../constants';

/**
 * Maybe sanitize sensitive user data.
 *
 * @param {string} name promptName
 * @param {*} value promptValue
 * @returns {*} value
 */
export function maybeSanitizeValue(name, value) {
  if (!SENSITIVE_PROMPT_NAMES.includes(name)) return value;
  return value ? value.replace(/./g, '*') : value;
}
