/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PromptWrapper from '../../journey/containers/PromptWrapper';
import Prompt from '../components/Prompt';
import { DEFAULT_VALIDATION_MESSAGE } from '../constants/validation';
import { hasVal } from '../utils/prompt-validation';
import { mapValueToOption, saveHandler } from '../utils/select';
import WithFloatingLabel from './WithFloatingLabel';
import '../css/Select.css';

const withSelectWrapper =
  SelectComponent =>
  ({
    value,
    label,
    name,
    id,
    save,
    options = [],
    placeholder,
    filterValue,
    prompt,
    onChange = data => saveHandler(data, save),
    isValid,
    showValidation,
    validationText = DEFAULT_VALIDATION_MESSAGE,
    selectProps,
    inputId,
    tooltip
  }) => {
    const [focus, setFocus] = useState(false);

    useEffect(() => {
      if (filterValue && hasVal(value)) {
        const filteredValue = options
          .map(o => o?.value?.toString()?.toLowerCase())
          .includes(value?.toString()?.toLowerCase())
          ? value
          : null;

        if (!hasVal(filteredValue)) save(filteredValue);
      }
    }, [options, value, filterValue]);

    const getSelected = value => {
      if (!hasVal(value)) return null;

      // Handle multi-selects.
      if (Array.isArray(value)) {
        return value.map(val => getSelected(val)).filter(Boolean);
      }

      // Look for the option that this value corresponds to.
      const optionValue = options.find(option => option.value?.toString() === value?.toString());

      // If we found the option, return it.
      if (optionValue) return optionValue;

      // Otherwise return a value label object.
      // This handles values that don't exist as options (e.g. asyncs or prefills).
      return mapValueToOption(value);
    };

    return (
      <PromptWrapper name={name}>
        <Prompt text={prompt} tooltip={tooltip} name={name} />
        <WithFloatingLabel
          label={label}
          hasFocus={!!focus}
          hasValue={hasVal(value)}
          validationText={validationText}
          invalid={!isValid && showValidation}
        >
          <SelectComponent
            id={id || `${name}-container`}
            inputId={inputId || `${name}-select`}
            classNamePrefix="react-select"
            options={options}
            value={getSelected(value)}
            placeholder={label ? '' : placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={onChange}
            menuPlacement={'auto'}
            menuPortalTarget={document.querySelector('body')}
            // z-index styles: https://github.com/JedWatson/react-select/issues/404#issuecomment-433608100
            styles={{
              menuPortal: base => ({ ...base, zIndex: 10 })
            }}
            {...selectProps}
          ></SelectComponent>
        </WithFloatingLabel>
      </PromptWrapper>
    );
  };

export default withSelectWrapper;
