import { combineReducers } from 'redux';
import { POST_VISIT_SUCCESS } from '../actions/visit';

const visitId = (state = {}, action) => {
  switch (action.type) {
    case POST_VISIT_SUCCESS:
      return action.payload.visitId;
    default:
      return state;
  }
};

export default combineReducers({ visitId });
