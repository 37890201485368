import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import supplementaryInfo from '../modules/auth-entry/reducers/supplementary-info';
import binding from '../modules/binding/reducers/binding';
import payments from '../modules/binding/reducers/payments';
import box from '../modules/core/reducers/box';
import canopy from '../modules/core/reducers/canopy';
import error from '../modules/core/reducers/error';
import journeyData from '../modules/core/reducers/journey-data';
import loading from '../modules/core/reducers/loading';
import origination from '../modules/core/reducers/origination';
import originator from '../modules/core/reducers/originator';
import partner from '../modules/core/reducers/partner';
import scenario from '../modules/core/reducers/scenario';
import sidebar from '../modules/core/reducers/sidebar';
import user from '../modules/core/reducers/user';
import visit from '../modules/core/reducers/visit';
import journey from '../modules/journey/reducers/journey';
import pages from '../modules/journey/reducers/pages';
import scrollTo from '../modules/journey/reducers/scroll-to';
import submodules from '../modules/journey/reducers/submodules';
import consumerInfo from '../modules/quote/reducers/consumer-info';
import coverageTypes from '../modules/quote/reducers/coverage-types';
import mortgage from '../modules/quote/reducers/mortgage';
import quoteset from '../modules/quote/reducers/quoteset';
import selectedQuotes from '../modules/quote/reducers/selectedQuotes';

const createRootReducer = history =>
  combineReducers({
    router: history ? connectRouter(history) : null,
    journey,
    journeyData,
    pages,
    submodules,
    quoteset,
    selectedQuotes,
    sidebar,
    loading,
    visit,
    user,
    coverageTypes,
    payments,
    binding,
    supplementaryInfo,
    scrollTo,
    mortgage,
    error,
    consumerInfo,
    origination,
    partner,
    originator,
    scenario,
    campaign: state => state || null,
    box,
    canopy
  });

export default createRootReducer;
