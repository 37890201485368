import produce from 'immer';
import { CLEAR_QUOTES, GET_QUOTESET_SUCCESS } from '../actions';
import { formatBundledQuotes, formatQuotes, sortBundles, sortQuotes } from '../utils/quotes';

const INITIAL_STATE = {};

const quoteset = produce((draft, action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS: {
      const {
        agentNotes,
        currentPolicies,
        allQuotes,
        singleQuotes = [],
        effectiveDate,
        bundledQuotes = [],
        finalization
      } = action.payload;

      const formattedBundles = sortBundles(formatBundledQuotes(bundledQuotes, currentPolicies));
      const formattedSingleQuotes = sortQuotes(formatQuotes(singleQuotes, currentPolicies));

      // Set state
      return {
        ...draft,
        agentNotes,
        allQuotes,
        singleQuotes: formattedSingleQuotes,
        currentPolicies,
        effectiveDate,
        finalization,
        bundledQuotes: formattedBundles
      };
    }
    case CLEAR_QUOTES:
      return INITIAL_STATE;
  }
}, INITIAL_STATE);

export default quoteset;
