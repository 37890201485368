import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CONNECT_ROUTE_PAGES } from '../../../../../../router/constants/routes';
import { connectRoute } from '../../../../../../router/utils';
import { setMfaOption, POLL_FOR_PULL, SET_MFA_OPTION } from '../../../../actions/canopy';
import { PULL_STATUSES } from '../../../../api/canopy/constants';
import H3 from '../../../headers/H3';
import { Spinner } from '../../../Spinner';
import ConnectVisual from '../ConnectVisual';
import styles from './styles.css';

const MFAOptionsScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    canopy: { mfa, carrier, status },
    loading,
    router
  } = useSelector(state => state);

  const isLoading = loading.includes(SET_MFA_OPTION) || loading.includes(POLL_FOR_PULL);

  const submitMfa = key => {
    dispatch(setMfaOption(key));
  };

  useEffect(() => {
    if (status === PULL_STATUSES.IDENTITY_VERIFICATION)
      history.replace(connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.MFA_INPUT));
  }, [status]);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ConnectVisual logoUrl={carrier.logo} />
          <H3>Verify your login</H3>
          <p>Please choose a verification method from the options below:</p>
          <div className={styles.options}>
            {Object.entries(mfa.options).map(([key, value]) => (
              <div key={key} className={styles.option} onClick={() => submitMfa(key)}>
                {value}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MFAOptionsScreen;
