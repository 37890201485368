// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__text--3hT4J{font-size:12px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,qBACE,cACF","file":"styles.css","sourcesContent":["@import '../../../css/variables.css';\n\n.text {\n  font-size: $fontXS;\n}\n"]}]);
// Exports
exports.locals = {
	"text": "styles__text--3hT4J"
};
module.exports = exports;
