// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--3u5Io{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;padding:0 20px}.styles__wrapper--3u5Io h3{margin-top:20px;font-weight:600}.styles__wrapper--3u5Io p{margin-top:5px;font-weight:300;font-size:14px;color:rgba(var(--woop-body-text-color),.8);line-height:1.6}.styles__wrapper--3u5Io .styles__spinner--3RwOm{position:static;margin-top:20px}.styles__wrapper--3u5Io .styles__spinner--3RwOm svg{height:60px;width:100%}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,WAAY,CACZ,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CACvB,iBAAkB,CAClB,cAwBF,CAtBE,2BACE,eAAgB,CAChB,eACF,CAEA,0BACE,cAAe,CACf,eAAsB,CACtB,cAAiB,CACjB,0CAA6C,CAC7C,eACF,CAEA,gDACE,eAAgB,CAChB,eAMF,CAJE,oDACE,WAAY,CACZ,UACF","file":"styles.css","sourcesContent":["@import '../../../../../core/css/variables.css';\n\n.wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  padding: 0 20px;\n\n  h3 {\n    margin-top: 20px;\n    font-weight: $fw_semibold;\n  }\n\n  p {\n    margin-top: 5px;\n    font-weight: $fw_light;\n    font-size: $fontS;\n    color: rgba(var(--woop-body-text-color), 0.8);\n    line-height: $lineHeight;\n  }\n\n  .spinner {\n    position: static;\n    margin-top: 20px;\n\n    svg {\n      height: 60px;\n      width: 100%;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--3u5Io",
	"spinner": "styles__spinner--3RwOm"
};
module.exports = exports;
