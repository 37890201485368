import { combineReducers } from 'redux';
import { SET_USER } from '../actions/user';

const id = (state = '', action) => {
  switch (action.type) {
    case SET_USER: {
      return action.payload?.id ?? state;
    }
    default:
      return state;
  }
};

const exists = (state = false, action) => {
  switch (action.type) {
    case SET_USER: {
      return action.payload?.exists ?? state;
    }
    default:
      return state;
  }
};

export default combineReducers({
  id,
  exists
});
