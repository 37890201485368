import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import CircleButton from '../../../CircleButton';
import styles from './styles.css';

const cx = classNames.bind(styles);

const BackButton = ({ mobileNav, to, replace }) => {
  const history = useHistory();
  return (
    <CircleButton
      className={cx('wrapper', { mobileNav })}
      onClick={() => {
        if (to) return replace ? history.replace(to) : history.push(to);
        history.goBack();
      }}
    >
      <FiArrowLeft alt="Go Back" />
    </CircleButton>
  );
};

export default BackButton;

BackButton.propTypes = {
  mobileNav: PropTypes.bool,
  replace: PropTypes.bool,
  to: PropTypes.string
};
