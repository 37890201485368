import React from 'react';
import TitleIconCard from '../..';
import Headset from '../../../../../../../core/assets/icons/headset.svg';
import IntercomLink from '../../../../../../../core/components/IntercomLink';
import LinkText from '../../../../../../../core/components/LinkText';
import { LEGAL_PHONE } from '../../../../../../../core/constants/legal';

const heading = 'Need help?';

const NeedHelpWelcome = () => (
  <TitleIconCard icon={Headset} heading={heading}>
    <div>You can talk to a real person at any time.</div>
    <span>
      Speak to an agent at <LinkText href={LEGAL_PHONE}>{`${LEGAL_PHONE}`}</LinkText> or{' '}
      <IntercomLink text="chat now" />
      {'.'}
    </span>
  </TitleIconCard>
);

export default NeedHelpWelcome;
