import PropTypes from 'prop-types';
import React from 'react';
import { ICON_NAMES } from 'woop-shared/prompts/icons';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Card from '../';
import { ICONS } from '../../../../../../core/constants/icons';
import { getVal } from '../../../../../../core/utils/journey-data';

const ExcludedDriverCard = ({ entity, className, onClick, ...rest }) => {
  const line1 = getVal(entity[PROMPT_NAMES.NAME]);
  const line2 = getVal(entity[PROMPT_NAMES.EXCLUSION_REASON]);

  return (
    <Card
      icon={ICONS[ICON_NAMES.USER]}
      line1={line1}
      line2={line2}
      onEdit={onClick}
      className={className}
      {...rest}
    />
  );
};

export default ExcludedDriverCard;

ExcludedDriverCard.propTypes = {
  entity: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};
