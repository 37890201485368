import axios from 'axios';

const ENDPOINTS = {
  USERS: `${process.env.AUTH_URI}/users`
};

/**
 * Create a user record for portal access
 *
 * @param {object} params A collection of fields to save on the user.
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.dob
 * @param {string} params.email
 * @param {string} params.phone
 * @param {string} params.oid
 * @param {string} params.journeyId
 * @param {string} params.scenario
 * @returns {{ id: string, exists: boolean }} A user id
 */
export const postUser = async ({
  firstName,
  lastName,
  dob,
  email,
  phone,
  oid,
  journeyId,
  scenario
}) => {
  const uri = ENDPOINTS.USERS;
  const res = await axios
    .post(uri, { firstName, lastName, dob, email, phone, oid, journeyId, scenario })
    .catch(error => {
      if (error?.response?.status === 409) return error.response;
      throw error;
    });
  return { ...res.data, exists: res?.status === 409 };
};

/**
 * Create a pull record for a user
 
 * @param {object} params
 * @param {string} params.uid
 * @param {string} params.pullId
 * @returns {Promise}
 */
export const postPull = async ({ uid, pullId }) => {
  const uri = `${ENDPOINTS.USERS}/${uid}/pulls`;
  return axios.post(uri, { pullId });
};
