import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import InvalidIcon from '../components/InvalidIcon';
import styles from '../css/FloatingLabel.css';

const cx = classNames.bind(styles);

/* Wraps a input component with an overlaying floating label */
const WithFloatingLabel = ({ children, hasFocus, hasValue, label, invalid, validationText }) => {
  const floatingMode = hasFocus || hasValue;
  return (
    <div className={styles.wrapper}>
      {children}
      {invalid && <InvalidIcon className={cx('invalidIcon')} />}
      <label className={cx('floating-label', { floating: floatingMode })}>{label}</label>
      <span className={cx('focus-border', { focus: floatingMode, invalid })}></span>
      {invalid && <span className={cx('validation-text')}>{validationText}</span>}
    </div>
  );
};

export default WithFloatingLabel;

WithFloatingLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  hasFocus: PropTypes.bool,
  hasValue: PropTypes.bool,
  invalid: PropTypes.bool,
  validationText: PropTypes.string
};
