import { createAction } from 'redux-actions';

export const FINALIZE_BINDING = 'FINALIZE_BINDING';
export const finalizeBinding = createAction(FINALIZE_BINDING);

export const FINALIZE_BINDING_SUCCESS = 'FINALIZE_BINDING_SUCCESS';
export const finalizeBindingSuccess = createAction(FINALIZE_BINDING_SUCCESS);

export const FINALIZE_BINDING_FAIL = 'FINALIZE_BINDING_FAIL';
export const finalizeBindingFail = createAction(FINALIZE_BINDING_FAIL);

export const UPDATE_AGREEMENT = 'UPDATE_AGREEMENT';
export const updateAgreement = createAction(UPDATE_AGREEMENT);

const COMPLETE_PAYMENT_PAGE = 'COMPLETE_PAYMENT_PAGE';
export const completePaymentPage = createAction(COMPLETE_PAYMENT_PAGE);
