import { LOCATION_CHANGE } from 'connected-react-router';
import { decode } from 'html-entities';
import mixpanel from 'mixpanel-browser';
import { select, spawn, takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import { QUOTESET_FIELDS } from 'woop-shared/db/models';
import { SCENARIOS } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { getAddressComposite } from 'woop-shared/utils';
import { UPDATE_PROMPT, UPDATE_PROMPT_GROUP } from '../modules/core/actions/journey-data';
import { POST_VISIT_SUCCESS } from '../modules/core/actions/visit';
import { ORIGINATION_FIELDS } from '../modules/core/constants/origination';
import { QUERY_PARAMS } from '../modules/core/constants/url';
import { isCustomAddress } from '../modules/core/utils/address-autocomplete';
import { getVal } from '../modules/core/utils/journey-data';
import { isPrimaryDriver } from '../modules/core/utils/journey-data';
import { error } from '../modules/core/utils/logger';
import { getFirstInvalidPrompt, isValidPrompt } from '../modules/core/utils/prompt-validation';
import { getQueryStringParam } from '../modules/core/utils/url';
import { POST_JOURNEY_SUCCESS } from '../modules/journey/actions/journey';
import { REFERRER_CTA } from '../modules/journey/constants/signup';
import {
  getActiveModulePath,
  getActivePageNum,
  getActiveSubmodulePath
} from '../modules/journey/utils/journey-content';
import { getBoxFileIds, getCanopyPulls } from '../modules/journey/utils/third-party-data';
import {
  SYNC_KLAVIYO_ROUTES,
  ROUTES,
  CONNECT_ROUTE_PAGES,
  SYNC_KLAVIYO_MODULES
} from '../router/constants/routes';
import { getPathname } from '../store/utils';
import { LOG_CONNECT_CANOPY, LOG_FORM_ERROR, LOG_UPLOAD_DOCUMENTS } from './actions';
import * as amplitude from './amplitude/utils';
import { EVENTS as SHARED_EVENTS, TRACKED_PROMPTS } from './constants';
import { EVENTS } from './intercom/constants';
import * as intercom from './intercom/utils';
import { syncKlaviyoUser } from './klaviyo/utils';
import { EVENTS as OPTIMIZELY_EVENTS } from './optimizely/constants';
import * as optimizely from './optimizely/utils';
import { trackEvent, trackUserProperty } from './utils';
import { maybeSanitizeValue } from './utils/helpers';

let timeOfLastPageView;
let lastPagePathname;

function* logLocationChange() {
  try {
    const pathname = yield select(getPathname);
    const moduleName = getActiveModulePath(pathname);

    if (SYNC_KLAVIYO_MODULES.includes(moduleName) || SYNC_KLAVIYO_ROUTES.includes(pathname)) {
      const { journeyData, origination, partner } = yield select();
      syncKlaviyoUser(journeyData, origination, partner);
    }

    // Skip root for now since we don't render any page for it; we always redirect. See getLandingPage()
    if (pathname !== ROUTES.ROOT) {
      // Log view page event
      const pageName = getActiveSubmodulePath(pathname);
      const pageNum = getActivePageNum(pathname);

      const currentTimeInSeconds = new Date() / 1000;

      const eventProperties = {
        pathname,
        moduleName,
        pageName,
        pageNum,
        lastPagePathname,
        secondsSinceLastPageView:
          timeOfLastPageView && Math.round(currentTimeInSeconds - timeOfLastPageView)
      };

      trackEvent(SHARED_EVENTS.VIEW_PAGE, eventProperties);
      window.hj && window.hj('stateChange', pathname);

      timeOfLastPageView = currentTimeInSeconds;
      lastPagePathname = pathname;
    }

    // Log intercom view quotes event
    if (pathname === ROUTES.QUOTES) {
      yield spawn(logIntercomViewQuotes);
    }

    if (pathname === ROUTES.SUCCESS || pathname === ROUTES.JOURNEY_END) {
      yield spawn(logIntercomCompletedFlow, pathname);
    }

    // Log milestone events
    switch (pathname) {
      case ROUTES.SUCCESS: {
        const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
        const logCompleteEstimate = [SCENARIOS.TEST_DRIVE, SCENARIOS.ESTIMATE].includes(scenario);
        logCompleteEstimate
          ? trackEvent(SHARED_EVENTS.COMPLETE_ESTIMATE)
          : trackEvent(SHARED_EVENTS.COMPLETE_JOURNEY);
        if (scenario === SCENARIOS.SIGN_UP) trackEvent(SHARED_EVENTS.COMPLETE_SIGN_UP);
        break;
      }
      case ROUTES.JOURNEY_END:
        trackEvent(SHARED_EVENTS.COMPLETE_JOURNEY);
        break;
      case ROUTES.PAYMENT_SUBMITTED:
        trackEvent(SHARED_EVENTS.COMPLETE_BINDING);
        break;
      default:
        break;
    }
  } catch (e) {
    error(e);
  }
}

export function* watchLocationChangeForLogging() {
  yield throttle(1000, LOCATION_CHANGE, logLocationChange);
}

function* handlePostVisitSuccess({ payload }) {
  // Ensure oid is up-to-date
  const oid = payload.oid;

  // Pull origination fields from state.
  const { originatorId, srcAcctId } = yield select(state => state.origination);

  // Log to mixpanel
  mixpanel.identify(oid);
  const campaign = getQueryStringParam(QUERY_PARAMS.CAMPAIGN);
  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
  const properties = { oid, srcAcctId };
  if (originatorId) properties.originatorId = originatorId;
  if (campaign) properties.campaign = campaign;
  if (scenario) properties.scenario = scenario;
  mixpanel.people.set(properties);

  // Other platforms
  amplitude.setUserProperties({ [ORIGINATION_FIELDS.OID]: oid });
  intercom.update({ [ORIGINATION_FIELDS.OID]: oid });
  optimizely.updateUserId(oid);
}

export function* watchPostVisitSuccessForAnalytics() {
  yield takeLatest(POST_VISIT_SUCCESS, handlePostVisitSuccess);
}

function handleJourneyPostSuccess({ payload }) {
  const journeyId = payload?.journeyId;
  amplitude.setUserProperties({ journeyId });
  mixpanel.people.append('journey_ids', journeyId);
  intercom.update({ journeyId });
}

export function* watchJourneyPostSuccessForAnalytics() {
  yield takeLatest(POST_JOURNEY_SUCCESS, handleJourneyPostSuccess);
}

function* logFormValidationError() {
  const {
    journeyData,
    router: { location },
    pages
  } = yield select();
  try {
    const { prompts = [], promptGroupIndex, promptGroupName } = pages[location.pathname];

    const firstInvalidPrompt = getFirstInvalidPrompt({
      prompts,
      journeyData,
      promptGroupName,
      promptGroupIndex
    });

    const invalidPromptName = firstInvalidPrompt?.name;
    const invalidPromptValue = journeyData[invalidPromptName]?.value;

    const metadata = {
      pathname: location.pathname,
      promptName: invalidPromptName,
      promptValue: invalidPromptValue,
      promptGroupName,
      promptGroupIndex
    };

    trackEvent(SHARED_EVENTS.FORM_VALIDATION_ERROR, metadata);
  } catch (e) {
    error(e);
  }
}

export function* watchLogFormError() {
  yield takeLatest(LOG_FORM_ERROR, logFormValidationError);
}

function* logToOptimizely() {
  const pathname = yield select(getPathname);
  const { thirdPartyData, isQuickJourney } = yield select(state => state.journey);

  if (pathname.includes(CONNECT_ROUTE_PAGES.SUCCESS))
    window.optimizely?.track(OPTIMIZELY_EVENTS.CONNECTED);

  const hasFileIds = !!getBoxFileIds(thirdPartyData);

  if (pathname === ROUTES.IV_END && hasFileIds)
    window.optimizely?.track(OPTIMIZELY_EVENTS.UPLOADED);

  if (pathname.includes(ROUTES.PREJOURNEY)) {
    window.optimizely?.track(OPTIMIZELY_EVENTS.STARTED_JOURNEY);

    if (hasFileIds) window.optimizely?.track(OPTIMIZELY_EVENTS.UPLOADED);
    if (getCanopyPulls(thirdPartyData)) window.optimizely?.track(OPTIMIZELY_EVENTS.CONNECTED);

    if (!isQuickJourney) window.optimizely?.track(OPTIMIZELY_EVENTS.SELECTED_FULL_APPLICATION);
  }
}

export function* watchForOptimizelyLogging() {
  yield takeLatest([LOCATION_CHANGE], logToOptimizely);
}

function* logUpdatePrompt(action) {
  try {
    const { name, value, promptGroupName, promptGroupIndex } = action.payload;

    const promptValue = maybeSanitizeValue(name, value);

    const properties = {
      promptName: name,
      promptValue,
      promptGroupName,
      promptGroupIndex
    };

    // Handle address properties
    if (name === PROMPT_NAMES.ADDRESS) {
      properties.promptValue = getAddressComposite(value);
      properties.isCustomAddress = isCustomAddress(value);
    }

    // Track user properties to analytics services
    const _isPrimaryDriver = isPrimaryDriver(promptGroupName, promptGroupIndex);
    if (value && (!promptGroupName || _isPrimaryDriver)) {
      if (TRACKED_PROMPTS.has(name) && isValidPrompt(name, value)) {
        trackUserProperty(name, promptValue);
      }
    }

    // Track event
    yield trackEvent(SHARED_EVENTS.ANSWER_PROMPT, properties);
  } catch (e) {
    error(e);
  }
}

export function* watchUpdatePrompts() {
  yield takeEvery([UPDATE_PROMPT, UPDATE_PROMPT_GROUP], logUpdatePrompt);
}

function logAmplitudeConnectCanopy(action) {
  const { pullId } = action.payload;
  trackEvent(SHARED_EVENTS.CONNECT_CANOPY, { pullId });
}

export function* watchForAmplitudeConnectCanopy() {
  yield takeLatest([LOG_CONNECT_CANOPY], logAmplitudeConnectCanopy);
}

function logAmplitudeUploadDocuments(action) {
  const { fileIds } = action.payload;
  trackEvent(SHARED_EVENTS.UPLOAD_DOCUMENTS, { fileIds });
}

export function* watchForAmplitudeUploadDocuments() {
  yield takeLatest([LOG_UPLOAD_DOCUMENTS], logAmplitudeUploadDocuments);
}

function* logIntercomViewQuotes() {
  // Log view-quote event with agents notes to intercom to trigger a pop-up
  const quoteset = yield select(state => state.quoteset);
  const agentNotes = quoteset[QUOTESET_FIELDS.AGENT_NOTES] || '';
  intercom.logEvent(EVENTS.VIEW_QUOTES, {
    agent_notes: decode(agentNotes)
  });
}

function* logIntercomCompletedFlow(pathname) {
  const { scenario, journeyData } = yield select(state => state);
  const firstName = getVal(journeyData[PROMPT_NAMES.FIRST_NAME]);

  const getMessage = () => {
    if (pathname === ROUTES.JOURNEY_END) {
      return `Hi ${firstName}, we've received your request for quotes. An insurance professional will start searching the market for the best comparable quotes ASAP. Please let us know if you have any questions in the meantime.`;
    }

    if (pathname === ROUTES.SUCCESS) {
      switch (scenario) {
        case SCENARIOS.ESTIMATE:
        case SCENARIOS.TEST_DRIVE:
          return `Hi ${firstName}, we've received your insurance estimation request. One of our licensed insurances professionals will evaluate how each of your requested vehicles may impact your insurance costs. Please let us know if you have any questions in the meantime.`;
        case SCENARIOS.SIGN_UP: {
          const cta = getQueryStringParam(QUERY_PARAMS.REFERRER_CTA);
          if (cta === REFERRER_CTA.QUOTES || cta === REFERRER_CTA.REPORT) {
            const product =
              cta === REFERRER_CTA.QUOTES ? 'price-locked quotes' : 'insurance reports';
            return `Hi ${firstName}, thank you for signing up with us! One of our licensed insurance professionals will start working on your free ${product} shortly. Please let us know if you have any questions in the meantime.`;
          }
        }
      }
    }
  };

  const message = getMessage();

  if (message) {
    intercom.logEvent(EVENTS.COMPLETED_FLOW, {
      success_message: message
    });
  }
}
