// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper---NHNU{padding:0 10px 50px;max-width:600px;margin:auto}.styles__wrapper---NHNU .styles__hero--15Iu2{display:flex;flex-direction:column;align-items:center}.styles__wrapper---NHNU .styles__hero--15Iu2 svg{fill:rgb(var(--woop-body-text-color));width:150px;height:150px}.styles__wrapper---NHNU .styles__hero--15Iu2 svg.styles__balance--3ZVJ5{margin:20px 0;width:100px;height:100px}.styles__wrapper---NHNU .styles__title--qyPvu{text-align:center;margin:20px 0}.styles__wrapper---NHNU .styles__introText--2sF1b{font-size:18px;text-align:center}.styles__wrapper---NHNU section{margin-bottom:30px}.styles__wrapper---NHNU section h3{margin-bottom:10px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBAGE,mBAAoB,CACpB,eAAgB,CAChB,WAkCF,CAhCE,6CACE,YAAa,CACb,qBAAsB,CACtB,kBAWF,CAVE,iDACE,qCAAoB,CACpB,WAAY,CACZ,YAMF,CALE,wEACE,aAAc,CACd,WAAY,CACZ,YACF,CAIJ,8CACE,iBAAkB,CAClB,aACF,CAEA,kDACE,cAAiB,CACjB,iBACF,CAEA,gCACE,kBAIF,CAHE,mCACE,kBACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  padding: 10px;\n  padding-top: 0px; /* offset svg whitespace */\n  padding-bottom: 50px; /* offset svg whitespace */\n  max-width: 600px;\n  margin: auto;\n\n  .hero {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    svg {\n      fill: $bodyTextColor;\n      width: 150px;\n      height: 150px;\n      &.balance {\n        margin: 20px 0;\n        width: 100px;\n        height: 100px;\n      }\n    }\n  }\n\n  .title {\n    text-align: center;\n    margin: 20px 0px;\n  }\n\n  .introText {\n    font-size: $fontL;\n    text-align: center;\n  }\n\n  section {\n    margin-bottom: 30px;\n    h3 {\n      margin-bottom: 10px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper---NHNU",
	"hero": "styles__hero--15Iu2",
	"balance": "styles__balance--3ZVJ5",
	"title": "styles__title--qyPvu",
	"introText": "styles__introText--2sF1b"
};
module.exports = exports;
