import React, { useEffect, useState } from 'react';
import { getTos } from '../../../../api/canopy';
import H2 from '../../../../components/headers/H2';
import BackButton from '../../../BackButton';
import styles from './styles.css';
import { sanitizeTos } from './utils';

const TOS = () => {
  const [terms, setTerms] = useState();

  useEffect(() => {
    let cancel = false;
    getTos().then(data => !cancel && setTerms(sanitizeTos(data?.terms_html)));
    return () => (cancel = true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <BackButton className={'small'} />
      <H2 className={styles.header}>Privacy Policy</H2>
      <p className={styles.subHeader}>
        Woop Insurance Agency uses Canopy Connect to connect to your insurance provider.
      </p>

      <iframe className={styles.htmlText} srcDoc={terms} />
    </div>
  );
};

export default TOS;
