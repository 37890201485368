import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { stripNonDigits, formatMoney } from 'woop-shared/quotes/utils';
import BaseTextField from './BaseTextField';

const MoneyField = ({ name, isValid, showValidation, label, prompt, placeholder, ...rest }) => {
  const { value = '', save } = rest;
  const [formattedValue, setFormattedValue] = useState();

  useEffect(() => setFormattedValue(formatMoney(value), [value]));

  const handleInput = e => {
    const cleanedVal = stripNonDigits(e.target.value);
    save(cleanedVal);
  };

  return (
    <BaseTextField
      type="text"
      inputMode="decimal"
      name={name}
      label={label}
      value={formattedValue}
      isValid={isValid}
      showValidation={showValidation}
      placeholder={placeholder}
      prompt={prompt}
      onChange={handleInput}
    />
  );
};

export default MoneyField;

MoneyField.propTypes = {
  name: PropTypes.string,
  isValid: PropTypes.bool,
  showValidation: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  prompt: PropTypes.string
};
