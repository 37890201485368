import PropTypes from 'prop-types';
import React from 'react';
import LinkText from '../LinkText';
import styles from './styles.css';

const OriginatorCard = ({ originator }) => {
  const { avatar, name, title, email, tel, site, social } = originator;
  const LINKS = [
    { label: 'Email', url: email && `mailto:${email}` },
    { label: 'Call', url: tel && `tel:${tel}` },
    { label: 'Website', url: site },
    ...(social || [])
  ];

  return (
    <div className={styles.wrapper}>
      {avatar && (
        <div className={styles.avatar}>
          <img src={avatar} alt={name} />
        </div>
      )}

      <div className={styles.column}>
        <div className={styles.name}>{name}</div>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.links}>
          {LINKS.map((item, index) => {
            if (!item.url || !item.label) return;
            return (
              <>
                {index > 0 && <span className={styles.linkSeparator}> | </span>}
                <LinkText href={item.url} external key={item.url}>
                  {item.label}
                </LinkText>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OriginatorCard;

OriginatorCard.propTypes = {
  originator: PropTypes.object
};
