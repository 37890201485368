import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../css/Headers.css';

const cx = classnames.bind(styles);

const H2 = ({ children, className }) => <h2 className={cx('heading2', className)}>{children}</h2>;

export default H2;

H2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
