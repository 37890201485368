// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".VisualRadioGroup__radio-group--dwr1B{display:flex;flex-wrap:wrap}", "",{"version":3,"sources":["VisualRadioGroup.css"],"names":[],"mappings":"AAEA,sCACE,YAAa,CACb,cACF","file":"VisualRadioGroup.css","sourcesContent":["@import '../css/variables.css';\n\n.radio-group {\n  display: flex;\n  flex-wrap: wrap;\n}\n"]}]);
// Exports
exports.locals = {
	"radio-group": "VisualRadioGroup__radio-group--dwr1B"
};
module.exports = exports;
