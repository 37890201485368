import { GET_QUOTESET_SUCCESS } from '../../quote/actions';

const supplementaryInfo = (state = {}, action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.supplementaryInfo || {};
    default:
      return state;
  }
};

export default supplementaryInfo;
