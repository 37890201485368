import classnames from 'classnames/bind';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MOBILE_LOGO_ROUTES } from '../../../../router/constants/routes';
import AppLogo from '../AppLogo';
import OriginatorCard from '../OriginatorCard';
import styles from './styles.css';

const cx = classnames.bind(styles);

const AppHeader = () => {
  const [originator, partner] = useSelector(state => [state.originator, state.partner]);
  const location = useLocation();
  const hideMobile = !MOBILE_LOGO_ROUTES.includes(location.pathname);

  return (
    <header className={cx('wrapper', { hideMobile })}>
      <AppLogo logoUrl={partner?.logoUrl} logoMobileUrl={partner?.logoMobileUrl} />
      {originator?.showNameplate && <OriginatorCard originator={originator} />}
    </header>
  );
};

export default AppHeader;
