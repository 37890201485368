import { concatUri } from './url';

export const RESOURCES = {
  JOURNEY: 'journey',
  ORIGINATIONS: 'originations',
  ORIGINATORS: 'originators',
  PARTNERS: 'partners',
  CARRIERS: 'carriers',
  VEHICLES: 'vehicles',
  ROLES: 'roles'
};

export function getJourneyUri() {
  return concatUri(process.env.JOURNEY_URI, RESOURCES.JOURNEY);
}

export function getOriginationUri() {
  return concatUri(process.env.ORIGINATION_URI, RESOURCES.ORIGINATIONS);
}

export function getOriginatorsUri() {
  return concatUri(process.env.DATA_URI, RESOURCES.ORIGINATORS);
}

export function getRolesUri() {
  return concatUri(process.env.DATA_URI, RESOURCES.ROLES);
}

/**
 *
 * @param {string} srcAcctId Unique identifier for a Woop partner
 * @param {string} externOriginatorId Unique identifier for a Woop originator
 * @param {object} urlAccountData Account related fields passed via the URL.
 * @returns {object} An standard account object, holds attribution information for an origination
 */
export function formatAccountObject(srcAcctId, externOriginatorId, urlAccountData) {
  return {
    accountId: srcAcctId,
    externOriginatorId,
    urlAccountData
  };
}
