import React from 'react';
import H3 from '../../../../../core/components/headers/H3';
import { CONNECT_CONTENT } from './constants';
import styles from './styles.css';

const ExplainerSection = () => (
  <section className={styles.wrapper}>
    <H3>Why connect?</H3>
    {CONNECT_CONTENT.map(({ svg, text, key }) => {
      return (
        <div key={key} className={styles.row}>
          {svg()}
          {text()}
        </div>
      );
    })}
  </section>
);

export default ExplainerSection;
