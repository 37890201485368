import PropTypes from 'prop-types';
import React from 'react';
import Select, { createFilter } from 'react-select';
import withSelectWrapper from '../enhancers/WithSelectWrapper';
import { isSmallViewport } from '../utils/browser';

const SelectWithWrapper = withSelectWrapper(Select);

const SelectField = ({ selectFilterOptions, noOptionsMessage, selectProps, ...rest }) => {
  selectProps = {
    ...selectProps,
    filterOption: createFilter(selectFilterOptions),
    noOptionsMessage: () => noOptionsMessage,
    isSearchable: selectProps?.isSearchable || !isSmallViewport()
  };

  return <SelectWithWrapper selectProps={selectProps} {...rest} />;
};

export default SelectField;

SelectField.propTypes = {
  selectFilterOptions: PropTypes.object,
  noOptionsMessage: PropTypes.string,
  selectProps: PropTypes.object
};
