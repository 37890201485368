import { createAction } from 'redux-actions';

export const POST_QUOTESET = 'POST_QUOTESET';
export const postQuoteset = createAction(POST_QUOTESET);

/**
 * Fired after successful quoteset post
 */
export const POST_QUOTESET_SUCCESS = 'POST_QUOTESET_SUCCESS';
export const postQuotesetSuccess = createAction(POST_QUOTESET_SUCCESS);

export const CLEAR_QUOTES = 'CLEAR_QUOTES';
export const clearQuotes = createAction(CLEAR_QUOTES);

export const SET_SELECTED_QUOTES = 'SET_SELECTED_QUOTES';
export const setSelectedQuotes = createAction(SET_SELECTED_QUOTES);

export const GET_QUOTESET = 'GET_QUOTESET';
export const getQuoteset = createAction(GET_QUOTESET);

export const GET_QUOTESET_SUCCESS = 'GET_QUOTESET_SUCCESS';
export const getQuotesetSuccess = createAction(GET_QUOTESET_SUCCESS);
