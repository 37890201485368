import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { policeGPA } from '../utils/gpa';
import BaseTextField from './BaseTextField';

const GPAField = ({ name, isValid, label, prompt, placeholder, ...rest }) => {
  const { value = '', save } = rest;
  const [formattedValue, setFormattedValue] = useState(policeGPA(value));

  const handleInput = e => {
    const formatted = policeGPA(e.target.value, value);
    setFormattedValue(formatted);
    save(formatted);
    e.preventDefault();
  };

  return (
    <BaseTextField
      label={label}
      inputMode="decimal"
      name={name}
      value={formattedValue}
      onChange={handleInput}
      isValid={isValid}
      prompt={prompt}
      placeholder={placeholder}
    />
  );
};

export default GPAField;

GPAField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  prompt: PropTypes.string
};
