import { EXPAND_STATE, INITIAL_ANIMATION } from '../actions/sidebar';
import { expandStates } from '../constants/sidebar';

const sidebar = (state = { expandState: expandStates.closed, initialAnimation: true }, action) => {
  switch (action.type) {
    case EXPAND_STATE: {
      const currentState = state.expandState;
      const newState = action.payload;
      const hasChanged = newState !== currentState;
      const expandState = hasChanged ? newState : currentState;
      return { ...state, expandState };
    }
    case INITIAL_ANIMATION: {
      const initialAnimation = action.payload;
      return { ...state, initialAnimation };
    }
    default:
      return state;
  }
};

export default sidebar;
