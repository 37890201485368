import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../core/actions/box';
import { GET_TOKEN } from '../actions/box';
import styles from '../css/BoxUploader.css';
import { isProd } from '../utils/config';
import { Spinner } from './Spinner';

const cx = classNames.bind(styles);

const PROD_BOX_FOLDER_ID = '125453389414';
const DEV_BOX_FOLDER_ID = '125556832877';

const BoxUploader = ({ onUpload, className }) => {
  const container = useRef(null);
  const dispatch = useDispatch();
  const [token, loading] = useSelector(state => [
    state.box.token,
    state.loading.includes(GET_TOKEN)
  ]);

  useEffect(() => {
    dispatch(getToken());
  }, []);

  useEffect(() => {
    if (!window.Box?.ContentUploader || !token) return;

    const { ContentUploader } = window.Box;
    const uploader = new ContentUploader();
    const folderId = isProd() ? PROD_BOX_FOLDER_ID : DEV_BOX_FOLDER_ID;

    // Set or update listener
    if (onUpload) {
      uploader.removeListener('complete', onUpload);
      uploader.addListener('complete', onUpload);
    }

    uploader.show(folderId, token, {
      container: '#box-uploader',
      onClose: null
    });
  }, [container, window.Box, onUpload, token]);

  return (
    <>
      {loading && <Spinner />}
      <div className={cx('wrapper', className, { loading })} id="box-uploader" ref={container} />
    </>
  );
};

BoxUploader.propTypes = {
  onUpload: PropTypes.func,
  className: PropTypes.string
};

export default BoxUploader;
