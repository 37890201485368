import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { formatPhoneUS } from 'woop-shared/utils';
import { ORIGINATION_FIELDS } from '../../modules/core/constants/origination';
import { getVal } from '../../modules/core/utils/journey-data';
import { TRACKED_PROMPTS, KLAVIYO_PROPERTIES } from '../constants';
import { identify } from './api';

export function getIdentifiers(uiData) {
  const email = getVal(uiData[PROMPT_NAMES.EMAIL]);
  const phone = getVal(uiData[PROMPT_NAMES.PHONE]);
  if (email || phone) {
    return {
      $email: email || null,
      $phone_number: phone ? formatPhoneUS(phone) : null
    };
  }
}

const mapAddress = address => {
  const {
    [UI_ADDRESS_FIELDS.COMPOSITE]: composite,
    [UI_ADDRESS_FIELDS.HOUSE_NUMBER]: houseNumber,
    [UI_ADDRESS_FIELDS.STREET]: street,
    [UI_ADDRESS_FIELDS.CITY]: city,
    [UI_ADDRESS_FIELDS.STATE]: state,
    [UI_ADDRESS_FIELDS.POSTAL_CODE]: postalCode
  } = getVal(address) || {};
  const address_1 = houseNumber && street ? `${houseNumber} ${street}` : undefined;
  return {
    [KLAVIYO_PROPERTIES.FULL_ADDRESS]: composite,
    [KLAVIYO_PROPERTIES.ADDRESS_1]: address_1,
    [KLAVIYO_PROPERTIES.CITY]: city,
    [KLAVIYO_PROPERTIES.REGION]: state,
    [KLAVIYO_PROPERTIES.ZIP]: postalCode
  };
};

const getUserProperties = (uiData, origination, partner) => {
  const userProperties = {
    [KLAVIYO_PROPERTIES.ORIGINATION_ID]: origination?.id,
    [KLAVIYO_PROPERTIES.ACCOUNT_ID]: origination?.[ORIGINATION_FIELDS.ACCOUNT_ID],
    [KLAVIYO_PROPERTIES.ACCOUNT_NAME]: partner?.name,
    ...mapAddress(uiData[PROMPT_NAMES.ADDRESS])
  };

  TRACKED_PROMPTS.forEach(promptName => {
    const value = getVal(uiData[promptName]);
    if (value) userProperties[promptName] = value;
  });

  return userProperties;
};

/**
 * Send tracked user properties to a Klaviyo profile if an email or phone is present.
 *
 * @param {object} uiData
 * @param {object} origination
 * @param {object} partner
 */
export function syncKlaviyoUser(uiData, origination, partner) {
  const email = getVal(uiData[PROMPT_NAMES.EMAIL]);
  const phone = getVal(uiData[PROMPT_NAMES.PHONE]);

  if (email || phone) {
    const userProperties = getUserProperties(uiData, origination, partner);
    identify(userProperties);
  }
}
