import React from 'react';
import ConnectPage from '../ConnectPage';
import SignUpWrapper from '../SignUpWrapper';

const SignUpConnect = () => {
  return (
    <SignUpWrapper>
      <ConnectPage allowUploads={false} />
    </SignUpWrapper>
  );
};

export default SignUpConnect;
