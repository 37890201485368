import React from 'react';
import { SCENARIOS } from 'woop-shared/enums';
import { ROUTES } from '../../../../router/constants/routes';
import ConnectedLink from '../../../core/assets/icons/connected-link.svg';
import ConnectIllustration from '../../../core/assets/undraw_connect.svg';
import { QUERY_PARAMS } from '../../../core/constants/url';
import { getQueryStringParam } from '../../../core/utils/url';
import { REFERRER_CTA } from '../../constants/signup';

export function getImg(scenario) {
  switch (scenario) {
    case SCENARIOS.SIGN_UP:
      return <ConnectedLink style={{ width: 180 }} />;
    default:
      return <ConnectIllustration />;
  }
}

export function getText(scenario, partnerName, referrerCTA) {
  switch (scenario) {
    case SCENARIOS.IV:
      return `Login with your current insurer and we'll verify that you have the right coverage for a loan or lease.`;
    case SCENARIOS.IV_CHECKLIST:
      return `Login with your current insurer and we'll send your proof of insurance directly to ${partnerName}.`;
    case SCENARIOS.TEST_DRIVE:
      return `Login with your current insurer to instantly send ${partnerName} your insurance details.`;
    case SCENARIOS.SIGN_UP:
      return getSignUpText(referrerCTA);
    case SCENARIOS.ESTIMATE:
      return `Login with your current insurer so our experts can accurately estimate your new vehicle's price.`;
    default:
      return `Login with your current insurer so that we can match your coverages and ensure we're saving you money.`;
  }
}

function getSignUpText(referrerCTA) {
  const product =
    {
      [REFERRER_CTA.QUOTES]: 'price-locked quotes',
      [REFERRER_CTA.REPORT]: 'insurance report'
    }[referrerCTA] || 'insurance dashboard';

  return `Login with your current insurer to share the details we'll need to create your free ${product}.`;
}

export function getHeader() {
  return 'Connect your insurance';
}

export function getNextRoute() {
  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);

  switch (scenario) {
    case SCENARIOS.SIGN_UP:
      return ROUTES.SUCCESS;
    case SCENARIOS.TEST_DRIVE:
      return ROUTES.ESTIMATE;
    case SCENARIOS.IV_CHECKLIST:
      return ROUTES.AUTO_CHECKLIST_CHECKLIST;
    case SCENARIOS.IV:
      return ROUTES.IV_END;
    case SCENARIOS.ESTIMATE:
      return ROUTES.ESTIMATE_VEHICLES;
    default:
      return ROUTES.PREJOURNEY;
  }
}

export function maybeSendProofEmail(scenario) {
  return [SCENARIOS.IV, SCENARIOS.IV_CHECKLIST, SCENARIOS.TEST_DRIVE].includes(scenario);
}

/**
 * Returns a boolean indicating if we should run the explainer experiment for the given scenario.
 *
 * @param {string} scenario
 * @returns {boolean} True if we shouldn't run the experiment.
 */
export function shouldPreventExplainer(scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO)) {
  return [
    SCENARIOS.IV,
    SCENARIOS.IV_CHECKLIST,
    SCENARIOS.SIGN_UP,
    SCENARIOS.TEST_DRIVE,
    SCENARIOS.ESTIMATE
  ].includes(scenario);
}

/**
 * Get the upload text, run the optimizely experiment if scenario is IV.
 *
 * @param {string} scenario The scenario.
 * @returns {string} 'Upload or take a photo' || "Can't connect?"
 */
export function getUploadText(scenario) {
  const OPTIONS = {
    UPLOAD: 'Upload or take a photo',
    CANT: "Can't connect?"
  };
  if (scenario === SCENARIOS.IV) return OPTIONS.CANT;
  return OPTIONS.UPLOAD;
}
