import { LOCATION_CHANGE, push } from 'connected-react-router';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { GENERAL_FIELDS } from 'woop-shared/db/models';
import { ROUTES } from '../../../router/constants/routes';
import { hasRouteableQuoteId } from '../../../router/utils';
import { addToLoading, rmFromLoading } from '../../core/actions/loading';
import { JOURNEY_ID } from '../../core/constants/cookies';
import { QUERY_PARAMS } from '../../core/constants/url';
import { setCookie } from '../../core/utils/cookies';
import * as logger from '../../core/utils/logger';
import { hasVal } from '../../core/utils/prompt-validation';
import { getQueryStringParam } from '../../core/utils/url';
import { setSelectedQuotes } from '../../quote/actions';
import { postQuotesetSuccess, clearQuotes, POST_QUOTESET_SUCCESS, POST_QUOTESET } from '../actions';
import { GET_QUOTESET, getQuotesetSuccess } from '../actions/';
import * as api from '../api';

function* handlePostQuoteset(action) {
  yield put(addToLoading(POST_QUOTESET));
  const { journey } = yield select(state => state);
  const journeyId = journey.journeyId;
  const notifyConsumer = action?.payload?.notifyConsumer ?? true;
  const redirect = action?.payload?.redirect ?? true;
  const context = action?.payload?.context || null;
  try {
    yield call(api.postQuoteset, { journeyId, notifyConsumer, context });
    yield put(postQuotesetSuccess({ redirect }));
    setCookie(JOURNEY_ID, ''); // Clear the journey on a successful quote request.
  } catch (error) {
    logger.error(error, { journeyId });
  }
  yield put(rmFromLoading(POST_QUOTESET));
}

export function* watchPostQuotes() {
  yield takeLatest([POST_QUOTESET], handlePostQuoteset);
}

function* handlePostQuotesetSuccess({ payload }) {
  const { redirect } = payload;
  if (!redirect) return;
  yield put(push(ROUTES.JOURNEY_END));
}

export function* watchPostQuotesetSuccessfulResponse() {
  yield takeLatest([POST_QUOTESET_SUCCESS], handlePostQuotesetSuccess);
}

function* handleJourneyConfirmationLocationLanding(action) {
  const { pathname } = action.payload.location;
  if (pathname === ROUTES.JOURNEY_CONFIRMATION) yield put(clearQuotes());
}

export function* watchJourneyConfirmationLocationLanding() {
  yield takeLatest(LOCATION_CHANGE, handleJourneyConfirmationLocationLanding);
}

function* handleGetQuoteset(action) {
  try {
    const {
      quoteset: { id }
    } = yield select(state => state);

    yield put(addToLoading(GET_QUOTESET));

    const {
      agentNotes,
      allQuotes,
      singleQuotes,
      currentPolicies,
      bundledQuotes,
      coverageTypes,
      supplementaryInfo,
      mortgage,
      effectiveDate,
      consumerInfo,
      finalization,
      uiData
    } = yield call(api.getQuoteset, { id });

    yield put(
      getQuotesetSuccess({
        agentNotes,
        allQuotes,
        singleQuotes,
        currentPolicies,
        bundledQuotes,
        coverageTypes,
        supplementaryInfo,
        mortgage,
        effectiveDate,
        consumerInfo,
        finalization,
        uiData
      })
    );
  } catch (error) {
    logger.error(error, action.payload);
  }
  yield put(rmFromLoading(GET_QUOTESET));
}

export function* watchGetQuoteset() {
  yield takeLatest(GET_QUOTESET, handleGetQuoteset);
}

function* handleGetQuotesetSuccess({ payload: { allQuotes, bundledQuotes } }) {
  const { paymentFailedQuoteId } = yield select(state => state.quoteset);
  const hasRouteableQuote = hasRouteableQuoteId();
  if (hasRouteableQuote) {
    const quoteId = getQueryStringParam(QUERY_PARAMS.QUOTE_ID);
    const bundleId = getQueryStringParam(QUERY_PARAMS.BUNDLE_ID);
    // Set selected quotes based on id in url.
    const bundle = bundledQuotes?.find(bundle => bundle[GENERAL_FIELDS.ID] === bundleId);
    const selectedQuotes = bundle
      ? bundle.quotes
      : [allQuotes.find(q => q[GENERAL_FIELDS.ID] === quoteId)];
    yield put(setSelectedQuotes(selectedQuotes));
    return;
  }

  if (paymentFailedQuoteId) {
    const failedQuote = allQuotes.find(q => q[GENERAL_FIELDS.ID] === paymentFailedQuoteId);
    yield put(setSelectedQuotes([failedQuote]));
    yield put(push(ROUTES.PAYMENT));
    return;
  }

  // Conditional routing
  const { supplementaryInfo } = yield select(state => state);
  if (hasVal(supplementaryInfo)) {
    yield put(push(ROUTES.SUPPLEMENTARY_INFO));
  } else {
    yield put(push(ROUTES.QUOTES));
  }
}

export function* watchGetQuotesetSuccess() {
  yield takeLatest(getQuotesetSuccess, handleGetQuotesetSuccess);
}
