import { capitalize } from 'woop-shared/utils';

export const THIRD_PARTY_FIELDS = {
  BOX: 'box',
  CANOPY: 'canopy'
};

/**
 * Front-end data model for a canopy pull object
 */
export const CANOPY_FIELDS = {
  CARRIER: 'carrier',
  COVERAGE_TYPES: 'coverageTypes',
  PULL_ID: 'pullId',
  PULL_JWT: 'pullJwt'
};

const BOX_FIELDS = {
  FILE_IDS: 'fileIds'
};

export function getCarriers(thirdPartyData) {
  const pulls = getCanopyPulls(thirdPartyData);
  return pulls
    ?.map(pull => {
      const carrier = pull?.[CANOPY_FIELDS.CARRIER];
      if (!carrier) return null;
      const coverageTypes = pull?.[CANOPY_FIELDS.COVERAGE_TYPES] || [];
      const covTypeText = coverageTypes.map(c => capitalize(c)).join(', ');
      return covTypeText ? `${carrier} ${covTypeText}` : `${carrier}`;
    })
    .filter(Boolean);
}

export function getBoxFileIds(thirdPartyData) {
  return thirdPartyData?.[THIRD_PARTY_FIELDS.BOX]?.[BOX_FIELDS.FILE_IDS];
}

export function getCanopyPulls(thirdPartyData) {
  return thirdPartyData?.[THIRD_PARTY_FIELDS.CANOPY];
}

/**
 * Get the last canopy pull from the pulls array
 *
 * @param {object} thirdPartyData
 * @returns {object|null} A canopy pull
 */
export function getLatestPull(thirdPartyData) {
  const pulls = getCanopyPulls(thirdPartyData) || [];
  return pulls[pulls.length - 1] || null;
}

/**
 * Has the user connected or uploaded?
 *
 * @param {object} thirdPartyData
 * @returns {boolean}
 */
export function hasConnected(thirdPartyData) {
  return !!getBoxFileIds(thirdPartyData) || !!getCanopyPulls(thirdPartyData);
}
