// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__checkbox--fDIm1{padding:0}.styles-module__checkbox--fDIm1 p{margin-top:0;margin-bottom:0}", "",{"version":3,"sources":["styles.module.css"],"names":[],"mappings":"AAAA,gCACE,SAKF,CAJE,kCACE,YAAa,CACb,eACF","file":"styles.module.css","sourcesContent":[".checkbox {\n  padding: 0px;\n  p {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"checkbox": "styles-module__checkbox--fDIm1"
};
module.exports = exports;
