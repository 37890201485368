import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.css';

const cx = classnames.bind(styles);

const InfoText = ({ text, children, className, icon }) => (
  <aside className={cx('wrapper', className)}>
    {icon && <span className={styles.icon}>{icon}</span>}
    {text}
    {children}
  </aside>
);

export default InfoText;

InfoText.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node
};
