// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Textarea__wrapper--wke42>textarea{font-size:16px;color:rgb(var(--woop-body-text-color));border-color:#ccc;width:100%;height:80px;box-sizing:border-box;outline-color:rgb(var(--woop-primary-color));padding:10px;letter-spacing:.1mm}.Textarea__wrapper--wke42>textarea::placeholder{color:rgb(var(--woop-placeholder-text-color))}", "",{"version":3,"sources":["Textarea.css"],"names":[],"mappings":"AAGE,mCACE,cAAuB,CACvB,sCAAqB,CACrB,iBAA+B,CAC/B,UAAW,CACX,WAAY,CACZ,qBAAsB,CACtB,4CAA+B,CAC/B,YAAa,CACb,mBAIF,CAHE,gDACE,6CACF","file":"Textarea.css","sourcesContent":["@import './variables.css';\n\n.wrapper {\n  & > textarea {\n    font-size: $fontDefault;\n    color: $bodyTextColor;\n    border-color: $inputBorderColor;\n    width: 100%;\n    height: 80px;\n    box-sizing: border-box;\n    outline-color: $appPrimaryColor;\n    padding: 10px;\n    letter-spacing: $letterSpacing;\n    &::placeholder {\n      color: $placeholderTextColor;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "Textarea__wrapper--wke42"
};
module.exports = exports;
