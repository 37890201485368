// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--31dGC{height:100%;display:flex;flex-direction:column;box-sizing:border-box}.styles__wrapper--31dGC h3{font-size:16px}.styles__wrapper--31dGC p{margin-top:5px;line-height:1.6;font-weight:300;font-size:14px;color:rgba(var(--woop-body-text-color),.8)}.styles__wrapper--31dGC .styles__options--NMaej{display:flex;flex-direction:column;grid-gap:15px;gap:15px;overflow-y:auto}.styles__wrapper--31dGC .styles__option--3BmKl{background:#ddd;padding:15px 20px;border-radius:5px;background:hsla(0,0%,93.7%,.5);cursor:pointer}.styles__wrapper--31dGC .styles__option--3BmKl:hover{background:#efefef;transisiton:colors .2s ease-in-out}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,WAAY,CACZ,YAAa,CACb,qBAAsB,CACtB,qBAiCF,CA/BE,2BACE,cACF,CAEA,0BACE,cAAe,CACf,eAAwB,CACxB,eAAsB,CACtB,cAAiB,CACjB,0CACF,CAEA,gDACE,YAAa,CACb,qBAAsB,CACtB,aAAS,CAAT,QAAS,CACT,eACF,CAEA,+CACE,eAAgB,CAChB,iBAAkB,CAClB,iBAAkB,CAClB,8BAA0B,CAC1B,cAMF,CAJE,qDACE,kBAAkC,CAClC,kCACF","file":"styles.css","sourcesContent":["@import '../../../../../core/css/variables.css';\n\n.wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n\n  h3 {\n    font-size: $fontDefault;\n  }\n\n  p {\n    margin-top: 5px;\n    line-height: $lineHeight;\n    font-weight: $fw_light;\n    font-size: $fontS;\n    color: rgba(var(--woop-body-text-color), 0.8);\n  }\n\n  .options {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    overflow-y: auto;\n  }\n\n  .option {\n    background: #ddd;\n    padding: 15px 20px;\n    border-radius: 5px;\n    background: $tableRowColor;\n    cursor: pointer;\n\n    &:hover {\n      background: rgba(239, 239, 239, 1);\n      transisiton: colors 200ms ease-in-out;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--31dGC",
	"options": "styles__options--NMaej",
	"option": "styles__option--3BmKl"
};
module.exports = exports;
