import { enums } from '@optimizely/react-sdk';
import * as logger from '../../modules/core/utils/logger';
import { AMPLITUDE_EVENTS } from '../amplitude/events';
import * as amplitude from '../amplitude/utils';
import { ANONYMOUS_USER_ID_PREFIX, LOG_DECISION_TYPES, USER_FIELDS } from './constants';

/**
 * Tell optimizely to use our custom logging framework.
 *
 * @see https://docs.developers.optimizely.com/rollouts/docs/customize-logger
 * @param {string} level indicating the level of the log message
 * @param {string} message indicating the log message
 */
export function optimizelyLogger(level, message) {
  const LOG_LEVEL = enums.LOG_LEVEL;
  switch (level) {
    case LOG_LEVEL.INFO:
    case LOG_LEVEL.DEBUG:
      // eslint-disable-next-line no-console
      process.env.OPTIMIZELY_DEBUG && console.log(message);
      break;

    case LOG_LEVEL.WARNING:
    case LOG_LEVEL.ERROR:
      logger.log(message);
      break;
  }
}

/**
 * Send optimizely feature decisions to Amplitude.
 *
 * @see https://docs.developers.optimizely.com/rollouts/docs/set-up-notification-listener
 * @param {object} decisionObject Optimizely's decision object.
 */
export function onDecision(decisionObject) {
  if (LOG_DECISION_TYPES.includes(decisionObject.type)) {
    amplitude.logEvent(AMPLITUDE_EVENTS.OPTIMIZELY_DECISION, {
      userId: decisionObject.userId,
      event: decisionObject.type,
      properties: decisionObject.decisionInfo
    });
  }
}

/**
 * Set the optimizely user id.
 *
 * @param {string} id
 */
export function updateUserId(id) {
  if (window.optimizely && id) {
    window.optimizely.setUser({
      [USER_FIELDS.ID]: id
    });
  }
}

/**
 * Generate a random id for optimizely users that don't have OIDs.
 *
 * Note: this gets overwritten by the OID once visit(POST) returns and optimizely is enabled.
 * The reason we use this is so optimizely's feature logic is usable from init.
 * Without a userId, optimizely will mark all features as disabled.
 *
 * @returns {string}
 */
export function generateAnonymousUserId() {
  return `${ANONYMOUS_USER_ID_PREFIX}_${Math.random(0, 6).toString()}`;
}
