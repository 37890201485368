import { LOCATION_CHANGE } from 'connected-react-router';
import { combineReducers } from 'redux';
import { FINALIZE_BINDING_FAIL, UPDATE_AGREEMENT } from '../actions/binding';
import { BINDING_FIELDS } from '../constants/payment-page';

const BINDING_FAIL_MESSAGE = `There was an error processing your payment details.
Please review your information and try again.`;

function error(state = null, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return null;
    case FINALIZE_BINDING_FAIL:
      return BINDING_FAIL_MESSAGE;
    default:
      return state;
  }
}

const agreeToDocs = (state = false, action) => {
  switch (action.type) {
    case UPDATE_AGREEMENT: {
      const value = action.payload[BINDING_FIELDS.AGREE_TO_DOCS.key];
      return value === undefined ? state : value;
    }
    default:
      return state;
  }
};

export default combineReducers({ error, agreeToDocs });
