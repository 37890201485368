// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Headers__heading1--W89T8{font-size:40px;margin:0;font-weight:600}@media (max-width:550px){.Headers__heading1--W89T8{font-size:36px}}@media (max-width:320px){.Headers__heading1--W89T8{font-size:32px}}.Headers__heading2--2jyjT{font-size:24px}.Headers__heading2--2jyjT,.Headers__heading3--1z3EK{margin:0;font-weight:600}.Headers__heading3--1z3EK{font-size:18px}", "",{"version":3,"sources":["Headers.css"],"names":[],"mappings":"AAEA,0BACE,cAAoB,CACpB,QAAS,CACT,eAOF,CANE,yBAJF,0BAKI,cAKJ,CAJE,CACA,yBAPF,0BAQI,cAEJ,CADE,CAGF,0BACE,cAGF,CAEA,oDAJE,QAAS,CACT,eAOF,CAJA,0BAEE,cAEF","file":"Headers.css","sourcesContent":["@import '../../core/css/variables.css';\n\n.heading1 {\n  font-size: $headingL;\n  margin: 0;\n  font-weight: $fw_semibold;\n  @media (--small-viewport) {\n    font-size: $headingLMobile;\n  }\n  @media (--iphoneSE-viewport) {\n    font-size: $headingM;\n  }\n}\n\n.heading2 {\n  font-size: $fontXXL;\n  margin: 0;\n  font-weight: $fw_semibold;\n}\n\n.heading3 {\n  margin: 0;\n  font-size: $fontL;\n  font-weight: $fw_semibold;\n}\n"]}]);
// Exports
exports.locals = {
	"heading1": "Headers__heading1--W89T8",
	"heading2": "Headers__heading2--2jyjT",
	"heading3": "Headers__heading3--1z3EK"
};
module.exports = exports;
