import { FEE_FIELDS as FIELDS } from 'woop-shared/quotes/enums';
import { formatPremium } from './premium';

/**
 * Get the fees for the payment method.
 *
 * @param {object} fees The fees object.
 * @param {string} paymentMethod A payment method.
 * @returns {object} The fee object, e.g. { installmentFee: 2, serviceCharge: 0 }
 */
export function getFee(fees = {}, paymentMethod) {
  return fees?.[paymentMethod];
}

/**
 * Format a fee for display.
 *
 * @param {object} feeObject e.g. { installmentFee: 2, serviceCharge: 0 }
 * @returns {string}
 */
export function displayFee(feeObject = {}) {
  if (!feeObject?.[FIELDS.INSTALLMENT_FEE] > 0 && !feeObject?.[FIELDS.SERVICE_CHARGE] > 0)
    return `No fee!`;
  const fee =
    parseFloat(feeObject[FIELDS.INSTALLMENT_FEE]) || parseFloat(feeObject[FIELDS.SERVICE_CHARGE]);
  const hasInstallmentFee = feeObject?.[FIELDS.INSTALLMENT_FEE] > 0;
  return `${formatPremium(fee, 2)} fee per ${hasInstallmentFee ? `installment` : 'transaction'}`;
}

/**
 * Get the fee as a single number.
 *
 * @param {object} fee The fees object.
 * @param {boolean} isPaidInFull Whether this is the paid-in-full plan.
 * @returns {number} The fee (0 if no fee).
 */
export function getParsedFee(fee = {}, isPaidInFull) {
  const tempFee =
    parseFloat(fee?.[FIELDS.INSTALLMENT_FEE]) || parseFloat(fee?.[FIELDS.SERVICE_CHARGE]) || 0;
  return fee?.[FIELDS.INSTALLMENT_FEE] > 0 && isPaidInFull ? 0 : parseFloat(tempFee);
}

/**
 * Add the fee to the premium amount.
 *
 * @param {number} premiumAmount The premoum amount.
 * @param {number} fee The fee amount.
 * @param {boolean} isPaidInFull Whether this is the paid-in-full plan.
 * @returns {number} The premium + fee.
 */
export function addFees(premiumAmount, fee, isPaidInFull) {
  const parsedFee = getParsedFee(fee, isPaidInFull);
  return parseFloat(premiumAmount) + parsedFee;
}

/**
 * Given a full fee object, check if there any installment fees.
 *
 * @param {object} fees The full fees object with payment methods and fees.
 * @returns {boolean} whether or not there are installment fees.
 */
export function hasInstallmentFees(fees) {
  return (
    !!fees && !!Object.values(fees).find(feeObjects => feeObjects?.[FIELDS.INSTALLMENT_FEE] > 0)
  );
}
