import PropTypes from 'prop-types';
import React from 'react';
import { LEGAL_PHONE, LEGAL_INFO_EMAIL } from '../../../constants/legal';
import H3 from '../../headers/H3';
import styles from './styles.css';

const PrivacyPolicyText = () => {
  return (
    <div className={styles.wrapper}>
      <strong>{`Updated February 9, 2023`}</strong>
      <div>
        {`Thank you for choosing to engage with ItsWoop Technologies LLC ("IWT" or "Company"). This Privacy Policy explains how IWT and its affiliates (collectively "IWT") collect, share and use your information when you use our application and websites, including `}
        <a href="https://www.woopinsuranceagency.com" target="_blank" rel="noreferrer">
          https://www.woopinsuranceagency.com
        </a>{' '}
        and{' '}
        <a href="https://www.woopapp.io" target="_blank" rel="noreferrer">
          https://www.woopapp.io
        </a>
        {` ("Sites"), engage with our products and services ("Services"), when you communicate with us ("Communications"), and when we interact with our Business Partners (defined below). This Privacy Policy also explains options you have with regard to your information.`}
      </div>
      <H3>{`Our Privacy Assurance`}</H3>
      <ul>
        <li>{`We do not share your information with anyone except the IWT Group and the automobile dealership through which you are contracting or potentially contracting for the purchase or lease of an automotive vehicle (the "Dealer") and other non-affiliate companies involved in the automotive buying and ownership maintenance process such as lending and financing institutions ("Lenders") and insurance companies that would use it to contact you about their own products and services, or with other organizations permitted pursuant to a joint marketing agreement (collectively "Business Partners").`}</li>
        <li>{`We require all persons or organizations that represent or assist us in servicing your policy and claims to keep your information confidential.`}</li>
        <li>{`We require our employees to protect your personal information and keep it confidential.`}</li>
      </ul>
      <div>{`As you can see from our privacy assurance, protecting your Personal Information is important to us. In addition to the above practices, we use an array of physical, technical and administrative security measures that help us to safeguard your information. For instance, access to sensitive personal information including but not limited to Date of Birth ("DOB") and payment information, is restricted to our employees, agents and others who use such information only as permitted by law, to comply with applicable local, state and federal law, to provide you with products and services. Our privacy practices continue to apply to your Personal Information whether or not you complete the sale or leasing of any product or service.`}</div>
      <H3>{`What Personal Information Do We Have and Where Do We Get It?`}</H3>
      <div>{`We gather information about you:`}</div>
      <ul>
        <li>
          <u>{`Directly from you`}</u>
          {` when you sign-up for an account, use our Sites, use our Services, or Communicate with us, including but not limited to:`}
          <ul>
            <li>{`Your contact information, e-mail address, telephone number, mailing/billing address, demographic information, username, and password;`}</li>
            <li>{`Date of birth, driver's license number, and related information;`}</li>
            <li>{`Marital status;`}</li>
            <li>{`Information about hour household and their driving and vehicle information;`}</li>
            <li>{`Expired and current insurance policies;`}</li>
            <li>{`Details of orders and transactions you carry out through our Sites and Services, including product information, payment information, and credit card information;`}</li>
            <li>{`Any information you provide to us when you report a problem with our Sites;`}</li>
            <li>{`Records and copies of your correspondence with us;`}</li>
            <li>{`Any information you provide to us when you participate in one of our promotions or sweepstakes or surveys;`}</li>
            <li>{`Any information you provide on our Sites including product reviews, comments, questions, and answers; and`}</li>
            <li>{`Your search queries on the Sites.`}</li>
          </ul>
        </li>
        <li>
          <u>{`Automatically`}</u>
          {` when you use our Sites or Communicate with us, including but not limited to:`}
          <ul>
            <li>{`Details of your visits to the Sites, including traffic data, location data, logs and other communication data;`}</li>
            <li>{`Information about your computer, mobile device, and internet connection, including your cookies, hashed email addresses, mobile device IDs including advertising identifiers, IP address, operating system, date and time information, browser type, and other technical information about your computer and browsing information; and`}</li>
            <li>{`Interactivity with an e-mail, including opening, navigating, and click-through information.`}</li>
          </ul>
        </li>
        <li>
          <u>{`From Business Partners`}</u>
          {`, including but not limited to:`}
          <ul>
            <li>{`Your driving history, credit history, and other information relating to your vehicles and credit;`}</li>
            <li>{`Data analytics providers for the purpose of receiving statistical data about your activity on our Sites;`}</li>
            <li>{`Information provided from internet service providers, fraud prevention services, and related partners;`}</li>
            <li>{`Publicly available information from public sources, including local, state, and federal government agencies and departments, news sources, and other related databases and sources; and`}</li>
            <li>{`Data brokers, private databases, and other related sources.`}</li>
          </ul>
        </li>
      </ul>
      <H3>{`How We Use Your Information`}</H3>
      <div>{`We may use your information to:`}</div>
      <ul>
        <li>{`Alert you about new features on our Sites as it relates to our Services;`}</li>
        <li>{`Send you text messages, place telephone calls, or send you Communications concerning our Services and Sites;`}</li>
        <li>{`Fulfill an activity, service or transaction you requested or to service your policy;`}</li>
        <li>{`Market our Services;`}</li>
        <li>{`Prevent fraud;`}</li>
        <li>{`Manage and operate our Sites;`}</li>
        <li>{`Comply with requests from regulatory and law enforcement authorities. In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We may also disclose your personal information as required by law, such as to comply with a subpoena or other legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a lawful government request;`}</li>
        <li>{`Participate in insurance support organizations;`}</li>
        <li>{`Facilitate our work with our Business Partners;`}</li>
        <li>{`Make it possible for companies that perform services on our behalf, such as marketing, credit/debit card processing, and communication services, to do so`}</li>
        <li>{`Allow Business Partners that assist us with tracking how visitors use the Websites;`}</li>
        <li>{`Enable a business or businesses to conduct actuarial or research studies;`}</li>
        <li>{`Resolve disputes, investigate problems, and enforce our terms;`}</li>
        <li>{`Help diagnose problems with our Sites and Services;`}</li>
        <li>{`Comply with those who request information pursuant to a subpoena or court order; and`}</li>
        <li>{`Perform a business transaction, such as a merger, acquisition, sale of assets, bankruptcy, or related transactions.`}</li>
      </ul>
      <div>{`We and our Business Partners may use your information, such as your driving and claims / credit history, to calculate an accurate price for your insurance. New or updated information may be used to calculate your renewal premium. We may also collect third-party information from you to carry out the necessary services and/or requests, including adding another driver to your existing policy.`}</div>
      <div>{`IWT and its Business Partners may also use information collected through your use of the Sites and our Communications (e.g., your operating system, links you used to visit our Sites, web pages you viewed while visiting our Sites, Internet Protocol (IP) addresses, and cookies). We use cookies, analytics and other similar tracking technologies to help:`}</div>
      <ul>
        <li>{`Evaluate our marketing campaigns;`}</li>
        <li>{`Analyze how customers use our Sites;`}</li>
        <li>{`Develop new Services;`}</li>
        <li>{`Know how many visitors have seen or clicked on our ads; and`}</li>
        <li>{`Also, our Business Partners may assist us with monitoring information including, but not limited to, IP addresses, domain names and browser data, which can help us to better understand how visitors use the Sites.`}</li>
      </ul>
      <H3>{`How We Share Your Information`}</H3>
      <div>{`We may disclose or share your information for a variety of purposes, including:`}</div>
      <ul>
        <li>
          <u>{`With your consent`}</u>
          {`. We may disclose or share your information with your consent. We may obtain your consent in writing; online, through "click-through" agreements; when you accept our terms; orally, either in person or on the phone; or by other means.`}
        </li>
        <li>
          <u>{`In a business transfer`}</u>
          {`. We may disclose or share your information as part of a corporate business transaction, such as a merger or acquisition, joint venture, corporate reorganization, financing, or sale of company assets, or in the unlikely event of insolvency, bankruptcy, or receivership, in which such information could be transferred to third parties as a business asset in the transaction.`}
        </li>
        <li>
          <u>{`To Third-Parties`}</u>
          {`. We may disclose or share your information with certain Third-Parties to bring you the products and services we offer and to facilitate your access and use of our Sites, including but not limited to internet service providers, advertising networks, data analytics providers, governmental entities, operating systems and platforms, social medial networks, and service providers who provide us a service (e.g., credit / debit card processing, billing, shipping, repair, customer service, auditing, marketing, advertisement measurement services, debugging to identify and repair errors that impair existing intended functionality on our Sites, and/or protecting against malicious, deceptive, fraudulent, or illegal activity). For example, we may work with third parties to: (a) manage a database of customer information; (b) assist us in distributing e-mails; (c) assist us with direct marketing and data collection; (d) provide data storage and analysis; (e) provide fraud prevention; (f) provide customer service; (g) provide product fulfillment and shipping services; and (h) provide other services designed to assist us in developing and running our Sites and maximizing our business potential.`}
        </li>
        <li>
          <u>{`To subsidiaries and affiliates`}</u>
          {`. We may disclose or share your information with our subsidiaries and affiliates to further facilitate your use of our Sites, and to ensure the smooth and consistent operations of our Sites and Services by identifying and repairing errors that impede intended functionality and to protect against malicious, deceptive, fraudulent, or illegal activity.`}
        </li>
        <li>
          <u>{`For legal process and protection`}</u>
          {`. We may disclose or share your information to satisfy any law, regulation, legal process, governmental request, or where we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to:`}
          <ul>
            <li>{`Enforce or apply agreements, or initiate, render, bill, and collect for our services;`}</li>
            <li>{`Protect our rights or interests, property or safety or that of others;`}</li>
            <li>{`In connection with claims, disputes, or litigation - in court or elsewhere;`}</li>
            <li>{`Protect against misuse or unauthorized use of our Sites;`}</li>
            <li>{`Protect users of our Sites and other carriers or providers from fraudulent, abusive, or unlawful use of, or subscription to, such services;`}</li>
            <li>{`Facilitate or verify the appropriate calculation of taxes, fees, or other obligations due to a local, state, or federal government;`}</li>
          </ul>
        </li>
      </ul>
      <H3>{`Use of Cookies and Other Similar Tracking Technologies`}</H3>
      <div>
        {`We or our third-party marketing partners (such as Google Analytics and Adobe Analytics) use cookies, analytics, remarketing and other similar tracking technologies to help us provide users with a better service and a more customized web experience. Additionally, our Business Partners use tracking services, analytics and other similar tracking technologies to monitor visits to the Sites. The Sites may also use web beacons (also called "clear GIFs" or "pixel tags") in conjunction with cookies. If you prefer, you can choose to not accept cookies by changing the settings on your web browser. Many commercial Internet sites use cookies; a cookie enables the site to label you as a particular user, but it does not identify you by name or address unless you have provided us with such information (over the Internet or otherwise) or set up preferences in your browser to do so automatically.`}
      </div>
      <div>{`Cookies allow us to provide you with a more customized service and are used to track and measure website usage. This information may be used to manage and customize our website's content, layout, and services in order to improve our site, enhance customer service, and provide tailored messages.`}</div>
      <div>{`You may opt out of accepting cookies by changing the settings on your browser. However, rejecting cookies may prevent you from using certain functions and you may have to repeatedly enter information to take advantage of services or promotions. Although we believe that each of the sites to which we target our ads or from which we collect data should post a privacy statement that fully describes the advertising and data collection activities on its site, we cannot guarantee that all of those sites post such a statement.`}</div>
      <div>{`We may also use customer information to send you promotional communications about products, features, and options that we believe may be of interest to you. If we have your email address, we may do this by email. If you do not wish to receive promotional emails from us, please contact us at the following address:`}</div>
      <address>
        {`ItsWoop Technologies LLC`} <br />
        {`211 W. State Street`} <br />
        {`Media, PA 19063`} <br />
      </address>
      <div>
        {`or email us at `}
        <a href={`mailto:${LEGAL_INFO_EMAIL}`}>{`${LEGAL_INFO_EMAIL}`}</a>
      </div>
      <H3>{`Third Party Tracking / Do Not Track`}</H3>
      <div>
        {`We may, from time to time, collect information about your online activities, over time and across different websites. When you use our Sites, third parties may also collect information about your online activities, over time and across different internet websites, online or cloud computing services, online applications, or mobile applications. Some browsers support a "Do Not Track" feature, which is intended to be a signal to websites that you do not wish to be tracked across different websites you visit. Our Sites do not currently change the way they operate based upon detection of a "Do Not Track" or similar signal.`}
      </div>
      <H3>{`How Do We Store and Protect Your Information?`}</H3>
      <div>{`Although no system can guarantee the complete security of your information, we take commercially reasonable steps to ensure your information is protected in alignment with all applicable laws and regulations, as appropriate to the sensitivity of your information.`}</div>
      <H3>{`How Long Do We Keep Your Personal Information?`}</H3>
      <div>{`We keep your information for as long as necessary in accordance with the purpose for which it was collected, our business needs, and our legal and regulatory obligations. If we dispose of your information, we will do so in a way that is secure and appropriate to nature of the information subject to disposal.`}</div>
      <H3>{`Who Should Use Our Sites?`}</H3>
      <div>
        {`Our Sites are for verifying current automobile insurance, offering insurance and insurance related information, and financial products and services, and are not intended for children; we do not market any products or services to children under the age of sixteen or knowingly collect any information from children under the age of sixteen. If you become aware that information is or has been submitted by or collected from a child under the age of sixteen please contact us directly at `}
        <a href={`tel:${LEGAL_PHONE}`}>{`${LEGAL_PHONE}`}</a>
        {`.`}
      </div>
      <H3>{`Whose Privacy Policy Applies to Links and External Sites?`}</H3>
      <div>{`IWT Group provides links to other sites as a convenience to persons who visit our Websites. If you choose to use the services provided by those sites, you may be asked by those sites to provide certain personally identifiable information (some of which may be shared with IWT Group).`}</div>
      <div>{`Please be aware that IWT Group is not responsible for the privacy practices of those sites. We encourage you to be aware when you leave our site and to read the privacy policies of every website that you visit, as the privacy policies of those sites may differ from ours.`}</div>
      <H3>{`Your Choices`}</H3>
      <div>
        {`We provide you the ability to exercise certain controls and choices regarding your information. To exercise any of the rights described below, send your request to `}
        <a href={`mailto:${LEGAL_INFO_EMAIL}`}>{`${LEGAL_INFO_EMAIL}`}</a>
        {`. We will respond to your request as possible and in accordance with all applicable laws and regulations. To protect your identity, we may also use reasonable efforts, consistent with applicable law, to verify your identity before responding to your request.`}
      </div>
      <ul>
        <li>
          <u>{`Control Cookies Through Your Browsers`}</u>
          {`. You may be able to disable and manage some cookies through your browser settings, as described above. If you use multiple browsers on the same device, you will need to manage your settings for each browser.`}
        </li>
        <li>
          <u>{`Analytics Provider Opt-Outs`}</u>
          {`. To disable analytics cookies you can use the browser controls discussed above or, for some of our providers (e.g. Google), you can use their individual opt-out mechanisms, as described above.`}
        </li>
        <li>
          <u>{`Email Opt-Out`}</u>
          {`. If at any time you wish to opt-out or "unsubscribe" from receiving commercial or promotional emails from us, you may use the unsubscribe link at the bottom of each commercial email you receive. Please note that even if you opt-out of receiving commercial emails from us, we reserve the right to send transactional or relationship communications to you (e.g., if we need to contact you regarding your purchases, account status, technical support, changes to our Sites or Services, etc.).`}
        </li>
        <li>
          <u>{`Opt-Out of Sharing With Business Partners`}</u>
          {`. You can opt-out of our sharing of your information with Business Partners.`}
        </li>
        <li>
          <u>{`Correct Your Information`}</u>
          {`. If you believe we maintain inaccurate or out-of-date information about you, you may submit a request that we correct your information.`}
        </li>
      </ul>
      <H3>{`Changes to the Online Privacy Policy`}</H3>
      <div>{`Please be aware that we may periodically update or revise this Privacy Policy. Please take a look at the revision date at the top of this page to see when this Privacy Policy was last revised. Each of our customers or users may receive a copy of our Privacy Policy annually.`}</div>
      <H3>{`List of Affiliates`}</H3>
      <ul>
        <li>{`Woop Group, Inc.`}</li>
        <li>{`Woop Insurance Agency LLC`}</li>
        <li>{`Woop Management LLC`}</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicyText;

PrivacyPolicyText.propTypes = {
  srcAcctId: PropTypes.string
};
