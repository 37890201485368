/**
 *
 * @param {any} val the latest received input value
 * @param {string} prev previous input value
 * @returns {string}
 */
export function policeGPA(val, prev = '') {
  if (!val) return '';

  // Strip non-digits, non-periods.
  const cleanVal = val.toString().replace(/[^0-9.]/g, '');

  // Nothing more than 4.
  if (cleanVal[0] && cleanVal[0] > 4) return '';

  // Cast to float.
  const parsed = parseFloat(cleanVal);

  // If it's NaN, return the previous value.
  if (isNaN(parsed)) return prev;

  // Max 4 chars.
  if (cleanVal.length >= 4) {
    return cleanVal.substring(0, 4);
  }

  // Otherwise, return the legit value.
  return cleanVal;
}
