import { PARTNERS } from 'woop-shared/db/partners';
import { ROUTES } from '../../../router/constants/routes';
import { QUERY_PARAMS } from '../../core/constants/url';
import { hasConnected } from '../../journey/utils/third-party-data';

const PARTNER_START_PAGES = {
  [PARTNERS.PAW]: ROUTES.PAW_START
};

/**
 * Return the route the user should go to after a welcome page.
 *
 * @param {string} accountId
 * @param {boolean} isQuickJourney
 * @param {object} thirdPartyData
 * @returns {string} A route within the app
 */
export function getStartRoute(accountId, isQuickJourney, thirdPartyData) {
  const connected = hasConnected(thirdPartyData);
  if (connected) return ROUTES.CONNECTED;

  // If journeyId is in the URL, then we are resuming a saved journey.
  const isResume = location.search.includes(QUERY_PARAMS.JOURNEY_ID);
  if (isResume && !isQuickJourney) return ROUTES.PREJOURNEY;

  if (PARTNER_START_PAGES[accountId]) return PARTNER_START_PAGES[accountId];

  return ROUTES.CONNECT;
}
