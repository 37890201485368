import dayjs from 'dayjs';
import { COVERAGE_TYPES, UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import { PROMPT_VALIDATION } from '../constants/prompt-validation-mapping';
import { maybeShowConditonalPrompt } from './journey-data';

export function isValidPrompt(name, value) {
  // If there is no validation function for this prompt, return hasVal.
  if (!PROMPT_VALIDATION[name]) {
    return hasVal(value);
  }

  // If the validation function for this prompt isn't an array.
  if (typeof PROMPT_VALIDATION[name] === 'function') {
    return PROMPT_VALIDATION[name](value);
  }

  // Otherwise, get all of the validation functions for this prompt & run each one.
  const fns = PROMPT_VALIDATION[name];

  // If any of the validation functions return false, isValid is false.
  const isValid = fns.every(fn => fn(value));

  return isValid;
}

/**
 * Ensure val isn't an empty string, an empty object, undefined or null.
 *
 * @param {*} val
 * @returns {boolean}
 */
export function hasVal(val) {
  const type = typeof val;
  if (Array.isArray(val) || type === 'string') return val.length > 0;
  if (val === null || type === 'undefined') return false;
  if (type === 'object') return Object.keys(val).length > 0;

  return true;
}

/**
 * Check whether an address has a truthy composite.
 *
 * @param {*} val Raw address field value.
 * @returns {boolean}
 */
export function hasComposite(val) {
  return !!(val?.[UI_ADDRESS_FIELDS.COMPOSITE]?.length > 4);
}

/**
 * Ensure the date is a full date, mm/dd/yyyy.
 *
 * 2 digit months, 2 digit days, 1700/1800/1900/2000.
 *
 * @param {*} val
 * @returns {boolean}
 */
export function isFullDate(val) {
  return /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(17|18|19|20)\d{2}$/.test(val);
}

/**
 *
 * @param {object} promptGroup an object (e.g. Vehicle, Driver)
 * @param {Array} promptNames a collection of prompt keys, e.g. ['firstName', 'lastName']
 * @returns {boolean} a boolean indicating validity
 */
function validatePromptGroup(promptGroup, promptNames) {
  if (!promptGroup) return false;
  return promptNames.every(name => promptGroup?.[name]?.isValid);
}

/**
 * Get prompts that we need to validate.
 *
 * Conditions for not checking validity:
 * - noValidate flag on the prompt.
 * - Prompt doesn't have a name.
 * - Prompt is a conditional prompt and is currently not being shown.
 *
 * @param {Array} prompts
 * @param {object} journeyData
 * @param {number} promptGroupIndex
 * @returns {Array} Array of filtered prompt objects.
 */
export function getPromptsToValidate(prompts, journeyData, promptGroupIndex) {
  return prompts.filter(p => {
    if (p.noValidate) return false;
    if (!p.name) return false;
    if (p.conditionals)
      return maybeShowConditonalPrompt(journeyData, p.conditionals, p.name, promptGroupIndex);
    return true;
  });
}

/**
 * Return first invalid prompt from a collection of prompts
 *
 * @param {object} args
 * @param {object[]} args.prompts
 * @param {object} args.journeyData
 * @param {string} args.promptGroupName
 * @param {number} args.promptGroupIndex
 * @returns {object} prompt
 */
export function getFirstInvalidPrompt({ prompts, journeyData, promptGroupName, promptGroupIndex }) {
  const filteredPrompts = getPromptsToValidate(prompts, journeyData, promptGroupIndex);
  return filteredPrompts.find(({ name }) =>
    promptGroupName
      ? !journeyData[promptGroupName]?.[promptGroupIndex]?.[name]?.isValid
      : !journeyData[name]?.isValid
  );
}

export function isValidCoverageType(value) {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every(covType => Object.values(COVERAGE_TYPES).includes(covType))
  );
}

export function isValidBirthday(value) {
  if (!isFullDate(value)) return false;

  const inputDate = new Date(value);
  const MIN_AGE = 16;
  const MAX_AGE = 100;

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - MIN_AGE);

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - MAX_AGE);

  return inputDate <= maxDate && inputDate >= minDate;
}

export function isValidSsn(value) {
  return /^(\d{3})-(\d{2})-(\d{4})$/.test(value);
}

export function isValidPhone(value) {
  return /^(\d{3})-(\d{3})-(\d{4})$/.test(value);
}

/**
 * Check if the move-in date is less than a hundred years ago, or within 120 days.
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isValidMoveInDate(value) {
  if (!isFullDate(value)) return false;
  const isFuture = dayjs().isBefore(value, 'day');
  if (isFuture) return isWithin120Days(value);
  return isLessThanHundredYearsAgo(value);
}

/**
 * Check if the date string is less than 100 years old.
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isLessThanHundredYearsAgo(value) {
  if (!isFullDate(value)) return false;

  const dateObject = new Date(value);

  const today = new Date();
  const oneHundredYearsAgo = new Date().setFullYear(today.getFullYear() - 100);

  return dateObject > oneHundredYearsAgo;
}

/**
 * Is the date within X days in the future?
 *
 * @param {string} value A date string in the format MM/DD/YYYY.
 * @param {number} numDays The number of days in the future.
 * @returns {boolean}
 */
export function isWithinXDaysInFuture(value, numDays) {
  if (!isFullDate(value)) return false;

  const dateObj = new Date(value);
  dateObj.setHours(0, 0, 0, 0);

  const today = new Date();
  const yesterday = new Date(new Date().setDate(today.getDate() - 1));
  yesterday.setHours(0, 0, 0, 0);
  const maxDate = new Date(new Date().setDate(today.getDate() + numDays));
  maxDate.setHours(0, 0, 0, 0);

  return dateObj <= maxDate && dateObj > yesterday;
}

/**
 * Is the date less than 120 days in the future.
 *
 * @param {string} value A date string in the format MM/DD/YYYY.
 * @returns {boolean}
 */
export function isWithin120Days(value) {
  return isWithinXDaysInFuture(value, 120);
}

/**
 * Allow 4(.00) or a number in the pattern 0-3.99.
 *
 * @param {*} value
 * @returns {boolean}
 */
export function isValidGPA(value) {
  return parseFloat(value) === 4 || /^[0-3]([.]\d{0,2})?$/.test(value);
}

/**
 * Validate a card expiration date.
 *
 * A valid expiration date is 5 digits (MM/YY) and is in the future.
 *
 * @param {string} value The expiration date.
 * @returns {boolean}
 */
export function isValidExpirationDate(value) {
  if (value?.toString().length !== 5) return false;

  const [month, year] = value.split('/');
  const current = new Date();
  const currentMonth = current.getMonth() + 1;
  const currentYear = current.getFullYear().toString().substring(2, 4);

  if (year > currentYear) return true;
  if (year === currentYear) return parseInt(month) >= currentMonth;
}

export function isValidCvv(value) {
  return value?.toString().length === 3 && stripNonDigits(value).length === 3;
}

export function isValidRoutingNumber(value) {
  return value?.toString().length === 9 && stripNonDigits(value).length === 9;
}

export function isValidAccountNumber(value = '') {
  const totalLength = value?.toString().length;
  const digitlessLength = stripNonDigits(value).length;
  return totalLength === digitlessLength && digitlessLength >= 3;
}

export function isValidAccountNumberConfirm(accountNum, confAccountNum) {
  if (accountNum && confAccountNum) return accountNum === confAccountNum;
}

export function isValidPromptGroup({ prompts, journeyData, promptGroupIndex, promptGroup }) {
  const promptNames = getPromptsToValidate(prompts, journeyData, promptGroupIndex).map(p => p.name);
  return validatePromptGroup(promptGroup, promptNames);
}
