export const ANONYMOUS_USER_ID_PREFIX = 'anon';

export const USER_FIELDS = {
  ID: 'id',
  SOURCE_ACCOUNT_ID: 'srcAcctId',
  ENVIRONMENT: 'environment'
};

export const FEATURES = {
  AUTOCOMPLETE_OLD: 'autocomplete_old',
  JOURNEY_PICKER: 'journey_picker'
};

export const EXPERIMENTS = {
  SHORT_IV: 'short_iv_test'
};
export const VARIATIONS = {
  ON: 'on'
};

export const EVENTS = {
  SELECTED_CONNECT: 'selected_connect',
  SELECTED_UPLOAD: 'selected_upload',
  SELECTED_FULL_APPLICATION: 'selected_full_application',
  STARTED_JOURNEY: 'started_journey',
  UPLOADED: 'uploaded',
  CONNECTED: 'connected'
};

const DECISION_TYPES = {
  FEATURE: 'feature',
  EXPERIMENT: 'feature-test'
};

export const LOG_DECISION_TYPES = [DECISION_TYPES.FEATURE, DECISION_TYPES.EXPERIMENT];
