import { connect } from 'react-redux';
import { PROMPT_GROUP_NAMES } from 'woop-shared/prompts/names';
import DriverSelect from '../components/DriverSelect';
import { getDriverOptions } from '../utils/driver';

const mapStateToProps = ({ journeyData }) => {
  const driverOptions = getDriverOptions(journeyData[PROMPT_GROUP_NAMES.DRIVERS]);
  return { driverOptions };
};

export default connect(mapStateToProps)(DriverSelect);
