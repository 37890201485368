import Tooltip from 'rc-tooltip';
import React, { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import LinkText from '../../../../../core/components/LinkText';
import styles from './styles.css';

const WhyConnectTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleTooltip = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Tooltip
      trigger={['click']}
      align={{
        offset: [0, -20]
      }}
      placement={'bottom'}
      overlayClassName={styles.tooltipWrapper}
      mouseEnterDelay={0.1}
      visible={isVisible}
      overlay={
        <div>
          <div className={styles.whyConnect}>
            <b>Why do I need to connect?</b>
            <FaQuestionCircle />
          </div>
          <p>
            By securely logging in with your insurance provider, we receive your current insurance
            details instantly without any tedious forms. We’ll never bother you with spam or misuse
            your info.
          </p>
          <FiX className={styles.x} onClick={toggleTooltip} />
        </div>
      }
    >
      <div className={styles.whyConnect} onClick={toggleTooltip}>
        <LinkText>Why do I need to connect?</LinkText>
        <FaQuestionCircle />
      </div>
    </Tooltip>
  );
};

export default WhyConnectTooltip;
