import PropTypes from 'prop-types';
import React from 'react';
import { ICON_NAMES } from 'woop-shared/prompts/icons';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { capitalize } from 'woop-shared/utils';
import Card from '../';
import { ICONS } from '../../../../../../core/constants/icons';
import { getVal } from '../../../../../../core/utils/journey-data';

const IncidentCard = ({ entity, className, onClick, entityLabel, ...rest }) => {
  const incidentType = getVal(entity[PROMPT_NAMES.INCIDENT_TYPE]) || entityLabel;
  const incidentDate = getVal(entity[PROMPT_NAMES.INCIDENT_DATE]);

  return (
    <Card
      icon={ICONS[ICON_NAMES.INCIDENTS]}
      line1={capitalize(incidentType)}
      line2={incidentDate}
      onEdit={onClick}
      className={className}
      {...rest}
    />
  );
};

export default IncidentCard;

IncidentCard.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.object,
  entityLabel: PropTypes.string,
  onClick: PropTypes.func
};
