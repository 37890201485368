import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import styles from '../css/SSNField.css';
import { formatSSN, formatSSNMasked } from '../utils/ssn';
import BaseTextField from './BaseTextField';
import InfoText from './InfoText';
import LinkText from './LinkText';

const SSNField = ({ name, isValid, label, validationText, showValidation, ...rest }) => {
  const { value = '', save } = rest;
  const [maskedValue, setMaskedValue] = useState('');
  const [showInfo, setShowInfo] = useState(false);

  const handleInput = e => {
    const inputCharLength = e.target.value?.length;
    if (inputCharLength < value.length) {
      save('');
      setMaskedValue('');
      return;
    }
    const inputKey = e.target.value[inputCharLength - 1];
    // If key is anything but a digit this will be empty string
    const cleanedInput = stripNonDigits(inputKey);
    const maxLengthReached = inputCharLength === 12;
    if (maxLengthReached || !cleanedInput) {
      return e.preventDefault();
    }
    setMaskedValue(formatSSNMasked(cleanedInput, value));
    save(formatSSN(cleanedInput, value));
    e.preventDefault();
  };

  return (
    <>
      <BaseTextField
        label={label}
        name={name}
        value={maskedValue}
        onChange={handleInput}
        isValid={isValid}
        validationText={validationText}
        showValidation={showValidation}
        autoComplete="off"
        inputMode="numeric"
      />
      <InfoText className={styles.info}>
        <p>
          We require your SSN to ensure you get the most accurate quotes. Without it, your price
          could change by as much as 30%.
        </p>
        <LinkText className={styles.link} onClick={() => setShowInfo(!showInfo)}>
          Learn More
          {!showInfo ? <FaArrowDown /> : <FaArrowUp />}
        </LinkText>
        {showInfo && (
          <p>
            Most of our insurance company partners, where permissible, use your social security
            number to acquire a credit report(s) in order to examine your credit history. Except in
            the states of California, Hawaii and Massachusetts where the use of credit in
            determining insurance premium rates is not permissible, credit history plays a major
            factor in an insurer’s underwriting process to determine an insurance premium quote.
          </p>
        )}
      </InfoText>
    </>
  );
};

export default SSNField;

SSNField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isValid: PropTypes.bool,
  validationText: PropTypes.string,
  showValidation: PropTypes.bool
};
