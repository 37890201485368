import { PAYMENT_PLANS } from 'woop-shared/quotes/enums';

export const BILLING_CYCLE_UNIT = {
  [PAYMENT_PLANS.ANNUALLY]: 'year',
  [PAYMENT_PLANS.MONTHLY]: 'month',
  [PAYMENT_PLANS.INSTALLMENT]: 'installment',
  [PAYMENT_PLANS.SEMI_ANNUALLY]: '6 months'
};

export const STANDARD_BILLING_CYCLE_LABELS = {
  [PAYMENT_PLANS.ANNUALLY]: 'Annual',
  [PAYMENT_PLANS.MONTHLY]: 'Monthly',
  [PAYMENT_PLANS.INSTALLMENT]: 'Installment',
  [PAYMENT_PLANS.SEMI_ANNUALLY]: 'Semi-annual'
};

export const QUOTE_DEDUCTIBLE_LABEL = 'Deductible';
