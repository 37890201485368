import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import PromptWrapper from '../../journey/containers/PromptWrapper';
import { ICONS } from '../constants/icons';
import styles from '../css/VisualRadioGroup.css';
import { hasVal } from '../utils/prompt-validation';
import Prompt from './Prompt';
import VisualRadio from './VisualRadio';

const cx = classNames.bind(styles);

const VisualRadioGroup = ({
  prompt,
  options,
  multiSelect = false,
  showValidation,
  isValid,
  name,
  tooltip,
  ...rest
}) => {
  const { value, save } = rest;
  const selections = Array.isArray(value) ? value : hasVal(value) ? [value] : [];

  const isSelected = value => {
    return selections.includes(value);
  };

  const handleClick = (e, value) => {
    // handle deselect
    if (isSelected(value)) {
      // blur button
      e.currentTarget.blur();
      // set new val
      const newVal = multiSelect ? selections.filter(selected => selected !== value) : null;
      return save(newVal);
    }

    // handle select
    const newVal = multiSelect ? [...selections, value] : value;
    return save(newVal);
  };

  return (
    <PromptWrapper name={name}>
      <Prompt text={prompt} showValidation={!isValid && showValidation} tooltip={tooltip} />
      <div className={cx('radio-group')}>
        {options.map(option => {
          const selected = isSelected(option.value);
          return (
            <VisualRadio
              id={`${name}-${option.value}`}
              className={cx(option.className)}
              label={option.label}
              icon={ICONS[option.icon]}
              key={option.label}
              selected={selected}
              handleClick={e => handleClick(e, option.value)}
            />
          );
        })}
      </div>
    </PromptWrapper>
  );
};

export default VisualRadioGroup;

VisualRadioGroup.propTypes = {
  prompt: PropTypes.string,
  multiSelect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      className: PropTypes.string
    })
  ),
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  name: PropTypes.string,
  tooltip: PropTypes.string
};
