import { PARTNER_STRINGS } from '../constants/strings';

/**
 * Take a partner string key and return the corresponding string value.
 *
 * @param {string} key one of the keys enumerated in PARTNER_STRING_KEYS.
 * @param {string} srcAcctId The partner's accountId.
 * @returns {string} The partner's string. Default to an empty string so we never show the string undefined.
 */
export function getPartnerString(key, srcAcctId) {
  return PARTNER_STRINGS[srcAcctId]?.[key] || '';
}
