// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__content--ZP6Ga{display:flex;flex-direction:column;justify-content:center;align-items:center}@media (max-width:550px){.styles__content--ZP6Ga{position:absolute;width:250px;left:calc(50% - 125px);top:calc(50% - 125px)}}.styles__content--ZP6Ga .styles__spinner--26C-9{position:static}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAIA,wBACE,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,kBAYF,CAVE,yBANF,wBAOI,iBAAkB,CAClB,WAAkB,CAClB,sBAAiC,CACjC,qBAMJ,CALE,CAEA,gDACE,eACF","file":"styles.css","sourcesContent":["@import '../../../core/css/variables.css';\n\n$dimensions: 250px;\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  @media (--small-viewport) {\n    position: absolute;\n    width: $dimensions;\n    left: calc(50% - $dimensions / 2);\n    top: calc(50% - $dimensions / 2);\n  }\n\n  .spinner {\n    position: static;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"content": "styles__content--ZP6Ga",
	"spinner": "styles__spinner--26C-9"
};
module.exports = exports;
