import React from 'react';
import { capitalize } from 'woop-shared/utils';

const EnvBanner = () => (
  <div
    style={{
      position: 'absolute',
      padding: '2px 8px',
      fontSize: '10px',
      background: 'rgb(237, 108, 123)',
      color: 'white',
      width: '80px',
      margin: 'auto',
      left: 0,
      right: 0,
      zIndex: 7,
      textAlign: 'center'
    }}
  >
    {capitalize(process.env.ENV)}
  </div>
);

export default EnvBanner;
