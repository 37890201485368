import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import { FIELDS, GOOGLE_ADDRESS_TYPE_MAP } from './constants';

/**
 *
 * @param {string} addressField A Woop address field
 * @param {object[]} addressComponents Google address components
 * @returns {object} A matching address component
 */
function findAddressComponent(addressField, addressComponents) {
  const addressComponentType = GOOGLE_ADDRESS_TYPE_MAP[addressField];
  const addressComponent = addressComponents?.find(component =>
    component[FIELDS.TYPES].includes(addressComponentType)
  );
  return addressComponent;
}

/**
 * Maps a Google place object to a Woop address object
 *
 * @param {object} place A Google place object
 * @returns {object} A Woop address object
 */
export function mapGoogleAddress(place) {
  const formattedAddress = place[FIELDS.FORMATTED_ADDRESS];
  const addressComponents = place[FIELDS.ADDRESS_COMPONENTS];
  const mappedAddress = {
    [UI_ADDRESS_FIELDS.COMPOSITE]: formattedAddress
  };
  Object.values(UI_ADDRESS_FIELDS).forEach(field => {
    const googleField = findAddressComponent(field, addressComponents);
    if (googleField) mappedAddress[field] = googleField[FIELDS.SHORT_NAME];
  });
  return mappedAddress;
}

/**
 * Heuristic function for parsing out the house number from a composite address
 *
 * @param {string} compositeAddress
 * @returns {string} a house number
 */
export function parseHouseNumber(compositeAddress) {
  const houseNumber = compositeAddress?.split(' ')[0];
  return stripNonDigits(houseNumber);
}
