import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logUploadDocuments } from '../../../analytics/actions';
import { EVENTS as SHARED_EVENTS } from '../../../analytics/constants';
import { EVENTS } from '../../../analytics/optimizely/constants';
import { trackEvent } from '../../../analytics/utils';
import { patchJourney, setIsQuickJourney, setThirdPartyData } from '../../journey/actions/journey';
import { maybeSendProofEmail } from '../../journey/pages/ConnectPage/utils';
import { THIRD_PARTY_FIELDS, getBoxFileIds } from '../../journey/utils/third-party-data';
import { sendProof } from '../actions/origination';
import { QUERY_PARAMS } from '../constants/url';
import { getQueryStringParam } from '../utils/url';
import { useOverlayModal } from './use-overlay-modal';

export function useBoxUpload({ onUploadCompleteCallback }) {
  const [openBoxModal, setOpenBoxModal] = useOverlayModal();
  const [hasFiles, setHasFiles] = useState(false);

  const { thirdPartyData } = useSelector(state => state.journey);
  const dispatch = useDispatch();

  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);

  const handleOpenBox = () => {
    dispatch(patchJourney());
    setOpenBoxModal(true);
    window.optimizely?.track(EVENTS.SELECTED_UPLOAD);
    trackEvent(SHARED_EVENTS.SELECTED_UPLOAD);
  };

  const handleUpload = files => {
    const newFileIds = files.map(file => file.id);
    const existingFileIds = getBoxFileIds(thirdPartyData) || [];
    const fileIds = [...new Set([...existingFileIds, ...newFileIds])];
    dispatch(
      setThirdPartyData({
        [THIRD_PARTY_FIELDS.BOX]: { fileIds }
      })
    );
    dispatch(logUploadDocuments({ fileIds }));
    setHasFiles(true);
    if (maybeSendProofEmail(scenario)) dispatch(sendProof());
  };

  const onUploadComplete = () => {
    dispatch(setIsQuickJourney(true));
    dispatch(patchJourney({ callback: onUploadCompleteCallback }));
  };

  return {
    openBoxModal,
    setOpenBoxModal,
    hasFiles,
    handleOpenBox,
    handleUpload,
    onUploadComplete
  };
}
