import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../css/Headers.css';

const cx = classnames.bind(styles);

const H1 = ({ children, className }) => <h1 className={cx('heading1', className)}>{children}</h1>;

export default H1;

H1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
