import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import Button from './components/Button';
import Triangle from './components/Triangle';
import styles from './styles.css';

const cx = classNames.bind(styles);

const BackButton = ({ className, triangle, mobileNav, to, replace }) => (
  <div className={cx('wrapper', className, { triangle, mobileNav })}>
    {triangle && <Triangle />}
    <Button mobileNav={mobileNav} to={to} replace={replace} />
  </div>
);

export default BackButton;

BackButton.propTypes = {
  className: PropTypes.string,
  mobileNav: PropTypes.bool,
  replace: PropTypes.bool,
  to: PropTypes.string,
  triangle: PropTypes.bool
};
