import { stripNonDigits } from 'woop-shared/quotes/utils';
import { CARD_TYPES } from '../constants/credit-card';

const VALID_CARD_LENGTH = 16;

/**
 * @param {string} input
 * @returns {string} dasherized credit card
 */
function dashCard(input) {
  let val = input || '';
  val = val.replace(/^(\d{4})/, '$1-');
  val = val.replace(/^(\d{4})-(\d{4})/, '$1-$2-');
  val = val.replace(/^(\d{4})-(\d{4})-(\d{4})/, '$1-$2-$3-');
  val = val.replace(/^(\d{4})-(\d{4})-(\d{4})-(\d{4})$/, '$1-$2-$3-$4');
  return val;
}

/**
 * @param {string} rawInput
 * @returns {string} a formatted credit card num
 */
export function formatCard(rawInput) {
  const cleanedInput = stripNonDigits(rawInput);
  const trimmedInput = cleanedInput.substring(0, VALID_CARD_LENGTH);
  const dashedInput = dashCard(trimmedInput);
  return dashedInput;
}

/**
 * Check if a card is valid.
 *
 * A valid card is 16 digits and matches one of our accepted card types.
 *
 * @param {string} input Raw or formatted credit card input field value.
 * @returns {boolean}
 */
export function isCardValid(input = '') {
  return stripNonDigits(input).toString().length === 16 && !!getCardType(input);
}

/**
 * Take a credit card number and determine the type.
 *
 * @param {string} input Raw or formatted credit card input field value.
 * @returns {string} The type of credit card.
 */
export function getCardType(input) {
  if (isVisa(input)) return CARD_TYPES.VISA;
  if (isMastercard(input)) return CARD_TYPES.MASTERCARD;
}

/**
 * Is this a visa card?
 *
 * @see https://www.freeformatter.com/credit-card-number-generator-validator.html
 * @param {string} input
 * @returns {boolean}
 */
function isVisa(input) {
  return input?.toString().length >= 1 && parseInt(input[0]) === 4;
}

/**
 * Is this a Mastercard card?
 *
 * @see https://www.freeformatter.com/credit-card-number-generator-validator.html
 * @param {string} input
 * @returns {boolean}
 */
function isMastercard(input) {
  const firstTwoDigits = stripNonDigits(input).substring(0, 2);
  const firstSixDigits = stripNonDigits(input).substring(0, 6);

  return (
    (firstTwoDigits.length >= 2 && firstTwoDigits >= 51 && firstTwoDigits <= 55) ||
    (firstSixDigits.length >= 6 && firstSixDigits >= 222100 && firstSixDigits <= 272099)
  );
}
