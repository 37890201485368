import { EVENTS as SHARED_EVENTS } from '../../constants';

export const EVENTS = {
  VALIDATION_ERROR: 'validation-error',
  VIEW_QUOTES: 'view-quotes',
  COMPLETED_FLOW: 'completed-flow'
};

export const USER_PROPERTIES = {
  NAME: 'name'
};

export const INTERCOM_EVENTS_MAP = {
  [SHARED_EVENTS.FORM_VALIDATION_ERROR]: EVENTS.VALIDATION_ERROR
};
