import ConnectedLinked from '../../../core/assets/icons/connected-link.svg';
import HandShield from '../../../core/assets/icons/hand-shield.svg';
import MagnifyMoney from '../../../core/assets/icons/magnify-money.svg';
import Notification from '../../../core/assets/icons/notification.svg';

export const ITEMS = [
  {
    icon: ConnectedLinked,
    title: 'Connect Your Insurance',
    body: 'Login with your insurer to instantly connect your policy details.'
  },
  {
    icon: HandShield,
    title: 'Price-locked Quotes',
    body: 'We provide price-locked quotes tailored to your unique insurance profile. No hidden fees and no surprises.'
  },
  {
    icon: MagnifyMoney,
    title: 'Free Insurance Report',
    body: "Want to know why you pay what you pay? We'll give you a personalized breakdown of your insurance rate."
  },
  {
    icon: Notification,
    title: 'Smart Alerts',
    body: "We'll send you any important alerts and let you know about any potential savings."
  }
];
