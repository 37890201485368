import { useEffect, useRef, useState } from 'react';
import { isLargeViewport } from '../utils/browser';

export function useScrollHint() {
  const [shouldRenderScrollHint, setShouldRenderScrollHint] = useState(false);
  const ref = useRef(); // Ref to scrollable element

  useEffect(() => {
    setShouldRenderScrollHint(isLargeViewport() && !!ref?.current);
  }, []);

  return [ref, shouldRenderScrollHint];
}
