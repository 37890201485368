import { createAction } from 'redux-actions';

export const UPDATE_PAGE_VALIDITY = 'UPDATE_PAGE_VALIDITY';
export const updatePageValidity = createAction(UPDATE_PAGE_VALIDITY);

export const UPDATE_PAGES = 'UPDATE_PAGES';
export const updatePages = createAction(UPDATE_PAGES);

export const UPDATE_PAGE_SHOW_VALIDATION = 'UPDATE_PAGE_SHOW_VALIDATION';
export const updatePageShowValidation = createAction(UPDATE_PAGE_SHOW_VALIDATION);

export const SET_PROMPT_GROUP_INDEX = 'SET_PROMPT_GROUP_INDEX';
export const setPromptGroupIndex = createAction(SET_PROMPT_GROUP_INDEX);
