import PropTypes from 'prop-types';
import React from 'react';
import { BsArrowLeftRight } from 'react-icons/bs';
import Documents from '../../../../assets/icons/documents.svg';
import { ASSET_URLS } from '../../../../constants/app';
import styles from './styles.css';

const ConnectVisual = ({ logoUrl }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.blueCircle}>
        <img src={ASSET_URLS.W_BLUE_DOT} />
      </div>
      <BsArrowLeftRight />
      <div>{logoUrl ? <img src={logoUrl} /> : <Documents />}</div>
    </div>
  );
};

ConnectVisual.propTypes = {
  logoUrl: PropTypes.string
};

export default ConnectVisual;
