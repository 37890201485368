import { push } from 'connected-react-router';
import { takeLatest, take, select, put } from 'redux-saga/effects';
import { ROUTE_FROM_CROSS_SELL } from '../actions/cross-sell';
import { PATCH_JOURNEY_SUCCESS } from '../actions/journey';
import { getNextPath } from '../pages/CrossSellPage/utils';

function* handleRouteFromCrossSell({ payload }) {
  const needsPatch = payload?.needsPatch;
  if (needsPatch) yield take(PATCH_JOURNEY_SUCCESS);
  const [journeyData, submodules] = yield select(state => [
    state.journeyData,
    state.journey.submodules
  ]);
  const next = getNextPath(submodules, journeyData);
  yield put(push(next));
}

export function* watchRouteFromCrossSell() {
  yield takeLatest([ROUTE_FROM_CROSS_SELL], handleRouteFromCrossSell);
}
