import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../css/BigButton.css';
import CircleButton from './CircleButton';

const cx = classNames.bind(styles);

const BigButton = ({ disabled, children, className, to, blockMobile, invalidForm, ...rest }) => (
  <CircleButton
    className={cx('big-button', className, { blockMobile, invalidForm })}
    to={to}
    disabled={disabled}
    {...rest}
  >
    <span className={styles.text}>{children}</span>
  </CircleButton>
);

export default BigButton;

BigButton.propTypes = {
  blockMobile: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  invalidForm: PropTypes.bool
};
