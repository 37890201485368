import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import CardHeader from '../CardHeader';
import styles from './styles.css';

const cx = classNames.bind(styles);

const IconListCard = ({ heading, headingColor, listItems, className }) => {
  return (
    <div className={cx('wrapper', className)}>
      <CardHeader className={headingColor}>{heading}</CardHeader>
      <div className={styles.list}>
        {listItems.map(({ title, content, icon }) => (
          <div className={styles.listItem} key={title}>
            <span className={styles.icon}>{icon()}</span>
            <div>
              <div className={styles.title}>{title}</div>
              <div className={styles.content}>{content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconListCard;

IconListCard.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func,
      content: PropTypes.string,
      title: PropTypes.string
    })
  )
};
