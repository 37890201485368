import PropTypes from 'prop-types';
import React from 'react';
import InfoText from '../../../../../core/components/InfoText';

const IncidentInfoText = () => {
  const text =
    'You indicated that there has been at least one accident, claim, or violation in the last five years. Please add them here.';

  return <InfoText className="bar" text={text} />;
};

export default IncidentInfoText;

IncidentInfoText.propTypes = {
  journeyData: PropTypes.object
};
