import PropTypes from 'prop-types';
import React from 'react';
import { ICON_NAMES } from 'woop-shared/prompts/icons';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Card from '../';
import { ICONS } from '../../../../../../core/constants/icons';
import { getVal } from '../../../../../../core/utils/journey-data';

function getFullName(entity) {
  const name = getVal(entity[PROMPT_NAMES.NAME]);
  if (name) return name;
  const firstName = getVal(entity[PROMPT_NAMES.FIRST_NAME]);
  const lastName = getVal(entity[PROMPT_NAMES.LAST_NAME]);
  return firstName && lastName ? `${firstName} ${lastName}` : 'Person';
}

const PersonCard = ({ entity, className, onClick, ...rest }) => {
  const fullName = getFullName(entity);
  const dob = getVal(entity[PROMPT_NAMES.DOB]);
  return (
    <Card
      icon={ICONS[ICON_NAMES.USER]}
      line1={fullName}
      line2={dob}
      onEdit={onClick}
      className={className}
      {...rest}
    />
  );
};

export default PersonCard;

PersonCard.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.object,
  onClick: PropTypes.func
};
