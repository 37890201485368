function isLocalStorageAvailable() {
  try {
    window.localStorage;
    return true;
  } catch {
    return false;
  }
}

/**
 * Retrieve an item from local storage safely.
 *
 * @param {string} key The key in localStorage.
 * @returns {string|undefined} The value directly from local storage, or undefined if localStorage is disabled.
 */
export function getLocalStorage(key) {
  if (isLocalStorageAvailable()) return localStorage.getItem(key);
}

/**
 * Set an item in local storage safely.
 *
 * @param {string} key The key in local storage.
 * @param {string} value The value that the key points to. LocalStorage only supports string based values.
 */
export function setLocalStorage(key, value) {
  if (isLocalStorageAvailable()) localStorage.setItem(key, value);
}
