import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { SMS_LANGUAGE } from './constants';
import styles from './styles.css';

const cx = classNames.bind(styles);

const SMSDisclaimer = ({ className }) => (
  <aside className={cx('wrapper', className)}>
    <p className={cx('text')}>{SMS_LANGUAGE}</p>
  </aside>
);

export default SMSDisclaimer;

SMSDisclaimer.propTypes = {
  className: PropTypes.string
};
