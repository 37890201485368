// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--2rnGu{display:flex;flex-direction:row;padding:10px}@media (max-width:550px){.styles__wrapper--2rnGu{width:100%;box-sizing:border-box}.styles__wrapper--2rnGu.styles__hideMobile--2w4IY{display:none}}@media (max-height:400px) AND (max-width:550px){.styles__wrapper--2rnGu{display:none}}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,YAAa,CACb,kBAAmB,CACnB,YAWF,CAVE,yBAJF,wBAKI,UAAW,CACX,qBAQJ,CAPI,kDACE,YACF,CACF,CACA,gDAXF,wBAYI,YAEJ,CADE","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  @media (--small-viewport) {\n    width: 100%;\n    box-sizing: border-box;\n    &.hideMobile {\n      display: none;\n    }\n  }\n  @media (--open-keyboard) {\n    display: none;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--2rnGu",
	"hideMobile": "styles__hideMobile--2w4IY"
};
module.exports = exports;
