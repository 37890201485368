import { useSelector } from 'react-redux';
import { PARTNERS } from 'woop-shared/db/partners';
import { capitalize as cap } from 'woop-shared/utils';

const FALLBACK = 'your dealership';

/**
 * React Hook for getting a dealership partner name
 *
 * @param {object} params
 * @param {boolean} params.capitalize Whether the fallback should be capitalized.
 * @returns {string} the partner name or the string 'your dealership'
 */
export function useDealershipPartnerName({ capitalize } = {}) {
  const [partner, accountId] = useSelector(state => [state.partner, state.origination.srcAcctId]);
  if (accountId === PARTNERS.WOOP || !partner?.name) return capitalize ? cap(FALLBACK) : FALLBACK;
  return partner.name;
}
