// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--3gF5C{display:flex;align-items:center;grid-gap:10px;gap:10px;margin-bottom:15px}.styles__wrapper--3gF5C div{border-radius:50%;width:60px;height:60px;border:1px solid #f2f2f2;overflow:hidden}.styles__wrapper--3gF5C div.styles__blueCircle--oks6_{background-color:rgba(var(--woop-primary-color),.1);border:1px solid transparent}.styles__wrapper--3gF5C div svg{padding:10px}.styles__wrapper--3gF5C div img{object-fit:contain;height:100%;padding:5px;box-sizing:border-box;width:100%}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,YAAa,CACb,kBAAmB,CACnB,aAAS,CAAT,QAAS,CAET,kBA0BF,CAxBE,4BACE,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,wBAAiC,CACjC,eAkBF,CAhBE,sDACE,mDAA0C,CAC1C,4BACF,CAEA,gCACE,YACF,CAEA,gCACE,kBAAmB,CACnB,WAAY,CACZ,WAAY,CACZ,qBAAsB,CACtB,UACF","file":"styles.css","sourcesContent":["@import '../../../../css/variables.css';\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  margin-bottom: 15px;\n\n  div {\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n    border: solid 1px $appBorderColor;\n    overflow: hidden;\n\n    &.blueCircle {\n      background-color: $appPrimaryColorLightest;\n      border: solid 1px transparent;\n    }\n\n    svg {\n      padding: 10px;\n    }\n\n    img {\n      object-fit: contain;\n      height: 100%;\n      padding: 5px;\n      box-sizing: border-box;\n      width: 100%;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--3gF5C",
	"blueCircle": "styles__blueCircle--oks6_"
};
module.exports = exports;
