import classNames from 'classnames/bind';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CONNECT_ROUTE_PAGES } from '../../../../../../router/constants/routes';
import { connectRoute } from '../../../../../../router/utils';
import ErrorText from '../../../../../core/components/ErrorText';
import { CONNECT, connect, setLoginField } from '../../../../actions/canopy';
import { setError } from '../../../../actions/error';
import { getTos } from '../../../../api/canopy';
import BackButton from '../../../BackButton';
import Button from '../../../Button';
import H3 from '../../../headers/H3';
import LinkText from '../../../LinkText';
import ConnectVisual from '../ConnectVisual';
import { CANOPY_COMPONENT_STYLES } from './constants';
import styles from './styles.css';
import { handleErrorText } from './utils';

const cx = classNames.bind(styles);

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [termsVersion, setTermsVersion] = useState();
  const [formLoading, setFormLoading] = useState(false);

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const thirdFieldRef = useRef(null);
  const formRef = useRef(null);

  const {
    canopy: { carrier = {} },
    error,
    loading,
    router
  } = useSelector(state => state);

  const isLoading = formLoading || loading.includes(CONNECT);

  useEffect(() => {
    let cancel = false;
    getTos().then(data => !cancel && setTermsVersion(data.terms_version?.toString()));
    return () => (cancel = true);
  }, []);

  const handleSubmit = async () => {
    if (!formRef.current) return;

    dispatch(setError(''));
    setFormLoading(true);

    const { tokens, errors } = await formRef.current.submit();

    // Format error object as a single string
    if (errors) {
      dispatch(
        setError(
          Object.values(errors)
            .map(({ error }) => error)
            .filter(Boolean)
        )
      );
      setFormLoading(false);
      return;
    }

    const { username, password, thirdfield: thirdField } = tokens;

    // If we have the username, it was a successful submit
    // password and thirdField are optional for some carriers
    if (username) {
      dispatch(setLoginField({ username, password, thirdField }));
      dispatch(connect({ termsVersion }));
    }

    setFormLoading(false);
  };

  useEffect(() => {
    if (!carrier || !termsVersion) return;

    const form = window.CanopyConnectComponents.form({
      carrierConfig: {
        name: carrier.name,
        usernameFieldPlaceholder: carrier.usernameFieldPlaceholder,
        thirdFieldPlaceholder: carrier.thirdFieldPlaceholder,
        disablePasswordField: carrier.disablePasswordField,
        thirdFieldValidatorError: carrier.thirdFieldValidatorError,
        thirdFieldValidatorRgx: carrier.thirdFieldValidatorRgx,
        thirdFieldValidatorRgxFlags: carrier.thirdFieldValidatorRgxFlags
      },
      ...CANOPY_COMPONENT_STYLES
    });

    const { username, password, thirdfield } = form.components;

    // Mount fields
    username.mount(usernameRef.current);
    password && password.mount(passwordRef.current);
    thirdfield && thirdfield.mount(thirdFieldRef.current);

    // Unhide applicable fields
    usernameRef.current.parentNode.classList.remove(styles.hidden);
    password && passwordRef.current.parentNode.classList.remove(styles.hidden);
    thirdfield && thirdFieldRef.current.parentNode.classList.remove(styles.hidden);

    formRef.current = form;

    form.on('ready', () => {
      username.focus();
    });

    form.on('submit', () => {
      handleSubmit();
    });

    return () => {
      dispatch(setError(''));
      form.destroy();
    };
  }, [carrier, termsVersion]);

  return (
    <section className={styles.wrapper}>
      <BackButton
        className={'small'}
        to={connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.CARRIERS)}
        replace
      />

      <div className={styles.visualWrapper}>
        <ConnectVisual logoUrl={carrier.logo} />
      </div>

      <H3>Enter your credentials</H3>

      <p>
        By providing your <b>{carrier.name}</b> credentials, you agree to the Canopy Connect&nbsp;
        <Link to={connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.TOS)}>
          Privacy Policy.
        </Link>
      </p>

      <form className={styles.fields}>
        <fieldset className={cx('fieldset', 'hidden')}>
          <div ref={usernameRef} />
        </fieldset>

        <fieldset className={cx('fieldset', 'hidden')}>
          <div ref={passwordRef} />
        </fieldset>

        <fieldset className={cx('fieldset', 'hidden')}>
          <div ref={thirdFieldRef} />
        </fieldset>
      </form>

      {error && <ErrorText className={styles.error}>{handleErrorText(error)}</ErrorText>}

      <Button
        useKeyboard
        blockButtonMobile
        onClick={handleSubmit}
        className={styles.loginButton}
        loading={isLoading}
      >
        Login
      </Button>

      <div className={styles.accountLinks}>
        {carrier.resetPasswordUrl && (
          <LinkText external href={carrier.resetPasswordUrl}>
            Reset Password
          </LinkText>
        )}

        <LinkText external href={carrier.createAccountUrl}>
          Create Account
        </LinkText>
      </div>
    </section>
  );
};

export default LoginScreen;
