import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { bindIntercomToElement } from '../../../analytics/intercom/utils';
import { HELP_EMAIL } from '../constants/emails';
import LinkText from './LinkText';

const intercomClass = 'woop-intercom-link';

const IntercomLink = ({ text = 'Chat', className }) => {
  useEffect(() => {
    bindIntercomToElement(intercomClass);
  }, [window.Intercom]);

  const handleClick = () => {
    if (!window.Intercom) window.location.href = `mailto:${HELP_EMAIL}`;
  };

  return (
    <LinkText className={`${className} ${intercomClass}`} onClick={handleClick}>
      {text}
    </LinkText>
  );
};

export default IntercomLink;

IntercomLink.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};
