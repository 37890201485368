import { CONNECT_ROUTE_PAGES } from '../../../../../../router/constants/routes';
import { connectRoute } from '../../../../../../router/utils';
import { PULL_STATUSES } from '../../../../api/canopy/constants';

export const getNextRoute = (status, currentPath) => {
  switch (status) {
    case PULL_STATUSES.SUCCESS:
    case PULL_STATUSES.GETTING_CONSUMERS:
    case PULL_STATUSES.PULLING_DATA:
      return connectRoute(currentPath, CONNECT_ROUTE_PAGES.SUCCESS);
    case PULL_STATUSES.IDENTITY_VERIFICATION_OPTIONS:
      return connectRoute(currentPath, CONNECT_ROUTE_PAGES.MFA_OPTIONS);
    case PULL_STATUSES.IDENTITY_VERIFICATION:
      return connectRoute(currentPath, CONNECT_ROUTE_PAGES.MFA_INPUT);
    case PULL_STATUSES.NOT_AUTHENTICATED:
    case PULL_STATUSES.INTERNAL_ERROR:
    case PULL_STATUSES.PROVIDER_ERROR:
      return connectRoute(currentPath, CONNECT_ROUTE_PAGES.LOGIN);
  }
};

export const getStatusMessage = status => {
  switch (status) {
    case PULL_STATUSES.STARTED:
      return 'Started...';
    case PULL_STATUSES.QUEUED:
      return 'Queued...';
    case PULL_STATUSES.PROCESSING:
      return 'Processing...';
    case PULL_STATUSES.GETTING_CONSUMERS:
      return 'Almost there!';
    case PULL_STATUSES.PULLING_DATA:
    case PULL_STATUSES.SUCCESS:
      return 'Success!';
    case PULL_STATUSES.IDENTITY_VERIFICATION_OPTIONS:
    case PULL_STATUSES.IDENTITY_VERIFICATION:
      return 'Verifying identity...';
    case PULL_STATUSES.NOT_AUTHENTICATED:
      return 'Not Authenticated';
    case PULL_STATUSES.INTERNAL_ERROR:
      return 'Internal Error';
    case PULL_STATUSES.PROVIDER_ERROR:
      return 'Provider Error';
  }
};
