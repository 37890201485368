import { useEffect } from 'react';
import { ENTER } from '../constants/keys';

/**
 * React Hook for using keyboard to continue journey.
 *
 * @param {Function} onClick
 * @param {boolean} disabled
 * @param {string} triggerKey The key name, e.g. Enter.
 */
export function useKeyboardNav(onClick, disabled, triggerKey = ENTER) {
  const body = document.body;
  const nav = e => {
    if (!disabled && e.key === triggerKey) {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }
  };
  useEffect(() => {
    body.addEventListener('keydown', nav);
    return function cleanup() {
      body.removeEventListener('keydown', nav);
    };
  }, [disabled, onClick]);
}
