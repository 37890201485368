// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--1WMIH{position:fixed;bottom:0;padding:20px;font-size:14px;width:100%;text-align:center;z-index:-1}@media (max-width:550px){.styles__wrapper--1WMIH{display:none}}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,cAAe,CACf,QAAS,CACT,YAAa,CACb,cAAiB,CACjB,UAAW,CACX,iBAAkB,CAClB,UAIF,CAHE,yBARF,wBASI,YAEJ,CADE","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  position: fixed;\n  bottom: 0;\n  padding: 20px;\n  font-size: $fontS;\n  width: 100%;\n  text-align: center;\n  z-index: -1;\n  @media (--small-viewport) {\n    display: none;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--1WMIH"
};
module.exports = exports;
