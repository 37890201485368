import { COVERAGE_TYPES } from 'woop-shared/enums';
import { MARITAL_STATUSES } from 'woop-shared/origination/enums';
import { ICON_NAMES } from 'woop-shared/prompts/icons';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';

/**
 * Copied from Journey-svc
 */
const MARITAL_STATUS_OPTIONS = [
  {
    value: MARITAL_STATUSES.SINGLE,
    label: 'Single'
  },
  {
    value: MARITAL_STATUSES.MARRIED,
    label: 'Married'
  },
  {
    value: MARITAL_STATUSES.DOMESTIC_PARTNER,
    label: 'Domestic Partner'
  },
  {
    value: MARITAL_STATUSES.SEPARATED,
    label: 'Separated'
  },
  {
    value: MARITAL_STATUSES.WIDOWED,
    label: 'Widowed'
  },
  {
    value: MARITAL_STATUSES.DIVORCED,
    label: 'Divorced'
  }
];

export const PROMPT_PROPS = {
  [PROMPT_NAMES.FIRST_NAME]: {
    name: PROMPT_NAMES.FIRST_NAME,
    label: 'First name'
  },
  [PROMPT_NAMES.LAST_NAME]: {
    name: PROMPT_NAMES.LAST_NAME,
    label: 'Last name'
  },
  [PROMPT_NAMES.DOB]: {
    name: PROMPT_NAMES.DOB,
    label: 'Date of birth',
    validationText: "Hm, are you sure that's your birthday?"
  },
  [PROMPT_NAMES.EMAIL]: {
    name: PROMPT_NAMES.EMAIL,
    label: 'Email',
    validationText: 'Please enter a valid email'
  },
  [PROMPT_NAMES.PHONE]: {
    name: PROMPT_NAMES.PHONE,
    label: 'Phone',
    validationText: 'Please enter a valid phone number'
  },
  [PROMPT_NAMES.MARITAL_STATUS]: {
    name: PROMPT_NAMES.MARITAL_STATUS,
    label: 'Marital Status',
    options: MARITAL_STATUS_OPTIONS,
    validationText: 'Please select your marital status'
  }
};

export const BUNDLED_COVERAGE_TYPE_OPTIONS = [
  {
    label: 'Home',
    value: COVERAGE_TYPES.HOME,
    icon: ICON_NAMES.HOME
  },
  {
    label: 'Auto',
    value: COVERAGE_TYPES.AUTO,
    icon: ICON_NAMES.AUTO
  },
  {
    label: 'Renters',
    value: COVERAGE_TYPES.RENTERS,
    icon: ICON_NAMES.RENTERS
  },
  {
    label: 'Condo',
    value: COVERAGE_TYPES.CONDO,
    icon: ICON_NAMES.CONDO
  },
  {
    label: 'Umbrella',
    value: COVERAGE_TYPES.UMBRELLA,
    icon: ICON_NAMES.UMBRELLA
  }
];

export const OTHER_OPTION = 'Other/Not Listed';
