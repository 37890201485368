import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styles from './styles.css';

const cx = classnames.bind(styles);

const ProgressBar = ({ steps, activeStep }) => {
  const allComplete = steps.length === activeStep;
  const history = useHistory();
  const canGoBack = activeStep === 2;

  function onClick() {
    canGoBack && history.goBack();
  }

  return (
    <div className={styles.wrapper}>
      <hr className={cx({ dim: activeStep === 1 })} />
      <hr className={cx({ dim: !allComplete })} />
      {steps.map((stepName, i) => {
        const stepNum = i + 1;
        const complete = activeStep > stepNum || activeStep === steps.length;
        const active = activeStep === stepNum && !complete;
        return (
          <div
            key={stepName}
            className={cx('step', {
              dim: !active && !complete
            })}
            onClick={stepNum === 1 ? onClick : null}
          >
            <div
              className={cx('circle', {
                active,
                complete,
                clickable: stepNum === 1 && canGoBack
              })}
            >
              <div className={styles.stepNum}>{complete ? <FaCheck /> : stepNum}</div>
            </div>
            <label>{stepName}</label>
            {/* Hides trailing <hr> at the ends of the progress bar */}
            <div className={styles.hackyDiv}></div>
          </div>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string)
};

export default ProgressBar;
