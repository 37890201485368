import PropTypes from 'prop-types';
import React from 'react';
import InfoText from '../../../../../core/components/InfoText';

const PropertyIncidentInfoText = () => {
  const text = `You indicated that you've had at least one claim in the last five years. Please add them here.`;

  return <InfoText className="bar" text={text} />;
};

export default PropertyIncidentInfoText;

PropertyIncidentInfoText.propTypes = {
  journeyData: PropTypes.object
};
