import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import TermsOfService from '../';
import { useModalControls } from '../../../hooks/use-modal-controls';
import LinkText from '../../LinkText';
import Modal from '../../Modal';
import styles from '../../PrivacyPolicy/Link/styles.css';

const cx = classNames.bind(styles);

const TermsOfServiceLink = ({ className }) => {
  const [visible, closing, toggleModal] = useModalControls();
  return (
    <>
      <LinkText className={cx('text', className)} onClick={toggleModal}>
        Terms of Service
      </LinkText>
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <TermsOfService />
      </Modal>
    </>
  );
};

export default TermsOfServiceLink;

TermsOfServiceLink.propTypes = {
  className: PropTypes.string
};
