import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import React from 'react';
import { PROMPTS_TO_BLOGS } from '../../../analytics/intercom/constants/blogs';
import styles from '../css/Prompt.css';
import { openBlog } from '../utils/blogs';
import InvalidIcon from './InvalidIcon';

const cx = classNames.bind(styles);

const Prompt = ({ text, showValidation, tooltip, className, name }) => {
  const blog = PROMPTS_TO_BLOGS[name];
  return text ? (
    <p className={cx('prompt', className)}>
      {tooltip ? (
        <Tooltip
          overlayClassName={styles.tooltip}
          placement={'topLeft'}
          mouseEnterDelay={0.1}
          overlay={tooltip}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <span className={styles.underline}>{text}</span>
        </Tooltip>
      ) : (
        <span onClick={blog ? () => openBlog(blog) : null} className={cx({ underline: !!blog })}>
          {text}
        </span>
      )}
      {showValidation && <InvalidIcon />}
    </p>
  ) : null;
};

export default Prompt;

Prompt.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  showValidation: PropTypes.bool,
  text: PropTypes.string,
  tooltip: PropTypes.string
};
