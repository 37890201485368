import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import React from 'react';
import { EVENTS } from '../../../../../../analytics/constants';
import { trackEvent } from '../../../../../../analytics/utils';
import { ROUTES } from '../../../../../../router/constants/routes';
import PrettyDots from '../../../../../core/assets/pretty_dots.svg';
import Button from '../../../../../core/components/Button';
import H2 from '../../../../../core/components/headers/H2';
import { ASSET_URLS } from '../../../../../core/constants/app';
import CarrierLogos from '../../../../components/CarrierLogos';
import styles from './styles.css';

const HeroCard = ({ onClick }) => {
  const handleClick = () => {
    trackEvent(EVENTS.CLICK_PRIMARY_CTA, { page: ROUTES.WELCOME });
    onClick();
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <H2>
          Lower <span>Your Insurance</span> Rate In Minutes.
        </H2>
        <div>
          <Tooltip
            overlayClassName={styles.tooltip}
            placement={'bottom'}
            mouseEnterDelay={0.1}
            overlay={
              'Based on our insurance premium quotes to prospective customers. Not corroborated by an independent or third-party source.'
            }
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <span>
              We save our customers an average of $516 a year
              <span className={styles.asterisk}>*</span>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className={styles.content}>
        <img src={ASSET_URLS.PHONE_MOCK} className={styles.hero}></img>
        <PrettyDots className={styles.dots} />
        <div className={styles.leftSide}>
          <p>
            Tech-empowered insurance that makes shopping for insurance easy. In minutes you can
            compare your current coverage and find the best rates on policies with accurate, binding
            prices.
          </p>
          <Button onClick={handleClick} useKeyboard blockButtonMobile showArrow>
            Get quotes now
          </Button>
          <CarrierLogos hideLabel />
        </div>
      </div>
    </section>
  );
};

HeroCard.propTypes = {
  onClick: PropTypes.func
};

export default HeroCard;
