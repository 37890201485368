// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--1TJ0O{display:flex;flex-direction:column}.styles__wrapper--1TJ0O h3{font-size:16px}.styles__wrapper--1TJ0O p{margin-top:5px;font-weight:300;font-size:14px;color:rgba(var(--woop-body-text-color),.8);line-height:1.6}.styles__wrapper--1TJ0O .styles__button--vxNaG{align-self:center;width:100%}.styles__visualWrapper--24GRX{margin-top:40px}.styles__error--1P_XT{margin-bottom:20px!important}.styles__fields--1W8hJ{margin-top:5px}.styles__fields--1W8hJ div{margin-bottom:30px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,YAAa,CACb,qBAkBF,CAhBE,2BACE,cACF,CAEA,0BACE,cAAe,CACf,eAAsB,CACtB,cAAiB,CACjB,0CAA6C,CAC7C,eACF,CAEA,+CACE,iBAAkB,CAClB,UACF,CAGF,8BACE,eACF,CAEA,sBACE,4BACF,CAEA,uBACE,cAIF,CAHE,2BACE,kBACF","file":"styles.css","sourcesContent":["@import '../../../../css/variables.css';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n\n  h3 {\n    font-size: $fontDefault;\n  }\n\n  p {\n    margin-top: 5px;\n    font-weight: $fw_light;\n    font-size: $fontS;\n    color: rgba(var(--woop-body-text-color), 0.8);\n    line-height: $lineHeight;\n  }\n\n  .button {\n    align-self: center;\n    width: 100%;\n  }\n}\n\n.visualWrapper {\n  margin-top: 40px; /* Space for absolutely positioned back button */\n}\n\n.error {\n  margin-bottom: 20px !important;\n}\n\n.fields {\n  margin-top: 5px;\n  div {\n    margin-bottom: 30px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--1TJ0O",
	"button": "styles__button--vxNaG",
	"visualWrapper": "styles__visualWrapper--24GRX",
	"error": "styles__error--1P_XT",
	"fields": "styles__fields--1W8hJ"
};
module.exports = exports;
