/* eslint-disable react/jsx-no-target-blank */
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { EVENTS } from '../../../analytics/constants';
import { trackEvent } from '../../../analytics/utils';
import styles from '../css/LinkText.css';

const cx = classnames.bind(styles);

const LinkText = ({ children, className, external = false, onClick, href, disabled, ...rest }) => {
  const handleClick = () => {
    if (external) trackEvent(EVENTS.CLICK_EXTERNAL_LINK, { url: href });
    if (onClick) onClick();
  };
  return (
    <a
      className={cx('link', className)}
      target={external ? '_blank' : undefined}
      rel={external ? 'noreferrer' : undefined}
      onClick={handleClick}
      href={href}
      disabled={disabled}
      {...rest}
    >
      {children}
    </a>
  );
};

LinkText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  external: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool
};

export default LinkText;
