import mixpanel from 'mixpanel-browser';
import { combineReducers } from 'redux';
import { setUserProperties } from '../../../analytics/amplitude/utils';
import { CONNECT_SUCCESS, POP_CANOPY_PULL } from '../../core/actions/canopy';
import {
  PATCH_JOURNEY_SUCCESS,
  UPDATE_JOURNEY,
  POST_JOURNEY_SUCCESS,
  SAVE_JOURNEY_SUCCESS,
  TOGGLE_JOURNEY_SAVED,
  RESUME_JOURNEY_SUCCESS,
  SET_THIRD_PARTY_DATA,
  SET_IS_QUICK_JOURNEY
} from '../actions/journey';
import { getCanopyPulls, THIRD_PARTY_FIELDS } from '../utils/third-party-data';

const submodules = (state = [], action) => {
  switch (action.type) {
    case PATCH_JOURNEY_SUCCESS: {
      const { submodules = [] } = action.payload;
      const newSubmodules = [...submodules]; // Spread to force state to update.

      return newSubmodules;
    }
    case UPDATE_JOURNEY: {
      const { submodules } = action.payload;
      return submodules;
    }
    default:
      return state;
  }
};

const journeyId = (state = '', action) => {
  switch (action.type) {
    case POST_JOURNEY_SUCCESS:
      return action.payload?.journeyId;
    default:
      return state;
  }
};

const saved = (state = false, action) => {
  switch (action.type) {
    case SAVE_JOURNEY_SUCCESS:
      return true;
    case TOGGLE_JOURNEY_SAVED:
      return !state;
    default:
      return state;
  }
};

const thirdPartyData = (state = {}, action) => {
  switch (action.type) {
    case POP_CANOPY_PULL: {
      const pulls = getCanopyPulls(state) || [];
      // Safely remove the last element from pulls
      const newPulls = pulls.slice(0, pulls.length - 1);
      return { ...state, [THIRD_PARTY_FIELDS.CANOPY]: newPulls };
    }
    case CONNECT_SUCCESS: {
      const canopy = getCanopyPulls(state) || [];
      const pull = action.payload;
      // Append incoming pull to existing pulls
      return { ...state, [THIRD_PARTY_FIELDS.CANOPY]: [...canopy, pull] };
    }
    case SET_THIRD_PARTY_DATA:
      return { ...state, ...action.payload };
    case RESUME_JOURNEY_SUCCESS: {
      const { thirdPartyData } = action.payload;
      if (!thirdPartyData) return state;
      return { ...state, ...thirdPartyData };
    }
    default:
      return state;
  }
};

const isQuickJourney = (state = false, action) => {
  switch (action.type) {
    case SET_IS_QUICK_JOURNEY: {
      mixpanel.people.set({ isQuickJourney: action.payload });
      setUserProperties({ isQuickJourney: action.payload });
      return action.payload;
    }
    case RESUME_JOURNEY_SUCCESS: {
      mixpanel.people.set({ isQuickJourney: action.payload.isQuickJourney });
      setUserProperties({ isQuickJourney: action.payload.isQuickJourney });
      return action.payload.isQuickJourney || false;
    }
    default:
      return state;
  }
};

export default combineReducers({
  saved,
  submodules,
  journeyId,
  thirdPartyData,
  isQuickJourney
});
