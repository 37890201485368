import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useKeyboardNav } from '../../hooks/use-keyboard-nav';
import { Spinner } from '../Spinner';
import styles from './styles.css';

const cx = classNames.bind(styles);

const Button = ({
  className,
  classNameInner,
  children,
  disabled,
  onClick = () => {},
  useKeyboard,
  loading,
  showArrow,
  blockButtonMobile,
  invert,
  ...rest
}) => {
  const isDisabled = disabled || loading;

  if (useKeyboard) useKeyboardNav(onClick, isDisabled);

  return (
    <button
      className={cx(className, 'button', { showArrow, blockButtonMobile, invert })}
      onClick={onClick}
      disabled={isDisabled}
      {...rest}
    >
      <div className={cx('children', classNameInner, { loading })}>
        {children}
        {showArrow && <IoIosArrowForward className={styles.arrow} />}
      </div>
      {loading && <Spinner className={styles.spinner} />}
    </button>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  showArrow: PropTypes.bool,
  useKeyboard: PropTypes.bool,
  blockButtonMobile: PropTypes.bool,
  invert: PropTypes.bool
};
