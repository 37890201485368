export const KLAVIYO_EVENTS = {
  VISIT_WOOP: 'Visit Woop',
  ANSWER_PROMPT: 'Answer Prompt',
  COMPLETE_APPLICATION: 'Complete Application',
  VISIT_WOOP_QUOTES: 'Visit Woop (Quotes)',
  CONFIRM_QUOTES: 'Confirm Quotes',
  CONNECT_CANOPY: 'Connect Canopy',
  UPLOAD_DOCUMENTS: 'Upload Documents',
  COMPLETE_ESTIMATE: 'Complete Estimate',
  COMPLETE_SIGN_UP: 'Complete Sign Up'
};

export const KLAVIYO_FIELDS = {
  TEXT_IV_RESULTS: 'text_iv_results'
};
