import { createAction } from 'redux-actions';

export const PATCH_ORIGINATION = 'PATCH_ORIGINATION';
export const patchOrigination = createAction(PATCH_ORIGINATION);

export const SEND_PROOF = 'SEND_PROOF';
export const sendProof = createAction(SEND_PROOF);

export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const unsubscribe = createAction(UNSUBSCRIBE);
