import { takeLatest, call, put, select } from 'redux-saga/effects';
import { EVENT_FIELDS } from 'woop-shared/db/models';
import { LOG_CONNECT_CANOPY, LOG_UPLOAD_DOCUMENTS } from '../../../analytics/actions';
import { FINALIZE_BINDING_SUCCESS } from '../../binding/actions/binding';
import { POST_JOURNEY_SUCCESS, RESUME_JOURNEY_SUCCESS } from '../../journey/actions/journey';
import { CANOPY_FIELDS, getBoxFileIds, getCanopyPulls } from '../../journey/utils/third-party-data';
import { getUrlAccountData } from '../../partners/viv';
import { POST_QUOTESET_SUCCESS, GET_QUOTESET_SUCCESS } from '../../quote/actions';
import { setError } from '../actions/error';
import { addToLoading, rmFromLoading } from '../actions/loading';
import { PATCH_ORIGINATION, SEND_PROOF, UNSUBSCRIBE } from '../actions/origination';
import { POST_VISIT_SUCCESS } from '../actions/visit';
import { patchOrigination, sendProofOfInsurance, track, unsubscribe } from '../api/originations';
import { GENERIC_ERROR_500 } from '../constants/error';
import { ORIGINATION_FIELDS } from '../constants/origination';
import { getUrlJourneyData } from '../utils/journey-data';
import * as logger from '../utils/logger';
import { setOriginationDataField } from '../utils/origination';

function* handlePatchOrigination(action) {
  yield put(addToLoading(action.type));
  const callback = action.payload.callback;
  const { id: oid, originatorId, srcAcctId } = yield select(state => state.origination);
  try {
    yield call(patchOrigination, { oid, srcAcctId, originatorId });
    setOriginationDataField(ORIGINATION_FIELDS.ORIGINATOR_ID, originatorId);
    callback && callback();
  } catch (error) {
    yield put(setError(GENERIC_ERROR_500));
    logger.error(error, { oid, srcAcctId, originatorId });
  }
  yield put(rmFromLoading(action.type));
}

export function* watchPatchOrigination() {
  yield takeLatest(PATCH_ORIGINATION, handlePatchOrigination);
}

function* handleTrackOrigination(event) {
  const { id: oid } = yield select(state => state.origination);
  if (!oid) return;
  try {
    yield call(track, { oid, event });
  } catch (error) {
    logger.error(error, { oid, event });
  }
}

export function* watchJourneySuccessForOrigination() {
  yield takeLatest([POST_JOURNEY_SUCCESS, RESUME_JOURNEY_SUCCESS], () =>
    handleTrackOrigination(EVENT_FIELDS.APPLICATION_STARTED)
  );
}

function* handleSendProof(action) {
  const {
    journey: { thirdPartyData, journeyId },
    origination: { id: oid }
  } = yield select(state => state);

  const fileIds = getBoxFileIds(thirdPartyData);
  const pulls = getCanopyPulls(thirdPartyData) || [];
  const pullId = [...pulls].pop()?.[CANOPY_FIELDS.PULL_ID];
  const userId = action.payload;

  try {
    yield call(sendProofOfInsurance, { oid, journeyId, fileIds, pullId, userId });
  } catch (error) {
    logger.error(error, { oid, journeyId, fileIds, pullId });
  }
}

export function* watchSendProof() {
  yield takeLatest([SEND_PROOF], handleSendProof);
}

function* handleUnsubscribe() {
  const oid = yield select(state => state.origination.id);
  yield put(addToLoading(UNSUBSCRIBE));
  try {
    yield call(unsubscribe, oid);
  } catch (error) {
    logger.error(error);
  }
  yield put(rmFromLoading(UNSUBSCRIBE));
}

export function* watchUnsubscribe() {
  yield takeLatest(UNSUBSCRIBE, handleUnsubscribe);
}

export function* watchLogConnectCanopyForOrigination() {
  yield takeLatest(LOG_CONNECT_CANOPY, () => handleTrackOrigination(EVENT_FIELDS.CANOPY_CONNECTED));
}

export function* watchLogUploadDocumentsForOrigination() {
  yield takeLatest(LOG_UPLOAD_DOCUMENTS, () =>
    handleTrackOrigination(EVENT_FIELDS.UPLOADED_DOCUMENTS)
  );
}

export function* watchQuotesetPostForOrigination() {
  yield takeLatest(POST_QUOTESET_SUCCESS, () =>
    handleTrackOrigination(EVENT_FIELDS.APPLICATION_COMPLETED)
  );
}

export function* watchQuotesetGetForOrigination() {
  yield takeLatest(GET_QUOTESET_SUCCESS, () => handleTrackOrigination(EVENT_FIELDS.QUOTES_VISITED));
}

export function* watchBindingsPostForOrigination() {
  yield takeLatest(FINALIZE_BINDING_SUCCESS, () =>
    handleTrackOrigination(EVENT_FIELDS.QUOTES_CONFIRMED)
  );
}

function* handlePatchOriginationForViv(action) {
  const { srcAcctId } = yield select(state => state.origination);
  const urlAccountData = getUrlAccountData();
  const urlUiData = getUrlJourneyData();
  if (!urlAccountData && !urlUiData) return;
  const oid = action?.payload?.oid;
  yield call(patchOrigination, { oid, uiData: urlUiData, urlAccountData, srcAcctId });
}

export function* watchPostVisitSuccessForPatchOrigination() {
  yield takeLatest(POST_VISIT_SUCCESS, handlePatchOriginationForViv);
}
