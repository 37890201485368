import { GET_QUOTESET_SUCCESS } from '../actions';

const coverageTypes = (state = [], action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload.coverageTypes || state;
    default:
      return state;
  }
};

export default coverageTypes;
