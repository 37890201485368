import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BLOGS } from '../../../../../../analytics/intercom/constants/blogs';
import BoxUploader from '../../../../../core/components/BoxUploader';
import Button from '../../../../../core/components/Button';
import H3 from '../../../../../core/components/headers/H3';
import LinkText from '../../../../../core/components/LinkText';
import OverlayModal from '../../../../../core/components/OverlayModal';
import { openBlog } from '../../../../../core/utils/blogs';
import styles from './styles.css';

const BoxModal = ({ open, toggleOpen, onUpload, onContinue, isValid }) => {
  const [loading, setLoading] = useState(false);

  return (
    <OverlayModal
      open={open}
      toggleOpen={toggleOpen}
      closeable={true}
      contentClass={styles.wrapper}
    >
      <div className={styles.info}>
        <H3>Upload your declarations page</H3>
        Not sure where to find your declarations page?{' '}
        <LinkText onClick={() => openBlog(BLOGS.DOWNLOAD_DEC_PAGE)}>Click here</LinkText>.
      </div>

      <BoxUploader onUpload={onUpload} className={styles.box} />
      <Button
        onClick={() => {
          setLoading(true);
          onContinue();
        }}
        className={styles.button}
        disabled={!isValid}
        loading={loading}
        blockButtonMobile
        showArrow
      >
        Continue
      </Button>
    </OverlayModal>
  );
};

export default BoxModal;

BoxModal.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
  onUpload: PropTypes.func,
  onContinue: PropTypes.func,
  isValid: PropTypes.bool
};
