// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--2025Y{display:flex;grid-gap:10px;gap:10px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAAA,wBACE,YAAa,CACb,aAAS,CAAT,QACF","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--2025Y"
};
module.exports = exports;
