import { PAYMENT_PLANS, PAYMENT_METHODS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import { getCheapestPaymentPlan } from 'woop-shared/quotes/utils';
import {
  DEFAULT_PAYMENT_FIELDS,
  ACH_FIELDS,
  ESCROW_FIELDS,
  CARD_FIELDS,
  BINDING_FIELDS,
  PAYMENT_FIELDS
} from '../constants/payment-page';

export function isPaymentPageValid(payment, binding) {
  const agreementsValid = binding[BINDING_FIELDS.AGREE_TO_DOCS.key];
  return agreementsValid && isPaymentValid(payment);
}

export function isPaymentValid(payment) {
  // The fields that are required for all payment methods.
  const defaultFields = Object.values(DEFAULT_PAYMENT_FIELDS).every(field =>
    field.validator(payment?.[field.key])
  );

  if (!defaultFields) return false;

  // The fields that are specific to the payment method.
  switch (payment[DEFAULT_PAYMENT_FIELDS.METHOD.key]) {
    case PAYMENT_METHODS.ACH:
      return Object.values(ACH_FIELDS).every(field => {
        if (!field.validator) return false;
        if (field.key === PAYMENT_FIELDS.ACCOUNT_NUMBER_CONFIRM.key)
          return field.validator(
            payment?.[PAYMENT_FIELDS.ACCOUNT_NUMBER.key],
            payment?.[field.key]
          );
        return field.validator(payment?.[field.key]);
      });
    case PAYMENT_METHODS.ESCROW:
      return Object.values(ESCROW_FIELDS).every(
        field => field.validator && field.validator(payment?.[field.key])
      );
    case PAYMENT_METHODS.CARD:
      return Object.values(CARD_FIELDS).every(
        field => field.validator && field.validator(payment?.[field.key])
      );
  }
}

/**
 * Get the cheapest premium per payment plan. Or use payment method to select one per plan. Always fall back to cheapest plan.
 *
 * @param {object[]} premiums
 * @param {string} paymentMethod
 * @returns {object[]} Premiums, one per payment plan
 */
export function getPremiumPerPlan(premiums, paymentMethod) {
  return Object.values(PAYMENT_PLANS)
    .map(paymentPlan => {
      const planForMethod =
        paymentMethod && getPremiumByMethod({ premiums, paymentMethod, paymentPlan });
      return planForMethod ? planForMethod : getCheapestPaymentPlan(premiums, paymentPlan);
    })
    .filter(Boolean);
}

/**
 * Return premium with matching payment plan and method combination
 *
 * @param {object} params
 * @param {object[]} params.premiums
 * @param {string} params.paymentMethod
 * @param {string} params.paymentPlan
 * @returns {object}
 */
export function getPremiumByMethod({ premiums, paymentMethod, paymentPlan }) {
  return premiums.find(
    p =>
      p[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan &&
      p[PREMIUM_FIELDS.PAYMENT_METHOD] === paymentMethod
  );
}
