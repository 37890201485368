import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../../router/constants/routes';
import CoupleInCar from '../../../../core/assets/undraw_off_road_3.svg';
import AppWrapper from '../../../../core/components/AppWrapper';
import Button from '../../../../core/components/Button';
import H2 from '../../../../core/components/headers/H2';
import LegalLinks from '../../../../core/components/LegalLinks';
import { useSlimApp } from '../../../../core/hooks/use-slim-app';
import styles from './styles.css';

const AutoChecklistStart = () => {
  useSlimApp({ removeOnUnmount: true });
  const history = useHistory();
  const handleNext = () => {
    history.push(ROUTES.AUTO_CHECKLIST_PICKER);
  };
  return (
    <AppWrapper className={styles.wrapper} isFloatingCardParent>
      <div className={styles.content}>
        <CoupleInCar className={styles.desktopSvg} />
        <div className={styles.right}>
          <H2 className={styles.title}>The Ultimate Checklist</H2>
          <p className={styles.subtitle}>To save time at the dealership</p>
          <CoupleInCar className={styles.mobileSvg} />
          <p className={styles.text}>
            {`We'll get you prepared so your time in the dealership will be seamless and you can drive away satisfied.`}
          </p>
          <Button onClick={handleNext} blockButtonMobile showArrow className={styles.button}>
            Get Started
          </Button>
        </div>
        <LegalLinks className={styles.legal} />
      </div>
    </AppWrapper>
  );
};

export default AutoChecklistStart;

AutoChecklistStart.propTypes = {};
