import { useEffect } from 'react';
import { ESC } from '../constants/keys';

/**
 * React Hook for using keyboard to close something.
 *
 * @param {Function} closeIt A function that closes something || false.
 * @param {string} triggerKey The key name, e.g. ESC.
 */
export function useKeyboardClose(closeIt, triggerKey = ESC) {
  const body = document.body;
  const close = e => {
    if (closeIt && e.key === triggerKey) {
      e.preventDefault();
      e.stopPropagation();
      closeIt && closeIt();
    }
  };
  useEffect(() => {
    body.addEventListener('keydown', close);
    return function cleanup() {
      body.removeEventListener('keydown', close);
    };
  }, [closeIt]);
}
