import axios from 'axios';
import { RESOURCES } from '../../core/utils/api';
import * as logger from '../../core/utils/logger';
import { formatPost } from './formatter';
/**
 * Bindings(POST).
 *
 * @param {object} obj
 * @param {Array} obj.payments
 * @param {Array} obj.selectedQuotes
 * @param {string} obj.quotesetId
 * @param {boolean} obj.resubmit
 * @returns {boolean} Whether the call was successful
 */
export const bindPost = async ({ payments, selectedQuotes, quotesetId, resubmit }) => {
  const formattedData = formatPost({
    payments,
    selectedQuotes,
    quotesetId,
    resubmit
  });
  logger.log('bind(POST) request: ', formattedData);
  const { data } = await axios.post(process.env.BINDING_URI, formattedData);
  logger.log('bind(POST) response: ', data);
  return data;
};

const getRecurringPaymentLanguageUri = carrierCode => {
  return `${process.env.DATA_URI}${RESOURCES.CARRIERS}/${carrierCode}/recurringPaymentLanguage`;
};

/**
 * Get the recurring payment authorization language for a carrier
 *
 * @param {object} obj
 * @param {string} obj.carrierCode
 * @param {string} obj.paymentMethod
 * @param {string} obj.state A US state abbreviation
 * @returns {object} A response object
 */
export const getRecurringPaymentLanguage = async ({ carrierCode, paymentMethod, state }) => {
  const baseUri = getRecurringPaymentLanguageUri(carrierCode);
  const { data } = await axios.get(baseUri, { params: { paymentMethod, state } });
  return data;
};
