const PARTNER_ROUTES = {
  PAW_START: '/paw-start'
};

export const CONNECT_ROUTE_PAGES = {
  CARRIERS: '/carriers',
  LOGIN: '/login',
  TOS: '/tos',
  LOADING: '/loading',
  MFA_OPTIONS: '/mfa-options',
  MFA_INPUT: '/mfa-input',
  SUCCESS: '/success'
};

const ENTRY_ROUTES = {
  WELCOME: '/welcome',
  WELCOME_QUOTES: '/quotes/welcome',
  AUTO_CHECKLIST_START: '/auto-checklist/start',
  UNSUBSCRIBE: '/unsubscribe',
  SIGN_UP_WELCOME: '/sign-up/welcome',
  DEALERSHIP_PICKER: '/dealership-picker',
  ESTIMATE_START: '/estimate/start',
  IV_START: '/iv/start',
  IV_CONNECT: '/iv/connect'
};

export const ROUTES = {
  ROOT: '/',
  SIGN_UP: '/sign-up',
  SIGN_UP_INFO: '/sign-up/info',
  SIGN_UP_CONNECT: '/sign-up/connect',
  IV_SIGN_UP: '/iv/sign-up',
  IV_INFO: '/iv/info',
  IV_END: '/iv/end',
  ESTIMATE: '/estimate',
  ESTIMATE_INFO: '/estimate/info',
  ESTIMATE_VEHICLES: '/estimate/vehicles',
  ESTIMATE_TRADE_IN: '/estimate/trade-in',
  SUCCESS: '/success',

  CONNECT: '/connect',
  CONNECT_CARRIERS: `/connect${CONNECT_ROUTE_PAGES.CARRIERS}`,
  CONNECT_LOGIN: `/connect${CONNECT_ROUTE_PAGES.LOGIN}`,
  CONNECT_TOS: `/connect${CONNECT_ROUTE_PAGES.TOS}`,
  CONNECT_LOADING: `/connect${CONNECT_ROUTE_PAGES.LOADING}`,
  CONNECT_MFA_OPTIONS: `/connect${CONNECT_ROUTE_PAGES.MFA_OPTIONS}`,
  CONNECT_MFA_INPUT: `/connect${CONNECT_ROUTE_PAGES.MFA_INPUT}`,
  CONNECT_SUCCESS: `/connect${CONNECT_ROUTE_PAGES.SUCCESS}`,

  IV_CONNECT_CARRIERS: `/iv/connect${CONNECT_ROUTE_PAGES.CARRIERS}`,
  IV_CONNECT_LOGIN: `/iv/connect${CONNECT_ROUTE_PAGES.LOGIN}`,
  IV_CONNECT_TOS: `/iv/connect${CONNECT_ROUTE_PAGES.TOS}`,
  IV_CONNECT_LOADING: `/iv/connect${CONNECT_ROUTE_PAGES.LOADING}`,
  IV_CONNECT_MFA_OPTIONS: `/iv/connect${CONNECT_ROUTE_PAGES.MFA_OPTIONS}`,
  IV_CONNECT_MFA_INPUT: `/iv/connect${CONNECT_ROUTE_PAGES.MFA_INPUT}`,
  IV_CONNECT_SUCCESS: `/iv/connect${CONNECT_ROUTE_PAGES.SUCCESS}`,

  CONNECTED: '/connected',
  PREJOURNEY: '/prejourney',
  JOURNEY: '/journey',
  JOURNEY_CONFIRMATION: '/journey-confirmation',
  SUPPLEMENTARY_INFO: '/supplementary-info',
  QUOTES: '/quotes',
  QUOTE_DETAILS: '/quote-details',
  JOURNEY_END: '/journey-end',
  PAYMENT_CONFIRMATION: '/payment-confirmation',
  PAYMENT_SUBMITTED: '/quote-selected',
  PAYMENT: '/payment',
  RECURRING_PAYMENT_AUTHORIZATION: '/recurring-payment-authorization',
  AUTO_CHECKLIST_CHECKLIST: '/auto-checklist/checklist',
  CROSS_SELL: '/cross-sell',
  AUTO_CHECKLIST_PICKER: '/auto-checklist/picker',
  ...PARTNER_ROUTES,
  ...ENTRY_ROUTES
};

const MOBILE_LOGO_ROUTES_EXCLUDE = [ROUTES.IV_START, ROUTES.IV_CONNECT];

export const MOBILE_LOGO_ROUTES = [
  ...Object.values(ENTRY_ROUTES).filter(r => !MOBILE_LOGO_ROUTES_EXCLUDE.includes(r)),
  ROUTES.CONNECT,
  ROUTES.CONNECTED,
  ROUTES.SIGN_UP_INFO,
  ROUTES.SIGN_UP_CONNECT,
  ROUTES.ESTIMATE_INFO,
  ROUTES.ESTIMATE_TRADE_IN,
  ROUTES.ESTIMATE_VEHICLES,
  ROUTES.SUCCESS
];

export const RESUME_JOURNEY_LANDING_PAGES = [
  ROUTES.WELCOME,
  ROUTES.CONNECT,
  ROUTES.AUTO_CHECKLIST_START,
  ROUTES.SIGN_UP_WELCOME,
  ROUTES.DEALERSHIP_PICKER,
  ROUTES.ESTIMATE_START
];

export const NO_VISIT_POST_PATHS = [ROUTES.UNSUBSCRIBE, ROUTES.QUOTES, ROUTES.PAYMENT];

export const ENABLE_EXIT_CONFIRMATION_ROUTES = [
  ROUTES.PAYMENT_CONFIRMATION,
  ROUTES.JOURNEY_CONFIRMATION,
  ROUTES.QUOTE_DETAILS
];

// Paths where we collect user information to sync to Klaviyo.
export const SYNC_KLAVIYO_MODULES = [
  ROUTES.JOURNEY,
  ROUTES.PREJOURNEY,
  ROUTES.ESTIMATE,
  ROUTES.SIGN_UP,
  ROUTES.CONNECT,
  ROUTES.SUCCESS
];

export const SYNC_KLAVIYO_ROUTES = [ROUTES.IV_END];

export const SUBMODULE_PATHS = {
  FINAL_DETAILS: '/final-details',
  DRIVER_DISCOUNTS: '/auto-discounts'
};

export const PAGE_PATHS = {
  YOUR_SPOUSE_AUTO: 'your-spouse-auto',
  YOUR_SPOUSE: 'your-spouse',
  EXCLUDED_DRIVERS: 'excluded-drivers',
  AUTO_INCIDENTS: 'auto-incidents',
  ASSIGN_DRIVERS: 'assign-drivers',
  PROPERTY_CLAIMS: 'property-claims',
  PRIMARY_RESIDENCE: 'primary-residence'
};
