import { LOCATION_CHANGE } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';
import { getJourneyData, getPathname, getSubmodules, getPages } from '../../../store/utils';
import {
  UPDATE_PROMPT_VALIDITY,
  UPDATE_PROMPT_GROUP_VALIDITY,
  DELETE_PROMPT_GROUP
} from '../../core/actions/journey-data';
import { getFirstInvalidPrompt } from '../../core/utils/prompt-validation';
import { UPDATE_JOURNEY, PATCH_JOURNEY_SUCCESS } from '../actions/journey';
import {
  updatePageValidity,
  updatePages,
  UPDATE_PAGE_SHOW_VALIDATION,
  setPromptGroupIndex
} from '../actions/pages';
import { scrollToPrompt } from '../actions/scroll-to';
import { updateSubmoduleValidity } from '../actions/submodules';
import { getNextInvalidPageIndex, isJourneyPath } from '../utils/journey-content';

function* handleUpdateSubmoduleValidity() {
  const submodules = yield select(getSubmodules);
  const pages = yield select(getPages);
  const journeyData = yield select(getJourneyData);
  yield put(updateSubmoduleValidity({ submodules, pages, journeyData }));
}

function* handleUpdatePageValidity() {
  const pathname = yield select(getPathname);
  if (isJourneyPath(pathname)) {
    const journeyData = yield select(getJourneyData);
    yield put(updatePageValidity({ pathname, journeyData }));
    yield* handleUpdateSubmoduleValidity();
  }
}

export function* watchUpdatePageValidity() {
  yield takeLatest(
    [UPDATE_PROMPT_VALIDITY, UPDATE_PROMPT_GROUP_VALIDITY, DELETE_PROMPT_GROUP, LOCATION_CHANGE],
    handleUpdatePageValidity
  );
}

function* handleJourneyChanges({ payload }) {
  const { submodules } = payload;
  const journeyData = yield select(getJourneyData);
  yield put(updatePages({ submodules, journeyData }));
  yield* handleUpdateSubmoduleValidity();
}

export function* watchJourneyChanges() {
  yield takeLatest([UPDATE_JOURNEY, PATCH_JOURNEY_SUCCESS], handleJourneyChanges);
}

function* maybeScrollToNextInvalidPrompt() {
  const pathname = yield select(getPathname);
  const { pages, journeyData } = yield select(state => state);
  const { showValidation, createable, promptGroupName, prompts, promptGroupIndex } =
    pages[pathname];

  if (!showValidation) return;

  const prompt = getFirstInvalidPrompt({ prompts, journeyData, promptGroupName, promptGroupIndex });

  if (prompt?.name) {
    yield put(scrollToPrompt(prompt.name));
  } else if (createable) {
    // If all the prompts on this page are valid and it's a promptGroupPage, check if another page is invalid.
    const index = getNextInvalidPageIndex(prompts, journeyData, promptGroupName);
    if (index >= 0) yield put(setPromptGroupIndex({ index, pathname }));
  }
}

export function* watchForUpdatePageShowValidation() {
  yield takeLatest([UPDATE_PAGE_SHOW_VALIDATION], maybeScrollToNextInvalidPrompt);
}
