// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--qJRIX{position:absolute;bottom:0;right:0;padding:20px;display:flex;flex-flow:wrap;align-items:center}@media (max-width:550px){.styles__wrapper--qJRIX{position:relative;margin-bottom:60px}.styles__wrapper--qJRIX.styles__noMobileButton--39TxF{margin-bottom:0}}@media (max-height:400px) AND (max-width:550px){.styles__wrapper--qJRIX{display:none}}.styles__wrapper--qJRIX .styles__section--1XJS0{display:flex;flex-flow:wrap;align-items:center}@media (max-width:550px){.styles__wrapper--qJRIX .styles__section--1XJS0{justify-content:center;width:100%}}.styles__wrapper--qJRIX .styles__link--v99EC{font-size:12px;font-style:inherit;position:static;margin:0}.styles__wrapper--qJRIX .styles__separator--1l2p3{opacity:.2;margin:0 5px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,iBAAkB,CAClB,QAAW,CACX,OAAU,CACV,YAAa,CACb,YAAa,CACb,cAAe,CACf,kBAqCF,CAnCE,yBATF,wBAUI,iBAAkB,CAClB,kBAiCJ,CA/BI,sDACE,eACF,CACF,CAEA,gDAlBF,wBAmBI,YAyBJ,CAxBE,CAEA,gDACE,YAAa,CACb,cAAe,CACf,kBAMF,CAJE,yBALF,gDAMI,sBAAuB,CACvB,UAEJ,CADE,CAGF,6CACE,cAAkB,CAClB,kBAAmB,CACnB,eAAgB,CAChB,QACF,CAEA,kDACE,UAAY,CACZ,YACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  position: absolute;\n  bottom: 0px;\n  right: 0px;\n  padding: 20px;\n  display: flex;\n  flex-flow: wrap;\n  align-items: center;\n\n  @media (--small-viewport) {\n    position: relative;\n    margin-bottom: $mobileNavHeight;\n\n    &.noMobileButton {\n      margin-bottom: 0px;\n    }\n  }\n\n  @media (--open-keyboard) {\n    display: none;\n  }\n\n  .section {\n    display: flex;\n    flex-flow: wrap;\n    align-items: center;\n\n    @media (--small-viewport) {\n      justify-content: center;\n      width: 100%;\n    }\n  }\n\n  .link {\n    font-size: $fontXS;\n    font-style: inherit;\n    position: static;\n    margin: 0px;\n  }\n\n  .separator {\n    opacity: 0.2;\n    margin: 0px 5px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--qJRIX",
	"noMobileButton": "styles__noMobileButton--39TxF",
	"section": "styles__section--1XJS0",
	"link": "styles__link--v99EC",
	"separator": "styles__separator--1l2p3"
};
module.exports = exports;
