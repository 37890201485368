import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import PromptWrapper from '../../journey/containers/PromptWrapper';
import styles from '../css/Textarea.css';
import Prompt from './Prompt';

const cx = classNames.bind(styles);

const Textarea = ({ name, prompt, placeholder, className, tooltip, ...rest }) => {
  const { value = '', save } = rest;

  return (
    <PromptWrapper name={name} className={cx('wrapper', className)}>
      <Prompt text={prompt} tooltip={tooltip} />
      <textarea
        name={name}
        placeholder={placeholder}
        onChange={e => save(e.target.value)}
        value={value}
        {...rest}
      />
    </PromptWrapper>
  );
};

export default Textarea;

Textarea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  prompt: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string
};
