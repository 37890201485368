import { takeLatest, call, put, select, takeLeading } from 'redux-saga/effects';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { EVENTS } from '../../../analytics/constants';
import { trackEvent } from '../../../analytics/utils';
import { getSubmodules, getJourneyId } from '../../../store/utils';
import { UPDATE_PROMPT } from '../../core/actions/journey-data';
import { addToLoading, rmFromLoading } from '../../core/actions/loading';
import { POST_VISIT_SUCCESS } from '../../core/actions/visit';
import { JOURNEY_ID } from '../../core/constants/cookies';
import { QUERY_PARAMS } from '../../core/constants/url';
import { setCookie } from '../../core/utils/cookies';
import { flattenJourneyData } from '../../core/utils/journey-data';
import * as logger from '../../core/utils/logger';
import { getQueryStringParam, removeJourneyIdQueryParam } from '../../core/utils/url';
import {
  UPDATE_LOOPABLE_PAGES,
  updateJourney,
  postJourneySuccess,
  SAVE_JOURNEY,
  saveJourneySuccess,
  saveJourneyFail,
  resumeJourneySuccess,
  SAVE_JOURNEY_SUCCESS,
  POST_JOURNEY,
  postJourney,
  RESUME_JOURNEY,
  patchJourneySuccess,
  patchJourneyFail,
  PATCH_JOURNEY,
  patchJourney
} from '../actions/journey';
import * as api from '../api';
import { updateLoopablePages } from '../utils/journey-content';
import { getCanopyPulls } from '../utils/third-party-data';

function* handlePostJourney() {
  const { origination, scenario, campaign } = yield select(state => state);
  const oid = origination.id;

  yield put(addToLoading(POST_JOURNEY));

  try {
    const { journeyId, uiData } = yield call(api.postJourney, { oid, scenario, campaign });
    logger.log('journey(post) response data: ', journeyId);

    setCookie(JOURNEY_ID, journeyId);

    yield put(postJourneySuccess({ journeyId, uiData }));
  } catch (error) {
    logger.error(error, { oid });
  }
  yield put(rmFromLoading(POST_JOURNEY));
}

export function* watchPostJourney() {
  yield takeLeading(POST_JOURNEY, handlePostJourney);
}

function* maybeCallPostJourney() {
  // If someone is returning to view quotes, don't post journey.
  const quotesetId = getQueryStringParam(QUERY_PARAMS.QUOTESET_ID);
  if (quotesetId) return;

  // If we already have a journeyId, don't fire off the post.
  const hasJourneyId = yield select(getJourneyId);
  if (hasJourneyId) return;

  yield put(postJourney());
}

export function* watchPostVisitSuccessForJourney() {
  yield takeLatest(POST_VISIT_SUCCESS, maybeCallPostJourney);
}

function* maybePatchJourney(action) {
  const { name, value } = action.payload;
  if (name === PROMPT_NAMES.COVERAGE_TYPE && value?.length) {
    yield put(patchJourney());
  }
}

export function* watchUpdatePromptValidity() {
  yield takeLatest(UPDATE_PROMPT, maybePatchJourney);
}

function* handlePatchJourney(action) {
  yield put(addToLoading(PATCH_JOURNEY));

  const callback = action.payload?.callback;

  const {
    journey: { journeyId, isQuickJourney, thirdPartyData },
    journeyData,
    origination: { id: originationId },
    scenario,
    campaign
  } = yield select();

  try {
    const flatJourneyData = flattenJourneyData(journeyData);

    // Tracking issue https://sentry.io/organizations/woop/issues/2947617208/?environment=prod
    const pulls = getCanopyPulls(thirdPartyData);
    if (pulls?.length && (pulls.includes(null) || pulls.includes(undefined))) {
      const state = yield select();
      logger.warn('Attempting to patch a quick journey with invalid pulls array', {
        pulls,
        state
      });
    }

    const { journey, uiData } = yield call(api.patchJourney, {
      journeyId,
      journeyData: flatJourneyData,
      thirdPartyData,
      isQuickJourney,
      originationId,
      scenario,
      campaign
    });
    const { submodules } = journey;
    logger.log('Journey data:', journey);

    yield put(patchJourneySuccess({ submodules, uiData, isQuickJourney }));
    if (callback) yield call(callback);
  } catch (error) {
    yield put(patchJourneyFail(error));
    logger.error(error, { journeyId });
  }

  yield put(rmFromLoading(PATCH_JOURNEY));
}

export function* watchPatchJourney() {
  yield takeLatest(PATCH_JOURNEY, handlePatchJourney);
}

function* handleUpdateLoopablePages(action) {
  const { promptGroupName, promptGroupCount } = action.payload;
  const submodules = yield select(getSubmodules);
  const updatedSubmodules = updateLoopablePages(submodules, promptGroupName, promptGroupCount);
  yield put(updateJourney({ submodules: updatedSubmodules }));
}

export function* watchUpdateLoopablePages() {
  yield takeLatest(UPDATE_LOOPABLE_PAGES, handleUpdateLoopablePages);
}

function* handleSaveJourney(action) {
  yield put(addToLoading(action.type));

  const {
    journeyData,
    journey: { journeyId }
  } = yield select(state => state);

  const uiData = flattenJourneyData(journeyData);

  try {
    yield call(api.saveJourney, { journeyId, uiData });
    if (action.payload?.sendEmail) {
      // Log manual save
      trackEvent(EVENTS.SAVE_JOURNEY);
      yield put(saveJourneySuccess());
    }
  } catch (error) {
    logger.error(error, { journeyId });
    yield put(saveJourneyFail());
  }

  yield put(rmFromLoading(action.type));
}

export function* watchSaveJourney() {
  yield takeLatest([SAVE_JOURNEY], handleSaveJourney);
}

function* handleSendResumeEmail() {
  const journeyId = yield select(getJourneyId);
  yield call(api.sendResumeEmail, journeyId);
}

export function* watchSaveJourneySuccess() {
  yield takeLatest([SAVE_JOURNEY_SUCCESS], handleSendResumeEmail);
}

function* handleResumeJourney(action) {
  yield put(addToLoading(action.type));

  const { origination, journey } = yield select(state => state);
  const oid = origination.id;
  const journeyId = journey.journeyId;

  try {
    const { uiData, isQuickJourney, thirdPartyData } = yield call(api.resumeJourney, {
      oid,
      journeyId
    });
    yield put(resumeJourneySuccess({ isQuickJourney, uiData, thirdPartyData }));
  } catch (error) {
    if (![401, 403].includes(error?.response?.status)) {
      logger.error(error, { journeyId });
    }
    yield put(postJourney());
    removeJourneyIdQueryParam();
  }
  yield put(rmFromLoading(action.type));
}

export function* watchResumeJourney() {
  yield takeLatest([RESUME_JOURNEY], handleResumeJourney);
}
