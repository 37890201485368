import { LOCATION_CHANGE } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { RELATIONSHIPS } from 'woop-shared/origination/enums';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import { PAGE_PATHS } from '../../../router/constants/routes';
import { getPathname, getJourneyData } from '../../../store/utils';
import { DUPLICATED_DRIVER_FIELDS } from '../../journey/constants/prompts';
import {
  UPDATE_PROMPT,
  updatePromptGroupValidity,
  updatePromptValidity,
  UPDATE_PROMPT_GROUP,
  updatePrompt,
  updatePromptGroup
} from '../actions/journey-data';
import { getCoverageTypeByResidenceType, getPromptGroupVal, getVal } from '../utils/journey-data';
import { handleCrossSellValues } from '../utils/journey-data';
import { isValidPrompt } from '../utils/prompt-validation';

function* handleUpdatePromptValidity(action) {
  const { name, value, promptGroupName, promptGroupIndex } = action.payload;
  const isValid = isValidPrompt(name, value);
  if (promptGroupName) {
    yield put(updatePromptGroupValidity({ name, isValid, promptGroupName, promptGroupIndex }));
  } else {
    yield put(updatePromptValidity({ name, isValid }));
  }
}

export function* watchUpdateJourneyDataForPrompts() {
  yield takeLatest([UPDATE_PROMPT, UPDATE_PROMPT_GROUP], handleUpdatePromptValidity);
}

function* handleUpdateDependentFields({ payload }) {
  const { name, value, promptGroupName, promptGroupIndex, stopPropagation } = payload;

  if (DUPLICATED_DRIVER_FIELDS.has(name) && isValidPrompt(name, value) && !stopPropagation) {
    if (promptGroupName === PROMPT_GROUP_NAMES.DRIVERS && promptGroupIndex === 0) {
      yield put(updatePrompt({ name, value, stopPropagation: true }));
    } else if (!promptGroupName) {
      // Handle only top-level fields (Do not collide with coapplicants).
      yield put(
        updatePromptGroup({
          name,
          value,
          promptGroupName: PROMPT_GROUP_NAMES.DRIVERS,
          promptGroupIndex: 0,
          stopPropagation: true
        })
      );
    }
  }

  // Handle adding the cross-sell coverage type.
  if (name === PROMPT_NAMES.INTERESTED_IN_PROPERTY) {
    const journeyData = yield select(getJourneyData);
    const coverageTypes = getVal(journeyData[PROMPT_NAMES.COVERAGE_TYPE]);
    const shouldBundle = !!value;

    if (!shouldBundle && coverageTypes.length > 1) {
      yield put(
        updatePrompt({
          name: PROMPT_NAMES.COVERAGE_TYPE,
          value: [COVERAGE_TYPES.AUTO]
        })
      );
      return;
    }

    const residenceType = getVal(journeyData[PROMPT_NAMES.RESIDENCE_TYPE]);
    const propertyCoverageType = getCoverageTypeByResidenceType(residenceType);
    const crossSellValues = handleCrossSellValues(coverageTypes, propertyCoverageType);
    if (propertyCoverageType && shouldBundle) {
      yield put(
        updatePrompt({
          name: PROMPT_NAMES.COVERAGE_TYPE,
          value: [...crossSellValues]
        })
      );
    }
  }

  // Handle setting spouse fname + lname into coapplicants name field
  if (
    promptGroupName === PROMPT_GROUP_NAMES.PERSON &&
    promptGroupIndex === 0 &&
    (name === PROMPT_NAMES.FIRST_NAME || name === PROMPT_NAMES.LAST_NAME)
  ) {
    const journeyData = yield select(getJourneyData);
    const promptGroup = journeyData[promptGroupName];

    const firstName = getPromptGroupVal(promptGroup, promptGroupIndex, PROMPT_NAMES.FIRST_NAME);
    const lastName = getPromptGroupVal(promptGroup, promptGroupIndex, PROMPT_NAMES.LAST_NAME);
    yield put(
      updatePromptGroup({
        name: PROMPT_NAMES.NAME,
        value: `${firstName} ${lastName}`,
        promptGroupIndex,
        promptGroupName
      })
    );
  }
}

export function* watchUpdatePromptForDependentFields() {
  yield takeLatest([UPDATE_PROMPT, UPDATE_PROMPT_GROUP], handleUpdateDependentFields);
}

function* setDefaultFieldsForPage() {
  const pathname = yield select(getPathname);
  const { pages } = yield select(state => state);
  const isAutoSpousePage = pages?.[pathname]?.path === PAGE_PATHS.YOUR_SPOUSE_AUTO;
  const isSpousePage = pages?.[pathname]?.path === PAGE_PATHS.YOUR_SPOUSE;

  if (isAutoSpousePage || isSpousePage) {
    const promptGroupName = isAutoSpousePage
      ? PROMPT_GROUP_NAMES.DRIVERS
      : PROMPT_GROUP_NAMES.PERSON;
    const promptGroupIndex = isAutoSpousePage ? 1 : 0;
    yield put(
      updatePromptGroup({
        name: PROMPT_NAMES.RELATIONSHIP_TO_INSURED,
        value: RELATIONSHIPS.SPOUSE,
        promptGroupIndex,
        promptGroupName
      })
    );
  }
}

export function* watchLocationChangeForDefaultFields() {
  yield takeLatest([LOCATION_CHANGE], setDefaultFieldsForPage);
}
