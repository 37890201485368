import { combineReducers } from 'redux';
import { GET_ORIGINATOR_SUCCESS } from '../actions/originator';

const avatar = (state = '', action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.avatar || state;
    }
    default:
      return state;
  }
};

const email = (state = '', action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.email || state;
    }
    default:
      return state;
  }
};

const name = (state = '', action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.name || state;
    }
    default:
      return state;
  }
};

const showNameplate = (state = false, action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.showNameplate || state;
    }
    default:
      return state;
  }
};

const site = (state = '', action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.site || state;
    }
    default:
      return state;
  }
};

const social = (state = [], action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.social || state;
    }
    default:
      return state;
  }
};

const tel = (state = '', action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.tel || state;
    }
    default:
      return state;
  }
};

const title = (state = '', action) => {
  switch (action.type) {
    case GET_ORIGINATOR_SUCCESS: {
      return action.payload.title || state;
    }
    default:
      return state;
  }
};

export default combineReducers({
  avatar,
  email,
  name,
  showNameplate,
  site,
  social,
  tel,
  title
});
