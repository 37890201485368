import {
  MORTGAGE_FIELDS,
  GENERAL_FIELDS,
  CARRIER_FIELDS,
  QUOTE_FIELDS
} from 'woop-shared/db/models';
import { PAYMENT_METHODS } from 'woop-shared/quotes/enums';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import { PAYMENT_FIELDS } from '../constants/payment-page';
import { getPremiumByMethod } from '../utils/payment-page';

/**
 * Returns a paymentInfo object derived from payment data, formatted based on payment method
 *
 * @param {object} payment payment object from redux state
 * @returns {object} paymentInfo object for binding API request body
 */
function formatPaymentInfo(payment) {
  switch (payment[PAYMENT_FIELDS.METHOD.key]) {
    case PAYMENT_METHODS.CARD: {
      const [expiryMonth, expiryYear] = payment[PAYMENT_FIELDS.CARD_EXPIRATION_DATE.key].split('/');
      return {
        nameOnCard: payment[PAYMENT_FIELDS.NAME_ON_CARD.key],
        cardNumber: stripNonDigits(payment[PAYMENT_FIELDS.CARD_NUMBER.key]),
        cvv: payment[PAYMENT_FIELDS.CARD_CVV.key],
        expiryMonth,
        expiryYear
      };
    }
    case PAYMENT_METHODS.ACH:
      return {
        bankName: payment[PAYMENT_FIELDS.BANK_NAME.key],
        routingNumber: payment[PAYMENT_FIELDS.ROUTING_NUMBER.key],
        acctNumber: payment[PAYMENT_FIELDS.ACCOUNT_NUMBER.key]
      };
    default:
      return {};
  }
}

function formatMortgage(payment) {
  return {
    [MORTGAGE_FIELDS.COMPANY_NAME]: payment[PAYMENT_FIELDS.MORTGAGE_COMPANY_NAME.key],
    [MORTGAGE_FIELDS.COMPANY_CITY]: payment[PAYMENT_FIELDS.MORTGAGE_COMPANY_CITY.key],
    [MORTGAGE_FIELDS.COMPANY_STATE]: payment[PAYMENT_FIELDS.MORTGAGE_COMPANY_STATE.key],
    [MORTGAGE_FIELDS.LOAN_NUMBER]: payment[PAYMENT_FIELDS.LOAN_NUMBER.key]
  };
}

/**
 *
 * @param {object} obj
 * @param {Array} obj.payments
 * @param {Array} obj.selectedQuotes
 * @param {string} obj.quotesetId
 * @param {boolean} obj.resubmit
 * @returns {object} post body
 */
export function formatPost({ payments, selectedQuotes, quotesetId, resubmit }) {
  return {
    quotesetId,
    bindings: payments.map(p => {
      const quote = selectedQuotes.find(q => q[GENERAL_FIELDS.ID] === p.quoteId);
      const paymentPlan = p[PAYMENT_FIELDS.PLAN.key];
      const paymentMethod = p[PAYMENT_FIELDS.METHOD.key];
      const premium = getPremiumByMethod({
        premiums: quote[QUOTE_FIELDS.PREMIUMS],
        paymentPlan,
        paymentMethod
      });
      const fees = quote[QUOTE_FIELDS.FEES]?.[paymentMethod];
      return {
        quoteId: p.quoteId,
        paymentInfo: formatPaymentInfo(p),
        mortgage: formatMortgage(p),
        coverageType: quote.coverageType,
        paymentMethod,
        paymentPlan,
        premium,
        fees,
        carrierCode: quote.carrier[CARRIER_FIELDS.CODE],
        billingInfo: {
          billingAddress: p[PAYMENT_FIELDS.BILLING_ADDRESS.key]
        },
        legal: p.legal
      };
    }),
    resubmit
  };
}
