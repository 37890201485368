// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".BoxUploader__wrapper--2e-aF{margin:0 auto;min-height:200px}.BoxUploader__wrapper--2e-aF .BoxUploader__loading--2gilI{visibility:none}#box-uploader .fill-color{fill:rgb(var(--woop-body-text-color))}#box-uploader .stroke-color{stroke:rgb(var(--woop-body-text-color))}#box-uploader .be-input-link{color:rgb(var(--woop-primary-color))}#box-uploader .bcu-upload-state-message{font-size:16px}#box-uploader .bcu-droppable-content{border:1px dashed #ccc}@media (max-width:550px){#box-uploader .bcu-droppable-content{border:none}}#box-uploader .btn-primary{background:rgb(var(--woop-primary-color));border-color:rgb(var(--woop-primary-color))}", "",{"version":3,"sources":["BoxUploader.css"],"names":[],"mappings":"AAEA,6BACE,aAAc,CACd,gBAKF,CAHE,0DACE,eACF,CAMA,0BACE,qCACF,CACA,4BACE,uCACF,CAGA,6BACE,oCACF,CAEA,wCACE,cACF,CAEA,qCACE,sBAIF,CAHE,yBAFF,qCAGI,WAEJ,CADE,CAGF,2BACE,yCAA4B,CAC5B,2CACF","file":"BoxUploader.css","sourcesContent":["@import './variables.css';\n\n.wrapper {\n  margin: 0 auto;\n  min-height: 200px;\n\n  .loading {\n    visibility: none;\n  }\n}\n\n/* Overwrite Box's global styles */\n:global #box-uploader {\n  /* SVG */\n  .fill-color {\n    fill: $bodyTextColor;\n  }\n  .stroke-color {\n    stroke: $bodyTextColor;\n  }\n\n  /* Upload Link */\n  .be-input-link {\n    color: $appPrimaryColor;\n  }\n\n  .bcu-upload-state-message {\n    font-size: $fontDefault;\n  }\n\n  .bcu-droppable-content {\n    border: dashed 1px $inputBorderColor;\n    @media (--small-viewport) {\n      border: none;\n    }\n  }\n\n  .btn-primary {\n    background: $appPrimaryColor;\n    border-color: $appPrimaryColor;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "BoxUploader__wrapper--2e-aF",
	"loading": "BoxUploader__loading--2gilI"
};
module.exports = exports;
