import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { TiDelete } from 'react-icons/ti';
import LinkText from '../../../../../core/components/LinkText';
import styles from './styles.css';

const cx = classnames.bind(styles);

const Card = ({ icon, line1, line2, onEdit, onDelete, className, invalid }) => {
  return (
    <div className={cx('wrapper', className, { invalid })}>
      <div className={styles.content}>
        {icon()}
        <div className={styles.lines}>
          <div className={styles.line1}>{line1}</div>
          <div className={styles.line2}>{line2}</div>
        </div>
      </div>
      <div className={styles.right}>
        {invalid && <div className={styles.invalidBadge}>⚠️</div>}
        {onEdit && (
          <LinkText className={styles.edit} onClick={onEdit}>
            {invalid ? 'Add details' : 'Edit'}
          </LinkText>
        )}
      </div>
      {onDelete && (
        <div className={styles.delete} onClick={onDelete}>
          <TiDelete />
        </div>
      )}
    </div>
  );
};

export default Card;

Card.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  invalid: PropTypes.bool,
  line1: PropTypes.string,
  line2: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};
