import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unsubscribe } from '../../core/actions/origination';
import UnsubscribeIcon from '../../core/assets/icons/unsubscribe.svg';
import AppWrapper from '../../core/components/AppWrapper';
import FooterLinks from '../../core/components/FooterLinks';
import H2 from '../../core/components/headers/H2';
import styles from '../css/Unsubscribe.css';

const Unsubscribe = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(unsubscribe());
  }, []);

  return (
    <AppWrapper className={styles.wrapper} isFloatingCardParent>
      <div className={styles.content}>
        <UnsubscribeIcon />
        <H2>
          <div>{`You've unsubscribed!`}</div>
        </H2>
        <div className={styles.text}>
          <p>{`You will no longer receive reminder alerts. You will still receive important emails regarding your application.`}</p>
        </div>
      </div>
      <FooterLinks noMobileButton />
    </AppWrapper>
  );
};

export default Unsubscribe;

Unsubscribe.propTypes = {
  unsubscribe: PropTypes.func
};
