import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { LEGAL_PHONE } from '../../constants/legal';
import IntercomLink from '../IntercomLink';
import LinkText from '../LinkText';
import PrivacyPolicyLink from '../PrivacyPolicy/Link';
import TermsOfServiceLink from '../TermsOfService/Link';
import styles from './styles.css';

const cx = classnames.bind(styles);

const FooterLinks = ({ className, noMobileButton }) => {
  return (
    <div className={cx('wrapper', className, { noMobileButton })}>
      <div className={styles.section}>
        <PrivacyPolicyLink className={styles.link} />
        <span className={styles.separator}>|</span>
        <TermsOfServiceLink className={styles.link} />
        <span className={styles.separator}>|</span>
        <LinkText className={styles.link} href={`tel:${LEGAL_PHONE}`}>
          {LEGAL_PHONE}
        </LinkText>
        <span className={styles.separator}>|</span>
        <IntercomLink className={styles.link} />
      </div>
    </div>
  );
};

export default FooterLinks;

FooterLinks.propTypes = {
  className: PropTypes.string,
  noMobileButton: PropTypes.bool
};
