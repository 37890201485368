import { select, takeLatest, take } from 'redux-saga/effects';
import { SCENARIOS } from 'woop-shared/enums';
import { FINALIZE_BINDING_SUCCESS } from '../../modules/binding/actions/binding';
import { UPDATE_PROMPT } from '../../modules/core/actions/journey-data';
import {
  POST_JOURNEY_SUCCESS,
  RESUME_JOURNEY_SUCCESS
} from '../../modules/journey/actions/journey';
import { GET_QUOTESET_SUCCESS, POST_QUOTESET_SUCCESS } from '../../modules/quote/actions';
import { LOG_CONNECT_CANOPY, LOG_UPLOAD_DOCUMENTS } from '../actions';
import { track } from './api';
import { KLAVIYO_EVENTS } from './constants';
import { getIdentifiers } from './utils';

function* logKlaviyoVisit() {
  const { journeyData } = yield select(state => state);
  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) track(KLAVIYO_EVENTS.VISIT_WOOP, personalInfo);
}

export function* watchForKlaviyoVisit() {
  yield takeLatest([POST_JOURNEY_SUCCESS, RESUME_JOURNEY_SUCCESS], logKlaviyoVisit);
}

function* logKlaviyoAppCompletion() {
  const { journeyData, scenario } = yield select(state => state);
  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) {
    switch (scenario) {
      case SCENARIOS.TEST_DRIVE:
      case SCENARIOS.ESTIMATE:
        track(KLAVIYO_EVENTS.COMPLETE_ESTIMATE, personalInfo);
        break;
      case SCENARIOS.SIGN_UP:
        track(KLAVIYO_EVENTS.COMPLETE_SIGN_UP, personalInfo);
        track(KLAVIYO_EVENTS.COMPLETE_APPLICATION, personalInfo);
        break;
      default:
        track(KLAVIYO_EVENTS.COMPLETE_APPLICATION, personalInfo);
        break;
    }
  }
}

export function* watchForKlaviyoAppCompletion() {
  yield takeLatest([POST_QUOTESET_SUCCESS], logKlaviyoAppCompletion);
}

export function* logKlaviyoAnswerPromptOnce() {
  const action = yield take(UPDATE_PROMPT);
  const { journeyData } = yield select(state => state);

  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) {
    const { name, value } = action.payload;
    track(KLAVIYO_EVENTS.ANSWER_PROMPT, personalInfo, {
      prompt_name: name,
      prompt_value: value
    });
  }
}

function* logKlaviyoVisitQuotes() {
  const { journeyData } = yield select(state => state);
  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) track(KLAVIYO_EVENTS.VISIT_WOOP_QUOTES, personalInfo);
}

export function* watchForKlaviyoVisitQuotes() {
  yield takeLatest([GET_QUOTESET_SUCCESS], logKlaviyoVisitQuotes);
}

function* logKlaviyoConfirmQuotes() {
  const { journeyData } = yield select(state => state);
  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) track(KLAVIYO_EVENTS.CONFIRM_QUOTES, personalInfo);
}

export function* watchForKlaviyoConfirmQuotes() {
  yield takeLatest([FINALIZE_BINDING_SUCCESS], logKlaviyoConfirmQuotes);
}

function* logKlaviyoConnectCanopy(action) {
  const { journeyData } = yield select(state => state);
  const { pullId } = action.payload;
  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) track(KLAVIYO_EVENTS.CONNECT_CANOPY, personalInfo, { pull_id: pullId });
}

export function* watchForKlaviyoConnectCanopy() {
  yield takeLatest([LOG_CONNECT_CANOPY], logKlaviyoConnectCanopy);
}

function* logKlaviyoUploadDocuments(action) {
  const { journeyData } = yield select(state => state);
  const { fileIds } = action.payload;
  const personalInfo = getIdentifiers(journeyData);
  if (personalInfo) track(KLAVIYO_EVENTS.UPLOAD_DOCUMENTS, personalInfo, { file_ids: fileIds });
}

export function* watchForKlaviyoUploadDocuments() {
  yield takeLatest([LOG_UPLOAD_DOCUMENTS], logKlaviyoUploadDocuments);
}
