// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--2Cuvm{height:100%;display:flex;flex-direction:column}.styles__wrapper--2Cuvm .styles__header--J-beO{margin-top:30px;font-size:16px}.styles__wrapper--2Cuvm .styles__subHeader--1zrO_{font-size:14px;line-height:1.6}.styles__wrapper--2Cuvm .styles__htmlText--vn_5z{flex-grow:1;overflow:scroll;border:none;width:100%;margin:0 -7px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,WAAY,CACZ,YAAa,CACb,qBAkBF,CAjBE,+CACE,eAAgB,CAChB,cACF,CAEA,kDACE,cAAiB,CACjB,eACF,CAEA,iDACE,WAAY,CACZ,eAAgB,CAChB,WAAY,CACZ,UAAW,CACX,aACF","file":"styles.css","sourcesContent":["@import '../../../../../core/css/variables.css';\n\n.wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  .header {\n    margin-top: 30px;\n    font-size: $fontDefault;\n  }\n\n  .subHeader {\n    font-size: $fontS;\n    line-height: $lineHeight;\n  }\n\n  .htmlText {\n    flex-grow: 1;\n    overflow: scroll;\n    border: none;\n    width: 100%;\n    margin: 0px -7px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--2Cuvm",
	"header": "styles__header--J-beO",
	"subHeader": "styles__subHeader--1zrO_",
	"htmlText": "styles__htmlText--vn_5z"
};
module.exports = exports;
