import { createAction } from 'redux-actions';

export const LOG_FORM_ERROR = 'LOG_FORM_ERROR';
export const logFormError = createAction(LOG_FORM_ERROR);

export const LOG_CONNECT_CANOPY = 'LOG_CONNECT_CANOPY';
export const logConnectCanopy = createAction(LOG_CONNECT_CANOPY);

export const LOG_UPLOAD_DOCUMENTS = 'LOG_UPLOAD_DOCUMENTS';
export const logUploadDocuments = createAction(LOG_UPLOAD_DOCUMENTS);
