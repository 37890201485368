import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { OTHER_OPTION } from '../../../journeyjson/prompts/constants';
import * as api from '../api/vehicle';
import { getVal } from './journey-data';
import { formatOptions } from './select';

/**
 * Fetch & return formatted years.
 *
 * @returns {Array} Array of objects formatted for the Select field.
 */
export async function getYears() {
  const years = await api.getYears();
  return formatOptions(wrapOtherOption(years));
}

/**
 * Fetch & return formatted makes based on the given year.
 *
 * @param {object} params
 * @param {string|number} params.year
 * @returns {Array} Array of objects formatted for the Select field.
 */
export async function getMakes({ year }) {
  if (!year) return;
  const makes = await api.getMakes(year);
  return formatOptions(wrapOtherOption(makes));
}

/**
 * Fetch & return formatted models based on the given year & make.
 *
 * @param {object} params
 * @param {string|number} params.year
 * @param {string} params.make
 * @returns {Array} Array of objects formatted for the Select field.
 */
export async function getModels({ year, make }) {
  if (!year || !make) return;
  const models = await api.getModels(year, make);
  return formatOptions(wrapOtherOption(models));
}

/**
 * Fetch & return formatted submodels based on the given year & make.
 *
 * @param {object} params
 * @param {string|number} params.year
 * @param {string} params.make
 * @param {string} params.model
 * @returns {Array} Array of objects formatted for the Select field.
 */
export async function getDetails({ year, make, model }) {
  if (!year || !make || !model) return;
  const details = await api.getDetails(year, make, model);
  return formatOptions(wrapOtherOption(getDetailString(details)));
}

/**
 * Get the ChromeTrim prop from each details object, fall back to BodyStyleName + Trim if it doesn't exist.
 *
 * @param {Array} details array of detail objects, e.g. [{BodyStyleName: "Sedan", ChromeTrim: "330i Sedan", Trim: "i"}]
 * @returns {Array} array of submodel strings
 */
export function getDetailString(details) {
  const detailStrings = details
    ?.map(d =>
      d.ChromeTrim
        ? d.ChromeTrim
        : d.BodyStyleName && d.Trim
        ? `${d.BodyStyleName} ${d.Trim}`.trim()
        : ''
    )
    .filter(Boolean);
  return detailStrings;
}

/**
 * Add the OTHER_OPTION option to the end of an options array.
 *
 * @param {Array} options Array of options for a vehicles dropdown.
 * @returns {Array} Array of options w/ OTHER_OPTION appended.
 */
function wrapOtherOption(options) {
  Array.isArray(options) && options.push(OTHER_OPTION);
  return options;
}

export function getLabel(vehicle) {
  const year = getVal(vehicle?.[PROMPT_NAMES.VEHICLE_YEAR]);
  const abbreviatedYear = year && `'${year.toString().substring(2)}`;
  const make = getVal(vehicle?.[PROMPT_NAMES.VEHICLE_MAKE]);
  return year && make && `${abbreviatedYear} ${make}`;
}
