import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import { ROUTES, SUBMODULE_PATHS } from '../../../router/constants/routes';
import { getPromptsToValidate, isValidPromptGroup } from '../../core/utils/prompt-validation';
import { shouldShowPage, isExcludedDriversPageValid, showCrossSellPage } from './pages';

/**
 * The index where to find a submodule after splitting location.pathname by `/`
 * e.g. /journey/property/1 -> ['', 'journey', 'property', '1']
 */
const MODULE_PATH_INDEX = 1;
const SUBMODULE_PATH_INDEX = 2;
const PAGE_PATH_INDEX = 3;

/**
 * Get the page number of the current path.
 *
 * @param {string} pathname React-router's pathname, e.g. /journey/prejourney/1.
 * @returns {number} e.g. 1
 */
export function getActivePageNum(pathname) {
  const locationSplit = pathname.split('/');
  const pageNum = locationSplit[PAGE_PATH_INDEX];
  if (pageNum) return parseInt(pageNum);
}

/**
 * Based on the path, get the active module.
 *
 * @param {string} pathname React-router's pathname, e.g. /journey/prejourney/1.
 * @returns {string} e.g. /journey
 */
export function getActiveModulePath(pathname) {
  const locationSplit = pathname.split('/');
  const moduleName = locationSplit[MODULE_PATH_INDEX];
  if (moduleName) {
    const activeModule = `/${moduleName}`;
    return activeModule;
  }
}

export function isJourneyPath(pathname) {
  return getActiveModulePath(pathname) === ROUTES.JOURNEY;
}

export function isPaymentPath(pathname) {
  return getActiveModulePath(pathname) === ROUTES.PAYMENT;
}

export function getActiveSubmodulePath(pathname) {
  const locationSplit = pathname.split('/');
  const submoduleName = locationSplit[SUBMODULE_PATH_INDEX];
  if (submoduleName) {
    const activeSubmodule = `/${submoduleName}`;
    return activeSubmodule;
  }
}

/**
 * Get submodule based on its pathname
 *
 * @param {Array} submodules Array of journey submodules.
 * @param {string} pathname The full pathname from the router's location object.
 * @returns {object} A submodule
 */
export function getSubmoduleByPathname(submodules, pathname) {
  if (pathname === ROUTES.JOURNEY) return submodules?.[0]; // Handle first journey page.
  const submodulePath = getActiveSubmodulePath(pathname);
  return submodules.find(s => s.path === submodulePath);
}

/**
 * Get the next path in the journey.
 *
 * @param {Array} submodules An array of submodules
 * @param {string} pathname The full pathname from the router's location object.
 * @param {object} uiData The journeyData from state.
 * @returns {string} The next path.
 */
export function getNextPath(submodules, pathname, uiData) {
  const activeSubmodule = getSubmoduleByPathname(submodules, pathname);
  if (!activeSubmodule) return;

  const activeModulePath = getActiveModulePath(pathname);
  const activeSubmodulePath = activeSubmodule.path;
  const activePageNum = getActivePageNum(pathname);
  const lastPage = activePageNum === activeSubmodule.pages.length;

  if (
    activeSubmodulePath === SUBMODULE_PATHS.DRIVER_DISCOUNTS &&
    lastPage &&
    showCrossSellPage(uiData)
  ) {
    return ROUTES.CROSS_SELL;
  }

  const noMorePagesInJourney = lastPage && isLastSubmodule(submodules, activeSubmodule);

  if (noMorePagesInJourney) {
    // If this is the last page of the last journey submodule, go to the confirmation page.
    return ROUTES.JOURNEY_CONFIRMATION;
  }

  // Otherwise, find the next routable page.
  const nextPath = getNextPagePath(submodules, activeSubmodule, activePageNum, uiData);
  return `${activeModulePath}${nextPath}`;
}

/**
 *
 * @param {Array} submodules Array of all submodules.
 * @param {object} submodule The active submodule.
 * @param {number} currentPageNumber The current active page number.
 * @param {object} uiData
 * @returns {string} The path to the next page.
 */
export function getNextPagePath(submodules, submodule, currentPageNumber, uiData) {
  // Find next routable page in current submodule.
  const nextPage = submodule.pages.slice(currentPageNumber).find(page => {
    return !page.conditional || shouldShowPage(page.path, uiData);
  });

  // If we found a page in the current submodule, return the path.
  if (nextPage) {
    const nextPageNumber = submodule.pages.indexOf(nextPage) + 1; // +1 because page numbers are 0-based.
    return `${submodule.path}/${nextPageNumber}`;
  }

  // Otherwise, check the next submodule until we find a routable page.
  const nextSubmodule = getNextSubmodule(submodules, submodule);
  if (nextSubmodule) return getNextPagePath(submodules, nextSubmodule, 0, uiData);
}

/**
 * We're at the end of the journey if the current submodule is the last one.
 *
 * @param {Array} submodules
 * @param {object} currentSubmodule
 * @returns {boolean}
 */
function isLastSubmodule(submodules, currentSubmodule) {
  return submodules.indexOf(currentSubmodule) === submodules.length - 1;
}

/**
 * Get the next submodule.
 *
 * @param {Array} submodules
 * @param {object} currentSubmodule
 * @returns {boolean}
 */
function getNextSubmodule(submodules, currentSubmodule) {
  return submodules[submodules.indexOf(currentSubmodule) + 1];
}

/**
 * Given a submodule path and a page number return the full path.
 *
 * @param {string} path A submodule path, e.g. /property
 * @param {number} pageNum e.g. 1
 * @returns {string} Full relative path to page, e.g. /journey/property/1.
 */
export function getPagePath(path, pageNum) {
  return `${ROUTES.JOURNEY}${path}/${pageNum}`;
}

/**
 * Given a submodule path, append the page number of the first page.
 *
 * @param {string} path A submodule path, e.g. /property
 * @returns {string} Full relative path to page, e.g. /journey/property/1.
 */
export function getFirstPagePath(path) {
  return getPagePath(path, 1);
}

/**
 * Create a submodule validity object.
 *
 * e.g. {
 *   '/prejourney': {
 *    isValid: true,
 *    isNavigable: false
 *  },
 *  '/your-home': {...}
 * }
 *
 * isValid means every page in the submodule is valid (@see isSubmoduleValid)
 * isNavigable means no subsequent pages are invalid.
 *
 * @param {Array} submodules Array of submodules from journey json.
 * @param {object} pages The pages validity object from the state.
 * @param {object} journeyData The journeyData object from state.
 * @returns {object} A submodule validity object for the state.
 */
export function mapSubmodulesToState(submodules, pages, journeyData) {
  const submodValidity = {};
  let navigable = true;
  submodules.forEach(submodule => {
    const isValid = isSubmoduleValid(submodule, pages, journeyData);
    if (!isValid) navigable = false;
    const isNavigable = navigable && isValid;
    submodValidity[submodule.path] = { isValid, isNavigable };
  });
  return submodValidity;
}

/**
 * Map submodules to a flat array of page paths
 *
 * @param {Array} submodules
 * @returns {Array} pathPaths
 */
export function mapSubmodulesToPagePaths(submodules) {
  return submodules.flatMap(submodule => {
    return submodule.pages.map((page, i) => {
      const pageNum = i + 1;
      const pagePath = getPagePath(submodule.path, pageNum);
      return pagePath;
    });
  });
}

/**
 *
 * @param {Array} submodules Array of submodules.
 * @param {object} existingPages The pages object in state.
 * @param {object} journeyData The journeyData object from state.
 * @param {boolean} alwaysValid Dev-only flag to subvert validity logic.
 * @returns {object} New pages to put into the state.
 */
export function mapSubmodulesToPages(submodules, existingPages, journeyData, alwaysValid) {
  let newPages = {};
  submodules?.forEach(submodule => {
    submodule.pages.forEach((page, i) => {
      const pageNum = i + 1;
      const pagePath = getPagePath(submodule.path, pageNum);
      if (pageExists(page, existingPages[pagePath])) return;
      const newPage = {
        ...page,
        skippable: page.noValidate,
        promptGroupIndex: getPromptGroupIndex(page)
      };

      // Add entry to pages
      newPages[pagePath] = {
        ...newPage,
        isValid: isPageValid(newPage, journeyData, alwaysValid)
      };
    });
  });
  return newPages;
}

/**
 * Check if the page from the submodule is different than the page in state.
 *
 * A page is different if it has different prompts.
 *
 * @param {object} page A raw page object from a journey's submodule.
 * @param {object} existingPage A page in the `pages` state.
 * @returns {boolean}
 */
function pageExists(page, existingPage) {
  if (!existingPage) return false;
  const promptNames = page?.prompts?.map(p => p.name) || [];
  const existingPromptNames = existingPage?.prompts?.map(p => p.name) || [];
  return (
    promptNames.length === existingPromptNames.length &&
    promptNames.every(name => existingPromptNames.includes(name))
  );
}

/**
 * Go through each index in a createable page and check for an invalid page.
 *
 * @param {Array} prompts Array of prompt objects.
 * @param {object} uiData The uiData object.
 * @param {string} promptGroupName The current prompt group name.
 * @returns {number|undefined} The index of the first invalid page in the prompt group, or undefined if none found.
 */
export function getNextInvalidPageIndex(prompts, uiData, promptGroupName) {
  return uiData?.[promptGroupName]?.findIndex((promptGroup, index) => {
    const promptGroupPrompts = getPromptsToValidate(prompts, uiData, index);
    return promptGroupPrompts.some(({ name }) => !promptGroup?.[name]?.isValid);
  });
}

/**
 * Loop through each prompt in a page and check its validity in the state.
 *
 * @param {object} page A page object.
 * @param {object} journeyData The journeyData object from state.
 * @param {boolean} alwaysValid Dev-only flag to subvert validity logic.
 * @returns {boolean}
 */
export function isPageValid(page, journeyData, alwaysValid) {
  if (alwaysValid) return alwaysValid;

  const { promptGroupName, promptGroupIndex, prompts, skippable, createable } = page;
  let isPageValid;

  // If the page is skippable, it's valid.
  if (skippable) return true;

  // If there are no prompts on this page to validate.
  if (prompts.every(prompt => prompt.noValidate)) return true;

  const promptGroups = journeyData[promptGroupName];
  // For excluded drivers, verify the number of drivers matches residents of driving age.
  if (promptGroupName === PROMPT_GROUP_NAMES.EXCLUDED_DRIVERS) {
    isPageValid = isExcludedDriversPageValid(journeyData);
  }
  // If we are on page that creates prompt groups, validate all prompt groups
  // Or if we are saving to a promptGroup collection, validate all of this page's prompts in all promptGroups
  else if (promptGroupName && createable) {
    isPageValid =
      promptGroups?.length &&
      promptGroups.every((group, index) =>
        isValidPromptGroup({ prompts, journeyData, promptGroupIndex: index, promptGroup: group })
      );
  }
  // If we are on page that is linked to a single prompt group, validate that prompt group
  else if (promptGroupName && promptGroupIndex >= 0 && promptGroups) {
    const promptGroup = promptGroups[promptGroupIndex];
    isPageValid = isValidPromptGroup({
      prompts,
      journeyData,
      promptGroupIndex,
      promptGroup
    });
  }
  // Else validate all prompts in prompt array for that page
  else {
    const promptNames = getPromptsToValidate(prompts, journeyData).map(p => p.name);
    const allPromptsValid = promptNames && promptNames.every(name => journeyData?.[name]?.isValid);
    isPageValid = allPromptsValid;
  }

  return isPageValid;
}

/**
 * Get a page's promptGroupIndex.
 *
 * If a page is part of a promptGroup (i.e. has a promptGroupName) but no index is defined, fallback to 0.
 * If a page isn't part of a promptGroup, return undefined.
 *
 * @param {object} page Page object inside a submodule.
 * @returns {number}
 */
export function getPromptGroupIndex(page) {
  return page.promptGroupIndex || (page.promptGroupName && 0);
}

export function updateLoopablePages(submodules, promptGroupName, promptGroupCount) {
  return submodules.map(s => {
    const loopablePages = s.pages.filter(p => p.promptGroupName === promptGroupName && p.loopable);

    if (!loopablePages.length) return s;

    const updatedPages = [...s.pages];
    // Build pages to insert
    const pagesToInsert = new Array(promptGroupCount)
      .fill(loopablePages[0])
      .map((page, pageIndex) => {
        return { ...page, promptGroupIndex: pageIndex };
      });

    // Cut existing loopable pages in submodule and insert new pages
    const startIndex = updatedPages.findIndex(
      p => p.promptGroupName === promptGroupName && p.loopable
    );
    updatedPages.splice(startIndex, loopablePages.length, ...pagesToInsert);
    return { ...s, pages: updatedPages };
  });
}

/**
 * Loop through each page in a submodule and check its validity in the state.
 *
 * @param {object} submodule A submodule object.
 * @param {object} pages The page validation object stored in the state.
 * @param {object} journeyData The journeyData object from state.
 * @returns {boolean}
 */
function isSubmoduleValid(submodule, pages, journeyData) {
  return submodule.pages.every((_, index) => {
    const pageNum = index + 1;
    const pagePath = getPagePath(submodule.path, pageNum);
    const page = pages[pagePath];
    if (page.conditional && !shouldShowPage(page.path, journeyData)) return true;
    return page.isValid;
  });
}

/**
 * Check that first and last name, date of birth, email, and phone are all valid.
 *
 * @param {object} journeyData The journeyData object from state.
 * @returns {boolean}
 */
export const promptsAreValid = journeyData =>
  [
    PROMPT_NAMES.FIRST_NAME,
    PROMPT_NAMES.LAST_NAME,
    PROMPT_NAMES.DOB,
    PROMPT_NAMES.EMAIL,
    PROMPT_NAMES.PHONE
  ].every(prompt => journeyData[prompt]?.isValid);
