import { takeLatest, call, put } from 'redux-saga/effects';
import { addToLoading, rmFromLoading } from '../actions/loading';
import { getOriginatorSuccess, GET_ORIGINATOR } from '../actions/originator';
import { getOriginator } from '../api/originator';
import * as logger from '../utils/logger';

function* handleGetOriginator(action) {
  yield put(addToLoading(action.type));
  const { originatorId, accountId } = action.payload;
  try {
    const originator = yield call(getOriginator, originatorId, accountId);
    yield put(getOriginatorSuccess(originator));
  } catch (error) {
    logger.error(error, { originatorId, accountId });
  }
  yield put(rmFromLoading(action.type));
}

export function* watchGetOriginator() {
  yield takeLatest(GET_ORIGINATOR, handleGetOriginator);
}
