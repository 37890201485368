import { combineReducers } from 'redux';
import { ASSET_URLS } from '../constants/app';

const logoUrl = (state = ASSET_URLS.LOGO, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const logoMobileUrl = (state = ASSET_URLS.MOBILE_LOGO, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const name = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  logoMobileUrl,
  logoUrl,
  name
});
