import PropTypes from 'prop-types';
import React from 'react';
import InfoText from '../../../../../core/components/InfoText';

const VehicleInfoText = () => {
  const text = 'Please add the vehicles you would like to insure.';

  return <InfoText className="bar" text={text} />;
};

export default VehicleInfoText;

VehicleInfoText.propTypes = {
  journeyData: PropTypes.object
};
