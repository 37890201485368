import { combineReducers } from 'redux';
import { MORTGAGE_FIELDS, QUOTESET_FIELDS } from 'woop-shared/db/models';
import { GET_QUOTESET_SUCCESS } from '../actions';

const company = (state = '', action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.[QUOTESET_FIELDS.MORTGAGE]?.[MORTGAGE_FIELDS.COMPANY_NAME] || state;
    default:
      return state;
  }
};

const city = (state = '', action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.[QUOTESET_FIELDS.MORTGAGE]?.[MORTGAGE_FIELDS.COMPANY_CITY] || state;
    default:
      return state;
  }
};

const state = (state = '', action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.[QUOTESET_FIELDS.MORTGAGE]?.[MORTGAGE_FIELDS.COMPANY_STATE] || state;
    default:
      return state;
  }
};

const loanNumber = (state = '', action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.[QUOTESET_FIELDS.MORTGAGE]?.[MORTGAGE_FIELDS.LOAN_NUMBER] || state;
    default:
      return state;
  }
};

export default combineReducers({
  company,
  city,
  state,
  loanNumber
});
