import React from 'react';
import DeadComputer from '../../assets/icons/dead_computer.svg';
import { LEGAL_PHONE } from '../../constants/legal';
import AppWrapper from '../AppWrapper';
import H2 from '../headers/H2';
import IntercomLink from '../IntercomLink';
import LinkText from '../LinkText';
import styles from './styles.css';

const ErrorBoundary = () => (
  <AppWrapper className={styles.wrapper}>
    <div className={styles.content}>
      <DeadComputer />
      <H2>
        <div>{`We've encountered a problem.`}</div>
      </H2>
      <div className={styles.text}>
        <p>
          {`We apologize for any inconvenience.`}
          <br />
          Our team has been alerted.
          <br />
          <LinkText href={`tel:${LEGAL_PHONE}`}>{LEGAL_PHONE}</LinkText>
          {` or `}
          <IntercomLink />
        </p>
      </div>
    </div>
  </AppWrapper>
);

ErrorBoundary.propTypes = {};

export default ErrorBoundary;
