import { PROMPT_GROUP_NAMES } from 'woop-shared/prompts/names';
import { calcDiffInYears } from './date';
import { isFullDate } from './prompt-validation';

/**
 * If move-in date is less than 3 years from today, prompt user for prior address
 *
 * @param {string} value moveInDate in the format MM/DD/YYYY
 * @returns {boolean}
 */
export function shouldShowPriorAddress({ value }) {
  if (!isFullDate(value)) return false;
  const today = new Date();
  const moveIn = new Date(value);
  const yearDiff = calcDiffInYears(today, moveIn);
  return yearDiff <= 3;
}

/**
 * If this is the first driver (i.e. promptGroupIndex === 0), don't ask this question.
 *
 * @param {object} obj
 * @param {string} obj.conditionalPromptGroup One of the prompt group names defined in PROMPT_GROUP_NAMES.
 * @param {number} obj.promptGroupIndex The index of this prompt group.
 * @returns {boolean}
 */
export function isNotFirstDriverPage({ conditionalPromptGroup, promptGroupIndex }) {
  return conditionalPromptGroup === PROMPT_GROUP_NAMES.DRIVERS && promptGroupIndex >= 1;
}

/**
 * If this is the first driver (i.e. promptGroupIndex === 0), show this question.
 *
 * @param {object} obj
 * @param {string} obj.conditionalPromptGroup One of the prompt group names defined in PROMPT_GROUP_NAMES.
 * @param {number} obj.promptGroupIndex The index of this prompt group.
 * @returns {boolean}
 */
export function isFirstDriverPage({ conditionalPromptGroup, promptGroupIndex }) {
  return conditionalPromptGroup === PROMPT_GROUP_NAMES.DRIVERS && promptGroupIndex === 0;
}

/**
 * Is the given value more than XX years from the current date?
 *
 * @param {number} year A year 4 digit year.
 * @param {number} numYears The number of years ago.
 * @returns {boolean}
 */
export function isMoreThanXYearsAgo(year, numYears) {
  const isYear = !isNaN(parseInt(year));
  if (!year || !isYear) return false;
  const today = new Date().getFullYear();
  return today - year > numYears;
}
