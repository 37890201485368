export const APP_ELEMENT_ID = 'woop-app';

export const APP_CONTENT_ID = 'woop-app-content';
export const APP_SLIM_CLASS = 'slim';

export const ASSET_URLS = {
  LOGO: 'https://res.cloudinary.com/woopgroup/image/upload/v1668093227/assets/Woop-Insurance_new_dcufdn.png',
  MOBILE_LOGO:
    'https://res.cloudinary.com/woopgroup/image/upload/v1658409379/app/assets/w_bluedot_v55hkx.png',
  PHONE_MOCK:
    'https://res.cloudinary.com/woopgroup/image/upload/v1658410251/app/assets/iphone_mock_vnedyz.png',
  W_BLUE_DOT:
    'https://res.cloudinary.com/woopgroup/image/upload/v1658409379/app/assets/w_bluedot_v55hkx.png'
};
