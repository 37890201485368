import React from 'react';
import AppWrapper from '../AppWrapper';
import { Spinner } from '../Spinner';
import styles from './styles.css';

export const AppLoadingScreen = () => {
  return (
    <AppWrapper>
      <div className={styles.content}>
        <Spinner className={styles.spinner}></Spinner>
        Loading...
      </div>
    </AppWrapper>
  );
};
