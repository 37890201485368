export const CONSENT_LANGUAGE =
  'By providing your credientials you agree to the Canopy Connect Privacy Policy.';

const CANOPY_API_URL = 'https://app.usecanopy.com/api/v1.0.0/';

export const ENDPOINTS = {
  CARRIERS: `${process.env.JOURNEY_URI}canopy/carriers`,
  CONSENT_AND_CONNECT: `${process.env.JOURNEY_URI}canopy/consentAndConnect`,
  TOS: `${CANOPY_API_URL}tos`,
  PULL: `${CANOPY_API_URL}widget/pull`
};

export const PULL_STATUSES = {
  STARTED: 'STARTED',
  QUEUED: 'QUEUED',
  PROCESSING: 'PROCESSING',
  GETTING_CONSUMERS: 'GETTING_CONSUMERS',
  PULLING_DATA: 'PULLING_DATA',
  SUCCESS: 'SUCCESS',
  IDENTITY_VERIFICATION_OPTIONS: 'IDENTITY_VERIFICATION_OPTIONS',
  IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  PROVIDER_ERROR: 'PROVIDER_ERROR'
};

export const SUCCESS_STATUSES = [
  PULL_STATUSES.GETTING_CONSUMERS,
  PULL_STATUSES.PULLING_DATA,
  PULL_STATUSES.SUCCESS
];

export const ERROR_STATUSES = [
  PULL_STATUSES.NOT_AUTHENTICATED,
  PULL_STATUSES.INTERNAL_ERROR,
  PULL_STATUSES.PROVIDER_ERROR
];

export const MFA_INPUT_TYPES = {
  EMAIL_OTP: 'EMAIL_OTP',
  VOICE_OTP: 'VOICE_OTP',
  SMS_OTP: 'SMS_OTP',
  SECURITY_QUESTION: 'SECURITY_QUESTION',
  CARRIER_PIN: 'CARRIER_PIN',
  OTHER_OTP: 'OTHER_OTP'
};

export const DEFAULT_ERROR_MESSAGE =
  'Login unsuccessful. Please double check your credentials and try again.';

export const MFA_ERROR_MESSAGE = 'Unable to verify your code. Please check it and try again.';
