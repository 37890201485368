export const CANOPY_COMPONENT_STYLES = {
  fonts: [
    {
      family: 'Inter',
      style: 'normal',
      weight: '400',
      src: "url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2')",
      unicodeRange:
        'U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
    }
  ],
  styles: {
    base: {
      color: 'rgb(51, 51, 51)',
      fontWeight: '400',
      fontFamily: 'Inter, sans-serif',
      fontSize: '1rem',
      padding: '0',
      lineHeight: '1'
    },
    invalid: {
      color: 'rgb(51, 51, 51)'
    }
  }
};
