import React from 'react';
import Law from '../../assets/icons/law.svg';
import H2 from '../headers/H2';
import styles from '../PrivacyPolicy/styles.css';
import TermsOfServiceText from './Text';

const TermsOfService = () => (
  <div className={styles.wrapper}>
    <div className={styles.hero}>
      <Law className={styles.balance} />
    </div>
    <H2 className={styles.title}>Terms of Service</H2>
    <TermsOfServiceText />
  </div>
);

export default TermsOfService;
