import axios from 'axios';
import { formatAccountObject, getOriginationUri } from '../utils/api';
import { concatUri } from '../utils/url';

const ACTIONS = {
  SEND_PROOF: 'send-proof',
  TRACK: 'track',
  INIT_BOX: 'init-box',
  UNSUBSCRIBE: 'unsubscribe'
};

/**
 *
 * @param {object} params
 * @param {string} params.oid
 * @param {string} params.srcAcctId
 * @param {string} params.originatorId
 * @param {object} params.originationData
 * @param {object} params.urlAccountData account data passed in on the URL.
 * @param params.uiData
 * @returns {object}
 */
export const patchOrigination = async ({
  oid,
  srcAcctId,
  originatorId,
  uiData,
  urlAccountData
}) => {
  const uri = concatUri(getOriginationUri(), oid);
  const { data } = await axios.patch(uri, {
    account: formatAccountObject(srcAcctId, originatorId, urlAccountData),
    uiData
  });
  return data;
};

export const sendProofOfInsurance = async ({ oid, journeyId, fileIds, pullId, userId }) => {
  const uri = concatUri(getOriginationUri(), oid, ACTIONS.SEND_PROOF);
  const { data } = await axios.post(uri, { journeyId, fileIds, pullId, userId });
  return data;
};

export const track = async ({ oid, event }) => {
  const uri = concatUri(getOriginationUri(), oid, ACTIONS.TRACK);
  const { data } = await axios.patch(uri, { event });
  return data;
};

export const getBoxToken = async () => {
  const uri = concatUri(getOriginationUri(), ACTIONS.INIT_BOX);
  const { data } = await axios.get(uri);
  if (!data?.token) {
    // Retry once
    const { data: retryData } = await axios.get(uri);
    return retryData.token;
  }
  return data.token;
};

export const unsubscribe = async oid => {
  const uri = concatUri(getOriginationUri(), oid, ACTIONS.UNSUBSCRIBE);
  const { data } = await axios.patch(uri);
  return data;
};
