import React from 'react';
import Privacy from '../../assets/icons/privacy.svg';
import H2 from '../headers/H2';
import styles from './styles.css';
import PrivacyPolicyText from './Text';

const PrivacyPolicy = () => (
  <div className={styles.wrapper}>
    <div className={styles.hero}>
      <Privacy />
    </div>
    <H2 className={styles.title}>Privacy Policy</H2>
    <PrivacyPolicyText />
  </div>
);

export default PrivacyPolicy;
