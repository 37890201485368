import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import styles from './styles.css';

const cx = classnames.bind(styles);

const Checkbox = ({ children, value, save, id, showValidation, light, className }) => {
  const [checked, setChecked] = useState(value);
  const handleClick = () => {
    setChecked(!checked);
    save(!checked);
  };
  const handleSpacebar = e => {
    if (e.which === 32) {
      e.preventDefault();
      handleClick();
    }
  };

  // Allow labels to trigger checkbox selection but prevent <a> tags.
  const handleLabelClick = e => {
    const elementType = e?.target?.nodeName;
    if (elementType && elementType !== 'A') handleClick();
  };
  return (
    <div className={cx('wrapper', className)}>
      <div
        className={cx('checkbox', { checked, invalid: showValidation && !checked, light })}
        onClick={handleClick}
        tabIndex={0}
        onKeyDown={handleSpacebar}
        id={id}
      >
        <FiCheck className={cx('check')} />
      </div>
      <p onClick={handleLabelClick}>{children}</p>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  light: PropTypes.bool,
  save: PropTypes.func,
  showValidation: PropTypes.bool,
  value: PropTypes.bool
};
