import React from 'react';
import styles from './styles.css';

const AppFooter = () => {
  return (
    <footer className={styles.wrapper}>
      Powered by ItsWoop Technologies LLC © {new Date().getFullYear()}. All rights reserved.
    </footer>
  );
};

export default AppFooter;
