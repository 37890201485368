import { isSmallViewport } from '../../modules/core/utils/browser';
import { getOriginationData } from '../../modules/core/utils/origination';

export default function init(state) {
  if (!process.env.INTERCOM_APP_ID) return;

  const originationData = getOriginationData();
  window.intercomSettings = {
    app_id: process.env.INTERCOM_APP_ID,
    hide_default_launcher: isSmallViewport(),
    ...window.intercomSettings,
    ...originationData,
    user_url: window.location.href,
    quoteset_id: state.quoteset?.id
  };

  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://widget.intercom.io/widget/${process.env.INTERCOM_APP_ID}`;
    document.body.appendChild(s);
  }
}
