import axios from 'axios';
import { formatPhoneUS } from 'woop-shared/utils';

const PUBLIC_API_KEY = 'SkzAiK';
const TRACK_ENDPOINT = 'https://a.klaviyo.com/api/track';
const ID_ENDPOINT = 'https://a.klaviyo.com/api/identify';

/**
 * Track an event to Klaviyo
 *
 * @param {string} event An event name
 * @param {object} customerProps { "$email": "abraham.lincoln@klaviyo.com", "$phone_number": "abraham.lincoln@klaviyo.com" }
 * @param {object} eventProps { "item_name": "Boots", "$value": 100 }
 * @returns {object} A response object
 */
export const track = (event, customerProps, eventProps) => {
  const data = {
    token: PUBLIC_API_KEY,
    event,
    customer_properties: customerProps,
    properties: eventProps
  };
  return axios.post(TRACK_ENDPOINT, new URLSearchParams({ data: JSON.stringify(data) }));
};

/**
 * Notes: all properties are optional. If omitted, they will be left off the API call and will not erase existing values
 *
 * @param {object} data – Each property is a uiData promptName.
 * @param data.email
 * @param data.firstName
 * @param data.lastName
 * @param data.phone
 * @param data.zip
 * @returns {Promise}
 */
export const identify = ({ email, firstName, lastName, phone, zip, ...rest }) => {
  const formattedPhone = phone ? formatPhoneUS(phone) : phone;
  const data = {
    token: PUBLIC_API_KEY,
    properties: {
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $phone_number: formattedPhone,
      $zip: zip,
      ...rest
    }
  };
  return axios.post(ID_ENDPOINT, new URLSearchParams({ data: JSON.stringify(data) }));
};
