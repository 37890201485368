import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.css';

const AppLogo = ({ logoUrl, logoMobileUrl }) => {
  return (
    <div className={styles.wrapper}>
      <img alt="Insurance agency logo" src={logoUrl} className={styles.logo} />
      <img
        alt="Insurance agency logo"
        src={logoMobileUrl || logoUrl}
        className={styles.logoMobile}
      />
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  children: PropTypes.node,
  logoUrl: PropTypes.string,
  logoMobileUrl: PropTypes.string
};
