import { useEffect } from 'react';
import { APP_CONTENT_ID, APP_SLIM_CLASS } from '../constants/app';

export function useSlimApp({ removeOnUnmount } = {}) {
  useEffect(() => {
    const contentElement = window.document.getElementById(APP_CONTENT_ID);
    contentElement.classList.add(APP_SLIM_CLASS);
    if (removeOnUnmount) {
      return () => {
        const contentElement = window.document.getElementById(APP_CONTENT_ID);
        contentElement.classList.remove(APP_SLIM_CLASS);
      };
    }
  }, []);
}
