import { COVERAGE_TYPES } from 'woop-shared/enums';
import { MARITAL_STATUSES, PROPERTY_TYPES } from 'woop-shared/origination/enums';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import { calcDiffInYears } from '../../core/utils/date';
import { getNumDrivingResidents, getPromptGroupVal, getVal } from '../../core/utils/journey-data';
import { warn } from '../../core/utils/logger';
import { PAGE_CONDITIONAL_FUNCTIONS } from '../constants/page';

export function shouldShowPage(path, uiData) {
  const conditionalFn = PAGE_CONDITIONAL_FUNCTIONS[path];
  if (!conditionalFn) {
    warn(`Found a conditional page ${path} with no conditional function.`);
    return false;
  }
  return conditionalFn(uiData);
}

/**
 * Maybe show the your-spouse page.
 *
 * The your-spouse page is part of the Drivers/About You flow.
 *
 * @param {object} uiData
 * @returns {boolean}
 */
export function showYourSpouseAutoPage(uiData) {
  const maritalStatus = getPromptGroupVal(
    uiData[PROMPT_GROUP_NAMES.DRIVERS],
    0,
    PROMPT_NAMES.MARITAL_STATUS
  );
  return maritalStatus === MARITAL_STATUSES.MARRIED;
}

/**
 * Maybe show the quick-spouse page.
 *
 * The quick-spouse page is for property-only quick journeys.
 *
 * @param {object} uiData
 * @returns {boolean}
 */
export function showYourSpousePage(uiData) {
  const coverageTypes = getVal(uiData[PROMPT_NAMES.COVERAGE_TYPE]);
  const isAuto = coverageTypes.includes(COVERAGE_TYPES.AUTO);
  const maritalStatus = getVal(uiData[PROMPT_NAMES.MARITAL_STATUS]);
  if (!isAuto) return maritalStatus === MARITAL_STATUSES.MARRIED;
}

export function showExcludedDriversPage(uiData) {
  const numDrivers = uiData[PROMPT_GROUP_NAMES.DRIVERS]?.length || 0;
  const numDrivingResidents = getNumDrivingResidents(uiData);
  return numDrivingResidents > numDrivers;
}

export function isExcludedDriversPageValid(uiData) {
  const numDrivers = uiData[PROMPT_GROUP_NAMES.DRIVERS]?.length || 0;
  const numDrivingResidents = getNumDrivingResidents(uiData);
  const numExcludedDrivers = uiData[PROMPT_GROUP_NAMES.EXCLUDED_DRIVERS]?.length || 0;
  return numDrivers + numExcludedDrivers >= numDrivingResidents;
}

export function showPropertyClaimsPage(uiData) {
  const coverageTypes = getVal(uiData[PROMPT_NAMES.COVERAGE_TYPE]);
  if (coverageTypes.includes(COVERAGE_TYPES.RENTERS))
    return getVal(uiData[PROMPT_NAMES.RECENT_RENTERS_CLAIM]) === true;
  if (coverageTypes.includes(COVERAGE_TYPES.CONDO))
    return getVal(uiData[PROMPT_NAMES.RECENT_CONDO_CLAIM]) === true;
  if (coverageTypes.includes(COVERAGE_TYPES.HOME))
    return getVal(uiData[PROMPT_NAMES.RECENT_HOME_CLAIM]) === true;
}

/**
 * We should the cross-sell page if the user expressed interest in it, and is auto-only.
 *
 * @param {object} uiData Non-flattened journeyData object from state.
 * @returns {boolean}
 */
export function showCrossSellPage(uiData) {
  return getVal(uiData[PROMPT_NAMES.INTERESTED_IN_PROPERTY]) === true;
}

export function showAutoIncidentsPage(uiData) {
  return getVal(uiData[PROMPT_NAMES.RECENT_AUTO_CLAIM]) === true;
}

export function showPrimaryResidencePage(uiData) {
  return getVal(uiData[PROMPT_NAMES.PROPERTY_USAGE]) === PROPERTY_TYPES.RENTAL_PROPERTY;
}

/**
 * Decide whether to show the assign drivers page.
 *
 * We only show the page if:
 * - There's a driver under 25 years old.
 * - There are more vehicles than drivers.
 *
 * @param {object} uiData
 * @returns {boolean}
 */
export function showAssignDriversPage(uiData) {
  const numVehicles = uiData[PROMPT_GROUP_NAMES.VEHICLES]?.length || 0;
  const numDrivers = uiData[PROMPT_GROUP_NAMES.DRIVERS]?.length || 0;
  if (numDrivers === 1) return false;
  if (numVehicles > numDrivers) return true;

  const drivers = uiData[PROMPT_GROUP_NAMES.DRIVERS] || [];
  return (
    drivers.length &&
    drivers.some(driver => {
      const dob = new Date(getVal(driver[PROMPT_NAMES.DOB]));
      const now = new Date();
      return calcDiffInYears(now, dob) <= 25;
    })
  );
}
