import { connect } from 'react-redux';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import { updatePromptGroup } from '../actions/journey-data';
import AssignDrivers from '../components/AssignDrivers';

const mapStateToProps = ({ journeyData }) => {
  return {
    vehicles: journeyData[PROMPT_GROUP_NAMES.VEHICLES],
    drivers: journeyData[PROMPT_GROUP_NAMES.DRIVERS]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveAdditionalDriverUsage: (value, promptGroupName, promptGroupIndex) =>
      dispatch(
        updatePromptGroup({
          name: PROMPT_NAMES.ADDITIONAL_DRIVER_USAGE,
          value,
          promptGroupIndex,
          promptGroupName
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignDrivers);
