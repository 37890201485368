import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { EXTERNAL_LINK_IDS } from '../../amplitude/events';

export const BLOGS = {
  FIND_VIN: {
    id: 3538967,
    externalUrl: 'https://www.woopinsuranceagency.com/blog/where-can-you-find-your-vin',
    slug: EXTERNAL_LINK_IDS.FIND_MY_VIN
  },
  DOWNLOAD_DEC_PAGE: {
    id: 6093843,
    externalUrl: 'https://www.woopinsuranceagency.com/blog/downloading-my-declarations-page',
    slug: EXTERNAL_LINK_IDS.FIND_DEC_PAGE
  },
  CANCEL_POLICY: {
    id: 6093818,
    externalUrl:
      'https://www.woopinsuranceagency.com/blog/cancelling-your-insurance-before-the-renewal-date',
    slug: EXTERNAL_LINK_IDS.CANCEL_POLICY
  },
  ANNUAL_MILES: {
    id: 6427593,
    externalUrl: 'https://www.woopinsuranceagency.com/blog/what-is-annual-mileage',
    slug: EXTERNAL_LINK_IDS.ANNUAL_MILES
  }
};

/**
 * Prompts in this list will cause their <Prompt> as clickable text that will link to the blog.
 * Currently enabled for only certain prompt component types:
 * - Selects
 */
export const PROMPTS_TO_BLOGS = {
  [PROMPT_NAMES.ANNUAL_MILES]: BLOGS.ANNUAL_MILES
};
