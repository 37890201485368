import axios from 'axios';
import { getOriginatorsUri } from '../utils/api';
import { concatUri } from '../utils/url';

const ORIGINATOR_URI = getOriginatorsUri();

export const getOriginators = async accountId => {
  const { data } = await axios.get(ORIGINATOR_URI, { params: { partnerId: accountId } });
  return data;
};

export const getOriginator = async (id, accountId) => {
  const { data } = await axios.get(concatUri(ORIGINATOR_URI, id), {
    params: { partnerId: accountId }
  });
  return data;
};
