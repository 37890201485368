import { push } from 'connected-react-router';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { GENERAL_FIELDS, CARRIER_FIELDS } from 'woop-shared/db/models';
import { ROUTES } from '../../../router/constants/routes';
import { addToLoading, rmFromLoading } from '../../core/actions/loading';
import { getStateFromAddress } from '../../core/utils/address-autocomplete';
import { error } from '../../core/utils/logger';
import {
  FINALIZE_BINDING,
  finalizeBindingSuccess,
  finalizeBindingFail,
  completePaymentPage
} from '../actions/binding';
import { updatePayment } from '../actions/payment';
import * as api from '../api/binding';

function* handleFinalizeBinding() {
  const { payments, quoteset, selectedQuotes } = yield select(state => state);
  const resubmit = !!quoteset.paymentFailedQuoteId;
  const quotesetId = quoteset.id;
  try {
    yield put(addToLoading(FINALIZE_BINDING));
    const success = yield call(api.bindPost, {
      payments,
      selectedQuotes,
      quotesetId,
      resubmit
    });
    if (success) yield put(finalizeBindingSuccess());
    else yield put(finalizeBindingFail());
  } catch (e) {
    error(e, { payments, quotesetId });
    yield put(finalizeBindingFail());
  }
  yield put(rmFromLoading(FINALIZE_BINDING));
}

export function* watchFinalizeBinding() {
  yield takeLatest(FINALIZE_BINDING, handleFinalizeBinding);
}

function* handleFinalizeBindingSuccess() {
  yield put(push(ROUTES.PAYMENT_SUBMITTED));
}

export function* watchFinalizeBindingSuccess() {
  yield takeLatest(finalizeBindingSuccess, handleFinalizeBindingSuccess);
}

function* handleCompletePaymentPage() {
  const { payments, consumerInfo, selectedQuotes } = yield select(state => state);
  const state = getStateFromAddress(consumerInfo.address);
  let nextRoute = ROUTES.PAYMENT_CONFIRMATION;
  try {
    const hasLanguage = yield all(
      payments.map(payment =>
        call(maybeAppendRecurringPaymentLanguage, payment, selectedQuotes, state)
      )
    );

    if (hasLanguage.filter(Boolean).length) {
      nextRoute = ROUTES.RECURRING_PAYMENT_AUTHORIZATION;
    }
  } catch (e) {
    error(e, { payments });
  }
  yield put(push(nextRoute));
}

export function* watchCompletePaymentPage() {
  yield takeLatest(completePaymentPage, handleCompletePaymentPage);
}

function* maybeAppendRecurringPaymentLanguage(payment, selectedQuotes, state) {
  const { paymentMethod, quoteId } = payment;
  const quote = selectedQuotes.find(q => q[GENERAL_FIELDS.ID] === quoteId);
  const carrierCode = quote.carrier[CARRIER_FIELDS.CODE];
  const { language } = yield call(api.getRecurringPaymentLanguage, {
    carrierCode,
    paymentMethod,
    state
  });
  if (language)
    yield put(updatePayment({ quoteId, updates: { recurringPaymentLanguage: language } }));
  return !!language;
}
