import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';

export const CARD_TYPES = {
  VISA: 'visa',
  MASTERCARD: 'mastercard'
};

export const CARD_ICONS = {
  [CARD_TYPES.VISA]: FaCcVisa,
  [CARD_TYPES.MASTERCARD]: FaCcMastercard
};
