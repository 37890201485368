import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import { APP_ELEMENT_ID } from '../../constants/app';
import styles from './styles.css';

const cx = classnames.bind(styles);

const appRoot = document.getElementById(APP_ELEMENT_ID);

const OverlayModal = ({
  children,
  open,
  toggleOpen,
  contentClass,
  modalClass,
  closeable = true
}) => {
  const applyNoScroll = shouldAdd =>
    shouldAdd
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');

  useEffect(() => {
    applyNoScroll(open);
    return () => applyNoScroll(false);
  }, [open]);

  return ReactDOM.createPortal(
    <div className={cx('wrapper', { open })}>
      <div className={cx('modal', modalClass)}>
        {closeable && (
          <div className={styles.close} onClick={() => toggleOpen(false)}>
            <FiX />
          </div>
        )}
        <div className={contentClass}>{children}</div>
      </div>
      {ReactDOM.createPortal(
        <div
          className={cx('overlay', { open, closeable })}
          onClick={() => closeable && toggleOpen(false)}
        />,
        appRoot
      )}
    </div>,
    appRoot
  );
};

export default OverlayModal;

OverlayModal.propTypes = {
  children: PropTypes.node,
  closeable: PropTypes.bool,
  contentClass: PropTypes.string,
  modalClass: PropTypes.string,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func
};
