import { stripNonDigits } from 'woop-shared/quotes/utils';

const SECONDS_IN_MS = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_YEAR = 365.25;

const MonthDayLengthMap = {
  1: '31',
  2: '29',
  3: '31',
  4: '30',
  5: '31',
  6: '30',
  7: '31',
  8: '31',
  9: '30',
  10: '31',
  11: '30',
  12: '31'
};

/**
 * Police a date input field in the format of MM/YYYY.
 *
 * @param {string} value The full value of the input field.
 * @returns {string} A formatted month and year.
 */
export function policeMonthYearInput(value) {
  if (!value) return '';

  // Strip everything except digits and slashes.
  value = value.replace(/[^0-9/]/g, '');

  // Strip anything more than 7 chars.
  value = value.substring(0, 7);

  // Verify the month.
  if (value.length === 1) {
    // If it's "X" change it to "0X/".
    if (parseInt(value) > 1) {
      return '0' + value + '/';
    }

    // If it's '/', remove it.
    if (value === '/') {
      return '';
    }
  }

  // Verify the month.
  if (value.length >= 2) {
    // Don't allow 0/.
    if (value === '0/') {
      return '';
    }

    // If the second character is a slash, add a 0 before it.
    if (value[1] === '/') {
      return '0' + value[0] + '/' + value.substring(2, value.length);
    }

    // If it's a month greater than 12 or equal to 0, wipe it out.
    if (
      parseInt(value[0].toString() + value[1].toString()) > 12 ||
      parseInt(value[0].toString() + value[1].toString()) === 0
    ) {
      return '';
    }

    // Handle the 1[year] scenario (e.g. 11999).
    if (value.length === 5 && stripNonDigits(value).length === 5 && parseInt(value[0]) === 1) {
      return '01' + '/' + value.substring(1, value.length);
    }

    // If there's no slash between the month and the year, add one.
    if (typeof value[2] !== 'undefined' && value[2] !== '/') {
      return value.substring(0, 2) + '/' + value.substring(2, value.length);
    }
  }

  // Enforce the year.
  if (value.length >= 4) {
    // Enforce a year starting w/ 1 or 2.
    if (parseInt(value[3]) !== 1 && parseInt(value[3]) !== 2) {
      return value.substring(0, 3);
    }

    const year = value.substring(3, 7);
    const yearInt = parseInt(stripNonDigits(value.substring(3, 7)));

    // If any numbers or slashes were stripped, it's not a valid year.
    if (year.length !== yearInt.toString().length) {
      return value.substring(0, 3);
    }
  }

  return value;
}

/**
 * Police a date input field in the format of MM/DD/YYYY.
 *
 * @param {string} value The full value of the input field.
 * @returns {string} A formatted date.
 */
export function policeDateInput(value) {
  if (!value) return '';

  // Strip everything except digits and slashes.
  value = value.replace(/[^0-9/]/g, '');
  // replace double slashes with single slash
  value = value.replace(/\/\//g, '/');

  // Strip anything more than 10 chars.
  value = value.substring(0, 10);

  // Verify the month.
  if (value.length === 1) {
    // If it's "X" change it to "0X/".
    if (parseInt(value) > 1) {
      value = '0' + value + '/';
    }

    // If it's '/', remove it.
    if (value === '/') {
      return '';
    }
  }

  // Verify the month.
  if (value.length >= 2) {
    // Don't allow 0/.
    if (value === '0/') {
      return '';
    }

    // If the second character is a slash, add a 0 before it.
    if (value[1] === '/') {
      value = '0' + value[0] + '/' + value.substring(2, value.length);
    }

    // If it's a month greater than 12 or equal to 0, wipe it out.
    if (
      parseInt(value[0].toString() + value[1].toString()) > 12 ||
      parseInt(value[0].toString() + value[1].toString()) === 0
    ) {
      return '';
    }

    // If there's no slash between the month and the day, add one.
    if (typeof value[2] !== 'undefined' && value[2] !== '/') {
      value = value.substring(0, 2) + '/' + value.substring(2, value.length);
    }
  }

  // Verify the day.
  if (value.length >= 4) {
    const month = value[0] + value[1];

    // Add a 0 prefix to days > 3 || days > 2 if February.
    if (
      parseInt(value[3]) >= 4 ||
      value[4] === '/' ||
      (month === '02' && parseInt(value[3]) >= 3)
    ) {
      value = value.substring(0, 3) + '0' + value.substring(3, value.length);
    }
  }

  // Verify the day.
  if (value.length >= 5) {
    const month = value[0] + value[1];
    const day = value[3] + value[4];

    // Make sure it's a day that this month has and that it's not 00.
    if (dayOutOfRange(month, day) || day === '00') {
      return value.substring(0, 4);
    }
  }

  // Enforce the year.
  if (value.length >= 6) {
    if (value[5] !== '/') {
      value = value.substring(0, 5) + '/' + value.substring(5, value.length);
    }

    // Enforce a year starting w/ 1 or 2.
    if (value[6] && parseInt(value[6]) !== 1 && parseInt(value[6]) !== 2) {
      value = value.substring(0, 6);
    }

    const year = value.substring(6, 10);
    const yearInt = parseInt(stripNonDigits(value.substring(6, 10)));

    // If any numbers or slashes were stripped, it's not a valid year.
    if (year.length !== yearInt.toString().length) {
      return value.substring(0, 6);
    }

    if (year.length === 4) {
      const month = value[0] + value[1];
      const day = value[3] + value[4];
      return leapYearCheck(month, day, year);
    }
  }

  return value;
}

/**
 * Police a date input field in the format of MM/DD/YYYY.
 *
 * If the MM/DD is < today, automatically insert the next year.
 * If the MM/DD is >= today, automatically insert the current year.
 *
 * @param {string} value The full value of the input field.
 * @param {boolean} isDelete True if the user's input is shorter than the current value.
 * @returns {string} A formatted date.
 */
export function policeEffectiveDate(value, isDelete) {
  if (!value) return '';

  // Strip everything except digits and slashes.
  value = value.replace(/[^0-9/]/g, '');
  // replace double slashes with single slash
  value = value.replace(/\/\//g, '/');

  // Strip anything more than 10 chars.
  value = value.substring(0, 10);

  if (isDelete) {
    return value;
  }

  // Verify the month.
  if (value.length === 1) {
    // If it's "X" change it to "0X/".
    if (parseInt(value) > 1) {
      value = '0' + value + '/';
    }

    // If it's '/', remove it.
    if (value === '/') {
      return '';
    }
  }

  // Verify the month.
  if (value.length >= 2) {
    // Don't allow 0/.
    if (value === '0/') {
      return '';
    }

    // If the second character is a slash, add a 0 before it.
    if (value[1] === '/') {
      value = '0' + value[0] + '/' + value.substring(2, value.length);
    }

    // If it's a month greater than 12 or equal to 0, wipe it out.
    if (
      parseInt(value[0].toString() + value[1].toString()) > 12 ||
      parseInt(value[0].toString() + value[1].toString()) === 0
    ) {
      return '';
    }

    // If there's no slash between the month and the day, add one.
    if (typeof value[2] !== 'undefined' && value[2] !== '/') {
      value = value.substring(0, 2) + '/' + value.substring(2, value.length);
    }
  }

  // Verify the day.
  if (value.length >= 4) {
    const month = value[0] + value[1];

    // Add a 0 prefix to days > 3 || days > 2 if February.
    if (
      parseInt(value[3]) >= 4 ||
      value[4] === '/' ||
      (month === '02' && parseInt(value[3]) >= 3)
    ) {
      value = value.substring(0, 3) + '0' + value.substring(3, value.length);
    }
  }

  // Verify the day.
  if (value.length >= 5) {
    const month = value[0] + value[1];
    const day = value[3] + value[4];

    // Make sure it's a day that this month has and that it's not 00.
    if (dayOutOfRange(month, day) || day === '00') {
      return value.substring(0, 4);
    }
  }

  // Auto-add the year.
  if (value.length >= 5) {
    value = value.substring(0, 5) + '/';
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // JS Dates are 0-based.
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();
    const month = parseInt(value[0] + value[1]);
    const day = parseInt(value[3] + value[4]);

    if (month < currentMonth || (month === currentMonth && day < currentDay)) {
      return value + (currentYear + 1);
    } else {
      return value + currentYear;
    }
  }

  return value;
}

/**
 * Check if the current month includes the given day.
 *
 * @param {string} month
 * @param {string} day
 * @returns {boolean} True if the day is less than or equal to the month's max day.
 */
function dayOutOfRange(month, day) {
  return day > MonthDayLengthMap[parseInt(month).toString()];
}

/**
 * Check if there are leap year constraints w/ the current date.
 *
 * @param {string} month
 * @param {string} day
 * @param {string} year
 * @returns {string} The full date if valid || nothing if not valid.
 */
function leapYearCheck(month, day, year) {
  return !isLeapYear(year) && parseInt(month) === 2 && parseInt(day) === 29
    ? ''
    : month + '/' + day + '/' + year;
}

/**
 * Check if the year is a leap year.
 *
 * Leap Year: If a year is divisible by 4 but not 100, unless it is also divisible by 400 then it is a leap year.
 *
 * @param {string} year
 * @returns {boolean} True if it's a leap year, false if not.
 */
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

/**
 * Take a date object and return mm/dd/yyyy.
 *
 * @param {Date} date JavaScript Date Object.
 * @returns {string} Date in the format mm/dd/yyyy.
 */
export function createDateString(date) {
  return `${normalizeMonth(date)}/${normalizeDay(date)}/${date.getFullYear()}`;
}

/**
 * Take a date object and return mm/yyyy.
 *
 * @param {Date} date JavaScript Date Object.
 * @returns {string} Date in the format mm/yyyy.
 */

/**
 * Take a date object and return mm/dd.
 *
 * @param {Date} date JavaScript Date Object.
 * @returns {string} Date in the format mm/dd.
 */
export function createMonthDayString(date) {
  return `${normalizeMonth(date)}/${normalizeDay(date)}`;
}

/**
 * Turn a 0-11 based month into a 01-12 based month.
 *
 * @param {Date} date JavaScript Date Object.
 * @returns {string} formatted month.
 */
export function normalizeMonth(date) {
  const month = (date.getMonth() + 1).toString();
  return maybeAddLeadingZero(month);
}

/**
 * Turn a 1-digit day (e.g. 1) into a 2 digit day (e.g. 01).
 *
 * @param {Date} date JavaScript Date Object.
 * @returns {string} formatted month.
 */
function normalizeDay(date) {
  const day = date.getDate().toString();
  return maybeAddLeadingZero(day);
}

/**
 * Add a leading zero to any 1-digit val.
 *
 * @param {string} val
 * @returns {string}
 */
function maybeAddLeadingZero(val) {
  return val?.length === 1 ? `0${val}` : val;
}

/**
 * Substract date2 by date1, return diff in years as decimal
 *
 * @param {Date} date2
 * @param {Date} date1
 * @returns {number}
 */
export function calcDiffInYears(date2, date1) {
  const diffInSeconds = (date2.getTime() - date1.getTime()) / SECONDS_IN_MS;
  const diffInDays = diffInSeconds / (SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY);
  return diffInDays / DAYS_IN_YEAR;
}

/**
 * Convert MM/YYYY to date object
 *
 * @param {string} monthYearString
 * @returns {Date}
 */
export function monthYearToDate(monthYearString) {
  const monthYear = monthYearString.split('/');
  const derivedDate = new Date(`${monthYear[0]}/01/${monthYear[1]}`);
  if (!isNaN(derivedDate)) return derivedDate;
}

/**
 * Add x years to a date.
 *
 * @param {string} dateString in the format mm/dd/yyyy
 * @param {number} numYears The amount of years to add.
 * @returns {string} date in the format mm/dd/yyyy.
 */
export function addYearsToDate(dateString, numYears) {
  const date = new Date(dateString);
  date.setFullYear(date.getFullYear() + numYears);
  return createDateString(date);
}
