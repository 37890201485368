import React from 'react';
import LinkText from '../../../LinkText';

function getAccountErrorUrl(error) {
  const regex = /<a href="([^"]*)"/; // find the link text within the <a> tag
  const url = error.match(regex);
  return url[url.length - 1]; // match returns an array and inserts the retrieved link as the last element
}

export function handleErrorText(error) {
  if (Array.isArray(error)) {
    return (
      <ul>
        {error.map(err => (
          <li key={err}>{err}</li>
        ))}
      </ul>
    );
  }

  if (error.includes('href')) {
    const splitStart = error.indexOf('<a href=');
    const splitEnd = error.indexOf('</a>') + 4; // the +4 is to find the index after the closing </a> tag
    const errorUrl = getAccountErrorUrl(error);
    return (
      <>
        {error.slice(0, splitStart) + ' '}
        <LinkText external href={errorUrl}>
          {errorUrl}
        </LinkText>
        {error.slice(splitEnd)}
      </>
    );
  }
  return error;
}
