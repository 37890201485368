// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--1yZZw{padding:20px;height:100%;box-sizing:border-box}.styles__autoHeight--14ft-{height:auto!important}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,YAAa,CACb,WAAY,CACZ,qBACF,CAEA,2BACE,qBACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.wrapper {\n  padding: 20px;\n  height: 100%;\n  box-sizing: border-box;\n}\n\n.autoHeight {\n  height: auto !important;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--1yZZw",
	"autoHeight": "styles__autoHeight--14ft-"
};
module.exports = exports;
