// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--hbD9M{margin-right:auto}.styles__wrapper--hbD9M img{max-width:300px;max-height:50px}.styles__wrapper--hbD9M .styles__logoMobile--1HUxF{display:none}@media (max-width:550px){.styles__wrapper--hbD9M .styles__logo--3_-qf{display:none}.styles__wrapper--hbD9M .styles__logoMobile--1HUxF{display:block}}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AACA,wBACE,iBAgBF,CAfE,4BACE,eAAgB,CAChB,eACF,CACA,mDACE,YACF,CACA,yBACE,6CACE,YACF,CACA,mDACE,aACF,CACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n.wrapper {\n  margin-right: auto; /* push originator card to the left */\n  img {\n    max-width: 300px;\n    max-height: 50px;\n  }\n  .logoMobile {\n    display: none;\n  }\n  @media (--small-viewport) {\n    .logo {\n      display: none;\n    }\n    .logoMobile {\n      display: block;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--hbD9M",
	"logoMobile": "styles__logoMobile--1HUxF",
	"logo": "styles__logo--3_-qf"
};
module.exports = exports;
