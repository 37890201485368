import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { COVERAGE_TYPES, SCENARIOS } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { AMPLITUDE_EVENTS } from './analytics/amplitude/events';
import { initAmplitude } from './analytics/amplitude/utils';
import initIntercom from './analytics/intercom/intercom';
import { initOptimizely } from './analytics/optimizely/optimizely';
import { getOriginator } from './modules/core/actions/originator';
import { postVisit } from './modules/core/actions/visit';
import { getPartner } from './modules/core/api/partner';
import { ASSET_URLS } from './modules/core/constants/app';
import { JOURNEY_ID, MIXPANEL_TRACKING } from './modules/core/constants/cookies';
import { ENVS } from './modules/core/constants/environments';
import { QUERY_PARAMS } from './modules/core/constants/url';
import { isProd } from './modules/core/utils/config';
import { getCookie, setCookie } from './modules/core/utils/cookies';
import { getUrlJourneyData, formatJourneyData } from './modules/core/utils/journey-data';
import { log, warn } from './modules/core/utils/logger';
import { initOrigination } from './modules/core/utils/origination';
import { getQueryStringParam } from './modules/core/utils/url';
import { resumeJourney } from './modules/journey/actions/journey';
import { WOOP_MNEMONIC } from './modules/partners/constants/partners';
import { updateDomWithPartnerDetails } from './modules/partners/utils';
import { initTheme } from './modules/partners/utils/themes';
import { getQuoteset } from './modules/quote/actions';
import { NO_VISIT_POST_PATHS, RESUME_JOURNEY_LANDING_PAGES } from './router/constants/routes';
import { getLandingPage, hasRouteableQuoteId } from './router/utils';
import configureStore from './store/configureStore';

/**
 * Pre-render app setup.
 *
 * @returns {object} Collection of data used to render the app.
 */
export async function init() {
  log('Environment:', process.env.ENV);
  // eslint-disable-next-line no-console
  console.log('Version:', process.env.VERSION);

  // Sentry error reporting.
  if (process.env.ENV !== ENVS.LOCAL) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.ENV,
      release: 'ui@' + process.env.VERSION,
      beforeSend(event) {
        // Don't send chunk timeout errors to Sentry.
        const exception = event.exception?.values?.[0];
        if (exception?.type === 'ChunkLoadError' && exception?.value?.includes('timeout')) {
          return null;
        }

        // Skip webcrawler errors.
        const USER_AGENTS_TO_IGNORE = ['bitdiscovery'];
        const userAgent = event.request?.headers?.['User-Agent'];
        if (USER_AGENTS_TO_IGNORE.includes(userAgent)) return null;

        // Skip noisy zero status code error.
        const hasZeroError = event.breadcrumbs?.some(b => b?.data?.status_code === 0);
        if (hasZeroError) return null;

        return event;
      }
    });
  }

  // Fetch the partner.
  const partner = (await getPartnerWrapper()) || (await getPartner(WOOP_MNEMONIC));
  maybeLogBadPartner(partner);
  updateDomWithPartnerDetails(partner);

  // Origination.
  const urlOriginatorId = getQueryStringParam(QUERY_PARAMS.ORIGINATOR_ID);
  const urlOriginationId = getQueryStringParam(QUERY_PARAMS.OID);
  const { srcAcctId, oid, originatorId } = initOrigination(
    urlOriginationId,
    urlOriginatorId,
    partner.accountId
  );

  // Theming.
  initTheme(srcAcctId);

  // Optimizely Feature Switching.
  const optimizely = initOptimizely();

  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
  const campaign = getQueryStringParam(QUERY_PARAMS.CAMPAIGN);

  const [logoMobileUrl, logoUrl] = [partner?.appConfig?.logoMobileUrl, partner?.appConfig?.logoUrl];

  let preloadedState = {
    origination: {
      id: oid,
      srcAcctId,
      originatorId
    },
    partner: {
      logoMobileUrl,
      logoUrl,
      name: partner?.name
    },
    scenario,
    campaign
  };

  const landingPageRoute = await getLandingPage();
  const quotesetId = getQueryStringParam(QUERY_PARAMS.QUOTESET_ID);
  const quoteId = getQueryStringParam(QUERY_PARAMS.QUOTE_ID);
  const journeyId = getQueryStringParam(QUERY_PARAMS.JOURNEY_ID) || getCookie(JOURNEY_ID);

  const defaultAutoCoverage = [SCENARIOS.TEST_DRIVE, SCENARIOS.ESTIMATE].includes(scenario);
  if (defaultAutoCoverage) {
    preloadedState = {
      ...preloadedState,
      journeyData: formatJourneyData({
        [PROMPT_NAMES.COVERAGE_TYPE]: COVERAGE_TYPES.AUTO
      })
    };
  }

  // Amplitude.
  initAmplitude({ srcAcctId, oid, originatorId, journeyId });

  // Mixpanel.
  initMixpanel();

  const routeToQuote = !!hasRouteableQuoteId();
  if (quotesetId) {
    preloadedState = {
      ...preloadedState,
      quoteset: { id: quotesetId, paymentFailedQuoteId: routeToQuote ? null : quoteId }
    };
  }

  if (journeyId) {
    preloadedState = { ...preloadedState, journey: { ...preloadedState.journey, journeyId } };
    setCookie(JOURNEY_ID, journeyId);
  }

  const urlJourneyData = getUrlJourneyData();

  if (urlJourneyData) {
    const journeyData = { ...urlJourneyData };
    const validated = formatJourneyData(journeyData);
    preloadedState = { ...preloadedState, journeyData: validated };
  }

  // Configuring the store.
  const store = configureStore(preloadedState);

  // Initial API call visit(POST).
  if (!NO_VISIT_POST_PATHS.includes(landingPageRoute)) store.dispatch(postVisit());

  // Fetch the originator.
  if (originatorId) store.dispatch(getOriginator({ originatorId, accountId: srcAcctId }));

  // Resume journey.
  if (journeyId && RESUME_JOURNEY_LANDING_PAGES.includes(landingPageRoute)) {
    store.dispatch(resumeJourney());
  }

  // fetch quote for quote details
  if (routeToQuote) store.dispatch(getQuoteset());

  // Log to Amplitude analytics. Not needed for Mixpanel.
  amplitude.getInstance().logEvent(AMPLITUDE_EVENTS.START_WOOP, { startURL: window.location.href });

  return { optimizely, store };
}

export function afterRender(store) {
  const state = store.getState();
  initIntercom(state);
  initBox();
  initHotJar();
}

function initBox() {
  // Create script tag
  const script = document.createElement('script');
  script.src = 'https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/uploader.js';
  document.body.appendChild(script);

  // Create css link
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/uploader.css';
  document.head.appendChild(link);
}

/**
 * Get the partner from the URL, falling back to WOOP partner.
 *
 * If we have a mnemonic in the URL, e.g. #MMEPA, use that.
 * If we have an accountId in the URL, e.g. srcAcctId=100.000.000, use that.
 *
 * @returns {Promise<object>}
 */
async function getPartnerWrapper() {
  const mnemonic = getMnemonic();
  if (mnemonic) return getPartner(mnemonic);
  const accountId = getQueryStringParam(QUERY_PARAMS.SOURCE_ACCOUNT_ID);
  if (accountId) return getPartner(accountId);
}

function maybeLogBadPartner(partner) {
  const mnemonic = getMnemonic();
  if (mnemonic && partner.mnemonic?.toLowerCase() !== mnemonic?.toLowerCase()) {
    warn(`Unrecognized mnemonic: "${mnemonic}"–Falling back to ${partner.name}`);
    return;
  }

  const accountId = getQueryStringParam(QUERY_PARAMS.SOURCE_ACCOUNT_ID);
  if (accountId && partner.accountId !== accountId) {
    warn(`Unrecognized accountId: "${accountId}"–Falling back to ${partner.name}`);
  }
}

function getMnemonic() {
  const [, mnemonic] = location.hash.split('#');
  return mnemonic;
}

export function prefetchAssets() {
  Object.values(ASSET_URLS).forEach(url => {
    const img = new Image();
    img.src = url;
  });
}

function initHotJar() {
  const hjid = isProd() ? 3062757 : 3077104;

  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

/*
 * Disable non-production mixpanel tracking to help keep MTUs low
 * @returns {boolean}
 */
function shouldTrackUsers() {
  return isProd() || !!process.env.MIXPANEL_TRACKING || getCookie(MIXPANEL_TRACKING);
}

function initMixpanel() {
  const shouldTrack = shouldTrackUsers();

  mixpanel.init(process.env.MIXPANEL_API_KEY, {
    debug: !isProd(),
    opt_out_tracking_by_default: !shouldTrack
  });
}
