import { GENERAL_FIELDS } from 'woop-shared/db/models';
import {
  PAYMENT_PLANS,
  PAYMENT_PLAN_FACTORS,
  PREMIUM_FIELDS,
  PAYMENT_METHODS
} from 'woop-shared/quotes/enums';
import {
  formatMoney,
  getPaidInFullPremium,
  getTotalForInstallmentPlan
} from 'woop-shared/quotes/utils';
import { PAYMENT_FIELDS } from '../../binding/constants/payment-page';
import { getPremiumByMethod } from '../../binding/utils/payment-page';
import { STANDARD_BILLING_CYCLE_LABELS } from '../constants/quotes';
import { addFees, getFee } from './fees';

/**
 * Get term length of a quote in months
 *
 * @param {Array} premiums Array of premium objects.
 * @returns {number} Number of months of the quotes term
 */
export function getTermLength(premiums) {
  const premium = getPaidInFullPremium(premiums);
  const paymentPlan = premium?.[PREMIUM_FIELDS.PAYMENT_PLAN];
  switch (paymentPlan) {
    case PAYMENT_PLANS.SEMI_ANNUALLY:
      return 6;
    case PAYMENT_PLANS.ANNUALLY:
    default:
      return 12;
  }
}

/**
 * Get the total premium amount, exclusive of fees
 *
 * @param {object} premium A premium object.
 * @param {number} termLength The number of months in the term.
 * @returns {number}
 */
export function calculateTotal(premium, termLength) {
  const paymentPlan = premium[PREMIUM_FIELDS.PAYMENT_PLAN];
  switch (paymentPlan) {
    case PAYMENT_PLANS.INSTALLMENT: {
      return getTotalForInstallmentPlan(premium);
    }
    case PAYMENT_PLANS.MONTHLY:
      return premium[PREMIUM_FIELDS.AMOUNT] * termLength;
    case PAYMENT_PLANS.SEMI_ANNUALLY:
      return termLength === 6 ? premium[PREMIUM_FIELDS.AMOUNT] : premium[PREMIUM_FIELDS.AMOUNT] * 2;
    case PAYMENT_PLANS.ANNUALLY:
      return premium[PREMIUM_FIELDS.AMOUNT];
  }
}

export function formatPremium(premiumAmount, decimalPoints = 0) {
  if (premiumAmount?.toString()?.includes('.')) {
    const formattedNum = parseFloat(premiumAmount).toLocaleString('en-US', {
      maximumFractionDigits: decimalPoints,
      minimumFractionDigits: decimalPoints
    });
    return `$${formattedNum}`;
  }
  return formatMoney(premiumAmount);
}

/**
 * Is the selected premium the paid-in-full plan?
 *
 * @param {object[]} premiums
 * @param {object} selectedPremium The selected premium object.
 * @returns {boolean} Whether the selectedPremium is the paid in full premium.
 */
export function isPaidInFull(premiums, selectedPremium) {
  const numOfPayments = getNumOfPayments(selectedPremium);
  return (
    premiums.length &&
    premiums.every(p => {
      const _numOfPayments = getNumOfPayments(p);
      return _numOfPayments >= numOfPayments;
    })
  );
}

/**
 * Get the "Due Today" price given the selected quotes and payments.
 *
 * @param {Array} selectedQuotes Array of quote objects.
 * @param {Array} payments Array of payment objects.
 * @returns {string} The formatted total price.
 */
export function getFirstPayment(selectedQuotes, payments) {
  const premiumAmountsWithFees = selectedQuotes.reduce((sum, quote) => {
    const payment = payments.find(payment => payment.quoteId === quote[GENERAL_FIELDS.ID]);
    const paymentPlan = payment[PAYMENT_FIELDS.PLAN.key];
    const paymentMethod = payment[PAYMENT_FIELDS.METHOD.key];

    // If escrow, do not include it in the sum
    const isEscrow = paymentMethod === PAYMENT_METHODS.ESCROW;
    if (isEscrow) return sum;

    const { fees, premiums } = quote;
    const selectedPremium = getPremiumByMethod({ premiums, paymentPlan, paymentMethod });
    const paidInFull = isPaidInFull(premiums, selectedPremium);
    const fee = getFee(fees, paymentMethod);
    const baseAmount =
      selectedPremium[PREMIUM_FIELDS.DOWN_PAYMENT] || selectedPremium[PREMIUM_FIELDS.AMOUNT];
    sum += addFees(baseAmount, fee, paidInFull);
    return sum;
  }, 0);
  return formatPremium(premiumAmountsWithFees, 2);
}

/**
 *
 * @param {object} premium
 * @returns {number|undefined}
 */
export function getNumOfPayments(premium) {
  return (
    PAYMENT_PLAN_FACTORS[premium?.[PREMIUM_FIELDS.PAYMENT_PLAN]] ||
    premium?.[PREMIUM_FIELDS.NUM_OF_PAYMENTS]
  );
}

export function getPlanLabel(premium) {
  const label = STANDARD_BILLING_CYCLE_LABELS[premium[PREMIUM_FIELDS.PAYMENT_PLAN]];
  const isInstallment = premium[PREMIUM_FIELDS.PAYMENT_PLAN] === PAYMENT_PLANS.INSTALLMENT;
  return isInstallment ? `${premium[PREMIUM_FIELDS.NUM_OF_PAYMENTS]}-Pay` : label;
}
