import { ENVS } from '../constants/environments';

export function isProd() {
  return process.env.ENV === ENVS.PROD;
}

export function isDemo() {
  return process.env.ENV === ENVS.DEMO;
}

export function isTest() {
  return process.env.NODE_ENV === 'test';
}
