import PropTypes from 'prop-types';
import React from 'react';
import BaseTextField from '../BaseTextField';

const TextField = ({
  name,
  isValid,
  label,
  prompt,
  placeholder,
  validationText,
  maxLength,
  autoComplete,
  showValidation,
  inputMode,
  inputType,
  pattern,
  noPaste,
  ...rest
}) => {
  const { value = '', save } = rest;

  const disablePaste = e => {
    e.preventDefault();
  };

  return (
    <BaseTextField
      type={inputType || 'text'}
      name={name}
      label={label}
      value={value}
      isValid={isValid}
      placeholder={placeholder}
      prompt={prompt}
      validationText={validationText}
      maxLength={maxLength}
      inputMode={inputMode}
      pattern={pattern}
      autoComplete={autoComplete}
      showValidation={showValidation}
      onChange={e => save(e.target.value)}
      onPaste={noPaste && disablePaste}
    />
  );
};

export default TextField;

TextField.propTypes = {
  name: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  prompt: PropTypes.string,
  validationText: PropTypes.string,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  showValidation: PropTypes.bool,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  inputType: PropTypes.string,
  noPaste: PropTypes.bool
};
