import PropTypes from 'prop-types';
import React from 'react';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import VehicleCard from '../../journey/components/CreateablePage/components/Card/VehicleCard';
import DriverSelect from '../containers/DriverSelect';
import styles from '../css/AssignDrivers.css';
import { getAdditionalDriverUsageOptions, getAdditionalDriverVal } from '../utils/assign-drivers';
import { getNonPrimaryDriverOptions } from '../utils/driver';
import { getVal } from '../utils/journey-data';
import { hasVal } from '../utils/prompt-validation';
import { getLabel } from '../utils/vehicle';
import SelectField from './SelectField';

const AssignDrivers = ({ name, vehicles, drivers, saveAdditionalDriverUsage, showValidation }) => {
  /**
   * Custom save function to handle additional driver usage.
   *
   * The reason we use this is that we save additional fields (driver and usage) and not just a value.
   *
   * @param {string} driverId The driverId.
   * @param {string} value The value selected in the dropdown.
   * @param {string} assignedDriver The current assigned driver.
   * @param {Array} addlDriverUsage The current driver usage array.
   * @param {number} promptGroupIndex The vehicle's prompt group index.
   */
  const saveWrapper = (driverId, value, assignedDriver, addlDriverUsage, promptGroupIndex) => {
    // Filter out the selected driver's previous value, and remove the primary driver if that has changed.
    const newUsage = addlDriverUsage.filter(val => {
      if (val.startsWith(driverId)) return false; // Updating an existing usage.
      if (val.startsWith(assignedDriver)) return false; // this driver is now the primary, remove them.
      return true;
    });

    newUsage.push(value);

    saveAdditionalDriverUsage(newUsage, PROMPT_GROUP_NAMES.VEHICLES, promptGroupIndex);
  };

  return vehicles.map((vehicle, index) => {
    const vehicleLabel = getLabel(vehicle);
    const assignedDriver = getVal(vehicle[PROMPT_NAMES.ASSIGNED_DRIVER]);
    const additionalDriverOptions = getNonPrimaryDriverOptions(drivers, assignedDriver);
    const additionalDriverUsage = getVal(vehicle[PROMPT_NAMES.ADDITIONAL_DRIVER_USAGE]) || [];
    return (
      <section className={styles.row} key={`${vehicleLabel}-${index}`}>
        <VehicleCard entity={vehicle} />
        <div className={styles.select}>
          <DriverSelect
            id={`${name}-container-${index}`}
            inputId={`${name}-select-${index}`}
            prompt="Who is the primary driver of this vehicle?"
            name={name}
            promptGroupName={PROMPT_GROUP_NAMES.VEHICLES}
            promptGroupIndex={index}
            showValidation={showValidation}
            value={assignedDriver}
            filterValue
          />
          {!!assignedDriver &&
            additionalDriverOptions.map((option, i) => {
              const driverId = option.value;
              const options = getAdditionalDriverUsageOptions(driverId);
              const driver = getAdditionalDriverVal(additionalDriverUsage, driverId);
              return (
                <SelectField
                  id={`${PROMPT_NAMES.ADDITIONAL_DRIVER_USAGE}-container-${index}-${i}`}
                  inputId={`${PROMPT_NAMES.ADDITIONAL_DRIVER_USAGE}-select-${index}-${i}`}
                  key={driverId}
                  options={options}
                  prompt={`How often does ${option.label} use this vehicle?`}
                  name={PROMPT_NAMES.ADDITIONAL_DRIVER_USAGE}
                  value={driver}
                  save={val =>
                    saveWrapper(driverId, val, assignedDriver, additionalDriverUsage, index)
                  }
                  showValidation={showValidation}
                  isValid={hasVal(driver)}
                />
              );
            })}
        </div>
      </section>
    );
  });
};

export default AssignDrivers;

AssignDrivers.propTypes = {
  name: PropTypes.string.isRequired,
  vehicles: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  saveAdditionalDriverUsage: PropTypes.func,
  showValidation: PropTypes.bool
};
