import React from 'react';
import ChainLink from '../../../../../core/assets/icons/connected-link.svg';
import PiggyBank from '../../../../../core/assets/icons/piggy-bank.svg';
import Documents from '../../../../../core/assets/icons/quote-documents.svg';
import Wand from '../../../../../core/assets/icons_v2/magic-wand.svg';
import IconListCard from '../../../../../core/components/IconListCard';

const heading = 'How it works';

const listItems = [
  {
    title: 'Connect your insurance',
    content: 'Login with your insurer to instantly connect your policy details.',
    icon: ChainLink
  },
  {
    title: 'Get personalized quotes',
    content:
      'Get quotes from dozens of carriers that match your existing coverages with accurate, bindable prices.',
    icon: Documents
  },
  {
    title: 'Save on your insurance',
    content: "More money in the bank – it's as simple as that.",
    icon: PiggyBank
  },
  {
    title: 'Get smart policy alerts',
    content:
      'Before every renewal, we will automatically review your policy and alert you if we can find a better rate.',
    icon: Wand
  }
];

const HowItWorks = () => <IconListCard heading={heading} listItems={listItems} />;

export default HowItWorks;
