import { US_STATES } from 'woop-shared/data/states';
import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';

/**
 * Format Geocoder suggestion data into a format that matches react-select's options spec, filters results by zip
 *
 * @param {Array} suggestions an array of suggestions as defined by Geocoder's Autocomplete API spec (https://developer.here.com/documentation/geocoder-autocomplete/topics/resource-type-response-suggest.html)
 * @param {string} postalCode a postal code to filter results by
 * @returns {Array} suggestion data in react-select option format
 */
export function formatAddressSuggestions(suggestions, postalCode = '') {
  return (
    suggestions
      ?.map(data => {
        const address = data?.address || {};
        const isFullAddress =
          address.houseNumber && address.street && address.city && address.postalCode;
        if (isFullAddress && address.postalCode?.includes(postalCode)) {
          const formattedAddress = formatAddress(address);
          return {
            value: address,
            label: formattedAddress
          };
        }
      })
      .filter(s => s) || []
  );
}

/**
 * Take a here maps address object and create a composite address string.
 *
 * @param {object} address contains at least {houseNumber, street, city, state, postalCode}
 * @returns {string} Composite address.
 */
function formatAddress(address) {
  return `${address.houseNumber} ${address.street}, ${address.city}, ${address.state}, ${address.postalCode}`;
}

export function maybeAppendZip(input, zip) {
  return zip && !input.includes(zip) ? `${input} ${zip}` : input;
}

/**
 * Get the state from the address object.
 *
 * @param {object} address The full address object.
 * @returns {string} e.g. PA
 */
export function getStateFromAddress(address) {
  if (address?.state) return address.state; // Full address object.
  if (address?.composite) return getStateFromComposite(address.composite); // Forced-entry address structure.
}

/**
 * Get the state abbreviation from the composite string.
 *
 * @param {string} composite The composite, e.g. 22 E Oxford St, Philadelphia, PA, 19125
 * @returns {string} e.g. PA
 */
function getStateFromComposite(composite) {
  const stateAbbrevs = US_STATES.map(state => state.value);
  return stateAbbrevs.find(abbrev => {
    const searchTerm = ` ${abbrev}, `; // A state abbrev is always followed by a comma.
    return composite?.includes(searchTerm);
  });
}

export function isCustomAddress(address) {
  return !!(address && Object.keys(address).length === 1 && address[UI_ADDRESS_FIELDS.COMPOSITE]);
}
