import PropTypes from 'prop-types';
import React from 'react';
import { getMakes } from '../../utils/vehicle';
import DependentSelect from '../DependentSelect';

const Makes = ({ year, ...rest }) => {
  return (
    <DependentSelect
      getOptions={getMakes}
      dependentFieldVals={{ year }}
      keepInitialValue
      {...rest}
    />
  );
};

export default Makes;

Makes.propTypes = {
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
