import { combineReducers } from 'redux';
import { GET_TOKEN_SUCCESS } from '../actions/box';

const token = (state = null, action) => {
  switch (action.type) {
    case GET_TOKEN_SUCCESS: {
      return action.payload || null;
    }
    default:
      return state;
  }
};

export default combineReducers({ token });
