import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SCENARIOS } from 'woop-shared/enums';
import { EVENTS } from '../../../../analytics/optimizely/constants';
import { EVENTS as SHARED_EVENTS } from '../../../../analytics/optimizely/constants';
import { trackEvent } from '../../../../analytics/utils';
import { ROUTES } from '../../../../router/constants/routes';
import { clearCanopy } from '../../../core/actions/canopy';
import AppWrapper from '../../../core/components/AppWrapper';
import BackButton from '../../../core/components/BackButton';
import Button from '../../../core/components/Button';
import CanopyModal from '../../../core/components/CanopyModal';
import H2 from '../../../core/components/headers/H2';
import LinkText from '../../../core/components/LinkText';
import { QUERY_PARAMS } from '../../../core/constants/url';
import { useBoxUpload } from '../../../core/hooks/use-box-upload';
import { useDealershipPartnerName } from '../../../core/hooks/use-dealership-partner-name';
import { useOverlayModal } from '../../../core/hooks/use-overlay-modal';
import { getQueryStringParam } from '../../../core/utils/url';
import { setIsQuickJourney, patchJourney } from '../../actions/journey';
import BoxModal from './components/BoxModal';
import ExplainerSection from './components/ExplainerSection';
import WhyConnectTooltip from './components/WhyConnect';
import styles from './styles.css';
import {
  getNextRoute,
  getText,
  getImg,
  getHeader,
  shouldPreventExplainer,
  getUploadText
} from './utils';

const referrerCTA = getQueryStringParam(QUERY_PARAMS.REFERRER_CTA);

const cx = classnames.bind(styles);

const ConnectPage = ({ allowUploads = true }) => {
  const history = useHistory();
  const [openCanopy, setOpenCanopy] = useOverlayModal();
  const [hasExplainer, setHasExplainer] = useState();

  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
  const isSignUp = scenario === SCENARIOS.SIGN_UP;

  const { openBoxModal, setOpenBoxModal, hasFiles, handleOpenBox, handleUpload, onUploadComplete } =
    useBoxUpload({
      onUploadCompleteCallback: () => {
        setOpenBoxModal(false);
        if (scenario === SCENARIOS.IV) history.push(ROUTES.IV_END);
        else history.push(getNextRoute());
      }
    });

  const dispatch = useDispatch();

  const partnerName = useDealershipPartnerName();

  const isLandingPage = history?.length === 1;

  function goToPrejourney() {
    dispatch(setIsQuickJourney(false));
    history.push(ROUTES.PREJOURNEY);
  }

  function onContinue() {
    // After a connect, patch journey, then route to next page
    dispatch(setIsQuickJourney(true));
    const callback = () => history.push(getNextRoute());
    dispatch(patchJourney({ callback }));
  }

  function abortToBox() {
    setOpenCanopy(false);
    setOpenBoxModal(true);
  }

  function handleOpenCanopy() {
    setOpenCanopy(true);
    history.push(ROUTES.CONNECT_CARRIERS);
    window.optimizely?.track(EVENTS.SELECTED_CONNECT);
    trackEvent(SHARED_EVENTS.SELECTED_CONNECT);
  }

  useEffect(() => {
    !shouldPreventExplainer(scenario) && setHasExplainer(true);
  }, []);

  return (
    <AppWrapper
      className={cx('wrapper', { hasExplainer, hasProgressBar: isSignUp })}
      isFloatingCardParent
    >
      <section className={cx('content')}>
        {!isLandingPage && !isSignUp && <BackButton />}
        <div className={styles.leftColumn}>{getImg(scenario)}</div>
        <div className={styles.rightColumn}>
          <div className={styles.description}>
            <H2>{getHeader(scenario)}</H2>
            <div>{getText(scenario, partnerName, referrerCTA)}</div>
          </div>
          <div className={styles.ctas}>
            <Button
              className={styles.button}
              onClick={handleOpenCanopy}
              useKeyboard
              blockButtonMobile
              showArrow
              disabled={openCanopy}
            >
              {'Connect my insurance'}
            </Button>
            {isSignUp && <WhyConnectTooltip />}

            {allowUploads && (
              <div className={styles.uploadLink}>
                <LinkText onClick={handleOpenBox}>{getUploadText(scenario)}</LinkText>
              </div>
            )}
          </div>
        </div>
      </section>
      {hasExplainer && <ExplainerSection />}
      <div className={styles.otherOptions}>
        <div className={styles.notInsured}>Not insured?&nbsp;</div>
        <LinkText onClick={goToPrejourney}>
          Start here
          <IoIosArrowForward className={styles.arrow} />
        </LinkText>
      </div>
      <CanopyModal
        open={openCanopy}
        toggleOpen={bool => {
          // If closing, clear canopy state.
          if (!bool) {
            dispatch(clearCanopy());
            history.replace(ROUTES.CONNECT);
          }
          setOpenCanopy(bool);
        }}
        onContinue={onContinue}
        abortToBox={allowUploads && abortToBox}
      />
      {allowUploads && (
        <BoxModal
          open={openBoxModal}
          toggleOpen={setOpenBoxModal}
          onUpload={handleUpload}
          onContinue={onUploadComplete}
          isValid={hasFiles}
        />
      )}
    </AppWrapper>
  );
};

export default ConnectPage;

ConnectPage.propTypes = {
  allowUploads: PropTypes.bool
};
