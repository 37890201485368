// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__legalLinks--3g-qd{display:flex;justify-content:center;margin-top:-30px}@media (max-width:550px){.styles__legalLinks--3g-qd{position:relative;margin-top:10px;margin-bottom:20px}}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,2BACE,YAAa,CACb,sBAAuB,CACvB,gBAOF,CALE,yBALF,2BAMI,iBAAkB,CAClB,eAAgB,CAChB,kBAEJ,CADE","file":"styles.css","sourcesContent":["@import '../modules/core/css/variables.css';\n\n.legalLinks {\n  display: flex;\n  justify-content: center;\n  margin-top: -30px;\n\n  @media (--small-viewport) {\n    position: relative;\n    margin-top: 10px;\n    margin-bottom: 20px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"legalLinks": "styles__legalLinks--3g-qd"
};
module.exports = exports;
