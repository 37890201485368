import { combineReducers } from 'redux';
import { SET_ORIGINATOR_ID } from '../actions/originator';
import { POST_VISIT_SUCCESS } from '../actions/visit';

/*
Notes:
- Empty string defaults to avoid swagger null problem
*/

const id = (state = '', action) => {
  switch (action.type) {
    case POST_VISIT_SUCCESS: {
      return action.payload.oid;
    }
    default:
      return state;
  }
};

const srcAcctId = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const originatorId = (state = '', action) => {
  switch (action.type) {
    case SET_ORIGINATOR_ID: {
      return action.payload || '';
    }
    default:
      return state;
  }
};

export default combineReducers({
  id,
  srcAcctId,
  originatorId
});
