import axios from 'axios';
import { RESOURCES } from '../utils/api';
import { concatUri } from '../utils/url';

const PARTNERS_URI = `${process.env.DATA_URI}${RESOURCES.PARTNERS}`;

export const getPartner = async identifier => {
  const { data } = await axios.get(concatUri(PARTNERS_URI, identifier), { validateStatus: false });
  return data;
};
