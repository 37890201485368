import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BaseTextField from './BaseTextField';

const BaseDateField = ({
  name,
  isValid,
  prompt,
  label,
  validationFn,
  placeholder,
  validationText,
  autoComplete,
  showValidation,
  tooltip,
  ...rest
}) => {
  const { value = '', save } = rest;
  const [formattedValue, setFormattedValue] = useState(validationFn(value));

  useEffect(() => {
    if (formattedValue !== value) {
      setFormattedValue(validationFn(value || ''));
    }
  }, [value]);

  const handleInput = e => {
    let inputVal = e.target.value;
    const isDelete = value.length > inputVal?.length;

    // Append slash to let user know we do it for them
    if (!isDelete && inputVal.length === 2 && !inputVal.includes('/')) {
      inputVal += '/';
    }

    const formatted = validationFn(inputVal, isDelete);
    setFormattedValue(formatted);

    // Only update the journeyData if the value has changed
    if (formatted !== value) {
      save(formatted);
    }
  };

  return (
    <BaseTextField
      inputMode="numeric"
      label={label}
      name={name}
      isValid={isValid}
      value={formattedValue}
      placeholder={placeholder}
      onChange={handleInput}
      prompt={prompt}
      validationText={validationText}
      autoComplete={autoComplete}
      showValidation={showValidation}
      tooltip={tooltip}
    />
  );
};

export default BaseDateField;

BaseDateField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  prompt: PropTypes.string,
  validationFn: PropTypes.func,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  validationText: PropTypes.string,
  autoComplete: PropTypes.string,
  showValidation: PropTypes.bool,
  tooltip: PropTypes.string
};
