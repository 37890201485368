import { EVENTS } from '../constants';

export const AMPLITUDE_EVENTS = {
  START_WOOP: 'START_WOOP',
  END_WOOP: 'END_WOOP',
  LEAVE_WOOP: 'LEAVE_WOOP',
  RETURN_TO_WOOP: 'RETURN_TO_WOOP',
  OPTIMIZELY_DECISION: 'OPTIMIZELY_DECISION',
  ...EVENTS
};

export const EXTERNAL_LINK_IDS = {
  FIND_MY_VIN: 'find_my_vin',
  CANCEL_POLICY: 'cancel_policy',
  FIND_DEC_PAGE: 'find_dec_page',
  ANNUAL_MILES: 'annual_miles'
};

export const VIN_WARNING_MODAL_ACTIONS = {
  ENTERED_VIN: 'ENTERED_VIN',
  NO_VIN_EXISTS: 'NO_VIN_EXISTS',
  CONTINUE_ANYWAY: 'CONTINUE_ANYWAY'
};
