import { JOURNEY_ID } from '../constants/cookies';
import {
  ORIGINATION_OBJECT_NAME,
  DEFAULT_ORIGINATION_DATA,
  ORIGINATION_FIELDS
} from '../constants/origination';
import { setCookie } from './cookies';
import { getLocalStorage, setLocalStorage } from './local-storage';
import * as logger from './logger';

/**
 * Get the origination data object from localStorage.
 *
 * @returns {object}
 */
export function getOriginationData() {
  const originationData = getLocalStorage(ORIGINATION_OBJECT_NAME);
  return originationData ? JSON.parse(originationData) : {};
}

/**
 * Get a piece of origination data based on the given key.
 *
 * @param {string} key A key defined in ORIGINATION_OBJECT_KEYS.
 * @returns {*}
 */
function getOriginationDataField(key) {
  return getOriginationData()[key];
}

/**
 * Set a piece of origination data based on the given key.
 *
 * @param {string} key A key defined in ORIGINATION_OBJECT_KEYS.
 * @param {string} value A value to set for the given key.
 */
export function setOriginationDataField(key, value) {
  const originationData = { ...getOriginationData(), [key]: value };
  setOriginationData(originationData);
}

/**
 * Get the OID from the origination data object.
 *
 * @returns {string} The origination ID.
 */
export function getOid() {
  return getOriginationDataField(ORIGINATION_FIELDS.OID);
}

/**
 * Store originationData to localStorage.
 *
 * @param {object} originationData Origination data object.
 */
function setOriginationData(originationData) {
  setLocalStorage(ORIGINATION_OBJECT_NAME, JSON.stringify(originationData));
}

/**
 * Get the origination data object from localStorage, falling back to defaults.
 *
 * @returns {object}
 */
function getStoredOriginationData() {
  const storedData = getOriginationData();
  return { ...DEFAULT_ORIGINATION_DATA, ...storedData };
}

/**
 * Compare the origination URL params to what's in localStorage and return the real origination data.
 *
 * @param {string} originationId
 * @param {string} originatorId
 * @param {string} accountId
 * @returns {object} { oid, srcAcctId, originatorId }
 */
export function initOrigination(originationId, originatorId, accountId) {
  const originationData = getStoredOriginationData();

  const newOid =
    originationId &&
    originationData[ORIGINATION_FIELDS.OID] &&
    originationId !== originationData[ORIGINATION_FIELDS.OID];

  const newAccountId =
    accountId &&
    originationData[ORIGINATION_FIELDS.ACCOUNT_ID] &&
    accountId !== originationData[ORIGINATION_FIELDS.ACCOUNT_ID];

  if (originationId) {
    originationData[ORIGINATION_FIELDS.OID] = originationId;
  }

  if (accountId) {
    originationData[ORIGINATION_FIELDS.ACCOUNT_ID] = accountId;
  }

  if (originatorId) {
    originationData[ORIGINATION_FIELDS.ORIGINATOR_ID] = originatorId;
  }

  // If an explicit new oid exists, clear out the current journey.
  if (newOid) {
    setCookie(JOURNEY_ID, '');
  }

  // If new oid or accountId and no originator, clear the stored originatorId.
  if (!originatorId && (newAccountId || newOid)) {
    delete originationData[ORIGINATION_FIELDS.ORIGINATOR_ID];
  }

  setOriginationData(originationData);
  logger.log('Origination Data: ', originationData);

  return originationData;
}
