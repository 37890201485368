import { PARTNERS } from 'woop-shared/db/partners';

const THEMABLE_PROPS = {
  bodyTextColor: '--woop-body-text-color',
  placeholderTextColor: '--woop-placeholder-text-color',
  primaryColor: '--woop-primary-color',
  primaryColorLighter: '--woop-primary-color-lighter',
  primaryColorDarker: '--woop-primary-color-darker',
  white: '--woop-white',
  backgroundColor: '--woop-background-color',
  errorColor: '--woop-error-color',
  boxShadowColor: '--woop-box-shadow-color',
  boxShadowColorDarker: '--woop-box-shadow-color-darker'
};

export const WOOP_THEME_DEFAULT = {
  [THEMABLE_PROPS.bodyTextColor]: '51, 51, 51',
  [THEMABLE_PROPS.placeholderTextColor]: '150, 150, 150',
  [THEMABLE_PROPS.primaryColor]: '108, 187, 237',
  [THEMABLE_PROPS.primaryColorDarker]: '14, 76, 115',
  [THEMABLE_PROPS.white]: '256, 256, 256',
  [THEMABLE_PROPS.backgroundColor]: '256, 256, 256',
  [THEMABLE_PROPS.errorColor]: '237, 108, 123',
  [THEMABLE_PROPS.boxShadowColor]: '231, 231, 231',
  [THEMABLE_PROPS.boxShadowColorDarker]: '187, 187, 187'
};

/* Purple & Black */
const WOOP_THEME_ALT = {
  [THEMABLE_PROPS.primaryColor]: '140, 139, 254',
  [THEMABLE_PROPS.primaryColorDarker]: '0, 0, 0',
  [THEMABLE_PROPS.errorColor]: '254, 140, 139'
};

/* Dark Mode */
const WOOP_THEME_DARK = {
  [THEMABLE_PROPS.bodyTextColor]: '204, 204, 204',
  [THEMABLE_PROPS.primaryColor]: '212, 212, 212',
  [THEMABLE_PROPS.primaryColorDarker]: '173, 173, 173',
  [THEMABLE_PROPS.errorColor]: '172, 21, 152',
  [THEMABLE_PROPS.placeholderTextColor]: '210, 210, 210',
  [THEMABLE_PROPS.backgroundColor]: '40, 44, 53',
  [THEMABLE_PROPS.white]: '40, 44, 53',
  [THEMABLE_PROPS.boxShadowColor]: '0, 0, 0',
  [THEMABLE_PROPS.boxShadowColorDarker]: '0, 0, 0'
};

const SOLIDARITY_BENEFITS = {
  [THEMABLE_PROPS.primaryColor]: '239, 56, 65',
  [THEMABLE_PROPS.primaryColorDarker]: '4, 44, 73',
  [THEMABLE_PROPS.errorColor]: '0, 0, 0'
};

const NABU = {
  [THEMABLE_PROPS.primaryColor]: '100, 190, 100',
  [THEMABLE_PROPS.primaryColorDarker]: '107, 16, 100'
};

const JOVE = {
  [THEMABLE_PROPS.primaryColor]: '252, 202, 0',
  [THEMABLE_PROPS.primaryColorDarker]: '67, 29, 107'
};

const UNAP = {
  [THEMABLE_PROPS.primaryColor]: '113, 11, 105',
  [THEMABLE_PROPS.primaryColorDarker]: '61, 6, 57'
};

const STEWART = {
  [THEMABLE_PROPS.primaryColor]: '149, 35, 50',
  [THEMABLE_PROPS.primaryColorDarker]: '1, 59, 107'
};

const MERIDIO = {
  [THEMABLE_PROPS.primaryColor]: '3, 53, 255',
  [THEMABLE_PROPS.primaryColorDarker]: '1, 13, 64'
};

const WORD_AND_BROWN = {
  [THEMABLE_PROPS.primaryColor]: '220,38,151',
  [THEMABLE_PROPS.primaryColorDarker]: '55,216,247'
};

const LSG = {
  [THEMABLE_PROPS.primaryColor]: '149,187,180',
  [THEMABLE_PROPS.primaryColorDarker]: '17,94,75'
};

export const THEMES = {
  [PARTNERS.WOOP]: WOOP_THEME_DEFAULT,
  [PARTNERS.WOOP_ALT]: WOOP_THEME_ALT,
  [PARTNERS.KEITH]: WOOP_THEME_DARK,
  [PARTNERS.SOLIDARITY]: SOLIDARITY_BENEFITS,
  [PARTNERS.NABU]: NABU,
  [PARTNERS.JOVE]: JOVE,
  [PARTNERS.UNAP]: UNAP,
  [PARTNERS.STEWART]: STEWART,
  [PARTNERS.MERIDIO]: MERIDIO,
  [PARTNERS.WORD_AND_BROWN]: WORD_AND_BROWN,
  [PARTNERS.LSG]: LSG
};
