import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EVENTS } from '../../../../../../analytics/constants';
import { trackEvent } from '../../../../../../analytics/utils';
import { CONNECT_ROUTE_PAGES } from '../../../../../../router/constants/routes';
import { connectRoute } from '../../../../../../router/utils';
import { setCarrier } from '../../../../actions/canopy';
import { getCarriers } from '../../../../api/canopy';
import Documents from '../../../../assets/icons/documents.svg';
import { isLargeViewport } from '../../../../utils/browser';
import H3 from '../../../headers/H3';
import LinkText from '../../../LinkText';
import { Spinner } from '../../../Spinner';
import styles from './styles.css';
import { filterCarriers } from './utils';

const CarrierScreen = ({ abortToBox, modalOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputField = useRef(null);
  const router = useSelector(state => state.router);

  const [loading, setLoading] = useState(true);
  const [carriers, setCarriers] = useState([]);
  const [filter, setFilter] = useState('');

  const { CANOPY_SELECTED_CARRIER, CANOPY_SEARCH, CANOPY_SWITCH_TO_UPLOAD } = EVENTS;

  useEffect(() => {
    if (modalOpen && isLargeViewport()) inputField?.current?.focus();
  }, [modalOpen]);

  useEffect(() => {
    let cancel = false;
    getCarriers().then(carriers => {
      if (!cancel) {
        setCarriers(carriers);
        setLoading(false);
      }
    });
    return () => (cancel = true);
  }, []);

  useEffect(() => {
    if (filter) trackEvent(CANOPY_SEARCH, { searchFilter: filter });
  }, [filter]);

  const filteredCarriers = () => {
    if (!filter) return carriers;
    return filterCarriers(carriers, filter);
  };

  const handleSelect = carrier => {
    dispatch(setCarrier({ carrier }));
    history.replace(connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.LOGIN));
    trackEvent(CANOPY_SELECTED_CARRIER, { carrierCode: carrier.carrier_id });
  };

  const handleUploadOnClick = () => {
    trackEvent(CANOPY_SWITCH_TO_UPLOAD);
    abortToBox();
  };

  return (
    <div className={styles.wrapper}>
      <H3>Select your insurance provider</H3>
      <div className={styles.search}>
        <FiSearch className={styles.icon} />
        <input
          ref={inputField}
          type="search"
          placeholder="Search"
          className={styles.input}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        {filter && <FiX className={styles.xicon} onClick={() => setFilter('')} />}
      </div>
      <div className={styles.carrierList}>
        {loading || !carriers ? (
          <Spinner className={styles.spinner} />
        ) : (
          filteredCarriers().map(carrier => (
            <div
              key={carrier.carrier_id}
              className={styles.item}
              onClick={() => handleSelect(carrier)}
            >
              <div className={styles.logo}>
                {carrier?.logo ? <img src={carrier.logo} alt={carrier.name} /> : <Documents />}
              </div>
              <div className={styles.info}>
                <div className={styles.name}>{carrier.name}</div>
                <div className={styles.url}>{carrier.homepageUrl}</div>
              </div>
            </div>
          ))
        )}

        {abortToBox && (
          <div className={styles.footer}>
            <div>Provider not listed?</div>
            <div className={styles.link}>
              <LinkText onClick={handleUploadOnClick}>Upload your policy documents</LinkText>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarrierScreen;

CarrierScreen.propTypes = {
  abortToBox: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  modalOpen: PropTypes.bool
};
