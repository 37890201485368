import { DRIVER_USAGE } from 'woop-shared/origination/enums';
import { capitalize } from 'woop-shared/utils';

/**
 * Create the custom value field by concatenating the driverId and the usage.
 *
 * @param {string} driverId The driverId.
 * @param {string} usage One of the usage values, e.g. RARELY.
 * @returns {string} e.g. JimmyBeans01-01-1990 - RARELY.
 */
function createUsageValue(driverId, usage) {
  return `${driverId} - ${usage}`;
}

export function getAdditionalDriverUsageOptions(driverId) {
  return [
    {
      value: createUsageValue(driverId, DRIVER_USAGE.RARELY),
      label: capitalize(DRIVER_USAGE.RARELY)
    },
    {
      value: createUsageValue(driverId, DRIVER_USAGE.SOMETIMES),
      label: capitalize(DRIVER_USAGE.SOMETIMES)
    },
    {
      value: createUsageValue(driverId, DRIVER_USAGE.FREQUENTLY),
      label: capitalize(DRIVER_USAGE.FREQUENTLY)
    }
  ];
}

/**
 * Get the value of the additional driver field, handling flattened journeyData and the custom save structure.
 *
 * @param {Array} value The current value of the additional driver usage field.
 * @param {string} driverId The driverId.
 * @returns {string} The usage value.
 */
export function getAdditionalDriverVal(value, driverId) {
  return value?.find(val => {
    return val.startsWith(driverId);
  });
}
