import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../css/RadioGroup.css';

const cx = classNames.bind(styles);

const Radio = ({ id, label, selected, onClick, className, disabled, children, title }) => (
  <button
    id={id}
    key={label}
    onClick={onClick}
    disabled={disabled}
    className={cx('radio-button', { selected }, className)}
    title={title}
  >
    {label}
    {children}
  </button>
);

export default Radio;

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string
};
