/**
 * A collection of valid query params accepted by the UI, not used for API requests
 */
export const QUERY_PARAMS = {
  QUOTESET_ID: 'quotesetId',
  QUOTE_ID: 'quoteId',
  BUNDLE_ID: 'bundleId',
  JOURNEY_ID: 'journeyId',
  JOURNEY_DATA: 'woopUserData',
  ACCOUNT: 'account', // Used by Viv
  SOURCE_ACCOUNT_ID: 'srcAcctId',
  OID: 'oid',
  UNSUBSCRIBE: 'unsubscribe',
  SCENARIO: 'scenario',
  ORIGINATOR_ID: 'originatorId',
  CAMPAIGN: 'campaign',
  REFERRER: 'referrer',
  REFERRER_CTA: 'referrerCta',

  // For GI
  UNIFIED_IV_PAGE: 'unifiedIvPage'
};

export const REFERRERS = {
  PORTAL: 'portal',
  WIA: 'wia'
};
