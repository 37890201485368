import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SCENARIOS } from 'woop-shared/enums';
import { ROUTES } from '../../../../router/constants/routes';
import { setScenario } from '../../../core/actions/scenario';
import CheckShieldOutline from '../../../core/assets/icons_v2/check-shield-outline.svg';
import GivingInsurance from '../../../core/assets/icons_v2/giving_insurance.svg';
import XShieldOutline from '../../../core/assets/icons_v2/x-shield-outline.svg';
import AppWrapper from '../../../core/components/AppWrapper';
import H2 from '../../../core/components/headers/H2';
import H3 from '../../../core/components/headers/H3';
import LegalLinks from '../../../core/components/LegalLinks';
import { useDealershipPartnerName } from '../../../core/hooks/use-dealership-partner-name';
import { useSlimApp } from '../../../core/hooks/use-slim-app';
import { setIsQuickJourney } from '../../actions/journey';
import styles from './styles.css';

const DealershipPicker = () => {
  useSlimApp({ removeOnUnmount: true });

  const partnerName = useDealershipPartnerName({ capitalize: true });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setScenario(SCENARIOS.DEALERSHIP_PICKER));
  }, []);

  const goToFullApp = () => {
    dispatch(setIsQuickJourney(false));
    history.push(ROUTES.PREJOURNEY);
  };

  const goToQuickApp = () => {
    history.push(ROUTES.CONNECT);
  };

  return (
    <AppWrapper className={styles.wrapper}>
      <section className={styles.contentCard}>
        <GivingInsurance />
        <div>
          <H2>Your new car deserves a new insurance experience.</H2>
          <div className={styles.text}>
            {partnerName} uses <b>Woop Insurance Agency</b> to take care of your insurance needs.
          </div>
        </div>

        <div className={styles.optionCard} onClick={goToQuickApp}>
          <div className={styles.imageWrapper}>
            <CheckShieldOutline className={styles.icon} />
          </div>
          <div className={styles.optionText}>
            <H3>{'Already insured? Get quotes ->'}</H3>
            <p>{`Find the best coverage for your new vehicle. Connect your current insurance for personalized quotes from leading providers.`}</p>
          </div>
        </div>

        <div className={styles.optionCard} onClick={goToFullApp}>
          <div className={styles.imageWrapper}>
            <XShieldOutline className={styles.icon} />
          </div>
          <div className={styles.optionText}>
            <H3>{'Not insured? Get covered ->'}</H3>
            <p>{`Start here to get quick quotes. Drive away knowing that you're protected.`}</p>
          </div>
        </div>
        <LegalLinks className={styles.legal} />
      </section>
    </AppWrapper>
  );
};

export default DealershipPicker;
