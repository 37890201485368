/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

/**
 * Based on Sentry type SeverityLevel
 */
export const LOG_LEVELS = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
  LOG: 'log',
  INFO: 'info',
  DEBUG: 'debug'
};

export function log(...args) {
  if (process.env.DEBUG) console.log(...args);
}

/**
 * Log an error object to the console and Sentry.
 *
 * @param {string} message
 * @param {object} extras
 */
export function warn(message, extras) {
  process.env.DEBUG && console.warn(message);

  if (extras) Sentry.setExtras(extras);
  Sentry.captureMessage(message, LOG_LEVELS.WARNING);
}

/**
 * Log an error object to the console and Sentry.
 *
 * @param {Error} err JS Error object.
 * @param {object} [extras] { [key: string]: any; }
 */
export function error(err, extras) {
  if (err?.response?.status === 401) {
    Sentry.captureMessage('Request failed with status code 401', LOG_LEVELS.LOG);
    return;
  }

  const responseData = err.response?.data;
  if (responseData) {
    process.env.DEBUG && console.error(responseData);
    Sentry.setExtras({ responseData });
  }

  process.env.DEBUG && console.error(err);

  if (extras) Sentry.setExtras(extras);
  Sentry.captureException(err);
}
