// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SSNField__info--5pT_s{margin-top:-20px;margin-bottom:55px}@media (min-width:551px) AND (max-width:900px){.SSNField__info--5pT_s{margin-top:-165px}}@media (max-width:550px){.SSNField__info--5pT_s{margin-bottom:80px;margin-top:-115px}}.SSNField__link--b9LUD svg{margin-left:5px;height:12px;width:12px}", "",{"version":3,"sources":["SSNField.css"],"names":[],"mappings":"AAEA,uBACE,gBAAiB,CACjB,kBAQF,CAPE,+CAHF,uBAII,iBAMJ,CALE,CACA,yBANF,uBAOI,kBAAmB,CACnB,iBAEJ,CADE,CAGF,2BACE,eAAgB,CAChB,WAAY,CACZ,UACF","file":"SSNField.css","sourcesContent":["@import './variables.css';\n\n.info {\n  margin-top: -20px;\n  margin-bottom: 55px;\n  @media (--medium-viewport) {\n    margin-top: -165px;\n  }\n  @media (--small-viewport) {\n    margin-bottom: 80px;\n    margin-top: -115px;\n  }\n}\n\n.link svg {\n  margin-left: 5px;\n  height: 12px;\n  width: 12px;\n}\n"]}]);
// Exports
exports.locals = {
	"info": "SSNField__info--5pT_s",
	"link": "SSNField__link--b9LUD"
};
module.exports = exports;
