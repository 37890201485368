import { SCENARIOS } from 'woop-shared/enums';
import { EXPERIMENTS, VARIATIONS } from '../analytics/optimizely/constants';
import { QUERY_PARAMS, REFERRERS } from '../modules/core/constants/url';
import { getQueryStringParam } from '../modules/core/utils/url';
import { isJourneyPath, isPaymentPath } from '../modules/journey/utils/journey-content';
import { ROUTES, ENABLE_EXIT_CONFIRMATION_ROUTES } from './constants/routes';

export function connectRoute(current, path) {
  return current.startsWith(ROUTES.IV_CONNECT)
    ? `${ROUTES.IV_CONNECT}${path}`
    : `${ROUTES.CONNECT}${path}`;
}

export async function getLandingPage(optimizely) {
  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
  const referrer = getQueryStringParam(QUERY_PARAMS.REFERRER);

  const unifiedIvPage = getQueryStringParam(QUERY_PARAMS.UNIFIED_IV_PAGE);

  switch (scenario) {
    case SCENARIOS.IV: {
      if (unifiedIvPage) return ROUTES.IV_CONNECT;

      await optimizely?.onReady();
      const variation = optimizely?.activate(EXPERIMENTS.SHORT_IV);
      if (variation !== VARIATIONS.ON) return ROUTES.IV_START;
      return ROUTES.IV_CONNECT;
    }

    case SCENARIOS.TEST_DRIVE:
      return ROUTES.CONNECT;

    case SCENARIOS.IV_CHECKLIST: // insurance verification checklist
      return ROUTES.AUTO_CHECKLIST_START;

    case SCENARIOS.SIGN_UP:
      if (referrer === REFERRERS.WIA) return ROUTES.SIGN_UP_INFO;
      return ROUTES.SIGN_UP_WELCOME;

    case SCENARIOS.DEALERSHIP_PICKER:
      return ROUTES.DEALERSHIP_PICKER;

    case SCENARIOS.ESTIMATE:
      return ROUTES.ESTIMATE_START;
  }

  const unsubscribe = getQueryStringParam(QUERY_PARAMS.UNSUBSCRIBE);
  if (unsubscribe) return ROUTES.UNSUBSCRIBE;

  if (hasRouteableQuoteId()) return ROUTES.PAYMENT;

  // quotes-ready & payment-reentry
  const quotesetId = getQueryStringParam(QUERY_PARAMS.QUOTESET_ID);
  if (quotesetId) return ROUTES.WELCOME_QUOTES;

  return ROUTES.WELCOME;
}

/**
 * Based on the path, determine whether to show the exit confirmation dialog.
 *
 * @param {string} path The full pathname from the router.
 * @returns {boolean} True to show dialog, false to not.
 */
export function shouldShowExitConfirmation(path) {
  return (
    ENABLE_EXIT_CONFIRMATION_ROUTES.includes(path) || isPaymentPath(path) || isJourneyPath(path)
  );
}

/**
 * Return the id of the quote(s) we wish to show as selected when routing directly to quote details.
 *
 * @returns {boolean}
 */
export function hasRouteableQuoteId() {
  const quoteId = getQueryStringParam(QUERY_PARAMS.QUOTE_ID);
  const bundleId = getQueryStringParam(QUERY_PARAMS.BUNDLE_ID);
  const referrer = getQueryStringParam(QUERY_PARAMS.REFERRER);
  return (quoteId || bundleId) && referrer === REFERRERS.PORTAL;
}
