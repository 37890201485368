import React from 'react';
import SafeFamily from '../../../../../core/assets/icons_v2/safe_family.svg';
import SafePhone from '../../../../../core/assets/icons_v2/safe_phone.svg';
import Venn from '../../../../../core/assets/icons_v2/venn.svg';
import PrivacyPolicyLink from '../../../../../core/components/PrivacyPolicy/Link';

export const CONNECT_CONTENT = [
  {
    svg: SafePhone,
    key: 'SafePhone',
    text: () => (
      <p>
        <b>The transfer of your information is protected</b> by end-to-end encryption.
      </p>
    )
  },
  {
    svg: SafeFamily,
    key: 'SafeFamily',
    text: () => (
      <p>
        <b>We do not sell your personal information to anyone.</b> See our <PrivacyPolicyLink /> to
        learn more.
      </p>
    )
  },
  {
    svg: Venn,
    key: 'Venn',
    text: () => (
      <p>
        <b>Compare your policy</b> to other top insurers to find the best deal.
      </p>
    )
  }
];
