import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import H2 from '../headers/H2';
import styles from './styles.css';

const cx = classnames.bind(styles);

const CardHeader = ({ children, className }) => {
  return <H2 className={cx('wrapper', className)}>{children}</H2>;
};

CardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default CardHeader;
