import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { isValidEmail } from 'woop-shared/utils';
import {
  hasVal,
  isValidBirthday,
  isValidSsn,
  isValidPhone,
  isValidGPA,
  isValidCoverageType,
  isValidMoveInDate,
  isWithin120Days,
  hasComposite
} from '../utils/prompt-validation';

export const PROMPT_VALIDATION = {
  [PROMPT_NAMES.COVERAGE_TYPE]: [isValidCoverageType],
  [PROMPT_NAMES.ADDRESS]: [hasComposite],
  [PROMPT_NAMES.PRIOR_ADDRESS]: [hasComposite],
  [PROMPT_NAMES.CAR_ADDRESS]: [hasComposite],
  [PROMPT_NAMES.PRIMARY_RESIDENCE_ADDRESS]: [hasComposite],
  [PROMPT_NAMES.DOB]: [hasVal, isValidBirthday],
  [PROMPT_NAMES.EMAIL]: [hasVal, isValidEmail],
  [PROMPT_NAMES.TAX_ID]: [hasVal, isValidSsn],
  [PROMPT_NAMES.MOVE_IN_DATE]: [hasVal, isValidMoveInDate],
  [PROMPT_NAMES.EFFECTIVE_DATE]: [hasVal, isWithin120Days],
  [PROMPT_NAMES.PHONE]: [hasVal, isValidPhone],
  [PROMPT_NAMES.GPA]: [hasVal, isValidGPA]
};
