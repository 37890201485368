import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { FiCheckCircle, FiPlusCircle } from 'react-icons/fi';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import PromptWrapper from '../../../journey/components/PromptWrapper';
import { ICONS } from '../../constants/icons';
import { hasVal } from '../../utils/prompt-validation';
import ErrorText from '../ErrorText';
import Prompt from '../Prompt';
import VisualRadio from '../VisualRadio';
import styles from './styles.css';

const cx = classNames.bind(styles);

const CoverageTypeSelect = ({ prompt, options, showValidation, isValid, name, ...rest }) => {
  const { value, save } = rest;
  const [error, setError] = useState();
  const selections = Array.isArray(value) ? value : hasVal(value) ? [value] : [];

  const isSelected = value => {
    return selections.includes(value);
  };

  const handleClick = (e, value) => {
    const isUmbrella = value === COVERAGE_TYPES.UMBRELLA;
    if (isUmbrella && selections.length !== 2 && !isSelected(value)) {
      setError(true);
      return;
    }
    setError();

    // Handle de-select
    if (isSelected(value)) {
      // Blur button
      e.currentTarget.blur();
      // Set new val
      const newVal = selections.filter(selected => {
        return selected !== value && selected !== COVERAGE_TYPES.UMBRELLA; // On any de-select, de-select umbrella
      });
      return save(newVal);
    }

    const newVal = [...selections, value];
    return save(newVal);
  };

  const isPairable = (selections, value) => {
    if (selections.length === 0) return false;
    // Umbrella can always be bundled
    if (value === COVERAGE_TYPES.UMBRELLA) return true;

    if (selections.length === 1) {
      // If auto is selected, then all options are pariable
      if (selections.includes(COVERAGE_TYPES.AUTO)) return true;
      // Auto insurance can bundle with any selected option
      if (value === COVERAGE_TYPES.AUTO) return true;
    }
  };

  return (
    <PromptWrapper name={name}>
      <Prompt text={prompt} showValidation={!isValid && showValidation} />
      <div className={cx('radio-group')}>
        {options.map(option => {
          const selected = isSelected(option.value);
          const pairable = isPairable(selections, option.value);
          return (
            <VisualRadio
              id={`${name}-${option.value}`}
              className={cx(option.className)}
              label={option.label}
              sublabel={selected ? <FiCheckCircle /> : pairable && <FiPlusCircle />}
              icon={ICONS[option.icon]}
              key={option.label}
              selected={selected}
              handleClick={e => handleClick(e, option.value)}
              disabled={!pairable && selections.length > 0 && !selected}
            />
          );
        })}
        {error && (
          <ErrorText alt>
            We only offer umbrella insurance with an auto and property bundle
          </ErrorText>
        )}
      </div>
    </PromptWrapper>
  );
};

export default CoverageTypeSelect;

CoverageTypeSelect.propTypes = {
  prompt: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      className: PropTypes.string
    })
  ),
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  name: PropTypes.string
};
