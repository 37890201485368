import { PARTNERS } from 'woop-shared/db/partners';

export const PARTNER_STRING_KEYS = {
  RESPA: 'respa'
};

const YANCEY = {
  [PARTNER_STRING_KEYS.RESPA]: `By clicking "Start," you agree that you have acknowledged and read the following. You understand and acknowledge that (a) the purchase of insurance or other similar products from a particular source is not a condition to the provision of, and will not affect the terms of any loan, brokerage of a real estate transaction, or any other services by Yancey Realty, (b) insurance and other similar products are available through insurance agents not associated with Yancey Realty or any of their affiliates, (c) the choice of another insurance provider will not affect a decision relating to, or the terms of, any loan or extension of credit, brokerage of a real estate transaction, or any other services by Yancey Realty or its affiliates and (d) you accept and assume full responsibility for the evaluation, selection, and suitability of insurance or other similar products. Insurance products are offered through Yancey Insurance Agency, an affiliate of Yancey Realty (and administered by Woop Management LLC). Neither Yancey Realty, Woop Management LLC, IPCG Insurance, nor any of their affiliates underwrite any policy of insurance. This site is hosted by Yancey Insurance Agency. Yancey Realty maintains a minority ownership in Yancey Insurance Agency and may benefit from your purchase of insurance. Insurance products may not be available in all states.`
};

export const PARTNER_STRINGS = {
  [PARTNERS.YANCEY]: YANCEY
};
