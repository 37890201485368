import { CONNECT_SUCCESS } from '../../core/actions/canopy';
import { CLEAR_CANOPY } from '../actions/canopy';
import { SET_ERROR } from '../actions/error';

const error = (state = '', action) => {
  switch (action.type) {
    case SET_ERROR: {
      const message = action.payload;
      return message || null;
    }
    case CLEAR_CANOPY:
    case CONNECT_SUCCESS: {
      return null;
    }
    default:
      return state;
  }
};

export default error;
