import { SET_SCENARIO } from '../actions/scenario';
import { updateScenarioQueryParam } from '../utils/url';

const scenario = (state = null, action) => {
  switch (action.type) {
    case SET_SCENARIO:
      updateScenarioQueryParam(action.payload);
      return action.payload || null;
    default:
      return state;
  }
};

export default scenario;
