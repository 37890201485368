import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../css/VisualRadio.css';

const cx = classNames.bind(styles);

const VisualRadio = ({ id, label, sublabel, icon, className, selected, handleClick, disabled }) => {
  return (
    <button
      id={id}
      className={cx('radio', { selected }, className)}
      aria-label={label}
      onClick={handleClick}
      disabled={disabled}
    >
      {icon()}
      <div className={cx('label')}>{label}</div>
      {sublabel && <div className={cx('sublabel')}>{sublabel}</div>}
    </button>
  );
};

export default VisualRadio;

VisualRadio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  sublabel: PropTypes.node,
  icon: PropTypes.func,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};
