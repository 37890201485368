import { EVENTS } from '../../../analytics/constants';
import { intercomExists, openBlog as openIntercomBlog } from '../../../analytics/intercom/utils';
import { trackEvent } from '../../../analytics/utils';

export function openBlog({ id, externalUrl, slug }) {
  const intercom = intercomExists();
  if (intercom && id) {
    openIntercomBlog(id);
  } else window.open(externalUrl, '_blank');
  trackEvent(EVENTS.OPEN_BLOG, {
    id,
    externalUrl,
    slug,
    type: intercom ? 'intercom' : 'external'
  });
}
