import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../css/Headers.css';

const cx = classnames.bind(styles);

const H3 = ({ children, className }) => <h3 className={cx('heading3', className)}>{children}</h3>;

export default H3;

H3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
