import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CONNECT_ROUTE_PAGES } from '../../../../../../router/constants/routes';
import { connectRoute } from '../../../../../../router/utils';
import { getLatestPull } from '../../../../../journey/utils/third-party-data';
import { clearAuthStatus } from '../../../../actions/canopy';
import { verifyCode } from '../../../../api/canopy';
import { MFA_ERROR_MESSAGE } from '../../../../api/canopy/constants';
import * as logger from '../../../../utils/logger';
import Button from '../../../Button';
import ErrorText from '../../../ErrorText';
import H3 from '../../../headers/H3';
import TextField from '../../../TextField';
import ConnectVisual from '../ConnectVisual';
import styles from './styles.css';
import { getText } from './utils';

const MFAInput = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [code, setCode] = useState();
  const [error, setError] = useState(false);
  const [showValidation, setShowValidation] = useState();

  const {
    canopy: { carrier, mfa },
    journey: { thirdPartyData },
    router
  } = useSelector(state => state);

  const carrierName = carrier.name;

  function onSubmit() {
    if (code) {
      const { pullId, pullJwt } = getLatestPull(thirdPartyData);
      verifyCode({ pullId, pullJwt, mfaValue: code })
        .then(() => {
          dispatch(clearAuthStatus());
          history.replace(connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.LOADING));
        })
        .catch(e => {
          if (e?.response?.status !== 400) {
            logger.error(e);
          }
          setError(true);
        });
    } else {
      setShowValidation(true);
    }
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.visualWrapper}>
        <ConnectVisual logoUrl={carrier.logo} />
      </div>
      <H3>Two-step verification</H3>
      <p>{getText(mfa, carrierName)}</p>
      <div className={styles.fields}>
        <TextField
          name={'code'}
          placeholder={'Security Code'}
          label={'Security Code'}
          save={val => setCode(val)}
          isValid={!!code}
          value={code}
          showValidation={showValidation}
        />
      </div>
      {error && <ErrorText className={styles.error}>{MFA_ERROR_MESSAGE}</ErrorText>}
      <Button blockButtonMobile onClick={onSubmit} className={styles.button} useKeyboard>
        Submit
      </Button>
    </section>
  );
};

export default MFAInput;
