import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as logger from '../../core/utils/logger';
import { postVisitSuccess, postVisitFail, POST_VISIT } from '../actions/visit';
import * as api from '../api/visit';
import { ORIGINATION_FIELDS } from '../constants/origination';
import { setOriginationDataField } from '../utils/origination';

function* handlePostVisit() {
  const { id: oid, originatorId, srcAcctId } = yield select(state => state.origination);
  try {
    const data = yield call(api.postVisit, oid, srcAcctId, originatorId);
    logger.log('visit(post) response data:', data);
    setOriginationDataField(ORIGINATION_FIELDS.OID, data.oid);
    yield put(postVisitSuccess(data));
  } catch (error) {
    yield put(postVisitFail(error));
    logger.error(error, { oid, srcAcctId });
  }
}

export function* watchPostVisit() {
  yield takeLatest(POST_VISIT, handlePostVisit);
}
