export const OPEN_STATES = {
  slideOpen: 'slideOpen',
  opened: 'opened'
};

const CLOSED_STATES = {
  slideClose: 'slideClose',
  closed: 'closed'
};

export const expandStates = {
  ...OPEN_STATES,
  ...CLOSED_STATES
};
