import { PROMPT_GROUP_NAMES } from 'woop-shared/prompts/names';
import { PAGE_PATHS } from '../../../router/constants/routes';
import ExcludedDriverCard from '../components/CreateablePage/components/Card/ExcludedDriverCard';
import IncidentCard from '../components/CreateablePage/components/Card/IncidentCard';
import PersonCard from '../components/CreateablePage/components/Card/PersonCard';
import VehicleCard from '../components/CreateablePage/components/Card/VehicleCard';
import CoApplicantInfoText from '../components/CreateablePage/components/InfoText/CoApplicantInfoText';
import ExcludedDriversInfoText from '../components/CreateablePage/components/InfoText/ExcludedDriversInfoText';
import IncidentInfoText from '../components/CreateablePage/components/InfoText/IncidentInfoText';
import PropertyIncidentInfoText from '../components/CreateablePage/components/InfoText/PropertyIncidentText';
import VehicleInfoText from '../components/CreateablePage/components/InfoText/VehiclesInfoText';
import {
  showYourSpouseAutoPage,
  showYourSpousePage,
  showExcludedDriversPage,
  showAutoIncidentsPage,
  showAssignDriversPage,
  showPropertyClaimsPage,
  showPrimaryResidencePage
} from '../utils/pages';

export const PAGE_CONDITIONAL_FUNCTIONS = {
  [PAGE_PATHS.YOUR_SPOUSE_AUTO]: showYourSpouseAutoPage,
  [PAGE_PATHS.YOUR_SPOUSE]: showYourSpousePage,
  [PAGE_PATHS.EXCLUDED_DRIVERS]: showExcludedDriversPage,
  [PAGE_PATHS.AUTO_INCIDENTS]: showAutoIncidentsPage,
  [PAGE_PATHS.ASSIGN_DRIVERS]: showAssignDriversPage,
  [PAGE_PATHS.PROPERTY_CLAIMS]: showPropertyClaimsPage,
  [PAGE_PATHS.PRIMARY_RESIDENCE]: showPrimaryResidencePage
};

export const CREATEABLE_PAGE_CONFIG = {
  [PROMPT_GROUP_NAMES.DRIVERS]: {
    label: 'driver',
    card: PersonCard
  },
  [PROMPT_GROUP_NAMES.EXCLUDED_DRIVERS]: {
    label: 'excluded driver',
    card: ExcludedDriverCard,
    infoText: ExcludedDriversInfoText
  },
  [PROMPT_GROUP_NAMES.VEHICLES]: {
    label: 'vehicle',
    card: VehicleCard,
    infoText: VehicleInfoText
  },
  [PROMPT_GROUP_NAMES.PERSON]: {
    label: 'co-applicant',
    card: PersonCard,
    infoText: CoApplicantInfoText
  },
  [PROMPT_GROUP_NAMES.INCIDENTS]: {
    label: 'incident',
    card: IncidentCard,
    infoText: IncidentInfoText
  },
  [PROMPT_GROUP_NAMES.PROPERTY_INCIDENT]: {
    label: 'claim',
    card: IncidentCard,
    infoText: PropertyIncidentInfoText
  }
};
