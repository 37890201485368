import axios from 'axios';
import { RESOURCES } from '../utils/api';
import * as logger from '../utils/logger';
import { concatUri } from '../utils/url';

const BASE_URI = `${process.env.DATA_URI}${RESOURCES.VEHICLES}/`;
const YEARS_ENDPOINT = 'years';
const MAKES_ENDPOINT = 'makes';
const MODELS_ENDPOINT = 'models';
const DETAILS_ENDPOINT = 'details';

const constructYearsUri = () => concatUri(BASE_URI, YEARS_ENDPOINT);
const constructMakesUri = year => concatUri(BASE_URI, `${MAKES_ENDPOINT}/${year}`);
const constructModelsUri = (year, make) =>
  concatUri(BASE_URI, `${MODELS_ENDPOINT}/${year}/${make}`);
const constructDetailsUri = (year, make, model) =>
  concatUri(BASE_URI, `${DETAILS_ENDPOINT}/${year}/${make}/${model}`);

export const getYears = async () => {
  let response;
  try {
    response = await axios.get(constructYearsUri());
  } catch (err) {
    logger.error(err);
  }
  return response?.data || [];
};

export const getMakes = async year => {
  let response;
  try {
    response = await axios.get(constructMakesUri(year));
  } catch (err) {
    logger.error(err, { year });
  }
  return response?.data || [];
};

export const getModels = async (year, make) => {
  let response;
  try {
    response = await axios.get(constructModelsUri(year, make));
  } catch (err) {
    logger.error(err, { year, make });
  }
  return response?.data || [];
};

export const getDetails = async (year, make, model) => {
  let response;
  try {
    response = await axios.get(constructDetailsUri(year, make, model));
  } catch (err) {
    logger.error(err, { year, make, model });
  }
  return response?.data || [];
};
