// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__input--pVBQQ{position:absolute;bottom:0;border:0;padding:6px 4px;border-bottom:1px solid #ccc;background-color:initial;color:rgb(var(--woop-body-text-color))}input{font-size:16px;width:100%;box-sizing:border-box}input:focus{outline:none}input.styles__hidePlaceholder--mf4vj::placeholder{opacity:0}input.styles__hidePlaceholder--mf4vj:focus::placeholder{opacity:1;color:rgb(var(--woop-placeholder-text-color));transition:.5s}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAIA,sBACE,iBAAkB,CAClB,QAAS,CACT,QAAS,CACT,eAAsB,CACtB,4BAA0C,CAC1C,wBAA6B,CAC7B,sCACF,CAEA,MACE,cAAuB,CACvB,UAAW,CACX,qBACF,CAEA,YACE,YACF,CAEA,kDACE,SACF,CAEA,wDACE,SAAU,CACV,6CAA4B,CAC5B,cACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n$inputPadding: 6px 4px;\n\n.input {\n  position: absolute;\n  bottom: 0;\n  border: 0;\n  padding: $inputPadding;\n  border-bottom: 1px solid $inputBorderColor;\n  background-color: transparent;\n  color: $bodyTextColor;\n}\n\ninput {\n  font-size: $fontDefault;\n  width: 100%;\n  box-sizing: border-box;\n}\n\ninput:focus {\n  outline: none;\n}\n\ninput.hidePlaceholder::placeholder {\n  opacity: 0;\n}\n\ninput.hidePlaceholder:focus::placeholder {\n  opacity: 1;\n  color: $placeholderTextColor;\n  transition: 500ms;\n}\n"]}]);
// Exports
exports.locals = {
	"input": "styles__input--pVBQQ",
	"hidePlaceholder": "styles__hidePlaceholder--mf4vj"
};
module.exports = exports;
