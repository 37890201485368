import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardHeader from '../../../core/components/CardHeader';
import LegalLinks from '../../../core/components/LegalLinks';
import { useSlimApp } from '../../../core/hooks/use-slim-app';
import { PARTNER_STRING_KEYS } from '../../../partners/constants/strings';
import { getPartnerString } from '../../../partners/utils/strings';
import { getStartRoute } from '../../utils';
import GotYouCovered from './components/GotYouCovered';
import HeroCard from './components/HeroCard';
import HowItWorks from './components/HowItWorks';
import NeedHelpWelcome from './components/TitleIconCard/components/NeedHelpWelcome';
import SwitchingIsEasy from './components/TitleIconCard/components/SwitchingIsEasy';
import styles from './styles.css';

/**
 * The default landing page of our application
 *
 * @returns {Function} A react component
 */
const Welcome = () => {
  useSlimApp({ removeOnUnmount: true });
  const {
    journey: { isQuickJourney, thirdPartyData },
    origination: { srcAcctId: accountId }
  } = useSelector(state => state);

  const history = useHistory();

  const respa = getPartnerString(PARTNER_STRING_KEYS.RESPA, accountId);

  function onClick() {
    const nextRoute = getStartRoute(accountId, isQuickJourney, thirdPartyData);
    history.push(nextRoute);
  }

  return (
    <section className={styles.wrapper}>
      <HeroCard onClick={onClick} />
      <section className={styles.contentCard}>
        <CardHeader className={'purple'}>Don&apos;t overpay</CardHeader>
        <p>
          85% of people are overpaying for insurance by not shopping. Don’t pay more than you need
          to. With our simple and intuitive app, it’s never been easier to find the best rate.
        </p>
      </section>
      <section className={styles.contentCard}>
        <HowItWorks />
      </section>
      <GotYouCovered onClick={onClick} />
      <section className={styles.contentCard}>
        <CardHeader className="pink">About us</CardHeader>
        <p>
          Woop Insurance is a tech-forward insurance agency on a mission to save you time and money.{' '}
          <b>
            We believe that finding affordable insurance should be simple and convenient for
            everyone.
          </b>{' '}
          That’s why we created a platform that gives you access to real, binding quotes from dozens
          of the top insurance providers so you’re getting the best possible rates.
        </p>
      </section>
      <SwitchingIsEasy />
      <NeedHelpWelcome />
      <LegalLinks className={styles.legal} />
      {respa && <div className={styles.respa}>{respa}</div>}
    </section>
  );
};

export default Welcome;

Welcome.propTypes = {};
