import React from 'react';
import TitleIconCard from '../..';
import ManReclining from '../../../../../../../core/assets/icons_v2/man-reclining.svg';

const heading = 'Switching is easy.';

const SwitchingIsEasy = () => (
  <TitleIconCard icon={ManReclining} heading={heading}>
    {`Why wait until your policy is up for renewal to start saving? You can switch anytime and we'll make it easy.`}
  </TitleIconCard>
);

export default SwitchingIsEasy;
