import { connect } from 'react-redux';

/* Connects a component to its value within store -> journeyData */
const mapStateToProps = ({ journeyData }, { name, value, promptGroupName, promptGroupIndex }) => {
  let val;
  let isValid;
  if (promptGroupName) {
    const prompt = journeyData?.[promptGroupName]?.[promptGroupIndex]?.[name];
    val = prompt ? prompt.value : value;
    isValid = prompt?.isValid;
  } else {
    val = journeyData[name] ? journeyData[name].value : value;
    isValid = journeyData[name] ? journeyData[name].isValid : isValid;
  }
  return { value: val, isValid };
};

export default connect(mapStateToProps);
