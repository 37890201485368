import axios from 'axios';
import * as logger from '../../core/utils/logger';
import { concatUri } from '../../core/utils/url';

const RESOURCES = {
  QUOTESET: 'bquoteset',
  QUOTE: 'quote'
};

const QUOTESET_URI = concatUri(process.env.QUOTESET_URI, RESOURCES.QUOTESET);
const QUOTE_URI = concatUri(process.env.QUOTESET_URI, RESOURCES.QUOTE);

/**
 * Get a quoteset.
 *
 * @param {object} params
 * @param {string} params.id The quotesetId
 * @returns {object}
 */
export const getQuoteset = async ({ id }) => {
  logger.log('getQuoteset request vars:', id);
  const { data } = await axios.get(concatUri(QUOTESET_URI, id));
  return data;
};

/**
 * Post Quoteset to the server.
 *
 * @param {object} params { journeyData }
 * @param {string} params.journeyId The journey ID.
 * @param {boolean} params.notifyConsumer Flag indicating whether the service should send an email.
 * @param {string} params.context dashboard or quotes
 * @returns {object} postQuoteset response object.
 */
export const postQuoteset = async ({ journeyId, notifyConsumer, context = null }) => {
  const body = {
    journeyId,
    notifyConsumer,
    context
  };
  logger.log('bquoteset(POST) request: ', QUOTESET_URI, body);
  const { data } = await axios.post(QUOTESET_URI, body);
  logger.log('bquoteset(POST) response: ', data);
  return data;
};

/**
 * Get the PDF contents for a quote.
 *
 * @param {string} quoteId
 * @returns {ArrayBuffer} ArrayBuffer with the file contents.
 */
export const downloadPdf = async quoteId => {
  const uri = concatUri(QUOTE_URI, `${quoteId}/download`);
  const { data } = await axios.get(uri, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf'
    }
  });
  return data;
};
