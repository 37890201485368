import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { OTHER_OPTION } from '../../../journeyjson/prompts/constants';
import { warn } from '../utils/logger';
import { hasVal } from '../utils/prompt-validation';
import { mapValueToOption } from '../utils/select';
import SelectField from './SelectField';

const DependentSelect = ({ getOptions, dependentFieldVals, value, keepInitialValue, ...rest }) => {
  const [options, setOptions] = useState(hasVal(value) ? [mapValueToOption(value)] : []);
  const [initiatedValue, setInitiatedValue] = useState();

  useEffect(() => {
    let cancel = false;
    getOptions(dependentFieldVals)
      .then(opts => {
        if (keepInitialValue && value && !initiatedValue) {
          opts.push(mapValueToOption(value));
          setInitiatedValue(true);
        }
        if (!cancel) setOptions(opts);
      })
      .catch(e => {
        warn(`No values found for dependent select: ${JSON.stringify(dependentFieldVals)}.`, { e });
        if (value) return;
        setOptions([mapValueToOption(OTHER_OPTION)]);
        const { save } = rest;
        if (save) save(OTHER_OPTION);
      });

    return () => {
      cancel = true;
    };
  }, [JSON.stringify(dependentFieldVals)]);

  return <SelectField filterValue value={value} options={options} {...rest} />;
};

export default DependentSelect;

DependentSelect.propTypes = {
  value: PropTypes.any,
  dependentFieldVals: PropTypes.object,
  getOptions: PropTypes.func,
  keepInitialValue: PropTypes.bool
};
