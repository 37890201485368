import { combineReducers } from 'redux';
import {
  SET_CARRIER,
  SET_LOGIN_FIELD,
  PULL_SUCCESS,
  SET_MFA_OPTION,
  CLEAR_CANOPY,
  CLEAR_AUTH_STATUS
} from '../actions/canopy';

const carrier = (state = {}, action) => {
  switch (action.type) {
    case SET_CARRIER: {
      return action.payload?.carrier ?? state;
    }
    case CLEAR_CANOPY:
      return {};
    default:
      return state;
  }
};

const username = (state = '', action) => {
  switch (action.type) {
    case SET_LOGIN_FIELD: {
      return action.payload?.username ?? state;
    }
    case CLEAR_CANOPY:
      return '';
    default:
      return state;
  }
};

const password = (state = '', action) => {
  switch (action.type) {
    case SET_LOGIN_FIELD: {
      return action.payload?.password ?? state;
    }
    case CLEAR_CANOPY:
      return '';
    default:
      return state;
  }
};

/* A third authentication field necessary for some carriers */
const thirdField = (state = '', action) => {
  switch (action.type) {
    case SET_LOGIN_FIELD: {
      return action.payload?.thirdField ?? state;
    }
    case CLEAR_CANOPY:
      return '';
    default:
      return state;
  }
};

const status = (state = '', action) => {
  switch (action.type) {
    case PULL_SUCCESS: {
      return action.payload.status ?? state;
    }
    case CLEAR_CANOPY:
    case CLEAR_AUTH_STATUS: {
      return '';
    }
    default:
      return state;
  }
};

/**
 * @param {object} state
 * @param {string} state.email
 * @param {string} state.cellphone
 * @param {string} state.input
 * @param {string} state.inputType
 * @param {string} state.securityQuestion
 * @param {object} state.options
 * @param {object} action
 * @returns {object}
 */
const mfa = (state = {}, action) => {
  switch (action.type) {
    case PULL_SUCCESS: {
      return action.payload.mfa ?? state;
    }
    case CLEAR_CANOPY:
      return {};
    default:
      return state;
  }
};

const mfaOption = (state = '', action) => {
  switch (action.type) {
    case SET_MFA_OPTION: {
      return action.payload ?? state;
    }
    case CLEAR_CANOPY:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  carrier,
  username,
  password,
  thirdField,
  status,
  mfa,
  mfaOption
});
