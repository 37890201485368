import { PROMPT_NAMES, EXPLAINER_NAMES } from 'woop-shared/prompts/names';
import {
  shouldShowPriorAddress,
  isNotFirstDriverPage,
  isFirstDriverPage,
  isMoreThanXYearsAgo
} from '../utils/conditionals';

export const PROMPT_CONDITIONAL_FUNCTIONS = {
  [PROMPT_NAMES.MOVE_IN_DATE]: {
    [PROMPT_NAMES.PRIOR_ADDRESS]: shouldShowPriorAddress
  },
  [PROMPT_NAMES.RELATIONSHIP_TO_INSURED]: {
    [PROMPT_NAMES.RELATIONSHIP_TO_INSURED]: isNotFirstDriverPage
  },
  [EXPLAINER_NAMES.EXPLAIN_PRIMARY_DRIVER]: {
    [EXPLAINER_NAMES.EXPLAIN_PRIMARY_DRIVER]: isFirstDriverPage
  },
  [PROMPT_NAMES.YEAR_BUILT]: {
    [PROMPT_NAMES.YEAR_ELECTRIC_UPDATED]: ({ value }) => isMoreThanXYearsAgo(value, 50),
    [PROMPT_NAMES.ELECTRIC_UPDATE_TYPE]: ({ value }) => isMoreThanXYearsAgo(value, 50),
    [PROMPT_NAMES.YEAR_PLUMBING_UPDATED]: ({ value }) => isMoreThanXYearsAgo(value, 50),
    [PROMPT_NAMES.PLUMBING_UPDATE_TYPE]: ({ value }) => isMoreThanXYearsAgo(value, 50),
    [PROMPT_NAMES.YEAR_HEATING_UPDATED]: ({ value }) => isMoreThanXYearsAgo(value, 20),
    [PROMPT_NAMES.HEATING_UPDATE_TYPE]: ({ value }) => isMoreThanXYearsAgo(value, 20),
    [PROMPT_NAMES.YEAR_ROOF_UPDATED]: ({ value }) => isMoreThanXYearsAgo(value, 5),
    [PROMPT_NAMES.ROOF_UPDATE_TYPE]: ({ value }) => isMoreThanXYearsAgo(value, 5)
  },
  [PROMPT_NAMES.DRIVERS_LICENSE_NUMBER]: {
    [PROMPT_NAMES.DRIVERS_LICENSE_NUMBER]: isFirstDriverPage
  }
};
