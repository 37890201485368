import axios from 'axios';
import { CANOPY_FIELDS } from '../../../journey/utils/third-party-data';
import * as logger from '../../utils/logger';
import { ENDPOINTS } from './constants';

/**
 * Consent and connect to canopy
 *
 * @param {object} body
 * @param {string} body.device_identifier
 * @param {number} body.terms_version
 * @param {string} body.consent_language
 * @param {string} body.public_alias
 * @param {string} body.insurerName
 * @param {string} body.insurerUsername
 * @param {string} body.insurerPassword
 * @param {string} body.insurerThirdField
 * @returns {object} Response object. { pull_id, pull_jwt }
 */
export async function consentAndConnect(body) {
  const { data } = await axios.post(ENDPOINTS.CONSENT_AND_CONNECT, body);
  return {
    [CANOPY_FIELDS.PULL_ID]: data.pull_id,
    [CANOPY_FIELDS.PULL_JWT]: data.pull_jwt
  };
}

/*
 * Fetch list of supported carriers.
 *
 * @returns {Promise<object>}
 */
export async function getCarriers() {
  try {
    const { data } = await axios.get(ENDPOINTS.CARRIERS);
    return data;
  } catch (e) {
    logger.error(e);
  }
}

export async function getTos() {
  const { data } = await axios.get(ENDPOINTS.TOS);
  return data;
}

/**
 * Fetch the status of a pull.
 *
 * @param {{ pullId: string, pullJwt: string }} { pullId, pullJwt }
 * @returns {Promise<{ status: string, errorMessage: string, mfa: object }>}
 */
export async function getPull({ pullId, pullJwt }) {
  try {
    const { data } = await axios.get(`${ENDPOINTS.PULL}/${pullId}`, {
      headers: {
        'pull-jwt': pullJwt
      }
    });

    return {
      status: data.status,
      errorMessage: data.login_error_message,
      mfa: {
        email: data.mfa_email,
        cellphone: data.mfa_cellphone,
        input: data.mfa_input,
        inputType: data.mfa_input_type,
        securityQuestion: data.mfa_security_question,
        options: data.mfa_options
      }
    };
  } catch (e) {
    logger.error(e);
  }
}

/**
 * Submit an IDV Option for a pull.
 *
 * @param {{ pullId: string, pullJwt: string, optionKey: string }} { pullId, pullJwt, optionKey }
 * @returns {object} Response object { pullId }
 */
export async function submitIdvOption({ pullId, pullJwt, optionKey }) {
  const { data } = await axios.post(
    `${ENDPOINTS.PULL}/${pullId}/idvoptions`,
    {
      optionKey
    },
    {
      headers: {
        'pull-jwt': pullJwt
      }
    }
  );
  return {
    [CANOPY_FIELDS.PULL_ID]: data.pull_id
  };
}

export function verifyCode({ pullId, pullJwt, mfaValue }) {
  const endpoint = `${ENDPOINTS.PULL}/${pullId}/idv`;
  return axios.post(endpoint, { mfaValue }, { headers: { 'pull-jwt': pullJwt } });
}
