import PropTypes from 'prop-types';
import React from 'react';
import WithValueSave from '../enhancers/WithValueSave';
import SelectField from './SelectField';

const SelectWithSave = WithValueSave(SelectField);

const DriverSelect = ({ name, driverOptions, ...rest }) => {
  return (
    <SelectWithSave
      name={name}
      options={driverOptions}
      filterValue
      placeholder="Select Driver"
      {...rest}
    />
  );
};

export default DriverSelect;

DriverSelect.propTypes = {
  name: PropTypes.string.isRequired,
  driverOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};
