import { PROMPT_NAMES } from 'woop-shared/prompts/names';

export const SENSITIVE_PROMPT_NAMES = [PROMPT_NAMES.TAX_ID, PROMPT_NAMES.DRIVERS_LICENSE_NUMBER];

export const EVENTS = {
  COMPLETE_JOURNEY: 'COMPLETE_JOURNEY',
  COMPLETE_BINDING: 'COMPLETE_BINDING',
  COMPLETE_ESTIMATE: 'COMPLETE_ESTIMATE',
  COMPLETE_SIGN_UP: 'COMPLETE_SIGN_UP',
  ANSWER_PROMPT: 'ANSWER_PROMPT',
  FORM_VALIDATION_ERROR: 'FORM_VALIDATION_ERROR',
  TRIGGERED_VIN_WARNING_MODAL: 'TRIGGERED_VIN_WARNING_MODAL',
  CONTINUED_VIN_WARNING_MODAL: 'CONTINUED_VIN_WARNING_MODAL',
  VIEW_SIDEBAR: 'VIEW_SIDEBAR',
  VIEW_PAGE: 'VIEW_PAGE',
  SAVE_JOURNEY: 'SAVE_JOURNEY',
  RESUME_JOURNEY: 'RESUME_JOURNEY',
  OPEN_COMPARE_TABLE: 'OPEN_COMPARE_TABLE',
  CLICK_TEXT_ME_AUTH: 'CLICK_TEXT_ME_AUTH',
  CLICK_EMAIL_AGAIN_AUTH: 'CLICK_EMAIL_AGAIN_AUTH',
  CONNECT_CANOPY: 'CONNECT_CANOPY',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  CONTINUE_TO_CROSS_SELL: 'CONTINUE_TO_CROSS_SELL',
  SKIP_CROSS_SELL: 'SKIP_CROSS_SELL',
  CLICK_PRIMARY_CTA: 'CLICK_PRIMARY_CTA',
  CLICK_SECONDARY_CTA: 'CLICK_SECONDARY_CTA',
  CLICK_EXTERNAL_LINK: 'CLICK_EXTERNAL_LINK',
  ANSWER_PICKER: 'ANSWER_PICKER',
  OPEN_BLOG: 'OPEN_BLOG',
  SELECTED_CONNECT: 'SELECTED_CONNECT',
  SELECTED_UPLOAD: 'SELECTED_UPLOAD',
  CANOPY_ERROR: 'CANOPY_ERROR',
  CANOPY_SEARCH: 'CANOPY_SEARCH',
  CANOPY_SELECTED_CARRIER: 'CANOPY_SELECTED_CARRIER',
  CANOPY_SWITCH_TO_UPLOAD: 'CANOPY_SWITCH_TO_UPLOAD'
};

/**
 * A list of prompt names we save as user properties
 *
 */
export const TRACKED_PROMPTS = new Set([
  PROMPT_NAMES.FIRST_NAME,
  PROMPT_NAMES.LAST_NAME,
  PROMPT_NAMES.EMAIL,
  PROMPT_NAMES.PHONE,
  PROMPT_NAMES.COVERAGE_TYPE,
  PROMPT_NAMES.DOB
]);

/**
 * A list of prompt names we save as user properties in Mixpanel.
 * Values prefixed with a "$" are built-in Mixpanel properties.
 *
 */
export const MIXPANEL_PROPERTIES = {
  name: '$name',
  [PROMPT_NAMES.FIRST_NAME]: '$first_name',
  [PROMPT_NAMES.LAST_NAME]: '$last_name',
  [PROMPT_NAMES.EMAIL]: '$email',
  [PROMPT_NAMES.PHONE]: '$phone'
};

export const KLAVIYO_PROPERTIES = {
  ORIGINATION_ID: 'origination_id',
  ACCOUNT_ID: 'account_id',
  ACCOUNT_NAME: 'account_name',
  FULL_ADDRESS: 'full_address',
  ADDRESS_1: 'address_1',
  CITY: 'city',
  REGION: 'region',
  ZIP: 'zip'
};
