import { combineReducers } from 'redux';
import { GET_QUOTESET_SUCCESS } from '../actions';

const firstName = (state = '', action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.consumerInfo?.firstName || state;
    default:
      return state;
  }
};

const lastName = (state = '', action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.consumerInfo?.lastName || state;
    default:
      return state;
  }
};

const address = (state = {}, action) => {
  switch (action.type) {
    case GET_QUOTESET_SUCCESS:
      return action.payload?.consumerInfo?.address || state;
    default:
      return state;
  }
};

export default combineReducers({
  firstName,
  lastName,
  address
});
