import { replace } from 'connected-react-router';
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { logConnectCanopy } from '../../../analytics/actions';
import { EVENTS } from '../../../analytics/constants';
import { trackEvent } from '../../../analytics/utils';
import { CONNECT_ROUTE_PAGES } from '../../../router/constants/routes';
import { connectRoute } from '../../../router/utils';
import * as logger from '../../core/utils/logger';
import { maybeSendProofEmail } from '../../journey/pages/ConnectPage/utils';
import { getLatestPull } from '../../journey/utils/third-party-data';
import {
  clearAuthStatus,
  CONNECT,
  connectSuccess,
  pullSuccess,
  POLL_FOR_PULL,
  pollForPull,
  SET_MFA_OPTION,
  CONNECT_COMPLETE
} from '../actions/canopy';
import { setError } from '../actions/error';
import { addToLoading, rmFromLoading } from '../actions/loading';
import { sendProof } from '../actions/origination';
import { setUser } from '../actions/user';
import * as api from '../api/canopy';
import {
  CONSENT_LANGUAGE,
  DEFAULT_ERROR_MESSAGE,
  ERROR_STATUSES,
  PULL_STATUSES
} from '../api/canopy/constants';
import { postPull, postUser } from '../api/users';
import { getNextRoute } from '../components/CanopyModal/components/LoadingScreen/utils';
import { LINKS } from '../constants/canopy';
import { QUERY_PARAMS } from '../constants/url';
import { isDemo, isProd } from '../utils/config';
import { getVal } from '../utils/journey-data';
import { getQueryStringParam } from '../utils/url';

function* handleConnectInsurance(action) {
  yield put(addToLoading(action.type));

  const { termsVersion } = action.payload;
  const {
    canopy: { username, password, thirdField, carrier },
    origination: { id: oid },
    router
  } = yield select();

  try {
    const body = {
      deviceIdentifier: oid,
      termsVersion,
      consentLanguage: CONSENT_LANGUAGE,
      publicAlias: isProd() ? LINKS.PROD : isDemo() ? LINKS.DEMO : LINKS.SANDBOX,
      insurerName: carrier?.carrier_id,
      insurerUsername: username,
      insurerPassword: password,
      insurerThirdField: thirdField
    };
    const response = yield call(api.consentAndConnect, body);
    if (!response) {
      const state = yield select();
      // Tracking issue https://sentry.io/organizations/woop/issues/2947617208/?environment=prod

      logger.warn('No pull information from /consentAndConnect', { response, body, state });
    }
    yield put(connectSuccess(response));
    yield put(clearAuthStatus());
    yield put(replace(connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.LOADING)));
  } catch (error) {
    trackEvent(EVENTS.CANOPY_ERROR, { type: 'Login' });

    yield put(setError(DEFAULT_ERROR_MESSAGE));
    logger.error(error);
  }
  yield put(rmFromLoading(action.type));
}

export function* watchConnectInsurance() {
  yield takeLatest(CONNECT, handleConnectInsurance);
}

const POLL_INTERVAL_MS = 1500;

function* handlePollForPull(action) {
  const { stopStatus, interval } = action.payload || {};
  yield put(addToLoading(action.type));

  const {
    journey: { thirdPartyData },
    canopy: { mfaOption },
    router
  } = yield select();

  // Get active pull
  const latestPull = getLatestPull(thirdPartyData);

  // Poll canopy API
  let polling = true;
  while (polling) {
    try {
      const pull = yield call(api.getPull, latestPull);

      yield put(pullSuccess(pull));

      // Maybe set error
      if (ERROR_STATUSES.includes(pull.status)) {
        const errorMessage = pull.errorMessage || DEFAULT_ERROR_MESSAGE;
        trackEvent(EVENTS.CANOPY_ERROR, {
          type: mfaOption ? 'MFA' : 'Login',
          message: errorMessage
        });
        yield put(setError(errorMessage));
      }

      // If status maps to a route or we stop status matches, stop polling
      if (
        (!stopStatus && getNextRoute(pull.status, router.location.pathname)) ||
        stopStatus === pull.status
      ) {
        polling = false;
      }
    } catch (error) {
      yield put(setError(DEFAULT_ERROR_MESSAGE));
      trackEvent(EVENTS.CANOPY_ERROR, { type: mfaOption ? 'MFA' : 'Login' });
      logger.error(error);
    }
    if (polling) {
      yield delay(interval || POLL_INTERVAL_MS);
    }
  }

  yield put(rmFromLoading(action.type));
}

export function* watchPollForPull() {
  yield takeLatest(POLL_FOR_PULL, handlePollForPull);
}

function* handleSetMfaOption(action) {
  yield put(addToLoading(action.type));

  try {
    const {
      journey: { thirdPartyData }
    } = yield select();
    const { pullId, pullJwt } = getLatestPull(thirdPartyData);
    const optionKey = action.payload;
    yield call(api.submitIdvOption, { pullId, pullJwt, optionKey });
    // Start polling for updated status
    yield put(pollForPull({ stopStatus: PULL_STATUSES.IDENTITY_VERIFICATION, interval: 200 }));
  } catch (e) {
    logger.error(e);
  }
  yield put(rmFromLoading(action.type));
}

export function* watchForSetMfaOption() {
  yield takeLatest(SET_MFA_OPTION, handleSetMfaOption);
}

function* handleConnectComplete() {
  const {
    journey: { thirdPartyData }
  } = yield select();
  const { pullId } = getLatestPull(thirdPartyData);
  if (!pullId) return;

  yield put(logConnectCanopy({ pullId }));

  const uid = yield call(maybeCreateUser);
  if (!uid) return;

  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
  if (maybeSendProofEmail(scenario)) {
    yield put(sendProof(uid));
  }

  yield call(postPull, { uid, pullId });
}

export function* watchConnectComplete() {
  yield takeLatest(CONNECT_COMPLETE, handleConnectComplete);
}

function* maybeCreateUser() {
  const {
    journeyData,
    journey: { journeyId },
    origination: { id: oid }
  } = yield select();

  const firstName = getVal(journeyData[PROMPT_NAMES.FIRST_NAME]);
  const lastName = getVal(journeyData[PROMPT_NAMES.LAST_NAME]);
  const email = getVal(journeyData[PROMPT_NAMES.EMAIL]);
  const phone = getVal(journeyData[PROMPT_NAMES.PHONE]);
  const dob = getVal(journeyData[PROMPT_NAMES.DOB]);
  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);

  if (email || phone) {
    try {
      const { id, exists } = yield call(postUser, {
        firstName,
        lastName,
        dob,
        email,
        phone,
        oid,
        journeyId,
        scenario
      });
      yield put(setUser({ id, exists }));
      return id;
    } catch (e) {
      logger.error(e);
    }
  }

  return null;
}
