import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import chubb from '../../core/assets/carrier_logos/chubb.png';
import hartford from '../../core/assets/carrier_logos/hartford.png';
import hippo from '../../core/assets/carrier_logos/hippo.png';
import natgen from '../../core/assets/carrier_logos/natgen.png';
import nationwide from '../../core/assets/carrier_logos/nationwide.png';
import prock from '../../core/assets/carrier_logos/prock.png';
import progressive from '../../core/assets/carrier_logos/progressive.png';
import safeco from '../../core/assets/carrier_logos/safeco.png';
import stateauto from '../../core/assets/carrier_logos/stateauto.png';
import stillwater from '../../core/assets/carrier_logos/stillwater.png';
import travelers from '../../core/assets/carrier_logos/travelers.png';
import velocity from '../../core/assets/carrier_logos/velocity.png';
import styles from '../css/CarrierLogos.css';

const cx = classnames.bind(styles);

const CARRIERS = [
  [
    { name: 'Travelers', color: '#D81D23', logo: travelers },
    { name: 'Progressive', color: '#288EDC', logo: progressive },
    { name: 'Nationwide', color: '#002EF6', logo: nationwide }
  ],
  [
    { name: 'State Auto', color: '#006AAE', logo: stateauto },
    { name: 'The Hartford', color: '#9A3439', logo: hartford },
    { name: 'Chubb', color: '#696663', logo: chubb }
  ],
  [
    { name: 'Stillwater', color: '#F45923', logo: stillwater },
    { name: 'Plymouth Rock', color: '#0A89C7', logo: prock },
    { name: 'Hippo', color: '#2AD67D', logo: hippo }
  ],
  [
    { name: 'National General', color: '#F2873A', logo: natgen },
    { name: 'Safeco', color: '#18173C', logo: safeco },
    { name: 'Velocity', color: '#84FCC3', logo: velocity }
  ]
];

const SCROLL_INTERVAL = 5000;

const CarrierLogos = ({ hideLabel }) => {
  const [slideInIndex, setSlideInIndex] = useState(0);
  const [slideAwayIndex, setSlideAwayIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const lastSlideIndex = CARRIERS.length - 1;
      if (slideInIndex === lastSlideIndex) {
        setSlideAwayIndex(slideInIndex);
        setSlideInIndex(0);
        return;
      }
      setSlideAwayIndex(slideInIndex);
      setSlideInIndex(slideInIndex + 1);
    }, SCROLL_INTERVAL);
    return () => clearInterval(interval);
  }, [slideInIndex]);

  return (
    <div className={styles.wrapper}>
      {!hideLabel && (
        <div className={styles.label}>Compare prices from top insurance companies</div>
      )}
      <div className={styles.carriers}>
        {CARRIERS.map((group, index) => {
          const shouldSlideIn = slideInIndex === index;
          const shouldSlideAway = slideAwayIndex === index;
          return (
            <div
              key={index}
              className={cx({ slideIn: shouldSlideIn, slideAway: shouldSlideAway }, 'carrierGroup')}
            >
              {group.map(carrier => (
                <div
                  key={carrier.name}
                  style={{ borderColor: carrier.color }}
                  className={cx('carrier')}
                >
                  <img
                    src={carrier.logo}
                    alt={carrier.name}
                    className={cx('logoImg', {
                      addTop: carrier.name === 'Progressive' || carrier.name === 'State Auto'
                    })}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CarrierLogos;

CarrierLogos.propTypes = {
  hideLabel: PropTypes.bool
};
