// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__radio-group--19s9s{display:flex;flex-wrap:wrap}.styles__radio-group--19s9s p{animation:fadeIn .5s ease,growHeight .75s ease}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,4BACE,YAAa,CACb,cAMF,CAJI,8BACE,8CACF","file":"styles.css","sourcesContent":["@import '../../css/variables.css';\n\n.radio-group {\n  display: flex;\n  flex-wrap: wrap;\n  p {\n    :global {\n      animation: fadeIn 500ms ease, growHeight 750ms ease;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"radio-group": "styles__radio-group--19s9s"
};
module.exports = exports;
