import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ArrowDown from '../assets/icons/arrow-down.svg';
import styles from '../css/ScrollHintButton.css';
import { useTimeout } from '../hooks/use-timeout';

const cx = classnames.bind(styles);

// Instead of scrolling the entire height of the scrollableElement, we leave some padding at the top.
// This is the number of px to leave at the top.
const SCROLL_OFFSET_TOP = 150;

// Instead of scrolling to the very bottom of the scrollableElement, we leave some padding at the bottom.
// This is the number of px from the bottom that we can consider "the bottom."
const DEFAULT_SCROLL_OFFSET_BOTTOM = 40;

const ScrollHintButton = ({
  className,
  scrollableElement,
  bottomOffset = DEFAULT_SCROLL_OFFSET_BOTTOM,
  recalc
}) => {
  const [hide, setHide] = useState(true);
  const setTimer = useTimeout();

  const onChange = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollableElement;
    const top = scrollTop + clientHeight;

    if (top + bottomOffset >= scrollHeight) setHide(true);
    else setHide(false);
  };

  const onChangeTimeout = () => {
    setTimer(() => {
      onChange();
    }, 150);
  };

  useEffect(() => {
    onChange();
    scrollableElement.addEventListener('scroll', onChange);
    window.addEventListener('resize', onChange);
    return () => {
      scrollableElement.removeEventListener('scroll', onChange);
      window.removeEventListener('resize', onChange);
    };
  }, []);

  useEffect(() => {
    onChangeTimeout();
  }, [recalc]);

  const onClick = () => {
    const { clientHeight, scrollTop } = scrollableElement;
    const scrollHeight = clientHeight - SCROLL_OFFSET_TOP;
    const newScroll = scrollTop + scrollHeight;
    scrollableElement.scrollTo({
      top: newScroll,
      behavior: 'smooth'
    });
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx('wrapper', className, { hide })}
      style={{ bottom: bottomOffset }}
    >
      <ArrowDown className={cx('arrow')} />
    </button>
  );
};

export default ScrollHintButton;

ScrollHintButton.propTypes = {
  className: PropTypes.string,
  scrollableElement: PropTypes.object,
  bottomOffset: PropTypes.number,
  recalc: PropTypes.any
};
