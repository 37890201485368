import { QUOTE_FIELDS } from 'woop-shared/db/models';
import { PAYMENT_PLANS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import {
  getPaidInFullPremium,
  getBundledPrice,
  getCheapestPaymentPlan
} from 'woop-shared/quotes/utils';
import { addFees } from './fees';
import { getNumOfPayments } from './premium';

/**
 * Sort quotes by premium (lowest to highest).
 *
 * @param {Array} quotes Array of formatted quotes.
 * @returns {Array} quotes
 */
export function sortQuotes(quotes) {
  return [...quotes].sort((a, b) => (a?.comparePrice || 0) - (b?.comparePrice || 0));
}

/**
 * Sort additional coverages alphabetically according to their type.
 *
 * @param {Array<object>} additionalCoverages An array of additional coverage objects.
 * @returns {Array}
 */
export function sortAdditionalCoverages(additionalCoverages) {
  return [...additionalCoverages].sort((a, b) => {
    if (a?.type === b?.type) return 0;
    return a?.type > b?.type ? 1 : -1;
  });
}

/**
 * Format Bundle
 * - Calculate paidInFull price for bundle
 * - Calculate estimated monthly price for bundle
 * - Calculate savings
 *
 * @param {object[]} bundles
 * @param {object[]} currentPolicies
 * @returns {object[]} formatted bundles
 */
export function formatBundledQuotes(bundles, currentPolicies) {
  return bundles.map(bundle => {
    const quotes = formatQuotes(bundle.quotes, currentPolicies);
    const savings = quotes.map(quote => quote.savings).filter(Boolean);

    return {
      ...bundle,
      quotes,
      coverageTag: quotes.find(q => !!q[QUOTE_FIELDS.COVERAGE_TAG])?.[QUOTE_FIELDS.COVERAGE_TAG],
      paidInFull: getBundledPrice(quotes),
      monthly: getEstimatedMonthlyForBundle(quotes),
      savings: savings.reduce((a, b) => a + b, 0),
      recommended: quotes.some(q => q[QUOTE_FIELDS.RECOMMENDED])
    };
  });
}

/**
 * Sort bundles according to their paidInFull price.
 *
 * @param {Array} bundles array of bundle objects.
 * @returns {Array} sorted bundles.
 */
export function sortBundles(bundles) {
  return [...bundles].sort((a, b) => (a?.paidInFull || 0) - (b?.paidInFull || 0));
}

/**
 * Format quotes for presentation.
  - Sort the premiums.
  - Sort additional coverages
  - Maybe calculate savings
  - Add a comparePrice.
 *
 * @param {Array} quotes Array of quote objects.
 * @param {Array} currentPolicies
 * @returns {Array} Array of formatted quote objects.
 */
export function formatQuotes(quotes, currentPolicies) {
  return quotes.map(q => {
    const quote = {
      ...q,
      premiums: sortPremiums(q[QUOTE_FIELDS.PREMIUMS]),
      additionalCoverages: sortAdditionalCoverages(q[QUOTE_FIELDS.ADDITIONAL_COVERAGES] || [])
    };

    const paidInFullPremium = getPaidInFullPremium(q[QUOTE_FIELDS.PREMIUMS]);

    // Maybe calculate savings
    const currentPolicy = currentPolicies?.find(
      policy => policy[QUOTE_FIELDS.COVERAGE_TYPE] === quote[QUOTE_FIELDS.COVERAGE_TYPE]
    );
    if (currentPolicy) {
      quote.savings = calcSavings(
        paidInFullPremium,
        getPaidInFullPremium(currentPolicy[QUOTE_FIELDS.PREMIUMS])
      );
    }

    quote.comparePrice = calcAnnualPremium(paidInFullPremium);

    return quote;
  });
}

/**
 * Calculates the annual premium of two premium objects and returns the non-negative difference in price
 *
 * @param {object} newPremium a premium object
 * @param {object} currentPremium a premium object
 * @returns {number}
 */
function calcSavings(newPremium, currentPremium) {
  return calcAnnualPremium(currentPremium) - calcAnnualPremium(newPremium);
}

/**
 * Sort premiums by billing cycle, ascending
 *
 * @param {Array} premiums An array of premium objects.
 * @returns {Array}
 */
export function sortPremiums(premiums) {
  return [...premiums].sort((a, b) => {
    const factorA = getNumOfPayments(a);
    const factorB = getNumOfPayments(b);
    return factorA - factorB; // Ascending order
  });
}

/**
 * Calculate the annual premium for a given premium object.
 *
 * @param {object} premium A premium object.
 * @param {number} fee A fee.
 * @param {boolean} isPaidInFull Whether this premium is the paid-in-full premium.
 * @returns {number} The most expensive premium object, by price.
 */
export function calcAnnualPremium(premium, fee, isPaidInFull) {
  const premiumWithFees = addFees(premium?.[PREMIUM_FIELDS.AMOUNT], fee, isPaidInFull);
  const numOfPayments = getNumOfPayments(premium);
  return parseFloat(numOfPayments * premiumWithFees);
}

/**
 * Return the sum of quote estimated monthly premiums.
 *
 * @param {Array} quotes
 * @returns {number} paid in full amount for the set of quotes
 */
export function getEstimatedMonthlyForBundle(quotes) {
  const premiumAmounts = quotes.map(quote => {
    const premiums = quote[QUOTE_FIELDS.PREMIUMS];
    const premium =
      getCheapestPaymentPlan(premiums, PAYMENT_PLANS.MONTHLY) || getPaidInFullPremium(premiums);
    const amount = (premium?.[PREMIUM_FIELDS.AMOUNT] * getNumOfPayments(premium)) / 12;
    return amount;
  });
  return premiumAmounts?.reduce((sum, premiumAmount) => premiumAmount + sum, 0);
}
