import { QUERY_PARAMS } from '../constants/url';
import * as logger from './logger';

/**
 * Return the query string portion of a URL (everything after the initial `?`).
 *
 * @param {string} url
 * @returns {string} Query string w/ initial `?`, e.g. ?key=var.
 */
export function getQueryStringFromURL(url) {
  const queryStringIndex = typeof url === 'string' && url.indexOf('?');
  if (queryStringIndex > -1) return url.substring(queryStringIndex);
}

/**
 * Concat strings with exactly one / between them.
 *
 * @param {string} args A series of string arguments
 * @returns {string}
 */
export function concatUri(...args) {
  return args.map(a => removeLeadingSlash(removeTrailingSlash(a))).join('/');
}

/**
 * Add a slash after a string if there isn't one.
 *
 * @param {string} str The string to add a slash to.
 * @returns {string}
 */
function removeTrailingSlash(str) {
  if (typeof str !== 'string') return '';
  if (str[str.length - 1] === '/') return str.slice(0, -1);
  return str;
}

/**
 * Remove a slash at the beginning of a string.
 *
 * @param {string} str The string to remove a slash from.
 * @returns {string}
 */
function removeLeadingSlash(str) {
  if (typeof str !== 'string') return '';
  if (str[0] === '/') return str.substring(1);
  return str;
}

/**
 * Return the key's value in the query string.
 *
 * @param {string} key a key to look for in the query string.
 * @returns {string} the key's value.
 */
export function getQueryStringParam(key) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
}

export function removeJourneyIdQueryParam() {
  const queryParams = new URLSearchParams(window.location.search);
  const hash = window.location.hash;

  queryParams.delete(QUERY_PARAMS.JOURNEY_ID);

  const queryString = queryParams.toString();
  window.history.replaceState(null, document.title, `/?${queryString}${hash}`);
}

/**
 * Update the scenario URL query parameter with the given value
 *
 * @param {string} scenario the new scenario
 */
export function updateScenarioQueryParam(scenario) {
  const queryParams = new URLSearchParams(window.location.search);
  const hash = window.location.hash;

  scenario
    ? queryParams.set(QUERY_PARAMS.SCENARIO, scenario)
    : queryParams.delete(QUERY_PARAMS.SCENARIO);

  const queryString = queryParams.toString();
  window.history.replaceState(null, document.title, `/?${queryString}${hash}`);
}

/**
 * B64 decode and JSON parse the string.
 *
 * @param {string} data A b64 encoded, JSON stringified object.
 * @returns {object}
 */
export function decodeUrlData(data) {
  if (data) {
    try {
      return JSON.parse(atob(data));
    } catch (e) {
      logger.error(e, { data });
    }
  }
}
