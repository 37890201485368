// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__wrapper--35OfQ{display:flex;flex-direction:column;padding:20px}.styles__wrapper--35OfQ h3{margin-bottom:20px}.styles__wrapper--35OfQ .styles__listTitle--1wddf{margin-bottom:10px;font-weight:500}.styles__wrapper--35OfQ .styles__li--1znbU{font-weight:400;margin-bottom:5px;white-space:nowrap}.styles__wrapper--35OfQ .styles__info--1z9uy,.styles__wrapper--35OfQ .styles__li--1znbU{font-size:14px}.styles__wrapper--35OfQ .styles__box--1agJj{margin-top:25px;width:100%}.styles__wrapper--35OfQ .styles__button--2qS3o{display:flex;align-self:center;margin-top:20px;padding:20px 30px;width:200px}", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":"AAEA,wBACE,YAAa,CACb,qBAAsB,CACtB,YAkCF,CAhCE,2BACE,kBACF,CAEA,kDACE,kBAAmB,CACnB,eACF,CAEA,2CACE,eAAwB,CACxB,iBAAkB,CAElB,kBACF,CAEA,wFAJE,cAMF,CAEA,4CACE,eAAgB,CAChB,UACF,CAEA,+CACE,YAAa,CACb,iBAAkB,CAClB,eAAgB,CAChB,iBAAkB,CAClB,WACF","file":"styles.css","sourcesContent":["@import '../../../../../core/css/variables.css';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n\n  h3 {\n    margin-bottom: 20px;\n  }\n\n  .listTitle {\n    margin-bottom: 10px;\n    font-weight: $fw_medium;\n  }\n\n  .li {\n    font-weight: $fw_regular;\n    margin-bottom: 5px;\n    font-size: $fontS;\n    white-space: nowrap;\n  }\n\n  .info {\n    font-size: $fontS;\n  }\n\n  .box {\n    margin-top: 25px;\n    width: 100%;\n  }\n\n  .button {\n    display: flex;\n    align-self: center;\n    margin-top: 20px;\n    padding: 20px 30px;\n    width: 200px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--35OfQ",
	"listTitle": "styles__listTitle--1wddf",
	"li": "styles__li--1znbU",
	"info": "styles__info--1z9uy",
	"box": "styles__box--1agJj",
	"button": "styles__button--2qS3o"
};
module.exports = exports;
