import PropTypes from 'prop-types';
import React from 'react';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import InfoText from '../../../../../core/components/InfoText';
import { getVal } from '../../../../../core/utils/journey-data';

const CoApplicantInfoText = ({ journeyData }) => {
  const coverageTypes = getVal(journeyData[PROMPT_NAMES.COVERAGE_TYPE]);
  const propertyCoverageType = coverageTypes.find(c =>
    [COVERAGE_TYPES.CONDO, COVERAGE_TYPES.HOME, COVERAGE_TYPES.RENTERS].includes(c)
  );
  const text = `Please add any co-applicants for your ${
    propertyCoverageType?.toLowerCase() || ''
  } insurance.`;

  return <InfoText className="bar" text={text} />;
};

export default CoApplicantInfoText;

CoApplicantInfoText.propTypes = {
  journeyData: PropTypes.object
};
