import amplitude from 'amplitude-js';
import { QUERY_PARAMS } from '../../modules/core/constants/url';
import { getQueryStringParam } from '../../modules/core/utils/url';
import { AMPLITUDE_EVENTS } from './events';

/* Safe wrapper for amplitude's logEvent fn */
export function logEvent(...args) {
  amplitude.getInstance().logEvent(...args);
}

export function getDeviceId() {
  return amplitude.getInstance().options?.deviceId;
}

export function setUserProperties(data) {
  amplitude.getInstance().setUserProperties(data);
}

function onExitPage() {
  amplitude.getInstance().logEvent(AMPLITUDE_EVENTS.END_WOOP);
}

export function initAmplitude(originationData) {
  if (process.env.AMPLITUDE_API_KEY && !window.amplitude) {
    amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY, null, {
      onExitPage,
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
      batchEvents: true,
      eventUploadPeriodMillis: 2000
    });
    const campaign = getQueryStringParam(QUERY_PARAMS.CAMPAIGN);
    const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);
    setUserProperties({ campaign, scenario, ...originationData });
  }
}
