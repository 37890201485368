import { LOCATION_CHANGE } from 'connected-react-router';
import { takeLatest } from 'redux-saga/effects';
import { appScrollToTop, isLargeViewport } from '../../core/utils/browser';

const useSmooth = isLargeViewport();

function* scrollToTop() {
  appScrollToTop(useSmooth);
  yield;
}

export function* watchLocationChangeForScrolling() {
  yield takeLatest(LOCATION_CHANGE, scrollToTop);
}
