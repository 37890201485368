import { PAYMENT_METHODS } from 'woop-shared/quotes/enums';
import { isCardValid } from '../../core/utils/credit-card';
import {
  hasVal,
  isValidExpirationDate,
  isValidCvv,
  isValidRoutingNumber,
  isValidAccountNumber,
  isValidAccountNumberConfirm,
  hasComposite
} from '../../core/utils/prompt-validation';

export const CARD_FIELDS = {
  NAME_ON_CARD: {
    key: 'nameOnCard',
    label: 'Name on Card',
    validator: hasVal
  },
  CARD_NUMBER: {
    key: 'cardNumber',
    label: 'Card Number',
    validator: isCardValid
  },
  CARD_EXPIRATION_DATE: {
    key: 'cardExpirationDate',
    label: 'Expiration Date',
    placeholder: 'MM/YY',
    validator: isValidExpirationDate
  },
  CARD_CVV: {
    key: 'cardCvv',
    label: 'CVV',
    validator: isValidCvv
  },
  BILLING_ADDRESS: {
    key: 'billingAddress',
    label: 'Billing Address',
    validator: hasComposite
  }
};

export const ACH_FIELDS = {
  BANK_NAME: {
    key: 'bankName',
    label: 'Bank Name',
    validator: hasVal
  },
  ACCOUNT_NUMBER: {
    key: 'accountNumber',
    label: 'Checking Account Number',
    validator: isValidAccountNumber
  },
  ACCOUNT_NUMBER_CONFIRM: {
    key: 'accountNumberConfirm',
    label: 'Checking Account Number (Confirm)',
    validator: isValidAccountNumberConfirm
  },
  ROUTING_NUMBER: {
    key: 'routingNumber',
    label: 'Routing Number',
    validator: isValidRoutingNumber
  }
};

export const ESCROW_FIELDS = {
  MORTGAGE_COMPANY_NAME: {
    key: 'mortgageCompanyName',
    label: 'Mortgage Company Name',
    validator: hasVal
  },
  MORTGAGE_COMPANY_CITY: {
    key: 'mortgageCompanyCity',
    label: 'Mortgage Company City',
    validator: hasVal
  },
  MORTGAGE_COMPANY_STATE: {
    key: 'mortgageCompanyState',
    label: 'Mortgage Company State',
    validator: hasVal
  },
  LOAN_NUMBER: {
    key: 'loanNumber',
    label: 'Loan Number',
    validator: hasVal
  }
};

export const DEFAULT_PAYMENT_FIELDS = {
  METHOD: {
    key: 'paymentMethod',
    options: [
      { label: 'Card', icon: 'creditCard', value: PAYMENT_METHODS.CARD },
      { label: 'Escrow', icon: 'loan', value: PAYMENT_METHODS.ESCROW },
      { label: 'ACH', icon: 'bankTransfer', value: PAYMENT_METHODS.ACH }
    ],
    prompt: 'How would you like to pay?',
    validator: hasVal
  },
  PLAN: {
    key: 'paymentPlan',
    validator: hasVal
  }
};

export const PAYMENT_FIELDS = {
  ...DEFAULT_PAYMENT_FIELDS,
  ...CARD_FIELDS,
  ...ACH_FIELDS,
  ...ESCROW_FIELDS
};

export const BINDING_FIELDS = {
  AGREE_TO_DOCS: {
    key: 'agreeToDocs',
    validator: Boolean
  }
};
