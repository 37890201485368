/**
 * Take an array of values and return a format consumable by the react-select field.
 *
 * @param {Array} values an array of values
 * @returns {Array} data in react-select option format { "value": value, "label": label } || empty array
 */
export function formatOptions(values) {
  return Array.isArray(values)
    ? values.map(val => ({
        value: val,
        label: val
      }))
    : [];
}

/**
 * Maps a value to a React-Select option
 *
 * @param {string} value address
 * @returns {object|null} React-Select option or null
 */
export function mapValueToOption(value) {
  return value ? { value, label: value } : null;
}

export function saveHandler(data, save) {
  if (Array.isArray(data)) return save(data.map(d => d.value)); // multi-selects
  if (data) return save(data.value); // normal selects
  return save(data); // blank it out
}
