// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Spinner__wrapper--I0Nvb{position:absolute;left:calc(50% - 62.5px);top:calc(50% - 62.5px)}.Spinner__wrapper--I0Nvb{animation:fadeIn 1s}@media (max-width:550px){.Spinner__wrapper--I0Nvb{position:fixed}}.Spinner__icon--1kmqo{fill:rgb(var(--woop-primary-color-darker));height:125px;width:125px;animation:Spinner__spin--3StM5 2.3s infinite}@keyframes Spinner__spin--3StM5{to{transform:rotate(1turn)}}", "",{"version":3,"sources":["Spinner.css"],"names":[],"mappings":"AAIA,yBACE,iBAAkB,CAClB,uBAAiC,CACjC,sBASF,CAPE,yBACE,mBACF,CAEA,yBATF,yBAUI,cAEJ,CADE,CAGF,sBACE,0CAA4B,CAC5B,YAAmB,CACnB,WAAkB,CAClB,4CACF,CAEA,gCACE,GACE,uBACF,CACF","file":"Spinner.css","sourcesContent":["@import './variables.css';\n\n$dimensions: 125px;\n\n.wrapper {\n  position: absolute;\n  left: calc(50% - $dimensions / 2);\n  top: calc(50% - $dimensions / 2);\n\n  :global {\n    animation: fadeIn 1s;\n  }\n\n  @media (--small-viewport) {\n    position: fixed;\n  }\n}\n\n.icon {\n  fill: $appPrimaryColorDarker;\n  height: $dimensions;\n  width: $dimensions;\n  animation: spin 2.3s infinite;\n}\n\n@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "Spinner__wrapper--I0Nvb",
	"icon": "Spinner__icon--1kmqo",
	"spin": "Spinner__spin--3StM5"
};
module.exports = exports;
