import produce from 'immer';
import { SET_SELECTED_QUOTES } from '../actions';

const INITIAL_STATE = [];

const selectedQuotes = produce((draft, action) => {
  switch (action.type) {
    case SET_SELECTED_QUOTES:
      return action.payload;
  }
}, INITIAL_STATE);

export default selectedQuotes;
