import { SCROLL_TO_PROMPT } from '../actions/scroll-to';

const scrollTo = (state = '', action) => {
  switch (action.type) {
    case SCROLL_TO_PROMPT: {
      const name = action.payload || '';
      return name;
    }
    default:
      return state;
  }
};

export default scrollTo;
