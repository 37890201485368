import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { stripNonDigits, formatInt } from 'woop-shared/quotes/utils';
import BaseTextField from './BaseTextField';

const NumberField = ({
  name,
  isValid,
  label,
  prompt,
  placeholder,
  maxLength,
  autoComplete,
  showValidation,
  ...rest
}) => {
  const { value = '', save } = rest;
  const [formattedValue, setFormattedValue] = useState();

  useEffect(() => setFormattedValue(formatInt(value), [value]));

  const handleInput = e => {
    const cleanedVal = stripNonDigits(e.target.value);
    if (maxLength && cleanedVal.length > maxLength) return;
    save(cleanedVal);
  };

  return (
    <BaseTextField
      type="text"
      name={name}
      label={label}
      value={formattedValue}
      isValid={isValid}
      placeholder={placeholder}
      prompt={prompt}
      onChange={handleInput}
      autoComplete={autoComplete}
      showValidation={showValidation}
      inputMode="numeric"
    />
  );
};

export default NumberField;

NumberField.propTypes = {
  name: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  showValidation: PropTypes.bool
};
