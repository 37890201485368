import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../css/ErrorText.css';

const cx = classnames.bind(styles);

const ErrorText = ({ children, className, alt }) => (
  <p className={cx('wrapper', className, { alt })}>{children}</p>
);

export default ErrorText;

ErrorText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  alt: PropTypes.bool
};
