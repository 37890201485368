import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PromptWrapper from '../../../journey/containers/PromptWrapper';
import { DEFAULT_VALIDATION_MESSAGE } from '../../constants/validation';
import WithFloatingLabel from '../../enhancers/WithFloatingLabel';
import { hasVal } from '../../utils/prompt-validation';
import Prompt from '../Prompt';
import styles from './styles.css';

const cx = classnames.bind(styles);

// eslint-disable-next-line react/display-name
const BaseTextField = React.forwardRef(
  (
    {
      name,
      isValid,
      prompt,
      label,
      value,
      showValidation,
      validationText = DEFAULT_VALIDATION_MESSAGE,
      onBlur,
      tooltip,
      ...rest
    },
    ref
  ) => {
    const [showValidity, setShowValidity] = useState(showValidation);
    const [focus, setFocus] = useState(false);
    const hasValue = hasVal(value);
    return (
      <PromptWrapper name={name}>
        <Prompt text={prompt} tooltip={tooltip} />
        <WithFloatingLabel
          label={label}
          hasValue={hasValue}
          invalid={!isValid && (showValidation || (showValidity && hasValue))}
          hasFocus={focus}
          validationText={validationText}
        >
          <input
            ref={ref}
            type="text"
            name={name}
            className={cx('input', { hidePlaceholder: !!label })}
            value={value}
            onBlur={() => {
              setShowValidity(true);
              setFocus(false);
              onBlur && onBlur();
            }}
            onFocus={() => setFocus(true)}
            {...rest}
          />
        </WithFloatingLabel>
      </PromptWrapper>
    );
  }
);

export default BaseTextField;

BaseTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  prompt: PropTypes.string,
  isValid: PropTypes.bool,
  showValidation: PropTypes.bool,
  validationText: PropTypes.string,
  onBlur: PropTypes.func,
  tooltip: PropTypes.string
};
