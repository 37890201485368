import { PROMPT_FORMATTING } from '../constants/prompt-formatting-mapping';

/**
 * Run a prompt through a formatting function.
 *
 * @param {string} name Name of a prompt.
 * @param {*} value Value of the prompt.
 * @returns {*} The formatted value.
 */
export function formatPrompt(name, value) {
  // If there's no formatter, return the raw value.
  if (typeof PROMPT_FORMATTING[name] !== 'function') return value;

  // Otherwise return the formatted value.
  return PROMPT_FORMATTING[name](value);
}

export function formatCoverageType(covType) {
  if (!covType) return null;
  const coverageType = Array.isArray(covType) ? covType : [covType];
  const coverageTypes = coverageType.map(type => type.toUpperCase());
  return coverageTypes;
}

export function formatAdditionalDriverUsage(usage) {
  if (!usage) return [];
  return Array.isArray(usage) ? usage : [usage];
}
