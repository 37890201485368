import { createInstance, enums } from '@optimizely/react-sdk';
import { ORIGINATION_FIELDS } from '../../modules/core/constants/origination';
import { QUERY_PARAMS } from '../../modules/core/constants/url';
import { getOid, getOriginationData } from '../../modules/core/utils/origination';
import { getQueryStringParam } from '../../modules/core/utils/url';
import { USER_FIELDS } from './constants';
import { optimizelyLogger, onDecision, generateAnonymousUserId, updateUserId } from './utils';

/**
 * Initialize optimizely and return the optimizely SDK.
 *
 * @see https://docs.developers.optimizely.com/rollouts/docs/reactsdkclient
 * @returns {object} optimizely ReactSDKClient
 */
export const initOptimizely = () => {
  // Init the SDK.
  const optimizely = createInstance({
    sdkKey: process.env.OPTIMIZELY_SDK_KEY,
    logger: { log: optimizelyLogger }
  });

  const origination = getOriginationData();
  const userId = getOid() || generateAnonymousUserId();
  const campaign = getQueryStringParam(QUERY_PARAMS.CAMPAIGN);

  // Set up the user.
  optimizely.setUser({
    [USER_FIELDS.ID]: userId,
    attributes: {
      [USER_FIELDS.SOURCE_ACCOUNT_ID]: origination[ORIGINATION_FIELDS.ACCOUNT_ID] || null,
      [ORIGINATION_FIELDS.ORIGINATOR_ID]: origination[ORIGINATION_FIELDS.ORIGINATOR_ID] || null,
      [USER_FIELDS.ENVIRONMENT]: process.env.ENV,
      campaign
    }
  });

  // Listen for the decision notification.
  optimizely.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    onDecision
  );

  optimizely.onReady().then(() => {
    window.optimizely = optimizely;
    const oid = getOid();
    updateUserId(oid);
  });

  return optimizely;
};
