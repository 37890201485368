import axios from 'axios';
import { QUERY_PARAMS } from '../../core/constants/url';
import { getJourneyUri } from '../../core/utils/api';
import * as logger from '../../core/utils/logger';
import { concatUri, getQueryStringParam } from '../../core/utils/url';

const constructSaveUri = journeyId => {
  const SAVE_ENDPOINT = 'save';
  return concatUri(concatUri(getJourneyUri(), journeyId), SAVE_ENDPOINT);
};

const constructResumeUri = journeyId => {
  const RESUME_ENDPOINT = 'resume';
  return concatUri(concatUri(getJourneyUri(), journeyId), RESUME_ENDPOINT);
};

const constructSendResumeEmailUri = journeyId => {
  const SEND_RESUME_EMAIL_ENDPOINT = 'sendResumeEmail';
  return concatUri(concatUri(getJourneyUri(), journeyId), SEND_RESUME_EMAIL_ENDPOINT);
};

export const postJourney = async ({ visitId, oid, scenario, campaign }) => {
  logger.log('journey(post) request data: ', visitId, oid);

  const oidInUrl = getQueryStringParam(QUERY_PARAMS.OID);

  const body = {
    originationId: oid,
    visitId,
    prefill: !!oidInUrl,
    scenario: scenario || '', // handle swagger null
    campaign: campaign || ''
  };

  const { data } = await axios.post(getJourneyUri(), body);

  return {
    journeyId: data?.journeyId,
    uiData: data?.uiData
  };
};

/**
 * Send a PATCH request to the journey service.
 *
 * @param {object} obj
 * @param {string} obj.journeyId
 * @param {object} obj.journeyData Flattened journey data object.
 * @param {object} obj.thirdPartyData ThirdPartyData object.
 * @param {boolean} obj.isQuickJourney
 * @param {string} obj.originationId
 * @param {string} obj.scenario
 * @param obj.campaign
 * @returns {Array} Array of submodules.
 */
export const patchJourney = async ({
  journeyId,
  journeyData,
  thirdPartyData,
  isQuickJourney,
  originationId,
  scenario,
  campaign
}) => {
  const body = {
    uiData: journeyData,
    thirdParty: thirdPartyData,
    isQuickJourney: isQuickJourney || false, // Prevent swagger null issues, default behavior
    originationId,
    scenario: scenario || '', // handle swagger null ffs
    campaign: campaign || ''
  };
  const uri = concatUri(getJourneyUri(), journeyId);
  const { data } = await axios.patch(uri, body);
  return data;
};

/**
 * Save the journey.
 *
 * @param {object} obj
 * @param {string} obj.journeyId
 * @param {object} obj.uiData Flattened journeyData object.
 * @returns {object}
 */
export const saveJourney = async ({ journeyId, uiData }) => {
  const uri = constructSaveUri(journeyId);
  const body = { uiData };
  const { data } = await axios.patch(uri, body);
  return data;
};

/**
 * Send a resume email request.
 *
 * @param {string} journeyId
 * @returns {object}
 */
export const sendResumeEmail = async journeyId => {
  const uri = constructSendResumeEmailUri(journeyId);
  const { data } = await axios.put(uri);
  return data;
};

/**
 * Resume the journey.
 *
 * @param {object} obj
 * @param {string} obj.journeyId
 * @param {string} obj.oid
 * @returns {object} Response object.
 */
export const resumeJourney = async ({ journeyId, oid }) => {
  const uri = constructResumeUri(journeyId);
  const { data } = await axios.get(uri, { params: { oid } });
  return data;
};
